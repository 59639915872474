import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import {
  contactName,
  formatPhoneNumber,
} from 'client-lib/src/lib/utils/helpers';
import {
  Heading2,
  Text,
  Heading5,
  Button,
  IconButton,
  SquareBadge,
  Loading,
} from '../../../elements';
import THEMES from '../../../styles/themes/app';
import {
  ColumnContainer,
  InnerWrap,
  HeaderCloseSection,
  TeamHeadWrap,
  SubHeaderSection,
  TeamInfoWrapper,
  GroupBadgesSection,
  BadgesWrapper,
  TextWrap,
  LoadingWrapper,
} from './CustomerInfo';

const customTitleStyle = () => `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TeamMemberSlideout = ({
  closeCustomerInfo,
  teamMemberData,
  styledTheme,
  sectionItemCustomStyle,
  teamMemberGroupsNames,
  startContactThread,
  loading,
  isCurrentUser,
}) => (
  <ColumnContainer>
    {loading ? (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    ) : (
      <InnerWrap>
        <HeaderCloseSection>
          <IconButton
            noOutline
            contrast="high"
            size="lg"
            onClick={closeCustomerInfo}
          >
            <i className="ri-close-line" />
          </IconButton>
        </HeaderCloseSection>
        <TeamHeadWrap>
          <Heading2 contrast="high">{contactName(teamMemberData)}</Heading2>
          {teamMemberData?.title && (
            <TextWrap>
              <Text customStyle={customTitleStyle}>{teamMemberData.title}</Text>
            </TextWrap>
          )}
        </TeamHeadWrap>
        <SubHeaderSection
          backgroundColor={THEMES.BACKGROUND_TERTIARY({
            theme: styledTheme,
          })}
          slimVerticalPadding
        >
          <div>
            <Button
              onClick={() => startContactThread(teamMemberData)}
              dataTestId="contact-info-message-button"
              disabled={isCurrentUser}
            >
              {i18n.t('slideouts-CustomerInfo-message')}
            </Button>
          </div>
        </SubHeaderSection>
        <TeamInfoWrapper styledTheme={styledTheme}>
          <Heading5 contrast="high">
            {i18n.t('slideouts-CustomerInfo-phone')}
          </Heading5>
          <Text contrast="med" customStyle={sectionItemCustomStyle}>
            {formatPhoneNumber(teamMemberData.phoneNumber)}
          </Text>
        </TeamInfoWrapper>
        <TeamInfoWrapper styledTheme={styledTheme}>
          <Heading5 contrast="high">
            {i18n.t('slideouts-CustomerInfo-email')}
          </Heading5>
          <Text contrast="med" customStyle={sectionItemCustomStyle}>
            {teamMemberData.username}
          </Text>
        </TeamInfoWrapper>
        <GroupBadgesSection>
          <Heading5 contrast="high">
            {i18n.t('slideouts-CustomerInfo-groups')}
          </Heading5>
          <BadgesWrapper>
            {teamMemberGroupsNames.map((name, i) => (
              <SquareBadge key={name + i}>{name}</SquareBadge>
            ))}
          </BadgesWrapper>
        </GroupBadgesSection>
      </InnerWrap>
    )}
  </ColumnContainer>
);

TeamMemberSlideout.propTypes = {
  closeCustomerInfo: PropTypes.func.isRequired,
  teamMemberData: PropTypes.object.isRequired,
  styledTheme: PropTypes.object.isRequired,
  sectionItemCustomStyle: PropTypes.func.isRequired,
  startContactThread: PropTypes.func.isRequired,
  teamMemberGroupsNames: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  isCurrentUser: PropTypes.bool.isRequired,
};

export default TeamMemberSlideout;
