import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from 'styled-theming';
import THEMES from '../../styles/themes/app';
import BUTTON_THEMES from '../../styles/themes/library/button';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.backgroundColor || THEMES.BACKGROUND_QUATERNARY};
  cursor: pointer;
  overflow: hidden;
  max-width: 180px;
  min-width: 180px;
  max-height: 56px;
  min-height: 56px;
  border-radius: 6px;
  box-shadow: 0px 4px 4px 0px #0000001a;
  :hover {
    background-color: ${(props) =>
      !props.disabled && THEMES.BACKGROUND_SECONDARY};
  }
  ${(props) => props.disabled && `opacity: 40%; cursor: initial;`}
`;

const TEXT_LINEHEIGHT = theme('fontSize', {
  default: '20px',
  large: '22px',
});

const Text = styled.div`
  margin: 0;
  color: ${(props) =>
    props.textColor || props?.textColor?.(props) || THEMES.FOREGROUND_HIGH};
  font-size: ${FONTSIZE_THEMES.TEXT};
  font-family: 'Barlow', sans-serif;
  font-weight: 600;
  line-height: ${TEXT_LINEHEIGHT};
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 156px;
`;

const SUBTEXT_LINEHEIGHT = theme('fontSize', {
  default: '17px',
  large: '19px',
});

const Subtext = styled.span`
  display: block;
  margin: 0;
  color: ${(props) =>
    props?.highlightSubtext
      ? BUTTON_THEMES.LINK_TEXT
      : props.textColor ||
        props?.textColor?.(props) ||
        THEMES.FOREGROUND_MED(props)};
  font-size: ${FONTSIZE_THEMES.METATEXT};
  font-family: 'Barlow', sans-serif;
  font-weight: ${(props) => (props?.highlightSubtext ? '700' : 'initial')};
  line-height: ${SUBTEXT_LINEHEIGHT};
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 156px;
`;

const ClickableCard = ({
  // normal props
  maintext,
  subtext,
  backgroundColor,
  maintextColor,
  subtextColor,
  onClick,
  disabled,
  highlightSubtext,

  // custom styles
  customContainerStyle,
  customMaintextStyle,
  customSubtextStyle,

  // data-testid
  dataTestId,
}) => {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef(null);

  const handleOnClick = (e) => {
    onClick?.(e);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { threshold: 1 }
    );
    observer.observe(ref?.current);
    return () => observer.disconnect();
  }, []);

  return (
    <CardWrapper
      ref={ref}
      onClick={isIntersecting ? onClick && handleOnClick : null}
      backgroundColor={backgroundColor}
      customContainerStyle={customContainerStyle}
      css={(props) => props?.customContainerStyle?.(props)}
      data-testid={dataTestId}
      disabled={!isIntersecting}
    >
      <Text
        marginBottom={subtext}
        textColor={disabled ? THEMES.FOREGROUND_LOW : maintextColor}
        customMaintextStyle={customMaintextStyle}
        css={(props) => props?.customMaintextStyle?.(props)}
      >
        {maintext}
      </Text>
      {subtext && (
        <Subtext
          textColor={disabled ? THEMES.FOREGROUND_LOW : subtextColor}
          highlightSubtext={highlightSubtext}
          customSubtextStyle={customSubtextStyle}
          css={(props) => props?.customSubtextStyle?.(props)}
        >
          {subtext}
        </Subtext>
      )}
    </CardWrapper>
  );
};

ClickableCard.propTypes = {
  maintext: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  subtext: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  maintextColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  subtextColor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onClick: PropTypes.func,
  customContainerStyle: PropTypes.func,
  customMaintextStyle: PropTypes.func,
  customSubtextStyle: PropTypes.func,
  customTextContainerStyle: PropTypes.func,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  highlightSubtext: PropTypes.bool,
};

ClickableCard.defaultProps = {
  subtext: null,
  backgroundColor: null,
  maintextColor: null,
  subtextColor: null,
  onClick: null,
  customContainerStyle: null,
  customMaintextStyle: null,
  customSubtextStyle: null,
  customTextContainerStyle: null,
  dataTestId: null,
  disabled: false,
  highlightSubtext: false,
};

ClickableCard.displayName = 'ClickableCard';

export default ClickableCard;
