import {
  gmailRegex,
  frenchGmailRegex,
  spanishGmailRegex,
  outlookRegex,
  blockquoteRegex,
  divAppendOnSendRegex,
  boldFromSentRegex,
} from '../regex';

const useTruncateEmail = (text) => {
  const isMatchInMessage =
    text?.match(gmailRegex) ||
    text?.match(frenchGmailRegex) ||
    text?.match(spanishGmailRegex) ||
    text?.match(outlookRegex) ||
    text?.match(blockquoteRegex) ||
    text?.match(divAppendOnSendRegex) ||
    text?.match(boldFromSentRegex);

  const truncTextIndex = isMatchInMessage?.index;
  const truncText = text?.slice(0, truncTextIndex);
  const removedText = text?.slice(truncTextIndex, text?.length);

  return {
    original: text,
    truncated: truncText,
    removed: removedText,
    isTruncationMatch: !!isMatchInMessage,
  };
};

export default useTruncateEmail;
