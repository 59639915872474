import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient, useQuery } from '@apollo/client';
import { ANNOUNCEMENT_REACHABLE_AUDIENCE_COUNT_QUERY } from 'client-lib';
import debounce from 'lodash/debounce';
import GroupMessageTemplate from '../GroupMessageTemplate';
import SaveAndExit from './subComponents/SaveAndExit';
import OverviewItem from './subComponents/OverviewItem';
import { PageError } from './GroupMessageContent';
import { Loading, Modal } from '../../../../elements';
import SelectTime from '../../../../elements/Select/SelectTime';
import Calendar from '../../../../elements/Calendar/Calendar';
import {
  openSnackbar,
  setAnnouncementStatus,
  triggerRefetchAnnouncement,
  triggerRefetchBroadcast,
} from '../../../../actions/general';
import MessageSubTitle from './subComponents/MessageSubTitle';
import RecipientSubTitle from './subComponents/RecipientSubTitle';
import AnnouncementPermissionCheck from './subComponents/AnnouncementPermissionCheck';
import { ANNOUNCEMENT_TYPES } from '../GroupMessageWizard';
import GroupMessageOverviewDetails from './subComponents/GroupMessageOverviewDetails';
import GroupMessageOverviewName from './subComponents/GroupMessageOverviewName';
import Flyout from '../../../Flyout2/Flyout';
import THEMES from '../../../../styles/themes/app';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 850px;
  max-width: 85%;
  position: relative;
  padding-top: 10px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -40px;
  height: 90%;
`;

const DeliveryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
`;

const CalendarWrapper = styled.div`
  padding: 16px;
  border: 2px solid ${THEMES.BACKGROUND_SECONDARY};
`;

const SelectTimeContainer = styled.div`
  width: 100%;
  padding-left: 16px;
  padding-top: 40px;
  #select-container {
    width: 100%;
  }
`;

const getStartTime = () => {
  const startTime = moment();
  const remainder = 15 - (startTime.minute() % 15);
  const roundedStartTime = moment(startTime).add(remainder, 'minutes');
  return {
    startTime: roundedStartTime,
    hour: roundedStartTime.hour(),
    minute: roundedStartTime.minute(),
  };
};

const GroupMessageOverviewCombined = ({
  wizardState,
  wizardStateSetters,
  closeWizard,
  setWizardPage,
  mutations,
  wizardGlobalProps,
}) => {
  const client = useApolloClient();
  const { data, loading, error } = wizardGlobalProps.announcementQuery;
  const { sendLoading } = wizardGlobalProps.sendAnnouncement;
  const { announcementId, announcementType } = wizardState;
  const { setAnnouncementId, setAnnouncementType } = wizardStateSetters;
  const {
    createAnnouncementMutation,
    updateAnnouncementMutation,
    sendAnnouncementMutation,
  } = mutations;

  const timezone = moment.tz.zone(moment.tz.guess()).abbr(moment());
  const dispatch = useDispatch();
  const locale = useSelector((state) => state?.session?.currentUser?.language);

  const [hasPermission, togglePermission] = useState(false);
  const [pageError, setPageError] = useState('');
  const [messageError, setMessageError] = useState('');
  const GENERIC_ERR = i18n.t('slideouts-GroupMessageName-genericError');
  const BLANK_ERR = i18n.t('slideouts-GroupMessageName-blankError');

  const [openDeliveryModal, setOpenDeliveryModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [dateTime, setDateTime] = useState(getStartTime().startTime);
  const [selectedHour, setSelectedHour] = useState(getStartTime().hour);
  const [selectedMin, setSelectedMin] = useState(getStartTime().minute);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [deliveryDateTime, setDeliveryDateTime] = useState();
  const [deliveryError, setDeliveryError] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [isVisited, setIsVisited] = useState(false);
  const [messageName, setMessageName] = useState(
    data?.announcement?.announcement?.subject ?? ''
  );
  const [outboundChannelId, setOutboundChannelId] = useState(
    data?.announcement?.announcement?.outboundChannel?.id ??
      wizardGlobalProps?.channelsData?.channels[0]?.id
  );

  // selected audience data
  // Data for Selected Audience counts
  const { data: selectedAudienceCount, refetch } = useQuery(
    ANNOUNCEMENT_REACHABLE_AUDIENCE_COUNT_QUERY,
    {
      client,
      variables: {
        announcementId,
      },
    }
  );

  const text = data?.announcement?.announcement?.text;
  const link = data?.announcement?.announcement?.link;
  const attachment = data?.announcement?.announcement?.attachments?.[0];
  const sendStartedAt = data?.announcement?.announcement.sendStartedAt;
  const dueAt = data?.announcement?.announcement?.dueAt;
  const targetAllContacts = data?.announcement?.announcement?.targetAllContacts;

  const reachableAudienceCount =
    selectedAudienceCount?.reachableAudienceCount?.count;

  const defaultChannelOption = wizardGlobalProps?.channelsData?.channels[0]?.id;
  const controlledOutboundChannelId = outboundChannelId || defaultChannelOption;

  const recipientsAndMessageComplete =
    (reachableAudienceCount || targetAllContacts) &&
    (text?.length || attachment?.originalFilename);

  const renderAnnouncementPermissionCheck = () => {
    if ((reachableAudienceCount || targetAllContacts) && (text || attachment)) {
      return (
        <AnnouncementPermissionCheck
          dueAt={dueAt}
          hasPermission={hasPermission}
          togglePermission={togglePermission}
          hideCheckbox={!!sendStartedAt}
        />
      );
    }

    return null;
  };

  const handleTimeChange = (value, updatedValueName) => {
    if (updatedValueName === 'hour') {
      setSelectedHour(value);
    }
    if (updatedValueName === 'minute') {
      setSelectedMin(value);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleOnSchedule = async () => {
    if (dateTime.isAfter()) {
      setOpenDeliveryModal(false);
      setSubmitting(true);
      setDeliveryDateTime(dateTime);

      const updateVars = {};

      if (!outboundChannelId) {
        updateVars.outboundChannelId = controlledOutboundChannelId;
      }

      const utcDateTime = moment.utc(dateTime).format();

      updateVars.dueAt = utcDateTime;

      const onSuccess = (willCloseWizard) => {
        if (willCloseWizard) {
          dispatch(
            openSnackbar(
              i18n.t('broadcasts-success-message', {
                defaultValue: 'Broadcast scheduled successfully',
              })
            )
          );
          dispatch(setAnnouncementStatus('SCHEDULED'));
          closeWizard('saved');
        }
      };

      const hasBeenSent = !!dueAt;

      // send updateAnnouncement mutation
      const data = await updateAnnouncement(updateVars, () =>
        onSuccess(hasBeenSent)
      );
      setSubmitting(false);
      // send sendAnnouncement mutation
      if (!data?.updateAnnouncement?.errors && !hasBeenSent) {
        await sendAnnouncement(false);
      }
    } else {
      setDeliveryError(i18n.t('slideouts-GroupMessageOverview-deliveryError'));
    }
  };

  useEffect(() => {
    setMessageName(data?.announcement?.announcement?.subject);
  }, [data?.announcement?.announcement?.subject]);

  const handleConfirmCancelAnnouncement = async () => {
    const data = await updateAnnouncement(
      { dueAt: null },
      closeWizard('saved')
    );
    setOpenCancelModal(!openCancelModal);

    if (data?.updateAnnouncement?.errors) {
      setPageError(GENERIC_ERR);
    } else {
      dispatch(triggerRefetchAnnouncement(true));
      setDeliveryDateTime();
      dispatch(setAnnouncementStatus('DRAFT'));
      dispatch(
        openSnackbar(i18n.t('slideouts-GroupMessageOverview-cancelSnackbar'))
      );
    }
  };

  useEffect(() => {
    const newDateTime = moment(selectedDate);
    newDateTime.hour(selectedHour);
    newDateTime.minute(selectedMin);
    if (newDateTime.isBefore()) {
      setDeliveryError(i18n.t('slideouts-GroupMessageOverview-deliveryError'));
    } else {
      setDateTime(newDateTime);
      setDeliveryError('');
    }
  }, [selectedHour, selectedMin, selectedDate]);

  useEffect(() => {
    if (!outboundChannelId && !data) {
      return;
    }

    if (!outboundChannelId) {
      if (data?.announcement?.announcement?.outboundChannel?.id) {
        setOutboundChannelId(
          data?.announcement?.announcement?.outboundChannel?.id
        );
      } else {
        setPageError(
          i18n.t('slideouts-GroupMessageOverview-noBroadcastLine', {
            defaultValue:
              'You do not have access to an Announcement Line, please contact a manager or admin to obtain access to an announcement line.',
          })
        );
      }
    }
  }, [outboundChannelId, data]);

  useEffect(() => {
    if (messageName?.length > 255) {
      setMessageError(
        i18n.t('broadcasts-maximum-characterError', {
          defaultValue: "You've exceeded the 255 maximum character limit.",
        })
      );
    } else if (messageName?.length === 0 && isVisited) {
      setMessageError(
        i18n.t('broadcasts-name-validation', {
          defaultValue: 'Broadcast message name is required',
        })
      );
    } else {
      setMessageError('');
    }
  }, [messageName]);

  useEffect(() => {
    refetch();
  }, [data?.announcement?.announcement]);

  useEffect(() => {
    if (data?.announcement?.announcement?.id && !announcementId) {
      setAnnouncementId(data?.announcement?.announcement?.id);
    }
  }, [data?.announcement?.announcement, announcementId]);

  const updateAnnouncement = async (args, onSuccess) => {
    const { data } = await updateAnnouncementMutation({
      variables: {
        input: {
          id: announcementId,
          ...args,
        },
      },
    }).catch((e) => {
      console.error(e);
      setPageError(GENERIC_ERR);
    });

    const errors = data?.updateAnnouncement?.errors;
    if (errors) {
      if (errors[0].reason.includes('blank')) setMessageError(BLANK_ERR);
      else setPageError(GENERIC_ERR);
    } else {
      onSuccess?.();
    }

    return data;
  };

  const sendAnnouncement = debounce(
    async (sendToTab) => {
      const { data } = await sendAnnouncementMutation({
        variables: {
          id: announcementId,
        },
      }).catch((e) => {
        console.error(e);
        setPageError(GENERIC_ERR);
      });
      if (data?.sendAnnouncement?.errors) {
        setPageError(GENERIC_ERR);
      } else {
        const translatedMessage = sendToTab
          ? i18n.t('broadcasts-sentSuccess-message', {
              defaultValue: 'Broadcast Sent Successfully',
            })
          : i18n.t('broadcasts-success-message', {
              defaultValue: 'Broadcast scheduled successfully',
            });

        dispatch(openSnackbar(translatedMessage));
        dispatch(triggerRefetchBroadcast(true));
        dispatch(setAnnouncementStatus(sendToTab ? 'SENT' : 'SCHEDULED'));
        closeWizard('saved');
      }
    },
    1000,
    { leading: true }
  );

  const handleUpdateAndSendAnnouncement = async () => {
    setSubmitting(true);
    if (!outboundChannelId) {
      const data = await updateAnnouncement({
        outboundChannelId: controlledOutboundChannelId,
      });
      if (data?.updateAnnouncement?.errors) {
        setSubmitting(false);
        return;
      }
    }
    setSubmitting(false);
    dispatch(triggerRefetchBroadcast(true));
    await sendAnnouncement(true);
  };

  useEffect(() => {
    if (error) {
      setPageError(GENERIC_ERR);
      console.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (dueAt) {
      const date = moment(dueAt);
      setSelectedDate(date.toDate());
      setSelectedHour(date.hour());
      setSelectedMin(date.minute());
    }
  }, [dueAt]);

  useEffect(() => {
    dispatch(triggerRefetchBroadcast(false));
  }, []);

  useEffect(() => {
    if (
      (data?.announcement?.announcement.type && !announcementType) ||
      (data?.announcement?.announcement.type &&
        data?.announcement?.announcement.type !== announcementType)
    ) {
      setAnnouncementType(data?.announcement?.announcement.type);
    }
  }, [data?.announcement?.announcement, announcementType]);

  const isMilitaryTime = (locale) => {
    const clockType = Intl.DateTimeFormat(locale, {
      hour: 'numeric',
    }).resolvedOptions().hourCycle;
    if (clockType === 'h23') {
      return true;
    }
    return false;
  };

  const upsertBTM = async (page) => {
    if (!announcementId) {
      const { data } = await createAnnouncementMutation({
        variables: {
          input: {
            subject: messageName,
            outboundChannelId,
            type: announcementType,
          },
        },
      }).catch((e) => {
        console.error(e);
        setMessageError(GENERIC_ERR);
        setPageError(GENERIC_ERR);
      });

      const errors = data?.createAnnouncement?.errors;
      if (errors) {
        if (errors[0].reason.includes('blank')) setMessageError(BLANK_ERR);
        else setPageError(GENERIC_ERR);
      } else if (data?.createAnnouncement?.announcement?.id) {
        setAnnouncementId(data.createAnnouncement.announcement.id);
        if (page) setWizardPage(page);
      }
      return errors;
    } else if (messageName !== data?.announcement?.announcement?.subject) {
      const data = await updateAnnouncement({ subject: messageName });
      const errors = data?.updateAnnouncement?.errors;
      if (errors) {
        if (errors[0].reason.includes('blank')) setMessageError(BLANK_ERR);
        else setPageError(GENERIC_ERR);
      } else if (page) setWizardPage(page);
      return errors;
    } else if (page) {
      setWizardPage(page);
    }
    return null;
  };

  const handleSaveAndExit = async () => {
    try {
      if ((messageName || announcementId) && outboundChannelId) {
        const errs = await upsertBTM();
        if (!errs) {
          dispatch(
            openSnackbar(
              i18n.t('slideouts-GroupMessageAudience-successfullySaved')
            )
          );
          closeWizard('saved');
        }
      } else {
        closeWizard('nosave');
      }
    } catch (e) {
      console.error(e);
      closeWizard('error');
    }
  };

  const handleMessageName = (val) => {
    setMessageError('');
    setIsVisited(true);
    setMessageName(val);
  };
  const groupMessageTitle = useMemo(() => {
    if (announcementId) return i18n.t('broadcasts-list-editBroadcast');

    return announcementType === ANNOUNCEMENT_TYPES.PROMOTIONAL
      ? i18n.t('broadcasts-list-createPromotional')
      : i18n.t('broadcasts-list-createInformational');
  }, [announcementType, announcementId]);

  return (
    <GroupMessageTemplate
      title={groupMessageTitle}
      loading={loading || sendLoading}
      headerRightElement={
        <SaveAndExit
          closeWizard={handleSaveAndExit}
          sendStartedAt={!!sendStartedAt}
        />
      }
      showFooter={
        (!!recipientsAndMessageComplete && !sendStartedAt) ||
        (targetAllContacts && !!recipientsAndMessageComplete)
      }
      continueButtonText={i18n.t('slideouts-GroupMessageOverview-sendNow')}
      continueButtonDisabled={!hasPermission}
      continueButtonAction={handleUpdateAndSendAnnouncement}
      showScheduleButton
      hideBackButton
      deliveryDateTime={deliveryDateTime}
      toggleModalOpen={() => setOpenDeliveryModal(!openDeliveryModal)}
      toggleCancelModalOpen={() => setOpenCancelModal(!openCancelModal)}
      dueAt={dueAt}
      timezone={timezone}
    >
      {loading || submitting ? (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      ) : (
        <Container data-testid="group-message-overview-step">
          {pageError && <PageError>{pageError}</PageError>}
          <GroupMessageOverviewDetails />
          <GroupMessageOverviewName
            error={error && isVisited}
            messageName={messageName}
            setMessageName={(val) => {
              handleMessageName(val);
            }}
            messageError={messageError}
            outboundChannelId={outboundChannelId}
            pageError={pageError}
            setOutboundChannelId={setOutboundChannelId}
            wizardGlobalProps={wizardGlobalProps}
            disabled={!!sendStartedAt}
          />
          <OverviewItem
            title={i18n.t('slideouts-GroupMessageOverview-recipients')}
            subTitle={
              <RecipientSubTitle
                announcementId={announcementId}
                reachableAudienceCount={reachableAudienceCount}
                targetAllContacts={targetAllContacts}
              />
            }
            subTitleIsElement
            completed={!!reachableAudienceCount || !!targetAllContacts}
            allowNextStep={
              !!messageName && !!outboundChannelId && !messageError
            }
            onClickArrow={() => upsertBTM('GroupMessageAudience')}
            hasIcon
            iconName="contact"
            useButton
            buttonText={
              sendStartedAt
                ? i18n.t('slideouts-GroupMessageOverview-viewDetail')
                : i18n.t('slideouts-GroupMessageOverview-edit')
            }
          />
          <OverviewItem
            title={i18n.t('slideouts-GroupMessageOverview-message')}
            subTitle={
              <MessageSubTitle
                text={text}
                attachment={attachment}
                link={link}
              />
            }
            completed={!!text?.length || !!attachment?.originalFilename}
            onClickArrow={() => upsertBTM('GroupMessageContent')}
            allowNextStep={
              !!messageName && !!outboundChannelId && !messageError
            }
            hasIcon
            iconName="smsChannel"
            useButton
            buttonText={
              sendStartedAt
                ? i18n.t('slideouts-GroupMessageOverview-viewDetail')
                : i18n.t('slideouts-GroupMessageOverview-edit')
            }
          />
          {renderAnnouncementPermissionCheck()}
          <Flyout
            isOpen={openDeliveryModal}
            title={i18n.t('slideouts-GroupMessageOverview-schedule', {
              defaultValue: 'schedule delivery',
            })}
            onRequestClose={() => setOpenDeliveryModal(!openDeliveryModal)}
            buttonLabels={{
              primary: i18n.t('slideouts-GroupMessageOverview-save', {
                defaultValue: 'save',
              }),
              secondary: i18n.t('broadcasts-ReachableAudienceModal-close', {
                defaultValue: 'close',
              }),
            }}
            buttonActions={{
              primary: () => {
                handleOnSchedule();
              },
              secondary: () => {
                setOpenDeliveryModal(!openDeliveryModal);
              },
            }}
          >
            <DeliveryContainer>
              <CalendarWrapper>
                <Calendar
                  onChange={handleDateChange}
                  value={selectedDate}
                  locale={locale.substr(0, 2)}
                />
              </CalendarWrapper>
              <SelectTimeContainer>
                <SelectTime
                  label={i18n.t('slideouts-GroupMessageOverview-deliveryTime')}
                  hourValue={selectedHour}
                  minValue={selectedMin}
                  onChange={handleTimeChange}
                  error={deliveryError}
                  isMilitaryTime={isMilitaryTime(locale.replace('_', '-'))}
                />
              </SelectTimeContainer>
            </DeliveryContainer>
          </Flyout>
          <Modal
            isOpen={openCancelModal}
            size="sm"
            onRequestClose={() => setOpenCancelModal(!openCancelModal)}
            modalTitle={i18n.t('slideouts-GroupMessageOverview-returnDrafts')}
            primaryButtonText={i18n.t('slideouts-GroupMessageOverview-confirm')}
            primaryButtonOnClick={handleConfirmCancelAnnouncement}
            secondaryButtonText={i18n.t(
              'slideouts-GroupMessageOverview-cancel'
            )}
            secondaryButtonOnClick={() => setOpenCancelModal(!openCancelModal)}
          >
            {i18n.t('slideouts-GroupMessageOverview-returnMessage')}
          </Modal>
        </Container>
      )}
    </GroupMessageTemplate>
  );
};

GroupMessageOverviewCombined.propTypes = {
  wizardState: PropTypes.object.isRequired,
  closeWizard: PropTypes.func.isRequired,
  setWizardPage: PropTypes.func.isRequired,
  wizardStateSetters: PropTypes.object.isRequired,
  mutations: PropTypes.object.isRequired,
  wizardGlobalProps: PropTypes.object.isRequired,
};

export default GroupMessageOverviewCombined;
