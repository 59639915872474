import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'styled-theming';
import THEMES from '../../styles/themes/library/chip';
import DEFAULT_THEMES from '../../styles/themes/app';
import IconButton from '../Button/IconButton';
import COLORS from '../../styles/colors';
import { EmphasisText } from '../Text/Text';

const SMALL_CHIP_FONTSIZE = theme('fontSize', {
  default: '11px',
  large: '15px',
});

const DELETE_ICON = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_BLUE,
  light: COLORS.PK.GREY_40,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_LOW,
  holiday: COLORS.PK.GREY_40,
});

const DELETE_ICON_SIZE_MD = theme('fontSize', {
  default: 24,
  large: 28,
});

const DELETE_ICON_SIZE_SM = theme('fontSize', {
  default: 20,
  large: 24,
});

const sizeStyles = (props) => ({
  sm: {
    padding: props.extraPadding ? '2px 4px 2px 8px' : '2px 8px',
  },
  md: {
    padding: props.extraPadding ? '4px 4px 4px 8px' : '4px 8px',
  },
});

const customDeleteButtonStyle = (props) => `
  display: flex;
  align-items: center;
  height: ${
    props.size === 'sm'
      ? DELETE_ICON_SIZE_SM(props)
      : DELETE_ICON_SIZE_MD(props)
  }px !important;
  width: ${
    props.size === 'sm'
      ? DELETE_ICON_SIZE_SM(props)
      : DELETE_ICON_SIZE_MD(props)
  }px;
  && i { color: ${DEFAULT_THEMES.FOREGROUND_MED(props)}; }
`;

const DeleteIcon = styled.i`
  && {
    font-size: ${(props) =>
      props.size === 'sm'
        ? DELETE_ICON_SIZE_SM(props)
        : DELETE_ICON_SIZE_MD(props)}px;
    width: ${(props) =>
      props.size === 'sm'
        ? DELETE_ICON_SIZE_SM(props)
        : DELETE_ICON_SIZE_MD(props)}px;
    height: ${(props) =>
      props.size === 'sm'
        ? DELETE_ICON_SIZE_SM(props)
        : DELETE_ICON_SIZE_MD(props)}px;
    line-height: ${(props) =>
      props.size === 'sm'
        ? DELETE_ICON_SIZE_SM(props)
        : DELETE_ICON_SIZE_MD(props)}px;
    color: ${DELETE_ICON};
  }
`;

const ChipEncapsulator = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.highlight
      ? DEFAULT_THEMES.THEME_YELLOW(props)
      : THEMES.BACKGROUND(props)};
  padding: ${(props) => sizeStyles(props)[props.size].padding};
  border-radius: 4px;
  ${(props) =>
    props.greenHighlight
      ? `background-color: ${THEMES.REGION_BACKGROUND(props)}`
      : ''}
`;

const calcPaddingRight = (props) => {
  if (props.paddingRight) {
    return props.size === 'sm' ? 4 : 8;
  }
  return 0;
};

const TextWrap = styled.div`
  margin-right: ${(props) => calcPaddingRight(props)}px;
  display: flex;
  align-items: center;
  min-height: ${(props) =>
    props.size === 'sm'
      ? DELETE_ICON_SIZE_SM(props)
      : DELETE_ICON_SIZE_MD(props)}px;
  gap: ${(props) => (props.icon ? '4px' : null)};
  && i {
    color: ${(props) =>
      props?.highlight
        ? THEMES.HIGHLIGHTED_TEXT(props)
        : DEFAULT_THEMES.FOREGROUND_MED(props)};
  }
`;

const calcMaxWidth = (props) => {
  const deleteIconWidth =
    props.size === 'sm'
      ? DELETE_ICON_SIZE_SM(props)
      : DELETE_ICON_SIZE_MD(props);
  // Return sum of padingRight for text element plus paddingRight and paddingLeft (always 8) of Chip element
  const padding = calcPaddingRight(props) + (props.paddingRight ? 4 : 8) + 8;
  return props.maxWidth - (props.paddingRight ? deleteIconWidth : 0) - padding;
};

const textCustomStyle = (props) => `
  font-size: ${props.size === 'sm' ? SMALL_CHIP_FONTSIZE(props) : 'initial'};
  font-weight: 600;
  color: ${
    props?.highlight ? THEMES.HIGHLIGHTED_TEXT(props) : 'auto'
  } !important;

  ${
    props?.maxWidth &&
    `
    overflow: hidden;
    max-width: ${calcMaxWidth(props)}px;
    white-space: nowrap;
    text-overflow: ellipsis;
    `
  }
`;

const Chip = ({
  children,
  onDelete,
  size,
  icon,
  maxWidth,
  highlight,
  greenHighlight,
  dataTestId,
}) => {
  const displayX = !!onDelete;

  return (
    <ChipEncapsulator
      size={size}
      extraPadding={!!onDelete}
      highlight={highlight}
      data-testid={dataTestId}
      greenHighlight={greenHighlight}
    >
      <TextWrap
        icon={icon}
        paddingRight={displayX}
        size={size}
        highlight={highlight}
      >
        {icon || null}
        <EmphasisText
          contrast="med"
          paddingRight={displayX}
          size={size}
          maxWidth={maxWidth}
          highlight={highlight}
          customStyle={textCustomStyle}
        >
          {children}
        </EmphasisText>
      </TextWrap>
      {displayX && (
        <IconButton
          size={size}
          onClick={onDelete}
          highlight={highlight}
          customStyle={(props) => customDeleteButtonStyle({ ...props })}
        >
          <DeleteIcon className="ri-close-fill" size={size} />
        </IconButton>
      )}
    </ChipEncapsulator>
  );
};

Chip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]).isRequired,
  onDelete: PropTypes.func,
  size: PropTypes.string,
  icon: PropTypes.element,
  maxWidth: PropTypes.number,
  highlight: PropTypes.bool,
  dataTestId: PropTypes.string,
  greenHighlight: PropTypes.bool,
};

Chip.defaultProps = {
  onDelete: null,
  size: 'md',
  icon: null,
  maxWidth: 250,
  highlight: false,
  dataTestId: '',
  greenHighlight: false,
};

export default Chip;
