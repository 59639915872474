import LABEL_THEMES from '../label';

export const getLabelThemeColor = (color) => {
  const themeColorDefault = LABEL_THEMES.GREY_LABEL;

  if (!color) {
    return themeColorDefault;
  }

  const colorMap = {
    red: LABEL_THEMES.RED_LABEL,
    orange: LABEL_THEMES.ORANGE_LABEL,
    violet: LABEL_THEMES.PURPLE_LABEL,
    blue: LABEL_THEMES.BLUE_LABEL,
    green: LABEL_THEMES.GREEN_LABEL,
  };

  return colorMap[color] || themeColorDefault;
};
