import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import i18n from 'i18n-js';
import {
  CUSTOMER_CONTACT_MOST_RECENT_THREAD_QUERY,
  SEARCH_CUSTOMERS_QUERY,
  useCustomerQuery,
} from 'client-lib';
import { contactName, arrayToObject } from 'client-lib/src/lib/utils/helpers';
import { useApolloClient, useQuery } from '@apollo/client';
import {
  closeAllSlideouts,
  setActiveCreateCustomerSection,
} from '../../actions/general';
import {
  IconButton,
  Heading2,
  Button,
  InfiniteSuggest,
  SelectedEntity,
  TabBar,
} from '../../elements';
import THEMES from '../../styles/themes/app';
import {
  ColumnContainer,
  HeaderCloseSection,
  SubHeaderSection,
  InnerWrap,
  HeadWrap,
} from './CustomerInfo/CustomerInfo';
import { setCreateCustomerFormValues } from '../../actions/createSection';
import CustomerInfoReference from './MergeCustomer/CustomerInfoReference';
import RecentThread from './MergeCustomer/RecentThread';

const TitleWrap = styled.div`
  padding: 0.8rem 1rem;
  background-color: ${THEMES.BACKGROUND_SECONDARY};
  color: ${THEMES.FOREGROUND_HIGH};
`;

const InfiniteSuggestWrapper = styled.div`
  padding: 16px 1rem 0px;
`;

const ReferenceWrapping = styled.div`
  height: calc(100vh - 400px);
  overflow-y: auto;
`;

const MergeCustomerSlideout = ({ customerContactId }) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const styledTheme = useContext(ThemeContext);
  const searchInput = useRef();
  const [searchVal, setSearchVal] = useState('');
  const [mergeTarget, setMergeTarget] = useState(null);
  const [activeTab, setActiveTab] = useState('info');
  const [mostRecentThread, setMostRecentThread] = useState({});

  const tabBarOptions = [
    {
      label: i18n.t('customers-MergeCustomerSlideout-contactCard', {
        defaultValue: 'Contact Card',
      }),
      value: 'info',
    },
    {
      label: i18n.t('customers-MergeCustomerSlideout-mostRecentThread', {
        defaultValue: 'Most Recent Thread',
      }),
      value: 'thread',
    },
  ];

  const { customerContactQueryData: custData } = useCustomerQuery({
    client,
    customerContactId,
  });

  const { data, loading } = useQuery(
    CUSTOMER_CONTACT_MOST_RECENT_THREAD_QUERY,
    {
      variables: {
        customerContactId: mergeTarget?.id,
      },
      skip: !mergeTarget,
    }
  );

  const allGroups = useSelector((state) => state?.accountData?.allGroups);
  const accountGroups = allGroups ? arrayToObject(allGroups, 'id') : null;
  const groupsById = (groupIds) =>
    groupIds.map((id) => accountGroups?.[id] || null).filter(Boolean);

  useEffect(() => {
    if (mergeTarget?.id && data?.customerContactMostRecentThread?.thread?.id) {
      setMostRecentThread(data?.customerContactMostRecentThread);
    }
  }, [mergeTarget?.id, data?.customerContactMostRecentThread?.thread?.id]);

  const handleSelectSuggestion = (e, { suggestion }) => {
    setMergeTarget(suggestion);
    setSearchVal('');
    // refetch();

    // who knows why the time out is necessary here, the other focus call may be interfering?
    // setTimeout(() => messageInput?.current?.focus(), 0);
  };

  const handleDeleteSelected = () => {
    setMergeTarget(null);
    setMostRecentThread({});
    setTimeout(() => searchInput?.current?.focus(), 0);
  };

  useEffect(() => {
    const postAnimationFocus = setTimeout(
      () => searchInput?.current?.focus(),
      350
    );
    return () => {
      clearTimeout(postAnimationFocus);
    };
  }, []);

  const labelCopy = i18n.t('customers-MergeCustomerSlideout-addPhoneNumberTo', {
    contact: contactName(custData?.customerContact),
    defaultValue: `Add ${contactName(custData?.customerContact)} to `,
  });

  const mergeOn = (customer) => {
    if (customer?.phoneNumber) {
      return 'phoneNumber';
    }
    if (customer?.faxNumber) {
      return 'faxNumber';
    }
    if (customer?.emailAddress) {
      return 'emailAddress';
    }
    return '';
  };

  return (
    <ColumnContainer>
      <InnerWrap>
        <HeaderCloseSection>
          <IconButton
            noOutline
            contrast="high"
            size="lg"
            onClick={() => dispatch(closeAllSlideouts())}
            dataTestId="customer-info-close-button"
          >
            <i className="ri-close-line" />
          </IconButton>
        </HeaderCloseSection>
        <TitleWrap>
          <HeadWrap>
            <Heading2 contrast="high">
              {i18n.t('customers-MergeCustomerSlideout-addToContact', {
                defaultValue: 'Add to Contact',
              })}
            </Heading2>
          </HeadWrap>
        </TitleWrap>

        <SubHeaderSection
          backgroundColor={THEMES.BACKGROUND_TERTIARY({
            theme: styledTheme,
          })}
          slimVerticalPadding
        >
          <Button
            disabled={!mergeTarget?.id}
            onClick={() => {
              dispatch(
                setCreateCustomerFormValues({
                  firstName: mergeTarget?.firstName,
                  lastName: mergeTarget?.lastName,
                  newPhoneNumber: custData?.customerContact?.phoneNumber,
                  newEmail: custData?.customerContact?.emailAddress,
                  newFaxNumber: custData?.customerContact?.faxNumber,
                  id: mergeTarget?.id,
                  phoneNumber: mergeTarget?.phoneNumber,
                  email: mergeTarget?.emailAddress,
                  faxNumber: mergeTarget?.faxNumber,
                  prioritizeRep: mergeTarget.prioritizeRep,
                  jobTitle: mergeTarget.jobTitle,
                  accountRep: {
                    value: contactName(mergeTarget?.repUser),
                    id: mergeTarget?.repUser?.id,
                  },
                  company: {
                    value: mergeTarget?.account?.id,
                    text: mergeTarget?.account?.name,
                  },
                  phoneSelectValue: custData?.customerContact?.phoneNumber,
                  emailSelectValue: custData?.customerContact?.emailAddress,
                  faxSelectValue: custData?.customerContact?.faxSelectValue,
                  isMergeContact: true,
                  group: {
                    value: groupsById(mergeTarget?.groupIds),
                    text: '',
                  },
                  mergeContactId: custData?.customerContact?.id,
                  mergeOn: () => mergeOn(custData?.customerContact),
                })
              );
              dispatch(setActiveCreateCustomerSection());
              dispatch(closeAllSlideouts());
            }}
            dataTestId="merge-contact-continue-button"
          >
            {i18n.t('settings-withCustomerImportLayout-continue')}
          </Button>
        </SubHeaderSection>

        <InfiniteSuggestWrapper>
          {mergeTarget ? (
            <SelectedEntity
              label={labelCopy}
              onDelete={handleDeleteSelected}
              dataTestId="entity-card"
              avatarProps={{
                size: 'md',
              }}
              avatarChildren={contactName(mergeTarget)}
              maintext={contactName(mergeTarget)}
            />
          ) : (
            custData && (
              <InfiniteSuggest
                inputProps={{
                  label: labelCopy,
                  value: searchVal,
                  onChange: (e) => setSearchVal(e.target.value),
                  // onBlur: certifyToInputValueOnBlur,
                  ref: searchInput,
                  // placeholder: i18n.t('modals-AddMember-enterName'),
                }}
                query={SEARCH_CUSTOMERS_QUERY}
                queryKey="searchCustomers"
                queryVariables={{
                  query: searchVal,
                  after: null,
                  typeFilter: 'CUSTOMER_CONTACT',
                  excludeCustomerId: customerContactId,
                }}
                onSelect={handleSelectSuggestion}
                // includePresence={presenceTrackingAvailable}
              />
            )
          )}
        </InfiniteSuggestWrapper>

        {mergeTarget ? (
          <>
            <TabBar
              options={tabBarOptions}
              activeTabValue={activeTab}
              onClick={(option) => setActiveTab(option.value)}
            />
            <ReferenceWrapping>
              {activeTab === 'info' ? (
                <CustomerInfoReference customerContact={mergeTarget} />
              ) : null}
              {activeTab === 'thread' && mostRecentThread && !loading ? (
                <RecentThread
                  thread={mostRecentThread?.thread}
                  messages={mostRecentThread?.messages}
                />
              ) : null}
            </ReferenceWrapping>
          </>
        ) : null}
      </InnerWrap>
    </ColumnContainer>
  );
};

MergeCustomerSlideout.propTypes = {
  customerContactId: PropTypes.string.isRequired,
};

export default MergeCustomerSlideout;
