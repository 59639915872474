import { AUTH_SIGNIN, AUTH_SIGNOUT, RESET_STORE } from '../actions';
import {
  UPDATE_SESSION,
  UPDATE_STOCK_MESSAGES,
  UPDATE_DESKTOP_NOTIFICATION_SETTINGS,
  UPDATE_EMAIL_NOTIFICATION_SETTINGS,
  CHANGE_THREADS_ACTIVE_GROUP,
  SET_USER_SETTINGS,
  SET_THREADS_ACTIVE_GROUP_IDS,
  SET_DASHBOARDS_ACTIVE_GROUP_IDS,
  SET_USER_ACCOUNT_POLICIES,
  SET_USER_ACCOUNT_POLICIES_QUERIED,
  UPDATE_THREAD_NOTIFY_PREF,
} from '../actions/session';

const DEFAULT_STATE = {
  currentUser: null,
};

function maybeMergeAccountPolicies(state, actionAccountPolicies) {
  const currentUserAccountPolicies = state?.currentUser?.accountPolicies || [];
  let accountPolicies = actionAccountPolicies || [];

  if (currentUserAccountPolicies.length > 0) {
    accountPolicies = [
      {
        accountId: actionAccountPolicies?.[0]?.accountId,
        actions: [
          ...new Set([
            ...(state.currentUser.accountPolicies?.[0]?.actions || []),
            ...(actionAccountPolicies?.[0]?.actions || []),
          ]),
        ],
      },
    ];
  }

  return accountPolicies;
}

export default function (state = DEFAULT_STATE, action) {
  let threadsActiveGroupIds = state.threadsActiveGroupIds || [];
  const dashboardsActiveGroupIds = state.dashboardsActiveGroupIds || [];
  let theme = state?.currentUser?.theme;
  let fontSize = state?.currentUser?.fontSize;
  let language = state?.currentUser?.language;
  let avatarUrl = state?.currentUser?.avatarUrl;

  switch (action.type) {
    case AUTH_SIGNIN:
      return {
        ...state,
        currentUser: {
          userId: action.userId,
          contactId: action.contactId,
          accountId: action.accountId,
          username: action.username,
          groupIds: action.groupIds,
          accountPolicies: maybeMergeAccountPolicies(
            state,
            action.accountPolicies
          ),
          accountPoliciesQueried: [
            ...new Set([
              ...(state.currentUser?.accountPoliciesQueried || []),
              ...(action.accountPoliciesQueried || []),
            ]),
          ],
          groupPolicies: action.groupPolicies,
          firstName: action.firstName,
          lastName: action.lastName,
          stockMessagesConfig:
            action.stockMessages || action.stockMessagesConfig,
          notificationInterval: action.notificationInterval,
          notificationSound: action.notificationSound,
          notificationSettingsLocked: action.notificationSettingsLocked,
          groupInboxNotificationsEnabled: action.groupInboxNotificationsEnabled,
          announcementsAccess: action.announcementsAccess,
          theme: action.theme,
          role: action.role,
          title: action.title,
          fontSize: action.fontSize,
          language: action.language,
          emailNotificationsEnabled: action.emailNotificationsEnabled,
          limitEmailNotifications: action.limitEmailNotifications,
          emailNotificationDelayMins: action.emailNotificationDelayMins,
          unclaimedThreadEmailNotificationsEnabled:
            action.unclaimedThreadEmailNotificationsEnabled,
          unreadThreadEmailNotificationsEnabled:
            action.unreadThreadEmailNotificationsEnabled,
          unclaimedThreadMobileNotificationsEnabled:
            action.unclaimedThreadEmailNotificationsEnabled,
          avatarUrl: action.avatarUrl,
          transferThreadNotification: action.transferThreadNotification,
        },
        threadsActiveGroupIds,
        dashboardsActiveGroupIds,
      };

    case AUTH_SIGNOUT:
      return {
        ...state,
        currentUser: null,
      };

    case UPDATE_SESSION:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          theme: action.payload.theme || state.currentUser.theme,
          fontSize: action.payload.fontSize || state.currentUser.fontSize,
          language: action.payload.language || state.currentUser.language,
          avatarUrl: action.payload.avatarUrl || state.currentUser.avatarUrl,
          title: action.payload.title || state.currentUser.title,
          role: action.payload.role || state.currentUser.role,
          announcementsAccess:
            action.payload.announcementsAccess ||
            state.currentUser.announcementsAccess,
        },
      };

    case UPDATE_STOCK_MESSAGES:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          stockMessagesConfig: action.payload,
        },
      };

    case UPDATE_DESKTOP_NOTIFICATION_SETTINGS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notificationSound: action.payload.notificationSound,
          notificationInterval: action.payload.notificationInterval,
          notificationSettingsLocked: action.payload.notificationSettingsLocked,
          groupInboxNotificationsEnabled:
            action.payload.groupInboxNotificationsEnabled,
        },
      };

    case UPDATE_EMAIL_NOTIFICATION_SETTINGS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          emailNotificationsEnabled: action.payload.emailNotificationsEnabled,
          limitEmailNotifications: action.payload.limitEmailNotifications,
          emailNotificationDelayMins: action.payload.emailNotificationDelayMins,
          unclaimedThreadEmailNotificationsEnabled:
            action.payload.unclaimedThreadEmailNotificationsEnabled,
          unreadThreadEmailNotificationsEnabled:
            action.payload.unreadThreadEmailNotificationsEnabled,
        },
      };

    case CHANGE_THREADS_ACTIVE_GROUP:
      return {
        ...state,
        threadsActiveGroupIds: [action.groupId],
      };

    case SET_THREADS_ACTIVE_GROUP_IDS:
      return {
        ...state,
        threadsActiveGroupIds: action.groupIds,
      };

    case SET_DASHBOARDS_ACTIVE_GROUP_IDS:
      return {
        ...state,
        dashboardsActiveGroupIds: action.groupIds,
      };

    case RESET_STORE:
      return state;

    case SET_USER_SETTINGS:
      if (
        action.payload.groupIds.length > 0 &&
        action.payload.groupIds.includes(state.threadsActiveGroupIds?.[0]) ===
          false
      ) {
        threadsActiveGroupIds = [];
      }

      if (action.payload.theme) {
        theme = action.payload.theme;
      }

      if (action.payload.fontSize) {
        fontSize = action.payload.fontSize;
      }
      if (action.payload.language) {
        language = action.payload.language;
      }

      if (action.payload.avatarUrl) {
        avatarUrl = action.payload.avatarUrl;
      }

      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          userId: action.payload.userId,
          username: action.payload.username,
          contactId: action.payload.contactId,
          accountId: action.payload.accountId,
          groupIds: action.payload.groupIds,
          accountPolicies: maybeMergeAccountPolicies(
            state,
            action.payload.accountPolicies
          ),
          accountPoliciesQueried: [
            ...new Set([
              ...(state.currentUser?.accountPoliciesQueried || []),
              ...(action.payload.accountPoliciesQueried || []),
            ]),
          ],
          groupPolicies: action.payload.groupPolicies,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          role: action.payload.role,
          stockMessagesConfig: action.payload.stockMessages,
          announcementsAccess: action.payload.announcementsAccess,
          notificationInterval: action.payload.notificationInterval,
          notificationSound: action.payload.notificationSound,
          notificationSettingsLocked: action.payload.notificationSettingsLocked,
          groupInboxNotificationsEnabled:
            action.payload.groupInboxNotificationsEnabled,
          emailNotificationsEnabled: action.payload.emailNotificationsEnabled,
          limitEmailNotifications: action.payload.limitEmailNotifications,
          emailNotificationDelayMins: action.payload.emailNotificationDelayMins,
          unclaimedThreadEmailNotificationsEnabled:
            action.payload.unclaimedThreadEmailNotificationsEnabled,
          unreadThreadEmailNotificationsEnabled:
            action.payload.unreadThreadEmailNotificationsEnabled,
          unclaimedThreadMobileNotificationsEnabled:
            action.payload.unclaimedThreadMobileNotificationsEnabled,
          title: action.payload.title,
          theme,
          fontSize,
          language,
          avatarUrl,
        },
        threadsActiveGroupIds,
        dashboardsActiveGroupIds:
          action?.payload?.dashboardsActiveGroupIds || [],
        externalId: action.payload.external_id,
      };

    case 'SET_SOCKET_CONNECTION_TIMESTAMP':
      return {
        ...state,
        socketConnectionTimestamp: action.socketConnectionTimestamp,
      };

    case SET_USER_ACCOUNT_POLICIES:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          accountPolicies: maybeMergeAccountPolicies(
            state,
            action.userAccountPolicies
          ),
        },
      };

    case SET_USER_ACCOUNT_POLICIES_QUERIED:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          accountPoliciesQueried: [
            ...new Set([
              ...(state.currentUser.accountPoliciesQueried || []),
              ...action.policiesQueried,
            ]),
          ],
        },
      };

    case UPDATE_THREAD_NOTIFY_PREF:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          transferThreadNotification: action.payload,
        },
      };

    default:
      return state;
  }
}
