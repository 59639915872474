import React from 'react';
import PropTypes from 'prop-types';

const PrintThreads = React.forwardRef(({ children }, ref) => {
  return <div ref={ref}>{children}</div>;
});

PrintThreads.propTypes = {
  children: PropTypes.object.isRequired,
};

export default PrintThreads;
