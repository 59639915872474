import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';
import {
  accountName,
  accountNumber,
  contactName,
  isCustomerContactUnknown,
} from 'client-lib/src/lib/utils/helpers';
import {
  EmphasisText,
  EntityCard,
  InlineTextButton,
  Text,
} from '../../elements';

const CompanyWrapper = styled.div`
  display: flex;
`;

const SubtextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PrintContactHeader = ({ thread }) => {
  const companyName = accountName(thread?.externalContact);
  const jobTitle = thread?.externalContact?.jobTitle;
  const companyAccountNumber = accountNumber(thread?.externalContact);
  const displayName = contactName(thread?.externalContact);
  const displayContactButtons = isCustomerContactUnknown(
    thread?.externalContact
  );

  const subtextComponent = !displayContactButtons && (
    <SubtextWrapper>
      {jobTitle ? (
        <Text contrast="med" maxWidth="450px">
          {jobTitle}
        </Text>
      ) : (
        <InlineTextButton noOutline textType="EmphasisText">
          {i18n.t('customers-Contacts-assignTitle', {
            defaultValue: 'Assign Job Title',
          })}
        </InlineTextButton>
      )}
      &nbsp;
      <Text>{i18n.t('customers-Contacts-at', { defaultValue: 'at' })}</Text>
      &nbsp;
      {companyName ? (
        <CompanyWrapper>
          <EmphasisText maxWidth="450px">{companyName}</EmphasisText>
          &nbsp;
          {companyAccountNumber && (
            <EmphasisText>{` - #${companyAccountNumber}`}</EmphasisText>
          )}
        </CompanyWrapper>
      ) : (
        <InlineTextButton noOutline textType="EmphasisText">
          {i18n.t('customers-Contacts-assignCompany', {
            defaultValue: 'Assign Company',
          })}
        </InlineTextButton>
      )}
    </SubtextWrapper>
  );
  return (
    <EntityCard
      avatarChildren={displayName}
      maintext={displayName}
      subtext={subtextComponent}
    />
  );
};

PrintContactHeader.propTypes = {
  thread: PropTypes.object.isRequired,
};

export default PrintContactHeader;
