import React, { useState, useRef, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  PK_TYPENAMES,
  SEARCH_CUSTOMERS_QUERY,
  useCreateEmail,
  useEmailSignature,
  useIsEmailAvailable,
  hasEmailBlocked,
} from 'client-lib';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import Dropzone from 'react-dropzone';
import uniqid from 'uniqid';
import i18n from 'i18n-js';
import {
  isAttachmentFileTypeSupported,
  ALLOWED_FILENAME_REGEX,
  NOT_ALLOWED_FILENAME_REGEX,
  fileNameWithoutExtension,
  fileExtension,
  resolveAvatarUrl,
  contactName,
} from 'client-lib/src/lib/utils/helpers';
import { emailRegex } from 'client-lib/src/lib/utils/validation';
import {
  showUploadProgressBar,
  hideUploadProgressBar,
  showUploadFailureBar,
  setDefaultContact,
} from '../../actions/uploadModal';
import { closeCreateSectionModal, openSnackbar } from '../../actions/general';
import {
  clearCreateSection,
  setCreateEmail,
} from '../../actions/createSection';
import UploadArt from '../Modals/UploadArt';
import {
  Heading2,
  Button,
  TextArea,
  Select,
  SelectedEntity,
  InfiniteSuggest,
  TextInput,
  Text,
  Chip,
  InputError,
  Avatar,
} from '../../elements';
import AddEmailAddress from '../Modals/AddEmailAddress';
import THEMES from '../../styles/themes/app';
import BUTTON_THEMES from '../../styles/themes/library/button';
import CreateMultiTag from '../Tag/CreateMultiTag';
import AnnotationsModal from '../Annotations/AnnotationsModal';
import { CreateFormAttachment } from '../FileUpload/CreateFormAttachment';
import {
  MAX_COMBINED_FILE_SIZE,
  calcCombinedAttachmentSize,
} from '../Threads/SubmitMessageInput';
import CreateSectionButtons from './CreateSectionButtons';

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 50px;
  height: 90%;
  max-height: 90%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  max-width: 600px;
  align-items: stretch;
  justify-content: ${(props) =>
    props.centerVertically ? 'center' : 'flex-start'};
`;

const FullFormRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;
`;

const HeadingWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 48px;
  column-gap: 12px;
`;

const CarbonCopyChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
`;

const ChipContainer = styled.div`
  margin: 8px 8px 0 0;
`;

const addCcCustomStyle = (props) => `
 color: ${BUTTON_THEMES.LINK_TEXT(props)};
 :hover {
  color: ${BUTTON_THEMES.LINK_TEXT_HOVER(props)};
 }
`;

const modalBodyStyleWithDragging = {
  height: '100%',
  width: '100%',
};

const CreateEmail = ({ createSection }) => {
  const client = useApolloClient();

  const history = useHistory();
  const dispatch = useDispatch();

  const threadsActiveGroupIds = useSelector(
    (state) => state?.session?.threadsActiveGroupIds
  );
  const currentUser = useSelector((state) => state?.session?.currentUser);
  const uploadModal = useSelector((state) => state?.uploadModal);
  const activeEmailChannels = useSelector(
    (state) => state?.accountData?.activeEmailChannels
  );

  const [annotate, setAnnotate] = useState(false);

  const [emojiMenuOpen, setEmojiMenuOpen] = useState(false);

  const [contactText, setContactText] = useState('');

  const [carbonCopyContactText, setCarbonCopyContactText] = useState('');

  const [isDraggingOnPage, setIsDraggingOnPage] = useState(false);

  const [annotationAttachmentIndex, setAnnotationAttachmentIndex] = useState(0);

  const [addActiveEmailModal, setAddActiveEmailModal] = useState(false);

  const [addEmailModalContact, setAddEmailModalContact] = useState(null);

  const [addEmailModalOpenedFrom, setAddEmailModalOpenedFrom] = useState('');

  const [addCCField, setAddCCField] = useState(false);

  const [toFieldError, setToFieldError] = useState('');

  const [pasteMeta, setPasteMeta] = useState({
    cursorPosition: 0,
    pasted: false,
    event: null,
  });

  const [templateModalOpen, setTemplateModalOpen] = useState(false);

  const messageInput = useRef();
  const subjectInput = useRef();

  const { emailFeatureAvailable } = useIsEmailAvailable({
    currentUser,
    threadsActiveGroupIds,
    client,
  });

  const onMutationStart = () => {
    dispatch(showUploadProgressBar());
    dispatch(closeCreateSectionModal());
    dispatch(clearCreateSection());
  };

  const onMutationSuccess = (data) => {
    const startedEmailThread = data.startEmailThread.thread;
    let tab;
    senderGroup.allowInbound ? (tab = 'open') : (tab = 'closed');

    history.push(`/threads/${tab}/${startedEmailThread.id}`);

    dispatch(hideUploadProgressBar());
  };

  const onMutationError = (err) => {
    setAttachments([]);
    dispatch(showUploadFailureBar());
    setTimeout(() => dispatch(hideUploadProgressBar()), 3000);
    console.error(err);
  };

  const handleNotifyError = (msg) => {
    dispatch(openSnackbar(msg, 'error'));
  };

  const {
    handleMutationStartThread,
    contact,
    setContact,
    contactEmail,
    setContactEmail,
    carbonCopyContacts,
    setCarbonCopyContacts,
    senderGroup,
    setSenderGroup,
    groupOptions,
    tags,
    setTags,
    messageInputValue,
    setMessageInputValue,
    messageSubjectValue,
    setMessageSubjectValue,
    attachments,
    setAttachments,
    deleteAttachment,
  } = useCreateEmail({
    client,
    currentUser,
    threadsActiveGroupIds,
    onMutationStart,
    onMutationSuccess,
    onMutationError,
    initialThread: createSection.createEmail,
    onError: handleNotifyError,
    activeEmailChannels,
  });

  useEffect(() => {
    const suggestion = createSection.createEmail.contact;
    if (contact?.emailAddress === null) {
      setContactEmail('');
      setContact(suggestion);
      setAddEmailModalContact(suggestion);
      setAddActiveEmailModal(true);
      setAddEmailModalOpenedFrom('toField');
    }
  }, [createSection.createEmail.contact]);

  const { emailSigOn, autoAddSig, handleAddSignature } = useEmailSignature({
    client,
    userId: currentUser?.userId,
    messageVal: messageInputValue,
    setMessageVal: setMessageInputValue,
  });

  useEffect(() => {
    if (autoAddSig) handleAddSignature();
  }, [autoAddSig]);

  useEffect(() => {
    if (uploadModal.defaultContact) {
      const { defaultContact } = uploadModal;
      setContact(defaultContact);
      setContactEmail(defaultContact.emailAddress);
    }

    return () => dispatch(setDefaultContact(null));
  }, []);

  const showFromField = threadsActiveGroupIds?.length > 1;

  const focusOnSubjectInput = () => subjectInput?.current?.focus?.();

  useEffect(() => {
    if (contactEmail) {
      focusOnSubjectInput();
    }
  }, [contactEmail]);

  const checkIsSuggestionAlreadyIncluded = (suggestion) =>
    carbonCopyContacts?.some(
      (carbonCopy) => carbonCopy?.emailAddress === suggestion
    );

  const checkIsSuggestionAlreadyMainContact = (suggestion) =>
    suggestion === contactEmail;

  const onSuggestionSelected = (e, { suggestion }) => {
    const isSuggestionAlreadyIncluded = checkIsSuggestionAlreadyIncluded(
      suggestion.emailAddress
    );
    if (suggestion.emailAddress) {
      if (isSuggestionAlreadyIncluded) {
        setContactText('');
        setToFieldError(i18n.t('slideouts-CreateEmail-contactIncluded'));
      } else {
        setContactEmail(suggestion.emailAddress);
        setContact(suggestion);
        setToFieldError('');
      }
    } else {
      setContactEmail('');
      setContact(suggestion);
      setAddEmailModalContact(suggestion);
      setAddActiveEmailModal(true);
      setAddEmailModalOpenedFrom('toField');
    }
  };

  const onCCSuggestionSelected = (e, { suggestion }) => {
    if (suggestion.emailAddress) {
      const isSuggestionAlreadyIncluded = checkIsSuggestionAlreadyIncluded(
        suggestion.emailAddress
      );
      const isMainContact = checkIsSuggestionAlreadyMainContact(
        suggestion.emailAddress
      );
      setCarbonCopyContactText('');
      if (isSuggestionAlreadyIncluded || isMainContact)
        dispatch(
          openSnackbar(
            i18n.t('slideouts-CreateThread-duplicateContact', {
              defaultValue: 'Duplicate contact entered',
            }),
            'error'
          )
        );
      if (
        !isSuggestionAlreadyIncluded &&
        !isMainContact &&
        carbonCopyContacts?.length <= 9
      ) {
        setCarbonCopyContacts([...carbonCopyContacts, suggestion]);
      }
    } else {
      setCarbonCopyContactText('');
      setAddEmailModalContact(suggestion);
      setAddActiveEmailModal(true);
      setAddEmailModalOpenedFrom('cCField');
    }
  };

  const onCarbonCopyContactDeleted = (deletedContact) => {
    const newCarbonCopyContacts = carbonCopyContacts.filter(
      (contact) => contact.emailAddress !== deletedContact.emailAddress
    );
    setCarbonCopyContacts(newCarbonCopyContacts);
  };

  const onToFieldChange = (e) => setContactText(e.target.value);

  const onCCFieldChange = (e) => setCarbonCopyContactText(e.target.value);

  const handleInputChange = (e) => setMessageInputValue(e.target.value);

  const handleSubjectInputChange = (e) =>
    setMessageSubjectValue(e.target.value);

  const certifyToInputValueOnBlur = (e) => {
    const trimmedValue = e.target.value.trim();
    const isSuggestionAlreadyIncluded =
      checkIsSuggestionAlreadyIncluded(trimmedValue);
    if (emailRegex.test(trimmedValue) === true) {
      if (isSuggestionAlreadyIncluded) {
        setContactText('');
        setToFieldError(i18n.t('slideouts-CreateEmail-contactIncluded'));
      } else {
        setContactEmail(trimmedValue);
        setContactText(trimmedValue);
      }
    } else if (!contact || contactEmail === '') {
      setContactText('');
    }
  };

  const certifyCCInputValueOnBlur = (e) => {
    const trimmedValue = e.target.value.trim();
    const isAlreadyMainContact =
      checkIsSuggestionAlreadyMainContact(trimmedValue);
    const isAlreadyInCarbonCopyContacts =
      checkIsSuggestionAlreadyIncluded(trimmedValue);
    setCarbonCopyContactText('');
    if (isAlreadyMainContact || isAlreadyInCarbonCopyContacts)
      dispatch(
        openSnackbar(
          i18n.t('slideouts-CreateThread-duplicateContact', {
            defaultValue: 'Duplicate contact entered',
          }),
          'error'
        )
      );
    if (
      emailRegex.test(trimmedValue) === true &&
      !isAlreadyMainContact &&
      !isAlreadyInCarbonCopyContacts &&
      carbonCopyContacts.length <= 9
    ) {
      setCarbonCopyContacts([
        ...carbonCopyContacts,
        { emailAddress: trimmedValue },
      ]);
    }
  };

  const handleEnterSubmit = (e) => {
    if (e.shiftKey && e.key === 'Enter') {
      e.preventDefault();
      if (contactEmail) {
        handleMutationStartThread();
      }
    }
  };

  const handleSetAttachment = ({
    originalFile,
    originalFilename = originalFile.name,
  }) => {
    const reader = new window.FileReader();
    reader.onload = () => {
      const attachment = {
        data: reader.result,
        originalFilename,
        type: originalFile.type,
        id: uniqid(),
        size: originalFile.size,
      };
      setAttachments((attachments) => [...attachments, attachment]);
    };
    reader.readAsDataURL(originalFile);
  };

  const handleOnPaste = (e) => {
    e.preventDefault();
    e.persist();

    let clipBoardItem = null;

    if (e.clipboardData.types.includes('Files')) {
      clipBoardItem = e.clipboardData.items[1]
        ? e.clipboardData.items[1]
        : e.clipboardData.items[0];
    } else {
      clipBoardItem = e.clipboardData.items[0];
      if (
        e.clipboardData.types[0] === 'text/plain' ||
        e.clipboardData.types[0] === 'text/html'
      ) {
        // Paste in text based on cursor location.
        const pastedText = e.clipboardData.getData('Text');
        const selectionStart = e.target.selectionStart; // cursor location in input
        const selectionEnd = e.target.selectionEnd;
        const beforeSelection = e.target.value.slice(0, selectionStart);
        const afterSelection = e.target.value.slice(selectionEnd);
        const cursorPosition = beforeSelection.length + pastedText.length;

        setMessageInputValue(
          `${beforeSelection}${pastedText}${afterSelection}`
        );
        setPasteMeta({ cursorPosition, pasted: true, event: e });

        return;
      }
    }

    const originalFile = clipBoardItem.getAsFile();
    handleSetAttachment({ originalFile });
  };

  useEffect(() => {
    if (pasteMeta.pasted && pasteMeta.event) {
      const { event, cursorPosition } = pasteMeta;
      event.target.setSelectionRange(cursorPosition, cursorPosition);
      setPasteMeta({ pasted: false, event: null, cursorPosition: 0 });
    }
  }, [pasteMeta.pasted]);

  useEffect(() => {
    const createEmailRedux = {
      contact,
      contactEmail,
      messageInputValue,
      messageSubjectValue,
      attachments,
      senderGroup,
      carbonCopyContacts,
    };
    dispatch(setCreateEmail(createEmailRedux));
  }, [
    contact?.id,
    contactEmail,
    messageInputValue,
    attachments?.length,
    senderGroup?.value,
  ]);

  const clearToFieldBubble = () => {
    setContactText('');
    setContactEmail('');
    setContact(null);
  };

  const validateAttachment = (originalFile) => {
    const filename = fileNameWithoutExtension(originalFile.name);
    const extension = `.${fileExtension(originalFile.name)}`;
    if (!isAttachmentFileTypeSupported(originalFile.name)) {
      dispatch(
        openSnackbar(i18n.t('slideouts-CreateThread-unsupportedFile'), 'error')
      );
    } else if (
      calcCombinedAttachmentSize([originalFile], attachments) >
      MAX_COMBINED_FILE_SIZE
    ) {
      dispatch(
        openSnackbar(
          i18n.t('slideouts-CreateInternal-30mb', { fileSizeLimit: 30 }),
          'error'
        )
      );
    } else if (!filename.match(ALLOWED_FILENAME_REGEX)) {
      const removedSpecialCharacters = filename.replace(
        NOT_ALLOWED_FILENAME_REGEX,
        ''
      );
      const adaptedFilename = `${removedSpecialCharacters}${extension}`;
      handleSetAttachment({ originalFile, originalFilename: adaptedFilename });
    } else {
      handleSetAttachment({ originalFile });
    }
  };

  const handleFileReaderInputChange = (e) => {
    // const originalFile = e.target.files[0];

    const files = Array.from(e.target.files).map((file) => file);

    if (
      calcCombinedAttachmentSize(files, attachments) > MAX_COMBINED_FILE_SIZE
    ) {
      dispatch(
        openSnackbar(
          i18n.t('slideouts-CreateInternal-30mb', { fileSizeLimit: 30 }),
          'error'
        )
      );
      return;
    }

    if (files.length + attachments.length > 10) {
      dispatch(
        openSnackbar(i18n.t('slideouts-CreateInternal-limit10'), 'error')
      );
      return;
    }

    files.forEach((file) => validateAttachment(file));

    e.target.value = null;

    // validateAttachment(originalFile);
    // e.target.value = null;
  };

  const handleOnDragEnter = (e) => {
    e.preventDefault();
    setIsDraggingOnPage(true);
  };

  const handleOnDragLeave = (e) => {
    e.preventDefault();
    setIsDraggingOnPage(false);
  };

  const handleOnDrop = (acceptedFiles) => {
    setIsDraggingOnPage(false);
    validateAttachment(acceptedFiles[0]);
  };

  const handleEmojiSelection = (emoji) => {
    const selectionStart = messageInput.current.selectionStart; // cursor group in input
    const beforeSelection = messageInput.current.value.slice(0, selectionStart);
    const afterSelection = messageInput.current.value.slice(selectionStart);
    setMessageInputValue(`${beforeSelection}${emoji.native} ${afterSelection}`);
    setEmojiMenuOpen(false);

    setTimeout(() => messageInput?.current?.focus?.(), 0);
  };

  const handleOnModalError = () => {
    clearToFieldBubble();
    setAddEmailModalContact(null);
    setAddActiveEmailModal(false);
    setAddEmailModalOpenedFrom('');
    dispatch(
      openSnackbar(
        i18n.t('slideouts-CreateEmail-errorAddEmail', {
          defaultValue: 'There was an error adding email address.',
        }),
        'error'
      )
    );
  };

  const renderAttachmentSection = () =>
    attachments.length ? (
      <FullFormRow>
        <CreateFormAttachment
          attachments={attachments}
          onClose={deleteAttachment}
          disableSendAsLink
          onAnnotate={(i) => {
            setAnnotationAttachmentIndex(i);
            setAnnotate(true);
          }}
          allowAnnotation
        />
      </FullFormRow>
    ) : null;

  const handleCloseAnnotation = (uri) => {
    setAnnotate(false);
    handleSaveAnnotation(uri);
  };

  const handleSaveAnnotation = (uri) => {
    const activeAttachment = attachments[annotationAttachmentIndex];
    const newAttachment = {
      data: uri,
      originalFilename: activeAttachment.originalFilename,
      type: activeAttachment.type,
      // id: attachment.id,
      id: uniqid(), // creating a new id on annotation save, so that the image for sure re-renders when new state set.
      size: activeAttachment.size,
    };

    deleteAttachment(activeAttachment.id);
    setAttachments((attachments) => [...attachments, newAttachment]);
  };

  const inferAvatarChildren = (contact) => {
    if (contact?.__typename === PK_TYPENAMES.CUSTOMER_CONTACT) {
      return contact?.firstName && contact?.lastName
        ? contactName(contact)
        : undefined;
    }

    return undefined;
  };

  const inferEntityCardMainText = (contact) => {
    if (contact?.__typename === PK_TYPENAMES.CUSTOMER_CONTACT) {
      return contact?.firstName && contact?.lastName
        ? contactName(contact)
        : contactEmail;
    }
    if (!contact && contactEmail) {
      return contactEmail;
    }
    return '';
  };

  const inferEntityCardSubText = (contact) => contact?.account?.name || '';

  const avatarUrl = resolveAvatarUrl(contact);

  const sharedValidation = !!(
    contactEmail && (showFromField ? senderGroup?.value : true)
  );

  const enableSubmissionWithMessage = sharedValidation && messageInputValue;
  const enableSubmissionWithAttachment = sharedValidation && attachments.length;

  const checkTemplateDisabled =
    !contactEmail ||
    !senderGroup ||
    contact?.__typename === 'User' ||
    contact?.__typename === 'Group';

  const handleTemplateButtonClick = () => {
    if (!checkTemplateDisabled) {
      setTemplateModalOpen(!templateModalOpen);
    }
  };

  return (
    <Dropzone
      disableClick
      multiple={false}
      onDragEnter={handleOnDragEnter}
      onDragLeave={handleOnDragLeave}
      onDrop={handleOnDrop}
      style={modalBodyStyleWithDragging}
    >
      <Container>
        <InnerContainer centerVertically={isDraggingOnPage}>
          {isDraggingOnPage ? (
            <UploadArt height="60%" />
          ) : (
            <>
              <HeadingWrap>
                <Avatar size="lg" type="external" icon="emailChannel" />
                <Heading2 dataTestId="new-email-header">
                  {i18n.t('slideouts-CreateEmail-newEmail', {
                    defaultValue: 'New Email',
                  })}
                </Heading2>
              </HeadingWrap>
              {!emailFeatureAvailable && (
                <InputError
                  error={i18n.t('slideouts-CreateMessage-notEnabled', {
                    defaultValue: 'This channel is not enabled for this group',
                  })}
                />
              )}
              <Select
                label={i18n.t('slideouts-CreateThread-fromLabel')}
                options={groupOptions}
                value={senderGroup}
                onChange={(option) => setSenderGroup(option)}
                disabled={!emailFeatureAvailable || !showFromField}
              />
              {contactEmail === '' && !contact?.id ? (
                <InfiniteSuggest
                  inputProps={{
                    label: i18n.t('slideouts-CreateThread-toLabel'),
                    onBlur: certifyToInputValueOnBlur,
                    error: toFieldError,
                    value: contactText,
                    onChange: onToFieldChange,
                    autoFocus: true,
                    disabled: !emailFeatureAvailable,
                    placeholder: i18n.t('slideouts-CreateEmail-enterEmail', {
                      defaultValue: 'Enter email',
                    }),
                    rightSideLabelContent: !addCCField &&
                      emailFeatureAvailable && (
                        <Button
                          size="zero"
                          type="noStyle"
                          onClick={() => setAddCCField(true)}
                          customStyle={addCcCustomStyle}
                        >
                          {i18n.t('slideouts-CreateEmail-addCc')}
                        </Button>
                      ),
                  }}
                  query={SEARCH_CUSTOMERS_QUERY}
                  queryKey="searchCustomers"
                  queryVariables={{
                    query: contactText,
                    after: null,
                    typeFilter: 'CUSTOMER_CONTACT',
                  }}
                  onSelect={onSuggestionSelected}
                  includeGroupIndicator
                  isDisabled={(node) =>
                    node?.emailsOptOut || hasEmailBlocked(node)
                  }
                  rightAlignedText={(node) => {
                    if (hasEmailBlocked(node)) {
                      return i18n.t('threads-CreateThread-blocked', {
                        defaultValue: 'Blocked',
                      });
                    }
                    if (node?.emailsOptOut) {
                      return i18n.t(
                        'slideouts-GroupMessageRecipients-optedOut'
                      );
                    }
                    if (!node?.emailAddress) {
                      return i18n.t('slideouts-CreateEmail-addEmail', {
                        defaultValue: 'Add email',
                      });
                    }
                    return undefined;
                  }}
                />
              ) : (
                <div style={{ position: 'relative' }}>
                  {!addCCField && (
                    <Button
                      size="zero"
                      type="noStyle"
                      onClick={() => setAddCCField(true)}
                      disabled={!emailFeatureAvailable}
                      customStyle={(props) =>
                        `position: absolute;
                        top: 8px;
                        right: 0;
                        ${addCcCustomStyle(props)}
                        `
                      }
                    >
                      {i18n.t('slideouts-CreateEmail-addCc')}
                    </Button>
                  )}
                  <SelectedEntity
                    label={i18n.t('slideouts-CreateThread-toLabel')}
                    avatarChildren={inferAvatarChildren(contact)}
                    maintext={inferEntityCardMainText(contact)}
                    subtext={inferEntityCardSubText(contact)}
                    onDelete={clearToFieldBubble}
                    avatarProps={{
                      avatarUrl,
                      type: 'external',
                    }}
                  />
                </div>
              )}
              <>
                {addCCField && (
                  <InfiniteSuggest
                    inputProps={{
                      label: i18n.t('slideouts-CreateEmail-cC'),
                      onBlur: certifyCCInputValueOnBlur,
                      value: carbonCopyContactText,
                      onChange: onCCFieldChange,
                      disabled:
                        carbonCopyContacts?.length === 10 ||
                        !emailFeatureAvailable,
                      autoFocus: true,
                      placeholder: i18n.t('slideouts-CreateEmail-searchBy'),
                      hideBottomSpace: carbonCopyContacts?.length >= 1,
                      rightSideLabelContent: carbonCopyContacts?.length >=
                        1 && (
                        <Text
                          customStyle={(props) =>
                            `font-size: 12px; color: ${
                              carbonCopyContacts?.length === 10
                                ? THEMES.THEME_RED(props)
                                : THEMES.FOREGROUND_HIGH(props)
                            }`
                          }
                        >
                          {carbonCopyContacts?.length}
                          /10
                        </Text>
                      ),
                    }}
                    query={SEARCH_CUSTOMERS_QUERY}
                    queryKey="searchCustomers"
                    queryVariables={{
                      query: carbonCopyContactText,
                      after: null,
                      typeFilter: 'CUSTOMER_CONTACT',
                    }}
                    onSelect={onCCSuggestionSelected}
                    includeGroupIndicator
                    isDisabled={(node) =>
                      node?.emailsOptOut || hasEmailBlocked(node)
                    }
                    rightAlignedText={(node) => {
                      if (hasEmailBlocked(node)) {
                        return i18n.t('threads-CreateThread-blocked', {
                          defaultValue: 'Blocked',
                        });
                      }
                      if (node?.emailsOptOut) {
                        return i18n.t(
                          'slideouts-GroupMessageRecipients-optedOut'
                        );
                      }
                      if (!node?.emailAddress) {
                        return i18n.t('slideouts-CreateEmail-addEmail', {
                          defaultValue: 'Add email',
                        });
                      }
                      return undefined;
                    }}
                  />
                )}
                {carbonCopyContacts?.length >= 1 && (
                  <CarbonCopyChipsWrapper>
                    {carbonCopyContacts.map((contact, i) => {
                      const { emailAddress } = contact;
                      const name = contactName(contact);
                      return (
                        <ChipContainer key={i}>
                          <Chip
                            onDelete={() => onCarbonCopyContactDeleted(contact)}
                            customMaintextStyle={() =>
                              'max-width: 150px; text-overflow: ellipsis; overflow: hidden;'
                            }
                          >
                            {name || emailAddress}
                          </Chip>
                        </ChipContainer>
                      );
                    })}
                  </CarbonCopyChipsWrapper>
                )}
                <TextInput
                  ref={subjectInput}
                  value={messageSubjectValue}
                  label={i18n.t(
                    'slideouts-ForwardMessageSlideout-subjectLabel'
                  )}
                  onChange={handleSubjectInputChange}
                  placeholder={i18n.t('slideouts-CreateEmail-enterSub', {
                    defaultValue: 'Enter Subject',
                  })}
                  disabled={!emailFeatureAvailable}
                />
                <TextArea
                  label={i18n.t('slideouts-CreateThread-message')}
                  rightSideLabelContent={
                    emailFeatureAvailable && (
                      <CreateMultiTag tags={tags} setTags={setTags} />
                    )
                  }
                  placeholder={i18n.t('slideouts-CreateThread-pressShift')}
                  onKeyPress={handleEnterSubmit}
                  value={messageInputValue}
                  onChange={handleInputChange}
                  onPaste={handleOnPaste}
                  ref={messageInput}
                  id="textarea"
                  name="messageInputValue"
                  rows={4}
                  disabled={!emailFeatureAvailable}
                  dataTestId="at-textarea"
                  customLabelWrapperStyle={() => `
                    align-items: flex-end;
                    max-height: 38px;
                  `}
                />
              </>
              {renderAttachmentSection()}
              <CreateSectionButtons
                attachment={attachments}
                enableSubmissionWithAttachment={
                  !!enableSubmissionWithAttachment
                }
                enableSubmissionWithMessage={!!enableSubmissionWithMessage}
                handleMutationStartThread={handleMutationStartThread}
                checkTemplateDisabled={checkTemplateDisabled}
                hasSmsChannel={emailFeatureAvailable}
                handleTemplateButtonClick={handleTemplateButtonClick}
                handleFileReaderInputChange={handleFileReaderInputChange}
                handleEmojiSelection={handleEmojiSelection}
                emojiMenuOpen={emojiMenuOpen}
                setEmojiMenuOpen={setEmojiMenuOpen}
                setMessageInputValue={setMessageInputValue}
                setAttachment={setAttachments}
                templateModalOpen={templateModalOpen}
                setTemplateModalOpen={setTemplateModalOpen}
                multiple
                handleAddSignature={handleAddSignature}
                emailSigOn={!!emailSigOn}
                emailFeatureAvailable={emailFeatureAvailable}
                messageInput={messageInput}
                emailSignature
                contactId={contact?.id}
                senderGroupId={senderGroup?.value}
                channelType="EMAIL"
              />
            </>
          )}
        </InnerContainer>
        <AnnotationsModal
          open={annotate}
          setOpen={setAnnotate}
          attachment={attachments[annotationAttachmentIndex]}
          handleSave={handleCloseAnnotation}
        />
      </Container>
      {!!addEmailModalContact && (
        <AddEmailAddress
          open={addActiveEmailModal}
          onClose={() => {
            if (addEmailModalOpenedFrom === 'toField') {
              clearToFieldBubble();
            }
            setAddActiveEmailModal(false);
            setAddEmailModalContact(null);
          }}
          onSave={(contactEmail) => {
            setAddActiveEmailModal(false);
            if (addEmailModalOpenedFrom === 'toField') {
              setContactEmail(contactEmail);
            }
            if (addEmailModalOpenedFrom === 'cCField') {
              setCarbonCopyContacts([
                ...carbonCopyContacts,
                { ...addEmailModalContact, emailAddress: contactEmail },
              ]);
            }
            setAddEmailModalContact(null);
            dispatch(
              openSnackbar(
                i18n.t('slideouts-CreateEmail-emailAdded', {
                  contactName: contactName(addEmailModalContact),
                  defaultValue: `Email address for ${contactName(
                    addEmailModalContact
                  )} has been added.`,
                })
              )
            );
          }}
          onError={handleOnModalError}
          contact={addEmailModalContact}
        />
      )}
    </Dropzone>
  );
};

CreateEmail.propTypes = {
  createSection: PropTypes.object.isRequired,
};

export default CreateEmail;
