import { useMutation } from "@apollo/client";
import { openThreadSubscriptionHandler } from "../../configs/apollo/setupSubscriptions";
import CLOSE_THREAD_MUTATION from "../../graphql/mutation/CloseThreadMutation.graphql";

/*

DOCUMENTATION
  generic hook for using close thread mutation

  args:
    client: (obj) required. apollo client instance

  returns:
    [0] handleCloseThread: func that will call close thread mutation.
      args:
        threadId: (str) required. id of thread to close.
        onSuccess: (func) optional, will call on mutation success
        onError: (func) optional, will call on mutation failure

*/

const useCloseThreadMutation = ({ client, store }) => {
  const [closeThread] = useMutation(CLOSE_THREAD_MUTATION, { client });

  const handleCloseThread = async ({
    threadId,
    onSuccess = () => {},
    onError = () => {},
  }) => {
    const { data } = await closeThread({
      variables: {
        input: {
          threadId,
        },
      },
    }).catch((err) => {
      onError(err);
      console.error(err);
    });
    if (data && data.archiveThread && data.archiveThread.errors) {
      onError(data.archiveThread.errors[0]);
    } else {
      const thread = data?.archiveThread?.thread;
      onSuccess();
      if (store) openThreadSubscriptionHandler(store, client, thread);
    }
  };

  return [handleCloseThread];
};

export default useCloseThreadMutation;
