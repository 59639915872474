import React from 'react';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import THEMES from '../../styles/themes/app';
import { Button } from '../../elements';

const BottomFooter = styled.div`
  background-color: ${THEMES.BACKGROUND_SECONDARY};
  color: ${THEMES.FOREGROUND_HIGH};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px;
  height: 60px;
`;

const FooterButtonContainer = styled.div`
  margin-left: 12px;
`;

const CustomerSlideoutBottomFooter = ({
  handleClickOnMode,
  onSubmit,
  submitDisabled,
  mutationLoading,
}) => {
  return (
    <BottomFooter>
      <FooterButtonContainer>
        <Button onClick={() => handleClickOnMode('static')} type="secondary">
          {i18n.t('slideouts-EditSaveCancel-cancel')}
        </Button>
      </FooterButtonContainer>
      <FooterButtonContainer>
        <Button
          loadingSpinner={mutationLoading}
          disabled={!!submitDisabled}
          onClick={onSubmit}
        >
          {i18n.t('slideouts-EditSaveCancel-save')}
        </Button>
      </FooterButtonContainer>
    </BottomFooter>
  );
};

CustomerSlideoutBottomFooter.propTypes = {
  handleClickOnMode: PropTypes.func.isRequired,
  mutationLoading: PropTypes.bool.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CustomerSlideoutBottomFooter;
