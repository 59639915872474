import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { WORK_ORDERS_QUERY } from 'client-lib';
import i18n from 'i18n-js';
import THEMES from '../../styles/themes/library/button';
import { Button, InfiniteSuggest } from '../../elements';

const LowerThatJunk = styled.div`
  margin-bottom: -2px;
  position: relative;
  @media print {
    display: none;
  }
  div[role='menuitem'] {
    div {
      width: 250px;
    }
    p {
      max-width: 85%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const addRefNumberCustomStyles = (props) => `
  color: ${THEMES.LINK_TEXT(props)};
  height: 24px;
  padding: 0;
  :hover {
    color: ${THEMES.LINK_TEXT_HOVER(props)};
  }
`;

const AddTag = ({
  tagInputValue,
  setTagInputValue,
  editingRefNum,
  setEditingRefNum,
  handleAddThreadToWorkOrder,
  flipWorkOrderOptions,
}) => {
  const handleOnBlur = () => {
    // handleAddThreadToWorkOrder(tagInputValue);
    setEditingRefNum(false);
  };

  const handleOnSubmit = (tagInputValue) => {
    setTagInputValue('');
    handleAddThreadToWorkOrder(tagInputValue);
  };

  const handleStickyButton = (suggestions) => {
    if (tagInputValue?.trim() === '') return false;

    const displaySticky = suggestions.reduce((acc, suggestion) => {
      if (!acc) return false;
      return !(
        suggestion?.referenceNumber?.toLowerCase() ===
        tagInputValue?.toLowerCase()
      );
    }, true);

    return displaySticky;
  };

  return (
    <LowerThatJunk data-testid="reference-number">
      {editingRefNum ? (
        <InfiniteSuggest
          inputProps={{
            onBlur: handleOnBlur,
            value: tagInputValue,
            onChange: (e) => setTagInputValue(e.target.value),
            hideBottomSpace: true,
            customContainerStyle: () => 'width: 225px;',
            customInputStyle: () => 'max-height: 32px;',
            autoFocus: true,
          }}
          query={WORK_ORDERS_QUERY}
          queryKey="workOrders"
          queryVariables={{ referenceNumber: tagInputValue }}
          onSelect={(e, { suggestion }) =>
            handleOnSubmit(suggestion?.referenceNumber)
          }
          displayStickyButton={(suggestion) => handleStickyButton(suggestion)}
          stickyButtonText={i18n.t('threads-Tag-tagSuggestion-create', {
            tag: tagInputValue,
            defaultValue: `Create tag "${tagInputValue}"`,
          })}
          onStickyButtonClick={() => handleOnSubmit(tagInputValue)}
          onUnhighlightedEnter={() => handleOnSubmit(tagInputValue)}
          isDisabled={() => false}
          flipOptions={flipWorkOrderOptions}
        />
      ) : (
        <Button
          type="noStyle"
          size="md"
          onClick={() => setEditingRefNum(true)}
          customStyle={addRefNumberCustomStyles}
          dataTestId="add-tag-button"
          noOutline
        >
          {i18n.t('threads-Tag-addNewTag', { defaultValue: 'Add new tag' })}
        </Button>
      )}
    </LowerThatJunk>
  );
};

AddTag.propTypes = {
  tagInputValue: PropTypes.string.isRequired,
  setTagInputValue: PropTypes.func.isRequired,
  editingRefNum: PropTypes.bool.isRequired,
  setEditingRefNum: PropTypes.func.isRequired,
  handleAddThreadToWorkOrder: PropTypes.func.isRequired,
  flipWorkOrderOptions: PropTypes.bool,
};

AddTag.defaultProps = {
  flipWorkOrderOptions: false,
};

export default AddTag;
