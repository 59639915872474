import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import { Button } from '../../../elements';
import IgnoreThread from '../../Modals/IgnoreThread';

const IgnoreThreadButton = ({ activeThread }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button
        type="secondaryLight"
        onClick={() => setModalOpen(true)}
        dataTestId="threadsIgnoreButton"
      >
        {i18n.t('threads-ThreadDetailsHeader-ignore')}
      </Button>
      <IgnoreThread
        open={modalOpen}
        setIsOpen={setModalOpen}
        messageId={activeThread?.latestMessage?.id}
        contactName={contactName(activeThread?.externalContact)}
      />
    </>
  );
};

IgnoreThreadButton.propTypes = {
  activeThread: PropTypes.object.isRequired,
};

export default IgnoreThreadButton;
