import { useSelector } from 'react-redux';
import { webEventTrack } from 'client-lib';

const useEventTracker = () => {
  const activeGroupId = useSelector(
    (state) => state.session?.threadsActiveGroupIds?.[0]
  );
  const track = (eventName, properties) =>
    webEventTrack(eventName, properties, activeGroupId);

  return {
    track,
  };
};

export default useEventTracker;
