import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  useCustomerGroupEdit,
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
} from 'client-lib';
import { useApolloClient } from '@apollo/client';
import i18n from 'i18n-js';
import CustomerEditInput from '../../Inputs/CustomerEditInput';
import EditSaveCancel from './EditSaveCancel';
import { AppendageList } from '../../../elements';
import useGetUserAccountPolicies from '../../../hooks/customer/useGetUserAccountPolicies';

const CustomerGroupEdit = ({ onSave, onCancel, customer }) => {
  const client = useApolloClient();
  const userGroupIds = useSelector(
    (state) => state?.session?.currentUser?.groupIds
  );
  const { accountPolicies = [], groupPolicies = [] } = useSelector(
    (state) => state?.session?.currentUser
  );
  const allGroups = useSelector((state) => state?.accountData?.allGroups);
  const userId = useSelector((state) => state?.session?.currentUser?.id);

  useGetUserAccountPolicies({
    userId,
    actionList: [
      AVAILABLE_PERMISSIONS.EDIT_CUSTOMERS,
      AVAILABLE_PERMISSIONS.EDIT_GROUP,
    ],
  });

  const {
    groupDropdownVal,
    invalidMessage,
    groupIds,
    removeFromGroupIds,
    onGroupInputChange,
    onSuggestionSelect,
    handleSubmit,
    handleOnBlur,
  } = useCustomerGroupEdit({ customer, onSave, client });

  const selectedGroups = allGroups.filter((group) =>
    groupIds.includes(group.id)
  );

  const renderWithinRemoveableListItem = (selectedGroup) => selectedGroup.name;

  const userApartOfGroup = (selectedGroup) =>
    userGroupIds === undefined
      ? false
      : userGroupIds.includes(selectedGroup.id);

  const showRemove = (selectedGroup) => {
    const hasEditCustomersPermission = checkIfCurrentUserHasPermission(
      AVAILABLE_PERMISSIONS.EDIT_CUSTOMERS,
      accountPolicies,
      groupPolicies
    );

    const hasEditGroupPerimission = checkIfCurrentUserHasPermission(
      AVAILABLE_PERMISSIONS.EDIT_GROUP,
      accountPolicies,
      groupPolicies
    );

    const isApartOfGroup = userApartOfGroup(selectedGroup);

    return !!(
      hasEditCustomersPermission &&
      (hasEditGroupPerimission || isApartOfGroup)
    );
  };

  return (
    <div data-testid="customer-group-edit">
      <CustomerEditInput
        title={i18n.t('slideouts-CustomerInfo-groups')}
        value={groupDropdownVal}
        onChange={onGroupInputChange}
        invalidMessage={invalidMessage}
        onSelect={onSuggestionSelect}
        specialtyType="groupSuggest"
        onBlur={handleOnBlur}
        autoFocus
      >
        <AppendageList
          list={selectedGroups}
          listItemRender={renderWithinRemoveableListItem}
          onDelete={removeFromGroupIds}
          displayDelete={showRemove}
          dataTestId="rlasa-listitem"
        />
      </CustomerEditInput>
      <EditSaveCancel onSave={handleSubmit} onCancel={onCancel} />
    </div>
  );
};

CustomerGroupEdit.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  customer: PropTypes.instanceOf(Object).isRequired,
};

CustomerGroupEdit.defaultProps = {
  onSave: () => {},
  onCancel: () => {},
};

export default CustomerGroupEdit;
