import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { AVAILABLE_PERMISSIONS, useEditAccountDetails } from 'client-lib';
import { contactName } from 'client-lib/src/lib/utils/helpers';

import i18n from 'i18n-js';
import CustomerAccountCardStatic from './CustomerAccountCardStatic';
import { LoadingWrapper } from '../CustomerInfo';
import { Loading } from '../../../../elements';
import CustomerAccountEdit from './CustomerAccountEdit';
import { openSnackbar } from '../../../../actions/general';
import CustomerAccountContactMode from './CustomerAccountContactMode';
import useGetUserAccountPolicies from '../../../../hooks/customer/useGetUserAccountPolicies';

const CustomerAccountCardSlideout = ({
  loading,
  closeCustomerInfo,
  customerAccount,
  setActiveCustomerSource,
  styledTheme,
  history,
  sectionItemCustomStyle,
  accountPolicies,
  groupPolicies,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state?.session?.currentUser);
  const [viewMode, setViewMode] = useState('static');
  const [search, setSearch] = useState('');
  const [focus, setFocus] = useState(false);

  useGetUserAccountPolicies({
    userId: currentUser?.userId,
    actionList: [AVAILABLE_PERMISSIONS.EDIT_COMPANIES],
  });

  const filteredContactList = customerAccount?.contacts.filter((contact) =>
    contactName(contact).toLowerCase().includes(search)
  );

  const handleOnChange = (val) => {
    setSearch(val.target.value);
  };

  const handleClickOnMode = (toggle, focusOn) => {
    setViewMode(toggle);
    if (focusOn) {
      setFocus(focusOn);
    }
  };

  const handleSetFocus = (focusOn) => {
    setFocus(focusOn);
  };

  const handleOnClose = () => {
    handleClickOnMode('static');
    dispatch(openSnackbar(i18n.t('customers-CustomerForm-success')));
  };
  const initialAccountValues = {
    id: customerAccount?.id,
    accountNumber: customerAccount?.accountNumber,
    name: customerAccount?.name,
    phoneNumber: customerAccount?.phoneNumber,
    email: customerAccount?.emailAddress,
    notes: customerAccount?.notes,
    notesUpdatedAt: customerAccount?.notesUpdatedAt,
  };
  const accountDetailProps = useEditAccountDetails({
    currentUser,
    client,
    handleOnClose,
    // onHandleError,
    i18n,
    initialFormValues: initialAccountValues,
  });

  const { isVisitedForm, onSubmit, mutationLoading, userCanEditCompanies } =
    accountDetailProps;

  const submitDisabled = !isVisitedForm || mutationLoading;

  return loading ? (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  ) : (
    <>
      {viewMode === 'static' && (
        <CustomerAccountCardStatic
          loading={loading}
          closeCustomerInfo={closeCustomerInfo}
          customerAccount={customerAccount}
          setActiveCustomerSource={setActiveCustomerSource}
          styledTheme={styledTheme}
          history={history}
          sectionItemCustomStyle={sectionItemCustomStyle}
          dispatch={dispatch}
          accountPolicies={accountPolicies}
          groupPolicies={groupPolicies}
          handleClickOnMode={handleClickOnMode}
          userCanEditCompanies={userCanEditCompanies}
        />
      )}
      {viewMode === 'edit' && (
        <CustomerAccountEdit
          handleClickOnMode={handleClickOnMode}
          accountDetailProps={accountDetailProps}
          mutationLoading={mutationLoading}
          onSubmit={onSubmit}
          submitDisabled={submitDisabled}
          focus={focus}
          handleSetFocus={handleSetFocus}
        />
      )}
      {viewMode === 'contact' && (
        <CustomerAccountContactMode
          closeCustomerInfo={closeCustomerInfo}
          contactList={filteredContactList}
          handleOnChange={handleOnChange}
          search={search}
          handleClickOnMode={handleClickOnMode}
        />
      )}
    </>
  );
};

CustomerAccountCardSlideout.propTypes = {
  closeCustomerInfo: PropTypes.func.isRequired,
  customerAccount: PropTypes.object.isRequired,
  setActiveCustomerSource: PropTypes.func.isRequired,
  styledTheme: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  sectionItemCustomStyle: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  accountPolicies: PropTypes.array.isRequired,
  groupPolicies: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default CustomerAccountCardSlideout;
