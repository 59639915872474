import React from 'react';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import { EntityCard } from '../../../elements';

const GroupHeader = ({ activeCustomerData, onAvatarNameClick, ...props }) => {
  const subtext =
    (activeCustomerData?.member_count ?? 0) > 1
      ? i18n.t('slideouts-GroupMessageRecipients-members', {
          number: activeCustomerData?.member_count,
        })
      : i18n.t('slideouts-GroupMessageRecipients-member', {
          number: activeCustomerData?.member_count,
        });

  return (
    <EntityCard
      {...props}
      avatarChildren={activeCustomerData?.name}
      maintext={activeCustomerData?.name}
      maintextProps={{ onClick: onAvatarNameClick }}
      subtext={subtext}
      customMaintextStyle={() => `margin: 0;`}
      customContainerStyle={() => 'border-width: 0;'}
      avatarProps={{
        icon: 'group',
        onClick: onAvatarNameClick,
      }}
    />
  );
};

GroupHeader.propTypes = {
  activeCustomerData: PropTypes.object,
  onAvatarNameClick: PropTypes.func,
};

GroupHeader.defaultProps = {
  onAvatarNameClick: undefined,
  activeCustomerData: null,
};

export default GroupHeader;
