import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { useDispatch, useSelector } from 'react-redux';
import {
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
  useHasEmailChannel,
  useHasFaxChannel,
  useHasSmsChannel,
} from 'client-lib';
import { useApolloClient } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import {
  Wrapper,
  IconWrap,
  createButtonStyle,
  CreateButtonWrap,
  createIconButtonStyle,
  CreateTitleWrapper,
  NoPrintIcon,
} from './styles';
import { Text, Button } from '../../elements';
import {
  closeCreateSectionModal,
  setActiveCreateAnnouncementSection,
  setActiveCreateEmailSection,
  setActiveCreateFaxSection,
  setActiveCreatePaymentSection,
  setActiveCreateThreadSection,
  setActiveCreateInternalSection,
} from '../../actions/general';
import { clearCreateSection } from '../../actions/createSection';
import useGetUserAccountPolicies from '../../hooks/customer/useGetUserAccountPolicies';

const CreateSidebar = ({
  dispatchActiveSidebar,
  activeSidebar,
  showAnnouncementsNavItem,
  currentUser,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const location = useLocation();

  const [currentLocation, setCurrentLocation] = useState(location.pathname);

  const activeCreateAnnouncementModal = useSelector(
    (state) => state?.general?.activeCreateAnnouncementModal
  );
  const activeCreateBroadcastListModal = useSelector(
    (state) => state?.general?.activeCreateBroadcastListModal
  );
  const activeCreateThreadModal = useSelector(
    (state) => state?.general?.activeCreateThreadModal
  );
  const activeCreateEmailModal = useSelector(
    (state) => state?.general?.activeCreateEmailModal
  );
  const activeCreateFaxModal = useSelector(
    (state) => state?.general?.activeCreateFaxModal
  );
  const activeCreateInternalModal = useSelector(
    (state) => state?.general?.activeCreateInternalModal
  );
  const activeCreatePaymentModal = useSelector(
    (state) => state?.general?.activeCreatePaymentModal
  );

  const faxFeatureAvailable = useSelector(
    (state) => state?.accountData?.account?.ff_fax
  );
  const emailFeatureAvailable = useSelector(
    (state) => state?.accountData?.account?.ff_email
  );
  const threadsActiveGroupIds = useSelector(
    (state) => state?.session?.threadsActiveGroupIds
  );
  const internalThreadsAvailable = useSelector(
    (state) => state?.accountData?.account?.ff_internal_threads
  );

  useGetUserAccountPolicies({
    userId: currentUser?.userId,
    actionList: [AVAILABLE_PERMISSIONS.CREATE_PAYMENT_REQUEST],
  });

  const paymentFeatureAvailable = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.CREATE_PAYMENT_REQUEST,
    currentUser?.accountPolicies,
    currentUser?.groupPolicies
  );

  const { hasEmailChannel } = useHasEmailChannel({
    currentUser,
    client,
    threadsActiveGroupIds,
  });

  const { hasFaxChannel } = useHasFaxChannel({
    currentUser,
    client,
    threadsActiveGroupIds,
  });

  const { hasSmsChannel } = useHasSmsChannel({
    currentUser,
    client,
    threadsActiveGroupIds,
  });

  const handleOnClose = () => {
    dispatchActiveSidebar('default');
    dispatch(closeCreateSectionModal());
    dispatch(clearCreateSection());
  };

  useEffect(() => {
    if (location?.pathname && location?.pathname !== currentLocation) {
      setCurrentLocation(location.pathname);
      handleOnClose();
    }
  }, [location, currentLocation]);

  return (
    <Wrapper createSideBarOpen={activeSidebar === 'create'}>
      <CreateTitleWrapper>
        <Text
          customStyle={() => 'font-size: 14px'}
          contrast="colorHigh"
          dataTestId="create-sidebar-title"
        >
          {i18n.t('sidebar-Compose-message', { defaultValue: 'Compose' })}
        </Text>
      </CreateTitleWrapper>
      <CreateButtonWrap>
        <Button
          type="noStyle"
          size="zero"
          noOutline
          onClick={() => handleOnClose()}
          enableRotate
          customStyle={createButtonStyle}
          dataTestId="x-button"
        >
          <NoPrintIcon className="ri-close-line" />
        </Button>
      </CreateButtonWrap>
      <IconWrap active={activeCreateThreadModal} isCreate isTop>
        <Button
          type="noStyle"
          size="zero"
          noOutline
          title={i18n.t('sidebar-CreateSidebar-thread', {
            defaultValue: 'Thread',
          })}
          active={activeCreateThreadModal}
          onClick={() => dispatch(setActiveCreateThreadSection())}
          customStyle={createIconButtonStyle}
          dataTestId={
            hasSmsChannel ? 'new-message-button' : 'disabled-message-button'
          }
          disabled={!hasSmsChannel}
        >
          <NoPrintIcon className="ri-question-answer-fill" />
        </Button>
      </IconWrap>
      {emailFeatureAvailable && (
        <IconWrap active={activeCreateEmailModal} isCreate>
          <Button
            type="noStyle"
            size="zero"
            noOutline
            title={i18n.t('settings-ContactList-email', {
              defaultValue: 'Email',
            })}
            active={activeCreateEmailModal}
            onClick={() => dispatch(setActiveCreateEmailSection())}
            customStyle={createIconButtonStyle}
            dataTestId={
              hasEmailChannel ? 'new-email-button' : 'disabled-email-button'
            }
            disabled={!hasEmailChannel}
          >
            <NoPrintIcon className="ri-mail-fill" />
          </Button>
        </IconWrap>
      )}
      {faxFeatureAvailable && (
        <IconWrap active={activeCreateFaxModal} isCreate>
          <Button
            type="noStyle"
            size="zero"
            noOutline
            title={i18n.t('slideouts-CustomerInfo-fax', {
              defaultValue: 'Fax',
            })}
            active={activeCreateFaxModal}
            onClick={() => dispatch(setActiveCreateFaxSection())}
            customStyle={createIconButtonStyle}
            dataTestId={
              hasFaxChannel ? 'new-fax-button' : 'disabled-fax-button'
            }
            disabled={!hasFaxChannel}
          >
            <NoPrintIcon className="ri-printer-fill" />
          </Button>
        </IconWrap>
      )}
      {internalThreadsAvailable && (
        <IconWrap active={activeCreateInternalModal} isCreate>
          <Button
            type="noStyle"
            size="zero"
            dataTestId="internal-message-btn"
            noOutline
            title={i18n.t('slideouts-TransferThread-internalMessage', {
              defaultValue: 'Internal Message',
            })}
            active={activeCreateInternalModal}
            onClick={() => dispatch(setActiveCreateInternalSection())}
            customStyle={createIconButtonStyle}
          >
            <NoPrintIcon className="ri-send-plane-2-fill" />
          </Button>
        </IconWrap>
      )}
      {showAnnouncementsNavItem && (
        <IconWrap
          active={
            activeCreateAnnouncementModal || activeCreateBroadcastListModal
          }
          isCreate
        >
          <Button
            type="noStyle"
            size="zero"
            noOutline
            title={i18n.t('settings-SettingsNavigation-broadcastsHover', {
              defaultValue: 'Broadcast Text',
            })}
            active={
              activeCreateAnnouncementModal || activeCreateBroadcastListModal
            }
            onClick={() => dispatch(setActiveCreateAnnouncementSection())}
            customStyle={createIconButtonStyle}
            dataTestId="broadcast-btn"
          >
            <NoPrintIcon className="ri-base-station-fill" />
          </Button>
        </IconWrap>
      )}
      {paymentFeatureAvailable && (
        <IconWrap active={activeCreatePaymentModal} isCreate>
          <Button
            type="noStyle"
            size="zero"
            noOutline
            title={i18n.t('sidebar-CreateSidebar-payment', {
              defaultValue: 'Payment',
            })}
            active={activeCreatePaymentModal}
            onClick={() => dispatch(setActiveCreatePaymentSection())}
            customStyle={createIconButtonStyle}
            dataTestId="collect-button"
          >
            <NoPrintIcon className="ri-money-dollar-box-fill" />
          </Button>
        </IconWrap>
      )}
    </Wrapper>
  );
};

CreateSidebar.propTypes = {
  dispatchActiveSidebar: PropTypes.func.isRequired,
  activeSidebar: PropTypes.string.isRequired,
  showAnnouncementsNavItem: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
};

export default CreateSidebar;
