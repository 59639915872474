import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from 'styled-theming';
import IconButton from '../Button/IconButton';
import COLORS from '../../styles/colors';
import useMeasure from '../../components/Animations/useMeasure';

const BOX_SHADOW = theme('mode', {
  classic: '0px 2px 10px rgba(0, 0, 0, 0.15)',
  light: '0px 2px 10px rgba(0, 0, 0, 0.15)',
  dark: '0 0 0 2px #3A3A3A',
  holiday: '0px 2px 10px rgba(0, 0, 0, 0.15)',
});
const BACKGROUND = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK_DARK.GREY_4,
  holiday: COLORS.PK.WHITE,
});

const Container = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
`;

const Slider = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 16px 0px;
  gap: 8px;
  overflow: visible;
  ${(props) => props?.customStyle?.()}
`;

const customPrevButtonStyle = (props) => `
  position: absolute;
  z-index: 10;
  margin-left: ${props?.marginLeft ? '13px' : '0'};
  background-color: ${BACKGROUND(props)};

  border: 1px solid #E5E2DC;
  box-shadow: ${BOX_SHADOW(props)};
  border-radius: 4px;
`;

const customNextButtonStyle = (props) => `
  position: absolute;
  right: 25px;
  z-index: 10;
  background-color: ${BACKGROUND(props)};

  border: 1px solid #E5E2DC;
  box-shadow: ${BOX_SHADOW(props)};
  border-radius: 4px;
`;

const Carousel = ({
  children,
  childWidth,
  previousButtonMargin,

  // custom styles
  customSliderStyle,
  customContainerStyle,

  // data-testid
  dataTestId,
}) => {
  const [translateX, setTranslateX] = useState(0);
  const [childrenCount, setChildrenCount] = useState(children?.length || 0);
  const [containerBind, containerMeasurements] = useMeasure();
  const [sliderBind, sliderMeasurements] = useMeasure();

  const sliderRef = useRef(null);

  const sliderWidth = sliderMeasurements?.width ?? 0;
  const containerWidth = containerMeasurements?.width ?? 0;
  const sliderIsWiderThanContainer = sliderWidth - containerWidth > 0;
  const sliderIsAtRightMostPosition =
    sliderWidth - containerWidth < translateX * -1;

  useEffect(() => {
    if (children?.length < childrenCount && sliderIsAtRightMostPosition) {
      if (translateX < -(childWidth - 30)) {
        setTranslateX((prev) => prev + (childWidth + 8));
      } else if (translateX === -(childWidth - 30)) {
        setTranslateX((prev) => prev + (childWidth - 30));
      }
    }
    setChildrenCount(children?.length);
  }, [children.length]);

  const nextClickHandler = () => {
    if (translateX === 0) {
      setTranslateX((prev) => prev - (childWidth - 30));
    } else {
      setTranslateX((prev) => prev - (childWidth + 8));
    }
  };

  const previousClickHandler = () => {
    if (translateX === -(childWidth - 30)) {
      setTranslateX((prev) => prev + (childWidth - 30));
    } else {
      setTranslateX((prev) => prev + (childWidth + 8));
    }
  };

  const showNextButton = () => {
    if (sliderIsWiderThanContainer && !sliderIsAtRightMostPosition) {
      return (
        <IconButton
          customStyle={customNextButtonStyle}
          onClick={nextClickHandler}
        >
          <i className="ri-arrow-right-s-line" />
        </IconButton>
      );
    }
    return null;
  };

  return (
    <Container
      customContainerStyle={customContainerStyle}
      css={(props) => props?.customContainerStyle?.(props)}
      data-testid={dataTestId}
      {...containerBind}
    >
      {translateX < 0 && (
        <IconButton
          customStyle={customPrevButtonStyle}
          onClick={previousClickHandler}
          marginLeft={previousButtonMargin}
        >
          <i className="ri-arrow-left-s-line" />
        </IconButton>
      )}
      <Slider
        customStyle={() =>
          `transform: translateX(${translateX}px); transition: transform 330ms ease-in;`
        }
        customSliderStyle={customSliderStyle}
        css={(props) => props?.customSliderStyle?.(props)}
        ref={sliderRef}
        {...sliderBind}
      >
        {children}
      </Slider>

      {showNextButton()}
    </Container>
  );
};

Carousel.propTypes = {
  children: PropTypes.array.isRequired,
  childWidth: PropTypes.number,
  previousButtonMargin: PropTypes.bool,
  customContainerStyle: PropTypes.func,
  customSliderStyle: PropTypes.func,
  dataTestId: PropTypes.string,
};

Carousel.defaultProps = {
  childWidth: 248,
  customContainerStyle: null,
  previousButtonMargin: false,
  customSliderStyle: null,
  dataTestId: null,
};

export default Carousel;
