import React, { useEffect, useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';
import i18n from 'i18n-js';
import {
  fileExtension,
  isAttachmentFileTypeSupported,
  ALLOWED_FILENAME_REGEX,
  fileNameWithoutExtension,
  NOT_ALLOWED_FILENAME_REGEX,
} from 'client-lib/src/lib/utils/helpers';
import uniqid from 'uniqid';
import UploadArt from '../Modals/UploadArt';
import { closeUploadModal, pushAttachment } from '../../actions/uploadModal';
import { openSnackbar } from '../../actions/general';
import { Modal } from '../../elements';
import THEMES from '../../styles/themes/app';

const modalBodyStyleWithoutDragging = ({ theme }) => ({
  height: '85%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  border: `12px solid ${THEMES.BACKGROUND_PRIMARY({ theme })}`,
});
const modalBodyStyleWithDragging = ({ theme }) => ({
  height: '85%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  border: `12px solid ${THEMES.THEME_GREEN({ theme })}`,
});

const DragAndDropModal = () => {
  const [isDraggingOnModal, setIsDraggingOnModal] = useState(false);

  const dispatch = useDispatch();
  const uploadModal = useSelector((state) => state?.uploadModal);

  const handleDraggingStart = (e) => {
    e.preventDefault();
  };

  const handleDraggingStop = (e) => {
    e.preventDefault();
  };

  const handleDragEnter = () => {
    setIsDraggingOnModal(true);
  };

  const closeModalIfNotDropzone = (e) => {
    e.preventDefault();
    dispatch(closeUploadModal());
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    dispatch(closeUploadModal());
  };

  const handleOnDragLeave = (e) => {
    e.preventDefault();
    setIsDraggingOnModal(false);
  };

  const handleSetAttachment = (
    originalFile,
    originalFilename = originalFile.name
  ) => {
    const reader = new window.FileReader();
    reader.onload = () => {
      const attachment = {
        data: reader.result,
        originalFilename,
        type: originalFile.type,
        id: uniqid(),
      };
      dispatch(pushAttachment(attachment));
      dispatch(closeUploadModal());
    };
    reader.readAsDataURL(originalFile);
  };

  const validateAttachment = (originalFile) => {
    const filename = fileNameWithoutExtension(originalFile.name);
    const extension = `.${fileExtension(originalFile.name)}`;
    if (!isAttachmentFileTypeSupported(originalFile.name)) {
      dispatch(
        openSnackbar(i18n.t('modals-UploadModal-unsupportedFile'), 'error')
      );
      dispatch(closeUploadModal());
    } else if (originalFile.size > 5000000) {
      dispatch(
        openSnackbar(i18n.t('modals-UploadModal-fileTooLarge'), 'error')
      );
      dispatch(closeUploadModal());
    } else if (!filename.match(ALLOWED_FILENAME_REGEX)) {
      const removedSpecialCharacters = filename.replace(
        NOT_ALLOWED_FILENAME_REGEX,
        ''
      );
      const adaptedFilename = `${removedSpecialCharacters}${extension}`;
      handleSetAttachment(originalFile, adaptedFilename);
    } else {
      handleSetAttachment(originalFile);
    }
  };

  const handleOnDrop = (files) => {
    setIsDraggingOnModal(false);

    const originalFile = files[0];

    validateAttachment(originalFile);
  };

  useEffect(() => {
    document.addEventListener('drop', closeModalIfNotDropzone);
    document.body.addEventListener('dragenter', handleDraggingStart, true);
    document.body.addEventListener('dragleave', handleDraggingStop, true);

    return () => {
      document.removeEventListener('drop', closeModalIfNotDropzone);
      document.body.removeEventListener('dragenter', handleDraggingStart, true);
      document.body.removeEventListener('dragleave', handleDraggingStop, true);
    };
  }, []);

  const styledTheme = useContext(ThemeContext);

  const modalBodyStyle = isDraggingOnModal
    ? modalBodyStyleWithDragging({ theme: styledTheme })
    : modalBodyStyleWithoutDragging({ theme: styledTheme });

  return (
    <Modal
      isOpen={uploadModal.modalOpen}
      onRequestClose={handleCloseModal}
      modalTitle={i18n.t('modals-UploadModal-uploadFile')}
    >
      <Dropzone
        multiple={false}
        onDragEnter={handleDragEnter}
        onDragLeave={handleOnDragLeave}
        onDrop={handleOnDrop}
        style={modalBodyStyle}
      >
        <UploadArt />
      </Dropzone>
    </Modal>
  );
};

export default DragAndDropModal;
