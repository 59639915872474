import React from 'react';
import theme from 'styled-theming';
import { Text } from './Text';

/**
 * Font size for the message text.
 * @type {string}
 */
export const MESSAGE_TEXT_FONTSIZE = theme('fontSize', {
  default: '15px',
  large: '19px',
});

/**
 * Renders a message text component that honors line breaks while applying custom styles.
 *
 * @param {Object} props - The props for the component.
 * @returns {JSX.Element} The rendered message text component.
 */

const MessageText = ({ ...props }) => (
  <Text
    customStyle={(props) => `
      white-space: pre-wrap;
      font-size: ${MESSAGE_TEXT_FONTSIZE(props)};
    `}
    {...props}
  />
);

export default MessageText;
