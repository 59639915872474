import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from '../../InfiniteScroll/InfiniteScroll';
import GroupMemberListCard from './GroupMemberListCard';

const USERS_TO_RETRIEVE = 25;

const ScrollableGroupMembers = ({
  users,
  handleFetchMoreTeamMembers,
  pageInfo,
  usersQueryLoading,
}) => {
  const handleFetchMore = ({ scrolling }) => {
    const variables = {
      first: USERS_TO_RETRIEVE,
      after: null,
      filter: null,
    };

    handleFetchMoreTeamMembers({
      variables,
      scrolling,
      afterCursor: !!scrolling,
      append: !!scrolling,
    });
  };

  const loadMoreRows = (scrolling) => {
    handleFetchMore({ scrolling });
  };

  const hasNextPage = !usersQueryLoading && users && pageInfo?.hasNextPage;

  return (
    <InfiniteScroll
      loadMoreRows={loadMoreRows}
      loading={usersQueryLoading}
      hasNextPage={hasNextPage}
      handleFetchMore={handleFetchMore}
      scrollableList={users}
      height="calc(100vh - 400px)"
      noRowsElement={<div />}
      listItemHeight={77}
      loadingHeight={77}
      renderRow={({ list, index }) => (
        <GroupMemberListCard entity={list[index]} />
      )}
      loadingBorderBottom
    />
  );
};

ScrollableGroupMembers.propTypes = {
  handleFetchMoreTeamMembers: PropTypes.func.isRequired,
  pageInfo: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  usersQueryLoading: PropTypes.bool.isRequired,
};

export default ScrollableGroupMembers;
