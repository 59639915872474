import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import {
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
  useEditCustomerDetails,
} from 'client-lib';
import { contactName, sortLabels } from 'client-lib/src/lib/utils/helpers';

import ContactCardEditDetails from './ContactCardEditDetails';
import { openSnackbar } from '../../../../actions/general';
import useGetUserAccountPolicies from '../../../../hooks/customer/useGetUserAccountPolicies';

const ContactCardEdit = ({
  customerContact,
  toggleEdit,
  handleFocusLabel,
  promoAccess,
  disableFooter,
  submitting,
  setSubmitting,
}) => {
  const currentUser = useSelector((state) => state.session.currentUser);
  const allGroups = useSelector((state) => state?.accountData?.allGroups);
  const dispatch = useDispatch();
  const apiMessageFeatureEnabled = useSelector(
    (state) => state?.accountData?.account?.ff_api
  );
  const { groupIds } = customerContact;

  const client = useApolloClient();
  const contactGroups = allGroups
    .filter((group) => groupIds.some((id) => id === group.id))
    .map((group) => {
      return { text: group.name, value: group.id };
    });

  const handleOnClose = (cleanUp) => {
    dispatch(openSnackbar(i18n.t('customers-CustomerForm-success')));
    toggleEdit('save');
    cleanUp();
  };

  const onHandleError = () => {
    dispatch(openSnackbar(i18n.t('contacts-updateContact-error'), 'error'));
  };
  const renderLabels = customerContact?.labels?.map((label) => {
    return { color: label.color, text: label.name, value: label.id };
  });

  const initialCustomerValues = {
    id: customerContact?.id,
    firstName: customerContact?.firstName,
    lastName: customerContact?.lastName,
    phoneNumber: customerContact?.phoneNumber,
    faxNumber: customerContact?.faxNumber,
    email: customerContact?.emailAddress,
    company: {
      value: customerContact?.account?.id,
      text: customerContact?.account?.name,
      addCompanySuggestion: false,
    },
    accountPhoneNumber: customerContact?.account?.phoneNumber,
    accountEmail: customerContact?.account?.emailAddress,
    accountNumber: customerContact?.account?.accountNumber,
    group: {
      value: contactGroups,
      text: '',
    },
    notes: customerContact?.notes,
    notesUpdatedAt: customerContact?.notesUpdatedAt,
    apiOptOut: customerContact?.apiOptOut,
    announcementsOptOut: customerContact?.announcementsOptOut,
    promotionalBroadcastOptOut: customerContact?.promotionalBroadcastOptOut,
    conversationsOptOut: customerContact?.conversationsOptOut,
    emailsOptOut: customerContact?.emailsOptOut,
    faxOptOut: customerContact?.faxOptOut,
    accountRep: {
      value: contactName(customerContact?.repUser),
      id: customerContact?.repUser?.id,
      lastSelectedName: contactName(customerContact?.repUser),
    },
    prioritizeRep: customerContact?.prioritizeRep,
    jobTitle: customerContact?.jobTitle,
    labels: { value: sortLabels(renderLabels), text: '', color: '' },
  };

  useGetUserAccountPolicies({
    userId: currentUser?.userId,
    actionList: [
      AVAILABLE_PERMISSIONS.EDIT_CONTACT_OPT_OUT,
      AVAILABLE_PERMISSIONS.EDIT_CONTACT_EMAIL_OPT_OUT,
      AVAILABLE_PERMISSIONS.EDIT_CONTACT_FAX_OPT_OUT,
      AVAILABLE_PERMISSIONS.EDIT_CUSTOMER_REP,
      AVAILABLE_PERMISSIONS.TOGGLE_PRIORITY_NOTIFICATIONS,
      AVAILABLE_PERMISSIONS.EDIT_COMPANIES,
      AVAILABLE_PERMISSIONS.EDIT_CONTACT_LABELS,
    ],
  });

  const editContactLabelsPermissions = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.EDIT_CONTACT_LABELS,
    currentUser?.accountPolicies,
    currentUser?.groupPolicies
  );

  const customerDetailsProps = useEditCustomerDetails({
    currentUser,
    client,
    handleOnClose,
    onHandleError,
    i18n,
    initialFormValues: initialCustomerValues,
    contactGroups,
    apiMessageFeatureEnabled,
    editContactLabelsPermissions,
  });

  const {
    isVisitedForm,
    onSubmit,
    mutationLoading,
    existingPhoneNumberId,
    existingFaxNumberId,
    existingEmailId,
  } = customerDetailsProps;

  const submitDisabled =
    !isVisitedForm ||
    mutationLoading ||
    existingPhoneNumberId ||
    existingFaxNumberId ||
    existingEmailId;

  const handleSubmit = () => {
    onSubmit();
  };

  useEffect(() => {
    if (submitting) {
      handleSubmit();
      setSubmitting(false);
    }
  }, [submitting]);

  return (
    <ContactCardEditDetails
      customerDetailsProps={customerDetailsProps}
      mutationLoading={mutationLoading}
      submitDisabled={!!submitDisabled}
      onSubmit={handleSubmit}
      handleFocusLabel={handleFocusLabel}
      handleOnClose={handleOnClose}
      promoAccess={promoAccess}
      disableFooter={disableFooter}
    />
  );
};

ContactCardEdit.propTypes = {
  customerContact: PropTypes.object.isRequired,
  toggleEdit: PropTypes.func.isRequired,
  handleFocusLabel: PropTypes.func.isRequired,
  promoAccess: PropTypes.bool,
  disableFooter: PropTypes.bool,
  submitting: PropTypes.bool,
  setSubmitting: PropTypes.func,
};

ContactCardEdit.defaultProps = {
  promoAccess: false,
  disableFooter: false,
  submitting: false,
  setSubmitting: () => {},
};

export default ContactCardEdit;
