import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import 'react-virtualized/styles.css';
import * as Sentry from '@sentry/react';
import clientLibPackageJson from 'client-lib/package.json';
import { ApolloSyncProvider, createApolloClient } from 'client-lib';
import App from './containers/App';
import store from './store';
import GlobalErrorBoundary from './GlobalErrorBoundary';
import '../assets/index.css';
// import '../assets/font-awesome.css';
import 'remixicon/fonts/remixicon.css';

console.log(`PROKEEP VERSION:${process.env.VERSION}+${process.env.COMMIT_SHA}`);
console.log(
  `CLIENT-LIB VERSION:${clientLibPackageJson.version}+${process.env.CLIENT_LIB_COMMIT_SHA}`
);
console.log(`PROKEEP ENVIRONMENT:${process.env.PK_ENV}`);

const { session } = store.getState();

if (
  process.env.SENTRY_ENVIRONMENT &&
  process.env.SENTRY_ENVIRONMENT !== 'development'
) {
  console.log('sentry init');
  const userId = session && session.currentUser && session.currentUser.userId;
  const accountId =
    session && session.currentUser && session.currentUser.accountId;
  const firstName =
    session && session.currentUser && session.currentUser.firstName;
  const lastName =
    session && session.currentUser && session.currentUser.lastName;

  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
    integrations: [Sentry.replayIntegration()],
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  Sentry.withScope((scope) => {
    scope.setUser({ id: userId });
    scope.setTag('pk-userId', userId);
    scope.setTag('pk-accountId', accountId);
    scope.setTag('pk-firstName', firstName);
    scope.setTag('pk-lastName', lastName);
    scope.setTag('pk-clientVersion', process.env.VERSION);
  });
}

// this should only exist in dev
if (module.hot) {
  console.log('module HOT!');
  module.hot.accept();
}

export const getNetworkInformation = async () => {
  const { effectiveType, downlink, rtt } = navigator.connection || {};

  return new Promise((resolve) => {
    resolve({
      effectiveType: effectiveType || 'unknown',
      downlink: downlink || 0,
      rtt: rtt || 0,
    });
  });
};

const Application = Sentry.withProfiler(App);

const ApolloClientProvider = () => {
  const apolloClient = createApolloClient(store, Sentry, getNetworkInformation);

  return (
    <ApolloProvider client={apolloClient}>
      <ApolloSyncProvider>
        <Application />
      </ApolloSyncProvider>
    </ApolloProvider>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <GlobalErrorBoundary>
      <ApolloClientProvider />
    </GlobalErrorBoundary>
  </Provider>
);
