import {
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
  thread_utils,
} from 'client-lib';
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18n-js';
import { faxNumber, phoneNumber } from 'client-lib/src/lib/utils/helpers';
import { useHistory } from 'react-router';
import {
  blockChannelConfirm,
  openBlockChannelModalThread,
} from '../../../actions';
import { Button } from '../../../elements';
import BlockChannel from '../../Modals/BlockChannel';
import { openSnackbar } from '../../../actions/general';
import useGetUserAccountPolicies from '../../../hooks/customer/useGetUserAccountPolicies';

const BlockButton = ({ activeThread }) => {
  const history = useHistory();
  const currentUser = useSelector((state) => state?.session?.currentUser);
  const showBlockChannelConfirmation = useSelector(
    (state) => state?.threadInfo?.showBlockChannelConfirmation
  );
  const channelToBeBlocked = useSelector(
    (state) => state?.threadInfo?.channelToBeBlocked
  );
  const dispatch = useDispatch();

  useGetUserAccountPolicies({
    userId: currentUser?.userId,
    actionList: [AVAILABLE_PERMISSIONS.BLOCK_CHANNEL],
  });

  const blockCustomerAccess = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.BLOCK_CHANNEL,
    currentUser?.accountPolicies,
    currentUser?.groupPolicies
  );

  const whichChannel = thread_utils.isTypeFax(activeThread)
    ? faxNumber(activeThread?.externalContact)
    : phoneNumber(activeThread?.externalContact);

  if (!blockCustomerAccess) return null;

  return (
    <>
      <Button
        type="destructive"
        onClick={() =>
          dispatch(openBlockChannelModalThread(true, whichChannel))
        }
      >
        {i18n.t('threads-ThreadDetailsHeader-block')}
      </Button>
      <BlockChannel
        onClose={() => {
          dispatch(openBlockChannelModalThread(false, ''));
        }}
        open={showBlockChannelConfirmation}
        channelToBeBlocked={channelToBeBlocked}
        accountId={currentUser?.accountId}
        openSnackbar={(msg) => dispatch(openSnackbar(msg))}
        activeCustomerId={activeThread?.externalContact?.id}
        blockChannelConfirm={() => dispatch(blockChannelConfirm(true))}
        onBlock={() => history.push('/threads/inbox')}
      />
    </>
  );
};

BlockButton.propTypes = {
  activeThread: PropTypes.object.isRequired,
};

export default BlockButton;
