import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import { useTransferThread, TRANSFER_TARGETS_QUERY } from 'client-lib';
import i18n from 'i18n-js';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import { Grid } from '@mui/material';
import { openSnackbar, closeAllSlideouts } from '../../actions/general';
import THEMES from '../../styles/themes/app';
import {
  Text,
  Heading1,
  Button,
  TextArea,
  IconButton,
  InfiniteSuggest,
  SelectedEntity,
  Tooltip,
  Checkbox,
} from '../../elements';
import { updateThreadNotifyPref } from '../../actions/session';

const FullFormRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const AutocompleteWrapper = styled.div`
  min-height: 60;
  padding: 15px 1rem 0px;
`;

const ActionRow = styled.div`
  padding: 1rem 7.5% 5%;
  display: flex;
  justify-content: space-between;
`;

const InfoMessage = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  background-color: ${THEMES.THEME_YELLOW};
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 10px;
`;

const FollowThreadWrapper = styled.div`
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  padding: 10px 0;
`;

const Header = styled.div`
  min-height: 62px;
  margin: 0;
  display: flex;
  align-items: center;
`;

const FormWrapper = styled.div`
  display: block;
  min-height: calc(100vh - 75px);
`;

const HeaderCloseSection = styled.div`
  background-color: ${THEMES.BACKGROUND_SECONDARY};
  padding: 1rem 1rem 0.5rem 1rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CloseIcon = styled.i`
  display: block;
  margin-right: -8px;
`;

const InfoWrapper = styled(Grid)`
  margin-left: 8px;
  font-size: 15px;
  color: ${THEMES.FOREGROUND_LOW};
  display: flex;
  align-items: flex-end;
`;

const FIRST = 10;

const TransferThread = ({ isTest }) => {
  const client = useApolloClient();
  const { activeThreadId, filter } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const currentUserId = useSelector(
    (state) => state.session.currentUser.userId
  );
  const transferNotificationPref = useSelector(
    (state) => state.session.currentUser.transferThreadNotification
  );

  const presenceTrackingAvailable =
    useSelector((state) => state.accountData.account?.ff_presence_tracking) ||
    isTest;

  const onTransferThreadSuccess = (notifiedPref) => {
    handleClose();
    dispatch(updateThreadNotifyPref(notifiedPref));
    history.push(`/threads/${filter}`);
    dispatch(
      openSnackbar(i18n.t('slideouts-TransferThread-transferThreadSuccess'))
    );
  };

  const onTransferThreadError = () => {
    dispatch(openSnackbar(i18n.t('slideouts-TransferThread-error'), 'error'));
  };

  const {
    messageInputValue,
    setMessageInputValue,
    stayNotified,
    setStayNotified,
    handleMutationTransferThread,
    threadData,
    transferTarget,
    setTransferTarget,
    loading,
  } = useTransferThread({
    client,
    threadId: activeThreadId,
    onTransferThreadSuccess,
    onTransferThreadError,
  });

  const [inputValue, setInputValue] = useState(''); // The string value from text input that the fuzzy search uses
  const [disabledCheckbox, setDisabledCheckbox] = useState(false);
  const searchInput = useRef();
  const messageInput = useRef();

  useEffect(() => {
    setStayNotified(transferNotificationPref);
  }, [transferNotificationPref]);

  const onChange = (e) => setInputValue(e.target.value);

  const handleMessageChange = (e) => setMessageInputValue(e.target.value);

  const handleSelectSuggestion = (e, { suggestion }) => {
    setTransferTarget(suggestion);
    if (suggestion?.__typename === 'User') {
      setInputValue(contactName(suggestion));
    } else if (suggestion?.__typename === 'Group') {
      setInputValue(suggestion.name);
    }
    // who knows why the time out is necessary here, the other focus call may be interfering?
    setTimeout(() => messageInput?.current?.focus(), 0);
  };

  const handleClose = () => {
    dispatch(closeAllSlideouts());
    setTransferTarget(null);
    setInputValue('');
  };

  const handleEnterSubmit = (e) => {
    if (e.shiftKey && e.key === 'Enter') {
      e.preventDefault();
      if (transferTarget.contactId !== '' || transferTarget.id !== '') {
        handleMutationTransferThread();
      }
    }
  };

  const clearToFieldBubble = () => {
    setInputValue('');
    setTransferTarget(null);
  };

  const certifyToInputValueOnBlur = () => {
    const approvedToClearInputs = isTest
      ? !transferTarget && document.activeElement.tagName !== 'INPUT'
      : !transferTarget;

    if (approvedToClearInputs) {
      setInputValue('');
      setTransferTarget(() => null);
    }
  };

  useEffect(() => {
    const postAnimationFocus = setTimeout(
      () => searchInput?.current?.focus(),
      350
    );
    return () => {
      clearTimeout(postAnimationFocus);
    };
  }, []);

  useEffect(() => {
    if (currentUserId === transferTarget?.id) {
      setDisabledCheckbox(true);
      setStayNotified(false);
    } else {
      setDisabledCheckbox(false);
    }
  }, [currentUserId, transferTarget?.id]);

  const [firstName] = inputValue.split();

  const avatarProps =
    transferTarget?.__typename === 'User'
      ? {
          avatarUrl: transferTarget?.avatarUrl,
          displayPresence: presenceTrackingAvailable,
          type: 'internal',
          active: transferTarget?.online,
          size: 'md',
        }
      : {
          size: 'md',
          icon: transferTarget?.__typename === 'Group' ? 'group' : null,
        };

  const queryVariables = {
    name: inputValue,
    limit: FIRST,
    threadId: threadData?.thread?.id,
    type: filter === 'inbox' ? 'GROUP' : null,
  };

  return (
    <FormWrapper data-testid="transfer-thread">
      <HeaderCloseSection>
        <ButtonWrapper>
          <IconButton
            noOutline
            title="close"
            size="lg"
            contrast="high"
            onClick={() => dispatch(closeAllSlideouts())}
            dataTestId="customer-info-close-button"
          >
            <CloseIcon className="ri-close-line" />
          </IconButton>
        </ButtonWrapper>
        <Header>
          <Heading1 contrast="high">
            {i18n.t('slideouts-TransferThread-transferThread')}
          </Heading1>
        </Header>
      </HeaderCloseSection>
      <AutocompleteWrapper>
        {!transferTarget ? (
          <InfiniteSuggest
            inputProps={{
              label: i18n.t('slideouts-TransferThread-toLabel'),
              value: inputValue,
              onChange: (e) => onChange(e),
              onBlur: certifyToInputValueOnBlur,
              ref: searchInput,
              placeholder: i18n.t('slideouts-TransferThreads-enterGroup', {
                defaultValue: 'Enter group',
              }),
            }}
            query={TRANSFER_TARGETS_QUERY}
            queryKey="transferTargets"
            queryVariables={queryVariables}
            onSelect={handleSelectSuggestion}
            includePresence={presenceTrackingAvailable}
          />
        ) : (
          <SelectedEntity
            label={i18n.t('slideouts-TransferThread-toLabel')}
            onDelete={clearToFieldBubble}
            dataTestId="entity-card"
            avatarProps={avatarProps}
            avatarChildren={`${firstName}`}
            maintext={`${firstName}`}
          />
        )}
      </AutocompleteWrapper>
      <FullFormRow style={{ marginBottom: 0, padding: '10px 1rem' }}>
        <TextArea
          label={i18n.t('slideouts-TransferThread-internalMessage')}
          value={messageInputValue}
          onChange={handleMessageChange}
          onKeyPress={handleEnterSubmit}
          ref={messageInput}
          id="textarea"
          name="messageInput"
          rows={6}
          dataTestId="tt-textarea"
        />
      </FullFormRow>
      {presenceTrackingAvailable &&
      !transferTarget?.online &&
      transferTarget?.contactId ? (
        <InfoMessage data-testid="info-message">
          <Text contrast="colorHigh">
            {i18n.t('slideouts-TransferThread-currentlyUnavailable')}
          </Text>
        </InfoMessage>
      ) : (
        ''
      )}
      <FollowThreadWrapper>
        <Checkbox
          disabled={disabledCheckbox}
          dataTestId="loop-checkbox"
          checked={stayNotified}
          onCheck={() => setStayNotified((prev) => !prev)}
          label={i18n.t('slideouts-TransferThread-followThread', {
            defaultValue: 'Keep me in the loop',
          })}
        />
        <Tooltip
          elementsContent={
            <Text customStyle={() => `max-width: 223px;`}>
              {i18n.t('slideouts-TransferThread-followToolTip', {
                defaultValue:
                  'Continue to receive \nnotifications for new messages \nin this conversation',
              })}
            </Text>
          }
          id="topTip"
        >
          <InfoWrapper data-tip data-for="topTip">
            <i className="ri-information-line" />
          </InfoWrapper>
        </Tooltip>
      </FollowThreadWrapper>
      <ActionRow style={{ padding: '10px 1rem' }}>
        <Button
          valid={!!transferTarget}
          disabled={!transferTarget || loading}
          onClick={handleMutationTransferThread}
          dataTestId="send-transfer-btn"
        >
          {presenceTrackingAvailable &&
          !transferTarget?.online &&
          transferTarget?.contactId
            ? i18n.t('slideouts-TransferThread-transferAnyway')
            : i18n.t('slideouts-TransferThread-send')}
        </Button>
      </ActionRow>
    </FormWrapper>
  );
};

TransferThread.propTypes = { isTest: PropTypes.bool };

TransferThread.defaultProps = { isTest: false };

export default TransferThread;
