import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useReactToPrint } from 'react-to-print';
import { Button, IconLabel } from '../../elements';
import THEMES from '../../styles/themes/library/button';

const addRefNumberCustomStyles = (props) => `
  color: ${THEMES.LINK_TEXT(props)};
  height: 24px;
  padding: 0;
  :hover {
    color: ${THEMES.LINK_TEXT_HOVER(props)};
  }
`;

const PrintContainer = styled.div`
  margin-bottom: -2px;
  display: flex;
  align-items: center;
`;

const PrintContent = styled.div`
  display: none;
`;

const PrintLabelContainer = styled.div`
  padding-right: 6px;
`;

const Print = ({
  children,
  printLabel,
  showIcon,
  printLoadingState,
  openMenu,
  isDoneLoading,
}) => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (openMenu && isDoneLoading) {
      handlePrint();
    }
  }, [isDoneLoading]);

  return (
    <PrintContainer>
      <PrintContent>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            ref: (ref) => {
              componentRef.current = ref;
            },
          });
        })}
      </PrintContent>
      {showIcon ? (
        <PrintLabelContainer>
          <IconLabel onClick={handlePrint} size="sm" contrast="highColor">
            <i className="ri-printer-fill" />
          </IconLabel>
        </PrintLabelContainer>
      ) : null}
      <Button
        disabled={printLoadingState}
        dataTestId="print-single-button"
        type="noStyle"
        size="md"
        noOutline
        onClick={handlePrint}
        customStyle={addRefNumberCustomStyles}
      >
        {printLabel}
      </Button>
    </PrintContainer>
  );
};

Print.propTypes = {
  children: PropTypes.object.isRequired,
  showIcon: PropTypes.bool,
  printLabel: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  printLoadingState: PropTypes.bool,
  openMenu: PropTypes.bool,
  isDoneLoading: PropTypes.bool,
};

Print.defaultProps = {
  showIcon: true,
  printLabel: '',
  printLoadingState: false,
  openMenu: false,
  isDoneLoading: false,
};

export default Print;
