import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { useSelector } from 'react-redux';
import {
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
  GROUPS_QUERY,
  availableGroupsByGroupIds,
} from 'client-lib';
import i18n from 'i18n-js';
import { AsyncSelect } from '../../elements';
import useGetUserAccountPolicies from '../../hooks/customer/useGetUserAccountPolicies';

const GroupAsyncSelect = ({
  value,
  setValue,
  error,
  omitAll,
  omitIds,
  permissionFilter = AVAILABLE_PERMISSIONS.MANAGE_TEAM,
  areBroadcastEnabled,
  noComponents,
  ...props
}) => {
  const client = useApolloClient();
  const [queryError, setQueryError] = useState('');

  const currentUser = useSelector((state) => state?.session?.currentUser);

  useGetUserAccountPolicies({
    actionList: [permissionFilter],
    userId: currentUser?.userId,
  });

  // only give options based on permissions :)
  const generateOptions = (groups) => {
    let groupsAvailable = [];

    const canManageTeam = checkIfCurrentUserHasPermission(
      permissionFilter,
      currentUser.accountPolicies,
      currentUser.groupPolicies
    );

    if (canManageTeam) {
      const generatedAvailableGroups = availableGroupsByGroupIds(
        groups,
        currentUser?.groupIds || []
      );

      const allOption = !omitAll
        ? [
            {
              label: i18n.t('settings-manageRules-allGroups', {
                defaultValue: 'All',
              }),
              value: 'all',
            },
          ]
        : [];

      const filteredAvailableGroups = omitIds.length
        ? generatedAvailableGroups.filter(
            ({ value }) => !omitIds.includes(value)
          )
        : generatedAvailableGroups;

      groupsAvailable = [...allOption, ...filteredAvailableGroups];
    }

    return groupsAvailable;
  };

  const loadOptions = async (inputVal) => {
    setQueryError('');
    const variables = {};

    if (areBroadcastEnabled) {
      variables.broadcastEnabled = true;
    }

    const { data, error } = await client
      .query({
        query: GROUPS_QUERY,
        variables,
      })
      .catch(console.warn);

    if (error) {
      // handle the shit out of the error
      setQueryError(i18n.t('slideouts-GroupMessageName-genericError'));
      return [];
    }

    const permissionGroups = generateOptions(data?.groups);
    const groupsFilteredToInput = permissionGroups.filter((group) =>
      group.label.toLowerCase().includes(inputVal.toLowerCase())
    );

    return groupsFilteredToInput;
  };

  return (
    <AsyncSelect
      value={value}
      onChange={(selectedOption) => setValue(selectedOption)}
      loadOptions={loadOptions}
      error={queryError || error}
      dataTestId="group-async-select"
      noComponents
      {...props}
    />
  );
};

GroupAsyncSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  setValue: PropTypes.func.isRequired,
  error: PropTypes.string,
  omitAll: PropTypes.bool,
  omitIds: PropTypes.array,
  permissionFilter: PropTypes.string,
  areBroadcastEnabled: PropTypes.bool,
  noComponents: PropTypes.bool,
};

GroupAsyncSelect.defaultProps = {
  error: '',
  omitAll: false,
  omitIds: [],
  permissionFilter: AVAILABLE_PERMISSIONS.MANAGE_TEAM,
  areBroadcastEnabled: false,
  noComponents: false,
};

export default GroupAsyncSelect;
