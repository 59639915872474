import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import FailedMessageError from './FailedMessageError';
import { STATUS_MESSAGE } from './utils';

/**
 * Represents a component that displays different status messages based on the provided status.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.status - The status of the message.
 * @param {string} props.dataTestId - The data-testid attribute. Suggested use parent component name and an indentifier as prefix, ex. "Message-${messageId}".
 * @returns {JSX.Element|null} The rendered status message component.
 */
const StatusMessage = ({ status, dataTestId }) => {
  if (!status) return null;
  switch (status) {
    case STATUS_MESSAGE.NOT_IN_SERVICE:
      return (
        <FailedMessageError
          dataTestId={`${dataTestId}-StatusMessage-notInService`}
        >
          {i18n.t('threads-Message-outOfService', {
            defaultValue:
              'You have reached a number that has been disconnected or is no longer in service. If you feel this is an error, please check the number and try your text again.',
          })}
        </FailedMessageError>
      );
    case STATUS_MESSAGE.SUSPECTED_LANDLINE:
      return (
        <FailedMessageError dataTestId={`${dataTestId}-StatusMessage-landline`}>
          {i18n.t('threads-Message-landlineMessage', {
            defaultValue:
              'You have attempted to message a line that does not support text. This is most likely a landline.',
          })}
        </FailedMessageError>
      );
    case STATUS_MESSAGE.FAILED:
      return (
        <FailedMessageError
          dataTestId={`${dataTestId}-StatusMessage-messageFailed`}
        >
          <span>{i18n.t('threads-Message-messageFailed')}: </span>
        </FailedMessageError>
      );
    default:
      return null;
  }
};

StatusMessage.propTypes = {
  status: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
};

export default StatusMessage;
