import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import uniqid from 'uniqid';
import styled from 'styled-components';
import { useUserGroups } from 'client-lib';
import {
  isAttachmentFileTypeSupported,
  ALLOWED_FILENAME_REGEX,
  NOT_ALLOWED_FILENAME_REGEX,
  fileNameWithoutExtension,
  fileExtension,
  contactName,
} from 'client-lib/src/lib/utils/helpers';
import { useSpring, animated } from 'react-spring';
import {
  CurrencyInput,
  Heading2,
  SelectedEntity,
  TextInput,
  TextArea,
  Button,
  IconLabel,
} from '../../../../elements';
import CreateMultiTag from '../../../Tag/CreateMultiTag';
import FileReaderInput from '../../../Inputs/FileReaderInput';
import EmojiMenu from '../../../Threads/EmojiMenu';
import { openSnackbar } from '../../../../actions/general';
import TemplateAsyncSelect from '../../../AsyncSelects/TemplateAsyncSelect';
import THEMES from '../../../../styles/themes/app';
import CreatePaymentMessagePreview from './CreatePaymentMessagePreview';
import { CreateFormAttachment } from '../../../FileUpload/CreateFormAttachment';

const CreatePaymentContainer = styled.div`
  width: 100%;
  padding-top: 100px;
  display: flex;
  /* justify-content: space-evenly; */
  justify-content: center;
  height: 100%;
`;
const FlexContainer = styled.div`
  width: 600px;
  max-width: 600px;
`;

const FormContainer = styled.div`
  width: 100%;
  margin-top: 32px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const InputRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputWrapper = styled.div`
  flex: 1;
  margin-right: ${(props) => (props.marginrightval ? '0' : '5px')};
`;

const InputWrapperRight = styled.div`
  flex: 1;
  margin-left: 5px;
`;

const FullFormRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 32px;
`;

const ButtonRow = styled.div`
  margin: 8px 0;
  display: flex;
  position: relative;
`;

const AttachmentButtonWrap = styled.div`
  margin: 0 0 0 8px;
`;

const labelStyle = () => `
  margin-top: 8px;
  margin-left: 8px;
`;

const FileReaderInputWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PreviewContainer = styled.div`
  background-color: ${THEMES.BACKGROUND_TERTIARY};
  width: 100%;
  min-height: 100%;
  height: fit-content;
  border-radius: 4px;
  overflow: hidden;
`;

const CreatePaymentMessage = ({
  wizardState,
  wizardStateSetters,
  setWizardPage,
}) => {
  const {
    attachment,
    sendAttachmentAsLink,
    tags,
    currentUser,
    onSubmit,
    mutationLoading,
    requiredPaymentRequestFields,
    fields: {
      fromContact,
      toContact,
      messageType,
      amount,
      invoiceNumber,
      message,
      subject,
      template,
      url,
    },
  } = wizardState;

  const urlRequired =
    requiredPaymentRequestFields?.paymentRequestFields.includes('URL');

  const { updateField, setSendAttachmentAsLink, setAttachment, setTags } =
    wizardStateSetters;

  const [emojiMenuOpen, setEmojiMenuOpen] = useState(false);

  const [pasteMeta, setPasteMeta] = useState({
    cursorPosition: 0,
    pasted: false,
    event: null,
  });

  const dispatch = useDispatch();
  const messageInput = useRef();

  const allGroups = useSelector((state) => state?.accountData?.allGroups);
  const accountName = useSelector((state) => state?.accountData?.account?.name);

  const { groups } = useUserGroups(currentUser, allGroups);
  const groupIds = groups?.map((group) => group.id);

  const handleInvoiceNumberChange = (e) => {
    const value = e.target.value;
    updateField({ name: 'invoiceNumber', value });
  };

  const handleEmailSubjectChange = (e) => {
    const value = e.target.value;
    updateField({ name: 'subject', value });
  };

  const handleAmountChange = (e) => {
    updateField({ name: 'amount', value: e });
  };

  const handleMessageChange = (e) => {
    const value = e.target.value;
    updateField({ name: 'message', value });
  };

  const handleTemplateChange = (e) => {
    const { value, label, message } = e;
    const formatCurrency = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    updateField({
      name: 'template',
      value,
      otherProps: { text: label, message },
    });
    updateField({
      name: 'message',
      value: message
        .replaceAll('{FIRST_NAME}', '')
        .replaceAll('{LAST_NAME}', '')
        .replaceAll('{COMPANY_NAME}', '')
        .replaceAll('{ACCOUNT_NAME}', accountName)
        .replaceAll('{GROUP_NAME}', fromContact?.otherProps?.text)
        .replaceAll('{AMOUNT_DUE}', formatCurrency.format(amount.value))
        .replaceAll('{INVOICE_NUMBER}', invoiceNumber.value),
    });
  };

  const handleUrlChange = (e) => {
    const value = e.target.value;
    updateField({ name: 'url', value });
  };

  const handleSubmitMutation = () => {
    onSubmit();
  };

  const handleEmojiSelection = (emoji) => {
    const selectionStart = messageInput.current.selectionStart; // cursor group in input
    const beforeSelection = messageInput.current.value.slice(0, selectionStart);
    const afterSelection = messageInput.current.value.slice(selectionStart);
    updateField({
      name: 'message',
      value: `${beforeSelection}${emoji.native} ${afterSelection}`,
    });
    setEmojiMenuOpen(false);

    setTimeout(() => messageInput?.current?.focus?.(), 0);
  };

  const validateAttachment = (originalFile) => {
    const filename = fileNameWithoutExtension(originalFile.name);
    const extension = `.${fileExtension(originalFile.name)}`;
    if (!isAttachmentFileTypeSupported(originalFile.name)) {
      dispatch(
        openSnackbar(i18n.t('slideouts-CreateThread-unsupportedFile'), 'error')
      );
    } else if (originalFile.size > 5000000) {
      dispatch(
        openSnackbar(i18n.t('slideouts-CreateThread-fileTooLarge'), 'error')
      );
    } else if (!filename.match(ALLOWED_FILENAME_REGEX)) {
      const removedSpecialCharacters = filename.replace(
        NOT_ALLOWED_FILENAME_REGEX,
        ''
      );
      const adaptedFilename = `${removedSpecialCharacters}${extension}`;
      handleSetAttachment({ originalFile, originalFilename: adaptedFilename });
    } else {
      handleSetAttachment({ originalFile });
    }
  };

  const handleFileReaderInputChange = (e) => {
    const originalFile = e.target.files[0];
    validateAttachment(originalFile);
    e.target.value = null;
  };

  const handleSetAttachment = ({
    originalFile,
    originalFilename = originalFile.name,
  }) => {
    const reader = new window.FileReader();
    reader.onload = () => {
      const attachment = {
        data: reader.result,
        originalFilename,
        type: originalFile.type,
        id: uniqid(),
        size: originalFile.size,
      };
      setAttachment(attachment);
    };
    reader.readAsDataURL(originalFile);
  };

  const renderAttachmentSection = () =>
    attachment !== null ? (
      <FullFormRow>
        <CreateFormAttachment
          attachments={[attachment]}
          onClose={() => setAttachment(null)}
          sendAsLink={sendAttachmentAsLink}
          onMethodChange={() => setSendAttachmentAsLink(!sendAttachmentAsLink)}
        />
      </FullFormRow>
    ) : null;

  const handleOnPaste = (e) => {
    e.preventDefault();
    e.persist();

    let clipBoardItem = null;

    if (e.clipboardData.types.includes('Files')) {
      clipBoardItem = e.clipboardData.items[1]
        ? e.clipboardData.items[1]
        : e.clipboardData.items[0];
    } else {
      clipBoardItem = e.clipboardData.items[0];
      if (
        e.clipboardData.types[0] === 'text/plain' ||
        e.clipboardData.types[0] === 'text/html'
      ) {
        // Paste in text based on cursor location.
        const pastedText = e.clipboardData.getData('Text');
        const selectionStart = e.target.selectionStart; // cursor location in input
        const selectionEnd = e.target.selectionEnd;
        const beforeSelection = e.target.value.slice(0, selectionStart);
        const afterSelection = e.target.value.slice(selectionEnd);
        const cursorPosition = beforeSelection.length + pastedText.length;

        updateField({
          name: 'message',
          value: `${beforeSelection}${pastedText}${afterSelection}`,
        });
        setPasteMeta({ cursorPosition, pasted: true, event: e });

        return;
      }
    }
    const originalFile = clipBoardItem.getAsFile();
    handleSetAttachment({ originalFile });
  };

  useEffect(() => {
    if (pasteMeta.pasted && pasteMeta.event) {
      const { event, cursorPosition } = pasteMeta;
      event.target.setSelectionRange(cursorPosition, cursorPosition);
      setPasteMeta({ pasted: false, event: null, cursorPosition: 0 });
    }
  }, [pasteMeta.pasted]);

  const handleEnterSubmit = (e) => {
    if (e.shiftKey && e.key === 'Enter') {
      e.preventDefault();
      onSubmit();
    }
  };

  useEffect(() => {
    updateField({
      name: 'url',
      ...url,
      otherProps: { ...url.otherProps, required: urlRequired },
      unforceVisit: true,
    });
  }, [urlRequired]);

  const handleReturnToStart = () => {
    // clear out form first
    updateField({ name: 'amount', value: '', unforceVisit: true });
    updateField({ name: 'message', value: '', unforceVisit: true });
    updateField({ name: 'invoiceNumber', value: '', unforceVisit: true });
    updateField({
      name: 'template',
      value: '',
      otherProps: { message: '', text: '' },
      unforceVisit: true,
    });
    updateField({ name: 'subject', value: '', unforceVisit: true });
    updateField({ name: 'url', value: '', unforceVisit: true });
    setTags([]);
    setAttachment(null);
    setSendAttachmentAsLink(false);
    setWizardPage('CreatePaymentRecipient');
  };
  const previewWidth = message.value ? '30%' : '0%';

  const animation = useSpring({
    width: previewWidth,
    marginBottom: 100,
    marginTop: 24,
    marginLeft: 40,
    marginRight: -40,
  });

  return (
    <CreatePaymentContainer>
      <FlexContainer>
        <HeadingContainer>
          <Heading2
            onClick={handleReturnToStart}
            customStyle={() => `display:flex; cursor: pointer;`}
          >
            <i className="ri-arrow-left-s-line" />
          </Heading2>
          <Heading2>
            {i18n.t('slideouts-CreatePaymentRecipient-newPaymentLink', {
              defaultValue: 'New Collections Link',
            })}
          </Heading2>
        </HeadingContainer>
        <FormContainer>
          <InputRow>
            <InputWrapper>
              <TextInput
                label={i18n.t('slideouts-CreateThread-fromLabel')}
                value={fromContact.otherProps.text}
                disabled
              />
            </InputWrapper>
            <InputWrapperRight>
              <SelectedEntity
                disabled
                label={i18n.t('slideouts-CreateThread-toLabel')}
                maintext={toContact.otherProps.text}
                subtext={toContact.otherProps.accountName}
                avatarChildren={toContact.otherProps.text}
              />
            </InputWrapperRight>
          </InputRow>
          <InputRow>
            {urlRequired && (
              <TextInput
                label={i18n.t('slideouts-PaymentForm-insertLink', {
                  defaultValue: 'Insert Link',
                })}
                value={url.value}
                error={url.error}
                onChange={handleUrlChange}
                placeholder={i18n.t('slideouts-PaymentForm-pasteLink', {
                  defaultValue: 'Paste link here',
                })}
              />
            )}
          </InputRow>
          <InputRow>
            <InputWrapper>
              <CurrencyInput
                label={i18n.t('slideouts-PaymentForm-amount', {
                  defaultValue: 'Amount',
                })}
                placeholder="0.00"
                value={amount.value}
                error={amount.error}
                onChange={handleAmountChange}
                allowNegativeValue={false}
              />
            </InputWrapper>
            <InputWrapperRight>
              <TextInput
                value={invoiceNumber.value}
                error={invoiceNumber.error}
                onChange={handleInvoiceNumberChange}
                placeholder={i18n.t('slideouts-PaymentForm-enterInvoice', {
                  defaultValue: 'Enter invoice number',
                })}
                label={i18n.t('slideouts-PaymentForm-invoiceNumber', {
                  defaultValue: 'Invoice Number',
                })}
              />
            </InputWrapperRight>
          </InputRow>
          <InputRow>
            <TemplateAsyncSelect
              value={
                template?.value
                  ? {
                      value: template.value,
                      label: template.otherProps.text,
                      message: template.otherProps.message,
                    }
                  : null
              }
              setValue={handleTemplateChange}
              label={i18n.t('settings-AddOrEditTemplate-templates', {
                defaultValue: 'Templates',
              })}
              placeholder={i18n.t('slideouts-PaymentForm-selectTemplate', {
                defaultValue: 'Select Template',
              })}
              queryVars={{
                senderGroupId: fromContact.value,
                contactId: toContact.value,
                groupIds,
                channelTypeFilter:
                  messageType.value === 'ALL'
                    ? ['SMS', 'email']
                    : [messageType.value],
                defaultTokenValues: JSON.stringify({
                  AMOUNT_DUE: '{AMOUNT_DUE}',
                  INVOICE_NUMBER: '{INVOICE_NUMBER}',
                }),
              }}
            />
          </InputRow>
          {messageType.value !== 'SMS' && (
            <InputRow>
              <TextInput
                label={i18n.t('slideouts-ForwardMessageSlideout-subjectLabel', {
                  defaultValue: 'Subject',
                })}
                placeholder={i18n.t('slideouts-PaymentForm-enterEmailSubject', {
                  defaultValue: 'Enter email subject line',
                })}
                value={subject.value}
                onChange={handleEmailSubjectChange}
              />
            </InputRow>
          )}
          <>
            <TextArea
              label={i18n.t('slideouts-CreateThread-message')}
              rightSideLabelContent={
                <CreateMultiTag tags={tags} setTags={setTags} />
              }
              placeholder={i18n.t('slideouts-CreateThread-pressShift')}
              onKeyPress={handleEnterSubmit}
              value={message.value}
              error={message.error}
              onChange={handleMessageChange}
              onPaste={handleOnPaste}
              ref={messageInput}
              id="textarea"
              name="messageInputValue"
              rows={4}
              dataTestId="at-textarea"
              customLabelWrapperStyle={() => `
                    align-items: flex-end;
                    max-height: 38px;
                  `}
            />
            {renderAttachmentSection()}
            <ButtonRow>
              <Button
                disabled={mutationLoading}
                loadingSpinner={mutationLoading}
                onClick={handleSubmitMutation}
                data-testid="send-message-button"
              >
                {i18n.t('slideouts-CreateThread-send')}
              </Button>
              <AttachmentButtonWrap>
                <FileReaderInput
                  name="modalAttachmentInput"
                  WrapperComponent={FileReaderInputWrapper}
                  handleFileReaderInputChange={handleFileReaderInputChange}
                >
                  <IconLabel
                    htmlFor="modalAttachmentInput"
                    customStyle={labelStyle}
                    contrast="medColor"
                  >
                    <i className="ri-attachment-2" />
                  </IconLabel>
                </FileReaderInput>
              </AttachmentButtonWrap>

              <EmojiMenu
                labelType="grey"
                handleEmojiSelection={handleEmojiSelection}
                open={emojiMenuOpen}
                onClickOutside={() => setEmojiMenuOpen(false)}
              >
                {({ emojiTouchTap }) => (
                  <IconLabel
                    onClick={(e) => {
                      emojiTouchTap(e);
                      setEmojiMenuOpen(true);
                    }}
                    title={i18n.t('slideouts-CreateThread-insertEmoji')}
                    htmlFor="emojiInput"
                    customStyle={labelStyle}
                    contrast="medColor"
                  >
                    <i className="ri-emotion-line" />
                  </IconLabel>
                )}
              </EmojiMenu>
            </ButtonRow>
          </>
        </FormContainer>
      </FlexContainer>
      <animated.div style={animation}>
        <PreviewContainer>
          <CreatePaymentMessagePreview
            accountName={contactName(currentUser)}
            emailSubject={subject.value}
            messageText={message.value}
            sendAttachmentAsLink={sendAttachmentAsLink}
            messageType={messageType}
            attachment={attachment}
          />
        </PreviewContainer>
      </animated.div>
    </CreatePaymentContainer>
  );
};

CreatePaymentMessage.propTypes = {
  wizardState: PropTypes.object.isRequired,
  wizardStateSetters: PropTypes.object.isRequired,
  setWizardPage: PropTypes.func.isRequired,
};

export default CreatePaymentMessage;
