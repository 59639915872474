import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'styled-theming';
import THEMES from '../../styles/themes/app';
import BUTTON_THEMES from '../../styles/themes/library/button';
import { EmphasisText } from '..';
import COLORS from '../../styles/colors';

// generic colors
export const SELECTION_INDICATOR = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_NAVY,
  light: COLORS.PK.BLUE_100,
  dark: COLORS.PK.BLUE_60,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_PRIMARY,
});

export const SELECTION_INDICATOR_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.LIGHT_BLUE,
  light: COLORS.PK.BLUE_60,
  dark: COLORS.PK.BLUE_40,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_ACCENT_2,
});

export const SELECTION_INDICATOR_UNSELECTED = theme('mode', {
  classic: COLORS.PK_CLASSIC.DARK_GRAY,
  light: COLORS.PK.GREY_50,
  dark: COLORS.PK_DARK.TRANSPARENT_WHITE_LOW,
  holiday: COLORS.PK.GREY_50,
});

const SelectionIndicator = styled.div`
  height: 5px;
  width: 100%;
  background-color: ${(props) =>
    props.isSelected ? SELECTION_INDICATOR : SELECTION_INDICATOR_UNSELECTED};
`;

const TabNav = styled.div`
  display: inline-grid;
  height: 100%;
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  justify-items: center;
  align-items: flex-end;
  color: ${THEMES.FOREGROUND_HIGH};
  font-weight: bold;
  text-transform: capitalize;
  cursor: pointer;

  &:hover div.selection-indicator {
    background-color: ${SELECTION_INDICATOR_HOVER} !important;
  }
  &:focus {
    z-index: 3;
    outline-offset: -1px;
    outline-color: ${BUTTON_THEMES.BUTTON_OUTLINE};
  }
`;

const FilterAndBadgeContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Bar = styled.div`
  display: grid;
  grid-gap: 0;
  grid-template-columns: ${(props) =>
    props.threeTabs ? '33% 34% 33%' : '50% 50%'};
  height: 35px;
  width: 100%;
`;

const TabBar = ({ options, activeTabValue, onClick }) => {
  const threeTabs = options.length > 2;
  return (
    <Bar threeTabs={threeTabs} data-testid="scope-tab-bar">
      {options.map((option) => (
        <TabNav onClick={() => onClick(option)}>
          <FilterAndBadgeContainer>
            <EmphasisText>{option.label}</EmphasisText>
          </FilterAndBadgeContainer>
          <SelectionIndicator
            className="selection-indicator"
            isSelected={option.value === activeTabValue}
          />
        </TabNav>
      ))}
    </Bar>
  );
};

TabBar.propTypes = {
  options: PropTypes.array.isRequired,
  activeTabValue: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default TabBar;
