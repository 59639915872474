import theme from 'styled-theming';
import styled from 'styled-components';
import THEMES from '../../../styles/themes/app';
import FONTSIZE_THEMES from '../../../styles/themes/fontSize/fontSize';

/** ********************************
 *
 * Styles for the message component.
 *
 ********************************* */

/**
 * Width of the message bubble.
 * @type {number}
 */
export const BUBBLE_WIDTH = theme('fontSize', {
  default: 160,
  large: 190,
});

/**
 * Line height for the failed message.
 * @type {string}
 */
export const FAILED_MESSAGE_LINE_HEIGHT = theme('font-size', {
  default: '20px',
  large: '24px',
});

export const MessageWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: ${(props) => (props.reducePadding ? '10px 20px' : '20px')};

  & > div:first-child {
    margin: 0;
  }

  :hover {
    background-color: ${THEMES.BACKGROUND_SECONDARY};
  }
  @media print {
    break-inside: avoid !important;
  }
`;

export const MessageBody = styled.div`
  margin-left: 12px;
  width: calc(100% - 64px);
  word-break: break-word;
`;

export const NameAndTimeStamp = styled.span`
  display: flex;
  align-items: baseline;
  margin-bottom: 4px;
`;

export const TimeStamp = styled.span`
  padding-left: ${(props) => (props.noPaddingLeft ? '0' : '8px')};
  font-size: ${FONTSIZE_THEMES.TIMESTAMP};
  font-family: 'Barlow', sans-serif;
  font-weight: 500;
  & > span > span {
    color: ${THEMES.FOREGROUND_LOW};
  }
`;

export const TransferMessageText = styled.div`
  padding: 4px 0 4px 8px;
  margin: 0;
  border-left: solid 3px ${THEMES.BORDER_COLOR};
  font-style: italic;
  margin-top: 8px;

  white-space: pre-wrap;
  max-width: 70%;
`;

export const PhotoFrame = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: solid 1px ${THEMES.BORDER_COLOR};
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  border-radius: 4px;
  margin-top: 4px;
  padding: 8px;
  box-shadow: ${THEMES.BOX_SHADOW};
  margin-bottom: 2px;
  cursor: pointer;
`;

export const Photo = styled.img`
  width: auto;
  max-width: 100%;
  height: 200px;
`;

export const OptionsButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BubbleIcon = styled.i`
  font-size: 20px !important;
`;
