import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import {
  TableHead,
  Checkbox,
  EmphasisText,
  Button,
  Avatar,
  useMultiSelectTableItems,
  Table,
} from '../../../../../elements';
import {
  FlexCenterContainer,
  FlexBetweenContainer,
  EmptyListHeader,
  ContactDataWrapper,
  getStyledNameText,
  AvatarWrapper,
  StyledTableRow,
  StyledCheckboxTD,
} from '../../../shared/sharedStyles';
import AudienceFilterSort from '../../../shared/AudienceBuilderTables/subComponents/AudienceFilterSort';
import { getSortOptions } from '../../../shared/utils';
import InfiniteScroll from '../../../../InfiniteScroll/InfiniteScroll';
import THEMES from '../../../../../styles/themes/app';

const ListsTable = ({
  lists,
  loading,
  loadMoreRows,
  onAdd,
  onSort,
  pageInfo,
  sort,
  heightDifference,
  disableCheckboxes,
}) => {
  const sortOptions = getSortOptions(i18n);

  const { handleCheckItem, handleSelectNone, selectedItems, shouldCheckItem } =
    useMultiSelectTableItems({
      items: lists,
    });

  // Adds selected Ids to list and then resets selection
  const handleAdd = () => {
    onAdd({ tagIds: selectedItems });
    handleSelectNone();
  };

  /**
   * For accessibility, if the whole row is clickable for the select action,
   * then it must be tabbable and hitting the enter key should serve as a click
   * @param {*} e KeyBoard Event
   * @param {string} id Group ID
   */
  const handleKeyDown = (e, id) => {
    if (e.key === 'Enter') {
      handleCheckItem(id);
    }
  };

  return (
    <div>
      <FlexBetweenContainer>
        <AudienceFilterSort
          sortBy={sort}
          sortOptions={sortOptions}
          handleSubmitSort={onSort}
        />
        <Button
          onClick={() => handleAdd()}
          disabled={selectedItems.length === 0 || disableCheckboxes}
        >
          {i18n.t('broadcasts-broadcast-addSelected', {
            defaultValue: 'Add %{number} Selected',
            number: selectedItems.length,
          })}
        </Button>
      </FlexBetweenContainer>
      <Table>
        <TableHead>
          <tr>
            <th>
              {i18n.t('modals-AddOrEditList-listName', {
                defaultValue: 'List Name',
              })}
            </th>
          </tr>
        </TableHead>
      </Table>
      <InfiniteScroll
        dataTestId="broadcast-recipient-list"
        bidirectionalScroll={false}
        height={`calc(100vh - ${heightDifference}px)`}
        scrollableList={lists}
        noRowsElement={
          <FlexCenterContainer>
            <EmptyListHeader dataTestId="create-broadcastList-empty">
              {i18n.t('slideouts-TargetedAudience-noResults', {
                defaultValue:
                  'No results were found for this type of audience.',
              })}
            </EmptyListHeader>
          </FlexCenterContainer>
        }
        renderRow={({ list: data, index }) => {
          const list = data[index];
          return (
            <StyledTableRow
              key={list.id}
              role="button"
              tabIndex={0}
              onClick={() => {
                if (disableCheckboxes) return;
                handleCheckItem(list.id);
              }}
              onKeyDown={(e) => {
                if (disableCheckboxes) return;
                handleKeyDown(e, list.id);
              }}
            >
              <StyledCheckboxTD>
                <Checkbox
                  checked={shouldCheckItem(list.id)}
                  dataTestId="create-broadcastList-checkboxList"
                  onCheck={() => {
                    /* Not needed - entire table row is clickable */
                  }}
                  disabled={disableCheckboxes}
                />
                <AvatarWrapper>
                  <Avatar size="md" icon="tag" disabled={disableCheckboxes} />
                  <ContactDataWrapper>
                    <EmphasisText
                      customStyle={getStyledNameText}
                      disabled={disableCheckboxes}
                    >
                      {list.name}
                    </EmphasisText>
                  </ContactDataWrapper>
                </AvatarWrapper>
              </StyledCheckboxTD>
            </StyledTableRow>
          );
        }}
        hasNextPage={pageInfo.hasNextPage}
        hasPreviousPage={pageInfo.hasPreviousPage}
        listItemHeight={64}
        loadingHeight={64}
        loadingBackgroundColor={THEMES.BACKGROUND_PRIMARY}
        loadingBorderBottom
        loadMoreRows={loadMoreRows}
        loading={loading}
      />
    </div>
  );
};

ListsTable.propTypes = {
  lists: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  loadMoreRows: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  pageInfo: PropTypes.shape({
    hasNextPage: PropTypes.bool,
    hasPreviousPage: PropTypes.bool,
    totalCount: PropTypes.number,
  }),
  sort: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  heightDifference: PropTypes.number,
  disableCheckboxes: PropTypes.bool,
};

ListsTable.defaultProps = {
  pageInfo: {
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
  },
  heightDifference: 354,
  disableCheckboxes: false,
};

export default ListsTable;
