import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { ThemeContext } from 'styled-components';
import anchorme from 'anchorme';
import DOMPurify from 'dompurify';
import {
  MESSAGE_ATTACHMENT_FRAGMENT,
  checkIfCurrentUserHasPermission,
  AVAILABLE_PERMISSIONS,
} from 'client-lib';
import { useParams } from 'react-router-dom';
import i18n from 'i18n-js';
import { useDispatch, useSelector } from 'react-redux';
import {
  canPreviewBasedOnFileExtension,
  contactName,
  resolveAvatarUrl,
} from 'client-lib/src/lib/utils/helpers';
import PhotoModal from '../PhotoModal';
import FormattedDateTime from '../../Common/FormattedDateTime';
import {
  Heading4,
  Text,
  Loading,
  Avatar,
  IconButton,
  Button,
  EmphasisText,
  AttachmentTile,
} from '../../../elements';
import THEMES from '../../../styles/themes/app';
import Bubble from '../../Common/Bubble';
import { setActiveDeleteMessage } from '../../../actions/globalModals';
import { bubbleButtonHoverStyle } from '../../Navbar/HeaderComponents/HeaderOptionsButton';
import {
  setActiveForwardMessageSlideout,
  setActiveForwardMessageId,
  setActiveCustomerInfoSlideout,
} from '../../../actions/general';
import CollapsibleEmailText from '../CollapsibleEmailText';
import NonCollapsibleEmailText from '../NonCollapsibleEmailText';
import InternalUseOnlyMessage from '../InternalUseOnlyMessage';
import HtmlMessageText from '../HtmlMessageText';
import useGetUserAccountPolicies from '../../../hooks/customer/useGetUserAccountPolicies';
import StatusMessage from './StatusMessage';
import MessageText from '../../../elements/Text/MessageText';
import {
  BUBBLE_WIDTH,
  BubbleIcon,
  MessageBody,
  MessageWrapper,
  NameAndTimeStamp,
  OptionsButtonContainer,
  Photo,
  PhotoFrame,
  TimeStamp,
  TransferMessageText,
} from './styles';

const Message = ({
  message,
  reducePadding,
  allowAnnotateAndReply,
  flipOptionsBubbleYAxis,
  printMessage,
  mainThread,
}) => {
  const client = useApolloClient();
  const { activeThreadId } = useParams();

  const [previewLoaded, setPreviewLoaded] = useState(false);
  const [openAttachmentId, setOpenAttachmentId] = useState(false);
  const [attachmentLoaded, setAttachmentLoaded] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [optionBubbleOpen, setOptionBubbleOpen] = useState(false);

  const dispatch = useDispatch();
  const userId = useSelector((state) => state.session.currentUser?.userId);
  const currentContactId = useSelector(
    (state) => state?.session?.currentUser?.contactId
  );
  const activeEmailChannels = useSelector(
    (state) => state?.accountData?.activeEmailChannels
  );
  const ff_email = useSelector(
    (state) => state?.accountData?.account?.ff_email
  );
  const accountPolicies = useSelector(
    (state) => state?.session?.currentUser?.accountPolicies
  );
  const groupPolicies = useSelector(
    (state) => state?.session?.currentUser?.groupPolicies
  );

  useGetUserAccountPolicies({
    userId,
    actionList: [AVAILABLE_PERMISSIONS.DELETE_MESSAGE],
  });

  const userCanDeleteMessage = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.DELETE_MESSAGE,
    accountPolicies,
    groupPolicies
  );

  const styledTheme = useContext(ThemeContext);

  const handlePreviewLoaded = () => setPreviewLoaded(true);

  const handleAttachmentLoaded = () => setAttachmentLoaded(true);

  const handleOpenAttachment = (attachmentId) =>
    setOpenAttachmentId(attachmentId);

  const closeAttachment = () => setOpenAttachmentId(null);

  const getDisplayContactByMessageType = (
    messageType,
    threadContact,
    insertdByContact
  ) => {
    if (
      threadContact &&
      (messageType === 'pci_breach_warning' ||
        messageType === 'pci_message_text_warning')
    ) {
      return threadContact;
    }
    return insertdByContact;
  };

  const {
    id,
    type,
    status,
    createdAt,
    insertedByContact,
    text,
    attachments,
    deleted,
    metadata,
    html,
    thread,
  } = message;

  let avatar;
  let name;
  let messagePreface;
  let messageText;
  let messageSubject;

  const fullName = contactName(
    getDisplayContactByMessageType(
      type,
      mainThread?.externalContact,
      insertedByContact
    )
  );
  const avatarUrl = resolveAvatarUrl(insertedByContact?.user);
  const entityId = insertedByContact?.id;
  const entityType = insertedByContact?.__typename;
  const entityTypesWithoutSlideout = ['ApplicationContact'];
  const disableSlideout = entityTypesWithoutSlideout.includes(entityType);

  const interalUseOnly = [
    'transfer',
    'unclaim',
    'deleted',
    'ignore',
    'close',
    'spam_warning',
    'pci_breach_warning',
    'pci_message_text_warning',
  ].includes(type);

  const handleAvatarIconClick = () => {
    dispatch(
      setActiveCustomerInfoSlideout({
        activeSlideoutEntityId: entityId,
        activeSlideoutEntityType: entityType,
      })
    );
  };

  if (type === 'tsw') {
    avatar = <Avatar type="external">{fullName}</Avatar>;

    name = <Heading4>{fullName}</Heading4>;

    messagePreface = <Text contrast="med">{text}</Text>;
    messageText = (
      <TransferMessageText>
        <MessageText contrast="low">
          {i18n.t('threads-Message-sentUsingWidget')}
        </MessageText>
      </TransferMessageText>
    );
  } else if (type === 'announcement') {
    avatar = <Avatar type="bot" />;
    name = <Heading4>{i18n.t('threads-Message-broadcast')}</Heading4>;
    if (text !== null && text.length > 0) {
      messageText = <MessageText contrast="med">{text}</MessageText>;
    } else {
      messageText = <MessageText />;
    }
  } else if (type === 'system') {
    avatar = <Avatar type="bot" />;
    name = <Heading4>Prokeep</Heading4>;
    messageText = <MessageText contrast="med">{text}</MessageText>;
  } else {
    if (insertedByContact?.__typename === 'UserContact') {
      const isUser = currentContactId !== insertedByContact?.id;
      avatar = (
        <Avatar
          type="internal"
          icon={isUser ? 'user' : null}
          avatarUrl={avatarUrl}
        >
          {fullName}
        </Avatar>
      );
      name = <Heading4>{fullName}</Heading4>;
    } else if (
      insertedByContact?.__typename === 'ApplicationContact' &&
      insertedByContact?.firstName.startsWith('rule:')
    ) {
      avatar = <Avatar type="external" icon="settings" />;
      name = (
        <Heading4
          customStyle={(props) =>
            disableSlideout
              ? `:hover{color: ${THEMES.FOREGROUND_HIGH(props)}}`
              : `cursor: pointer; 
          :hover{ color: ${THEMES.FOREGROUND_LOW(props)};`
          }
          onClick={!disableSlideout && entityId ? handleAvatarIconClick : null}
        >
          {fullName}
        </Heading4>
      );
    } else {
      avatar = (
        <Avatar
          onClick={!disableSlideout && entityId ? handleAvatarIconClick : null}
        >
          {fullName}
        </Avatar>
      );
      name = (
        <Heading4
          customStyle={(props) =>
            disableSlideout
              ? `:hover{color: ${THEMES.FOREGROUND_HIGH(props)}}`
              : `cursor: pointer; 
          :hover{ color: ${THEMES.FOREGROUND_LOW(props)};`
          }
          onClick={!disableSlideout && entityId ? handleAvatarIconClick : null}
        >
          {fullName}
        </Heading4>
      );
    }

    if (type === 'email' && !deleted) {
      messageSubject = <EmphasisText>{metadata?.subject}</EmphasisText>;
    }

    if (type === 'fsm' && !deleted) {
      avatar = (
        <Avatar avatarUrl={avatarUrl || thread?.avatarUrl} icon="fsmChannel" />
      );
      messageSubject = (
        <EmphasisText>
          {thread?.subject === null
            ? `(${i18n.t('threads-message-noSubject', {
                defaultValue: '(No Subject)',
              })})`
            : thread?.subject}
        </EmphasisText>
      );
    }

    if (metadata.pageCount || type === 'fax') {
      const { pageCount } = metadata;
      if (pageCount > 1) {
        messageText = (
          <MessageText>
            {i18n.t('threads-Message-faxPages', {
              pageCount,
              defaultValue: 'Fax attachment (%{pageCount} pages)',
            })}
          </MessageText>
        );
      } else if (pageCount === 1) {
        messageText = (
          <MessageText>
            {i18n.t('threads-Message-faxPage', {
              pageCount,
              defaultValue: 'Fax attachment (%{pageCount} page)',
            })}
          </MessageText>
        );
      } else {
        messageText = (
          <MessageText>
            {i18n.t('threads-Message-fax', { defaultValue: 'Fax attachment' })}
          </MessageText>
        );
      }
    } else if (type === 'email') {
      if (html?.trim?.()) {
        messageText = <HtmlMessageText html={html} attachments={attachments} />;
      } else {
        messageText = printMessage ? (
          <NonCollapsibleEmailText text={text} />
        ) : (
          <CollapsibleEmailText text={text} />
        );
      }
    } else if (text !== null && text.length > 0) {
      if (text?.startsWith?.('http')) {
        const textWithLinks = {
          __html: DOMPurify.sanitize(
            anchorme(text || '', {
              attributes: [{ name: 'target', value: 'blank' }],
              truncate: 150,
            }),
            { ADD_ATTR: ['target'] }
          ),
        };
        messageText = (
          <MessageText contrast="med" dangerouslySetInnerHTML={textWithLinks} />
        );
      } else {
        messageText = <MessageText contrast="med">{text}</MessageText>;
      }
    } else {
      messageText = <MessageText />;
    }
  }

  let openAttachment = null;

  if (openAttachmentId !== null && openAttachmentId !== '') {
    openAttachment = client.readFragment({
      id: 'MessageAttachment_' + openAttachmentId,
      fragment: MESSAGE_ATTACHMENT_FRAGMENT,
    });
  }

  const onMouseLeave = (e) => {
    if (optionBubbleOpen && e.target.id === `options_bubble_${id}`) return;

    setHovering(false);
    setOptionBubbleOpen(false);
  };

  const handleClickForwardMessage = () => {
    dispatch(setActiveForwardMessageSlideout());
    dispatch(setActiveForwardMessageId(id));
  };

  const nonDeletable =
    type === 'system' ||
    type === 'announcement' ||
    type === 'transfer' ||
    type === 'unclaim';

  const messageBubbleIsPopulated =
    userCanDeleteMessage || (ff_email && activeEmailChannels?.length > 0);

  return interalUseOnly ? (
    <InternalUseOnlyMessage
      message={message}
      fullName={fullName}
      printMessage={printMessage}
    />
  ) : (
    <MessageWrapper
      reducePadding={reducePadding}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={onMouseLeave}
      data-testid="thread-message"
    >
      {avatar}
      <MessageBody>
        <NameAndTimeStamp>
          {name}
          <TimeStamp>
            <FormattedDateTime value={createdAt} />
          </TimeStamp>
        </NameAndTimeStamp>
        {messagePreface}
        {messageSubject}
        {messageText}
        {attachments
          ? attachments.map((attachment) => {
              if (
                attachment?.method !== 'LINK' &&
                type !== 'email' &&
                canPreviewBasedOnFileExtension(attachment.originalFilename)
              ) {
                if (
                  attachment.mediaRemoved &&
                  attachment.mediaRemovedReason === 'PCI_DETECTED'
                ) {
                  return (
                    <div key={attachment.id}>
                      <PhotoFrame onClick={() => {}}>
                        {previewLoaded ? null : (
                          <Loading size="xs" contrast="high" />
                        )}
                        <Photo
                          src="/images/removed_media.png"
                          onLoad={handlePreviewLoaded}
                        />
                      </PhotoFrame>
                      <Text contrast="red">
                        {i18n.t('threads-Message-imageNotDelivered', {
                          defaultValue: 'Image not delivered',
                        })}
                      </Text>
                    </div>
                  );
                }

                return (
                  <div key={attachment.id}>
                    <PhotoFrame
                      onClick={() => handleOpenAttachment(attachment.id)}
                    >
                      {previewLoaded ? null : (
                        <Loading size="xs" contrast="high" />
                      )}
                      <Photo
                        src={`/attachments/${attachment.id}/preview/${attachment.originalFilename}`}
                        onLoad={handlePreviewLoaded}
                      />
                    </PhotoFrame>
                    <Text
                      contrast="medColor"
                      customStyle={() => 'cursor: pointer;'}
                      onClick={() => handleOpenAttachment(attachment.id)}
                    >
                      {attachment.originalFilename}
                    </Text>
                  </div>
                );
              }

              return (
                <div style={{ paddingTop: 12 }}>
                  <AttachmentTile
                    attachment={attachment}
                    downloadable
                    boxShadow
                  />
                </div>
              );
            })
          : null}
        <StatusMessage status={status} dataTestId={'Message_id=' + id} />
      </MessageBody>
      {openAttachment === null || openAttachment === undefined ? null : (
        <PhotoModal
          openAttachment={openAttachment}
          closeAttachment={closeAttachment}
          attachmentLoaded={attachmentLoaded}
          handleAttachmentLoaded={handleAttachmentLoaded}
          threadId={activeThreadId}
          allowAnnotateAndReply={allowAnnotateAndReply}
        />
      )}
      {hovering && !deleted && !nonDeletable && messageBubbleIsPopulated ? (
        <div style={{ position: 'relative', width: 27 }}>
          <IconButton
            dataTestId="message-options-btn"
            onClick={() => setOptionBubbleOpen(true)}
          >
            <i className="ri-more-fill" />
          </IconButton>
          <Bubble
            isOpen={optionBubbleOpen}
            onClickOutside={() => setOptionBubbleOpen(false)}
            moveBubbleRightVal={-45}
            moveLeftVal={23}
            bubbleMinWidth={BUBBLE_WIDTH({ theme: styledTheme })}
            bubbleMaxWidth={BUBBLE_WIDTH({ theme: styledTheme })}
            top={flipOptionsBubbleYAxis ? '30px' : '4px'}
            zIndex={2}
            flipYAxis={flipOptionsBubbleYAxis}
            id={`options_bubble_${id}`}
          >
            <OptionsButtonContainer>
              {ff_email && activeEmailChannels?.length > 0 ? (
                <Button
                  type="noStyle"
                  noOutline
                  iconAndText
                  customStyle={bubbleButtonHoverStyle}
                  bubbleButtonPosition="top"
                  onClick={handleClickForwardMessage}
                  dataTestId="forward-msg-btn"
                >
                  <BubbleIcon className="ri-share-forward-line" />
                  {i18n.t('threads-Message-forwardMessage', {
                    defaultValue: 'Forward Message',
                  })}
                </Button>
              ) : null}
              {userCanDeleteMessage && (
                <Button
                  type="noStyle"
                  noOutline
                  iconAndText
                  customStyle={bubbleButtonHoverStyle}
                  bubbleButtonPosition={
                    ff_email && activeEmailChannels?.length > 0
                      ? 'bottom'
                      : 'single'
                  }
                  onClick={() => dispatch(setActiveDeleteMessage(message.id))}
                  dataTestId="delete-msg-btn"
                >
                  <BubbleIcon className="ri-delete-bin-4-fill" />
                  {i18n.t('threads-Message-deleteMessage')}
                </Button>
              )}
            </OptionsButtonContainer>
          </Bubble>
        </div>
      ) : (
        <div style={{ width: 27 }} />
      )}
    </MessageWrapper>
  );
};

Message.propTypes = {
  message: PropTypes.object.isRequired,
  reducePadding: PropTypes.bool,
  allowAnnotateAndReply: PropTypes.bool,
  flipOptionsBubbleYAxis: PropTypes.bool,
  printMessage: PropTypes.bool,
  mainThread: PropTypes.object,
};

Message.defaultProps = {
  reducePadding: false,
  allowAnnotateAndReply: false,
  flipOptionsBubbleYAxis: false,
  printMessage: false,
  mainThread: null,
};

export default Message;
