import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { Button } from '../../elements';

const ShowMoreShowLess = ({ isOpen, setIsOpen }) => {
  return isOpen ? (
    <Button
      noOutline
      size="md"
      type="link"
      onClick={() => {
        setIsOpen(false);
      }}
      customStyle={() => 'padding: 0;'}
    >
      {i18n.t('threads-EmailText-less')}
    </Button>
  ) : (
    <Button
      noOutline
      size="md"
      type="link"
      onClick={() => {
        setIsOpen(true);
      }}
      customStyle={() => 'padding: 0;'}
    >
      {i18n.t('threads-EmailText-more')}
    </Button>
  );
};

ShowMoreShowLess.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default ShowMoreShowLess;
