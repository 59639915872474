import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  contactName,
  findInternalThreadExternalUser,
  resolveAvatarUrl,
} from 'client-lib/src/lib/utils/helpers';
import { useSelector } from 'react-redux';
import i18n from 'i18n-js';
import THEMES from '../../../styles/themes/app';
import SeparatorDate from '../../Common/SeparatorDate';
import { Avatar, Heading4, Text } from '../../../elements';
import FONTSIZE_THEMES from '../../../styles/themes/fontSize/fontSize';
import FormattedDateTime from '../../Common/FormattedDateTime';
import { populateAvatarIcon } from '../../../elements/Avatar/Avatar';

const Wrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  display: contents;
`;

const DateWrapper = styled.div`
  padding: 0 20px;
`;

const DateThreadRefWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 32px;
`;

const SeparatorHr = styled.div`
  background: ${THEMES.BORDER_COLOR};
  height: 1px;
  margin: 5px 0 10px 0;
  text-align: center;

  & > span {
    background: ${THEMES.BACKGROUND_PRIMARY};
    padding: 8px;
    position: relative;
    top: -8px;
  }
`;

const DateAndGroupWrapper = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 16px;
`;

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  word-break: break-all;
  padding: 20px;
`;

const NameAndTimeStamp = styled.div`
  display: flex;
  align-items: baseline;
`;

const TimeStamp = styled.span`
  padding-left: 8px;
  font-size: ${FONTSIZE_THEMES.TIMESTAMP};
  font-family: 'Barlow', sans-serif;
  font-weight: 500;
`;

const WrapStampAndLink = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4px;
`;

const WrapNameAndText = styled.div`
  padding-left: 16px;
  width: 100%;
`;

const HorizontalPad = styled.div`
  width: 4px;
`;

const defaultRowHeight = 112;

const NoResultsWrap = styled.div`
  height: ${defaultRowHeight}px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;

const RecentThread = ({ thread, messages }) => {
  const currentUserId = useSelector(
    (state) => state?.session?.currentUser?.userId
  );
  const isInternal = thread?.type === 'internal';
  const isInternalGroupThread = isInternal && !!thread?.groupId;

  return thread?.id ? (
    <Wrapper data-testid="thread-history">
      <DateWrapper>
        <DateThreadRefWrap>
          <DateAndGroupWrapper>
            <SeparatorDate value={thread?.insertedAt} />
          </DateAndGroupWrapper>
        </DateThreadRefWrap>
        <SeparatorHr />
      </DateWrapper>
      {messages.map((m) => {
        const contact = isInternal
          ? findInternalThreadExternalUser(thread?.userShares, currentUserId)
              ?.contact
          : m?.insertedByContact;
        const name = isInternalGroupThread
          ? thread?.group?.name
          : contactName(contact);
        const avatarUrl = resolveAvatarUrl(contact?.user);

        return (
          <MessageWrapper key={m?.id}>
            <Avatar
              type={isInternal ? 'internal' : 'external'}
              icon={populateAvatarIcon({
                isUser: isInternal,
                isGroup: isInternalGroupThread,
              })}
              avatarUrl={avatarUrl}
            >
              {name}
            </Avatar>
            <WrapNameAndText>
              <WrapStampAndLink>
                <NameAndTimeStamp>
                  <Heading4>{name}</Heading4>
                  <HorizontalPad />
                  <TimeStamp>
                    <FormattedDateTime value={m.createdAt} />
                  </TimeStamp>
                </NameAndTimeStamp>
              </WrapStampAndLink>
              <Text>{m.text}</Text>
            </WrapNameAndText>
          </MessageWrapper>
        );
      })}
    </Wrapper>
  ) : (
    <NoResultsWrap>
      {i18n.t('customers-InfiniteScroll-noResultsFound', {
        defaultValue: 'No results found',
      })}
    </NoResultsWrap>
  );
};

RecentThread.propTypes = {
  thread: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
};

export default RecentThread;
