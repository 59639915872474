export const SET_ACTIVE_WIZARD = 'SET_ACTIVE_WIZARD';
export const SET_ACTIVE_CUSTOMER_SOURCE = 'SET_ACTIVE_CUSTOMER_SOURCE';
export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const SET_ACTIVE_SIDEBAR = 'SET_ACTIVE_SIDEBAR';
export const SET_ACTIVE_CREATE_SECTION = 'SET_ACTIVE_CREATE_SECTION';
export const SET_ACTIVE_CREATE_CUSTOMER_MODAL =
  'SET_ACTIVE_CREATE_CUSTOMER_MODAL';
export const SET_ACTIVE_CREATE_ANNOUNCEMENT_MODAL =
  'SET_ACTIVE_CREATE_ANNOUNCEMENT_MODAL';
export const SET_ACTIVE_CREATE_BROADCASTLIST_MODAL =
  'SET_ACTIVE_CREATE_BROADCASTLIST_MODAL';
export const SET_ACTIVE_CREATE_THREAD_MODAL = 'SET_ACTIVE_CREATE_THREAD_MODAL';
export const SET_ACTIVE_CREATE_FAX_MODAL = 'SET_ACTIVE_CREATE_FAX_MODAL';
export const SET_ACTIVE_CREATE_EMAIL_MODAL = 'SET_ACTIVE_CREATE_EMAIL_MODAL';
export const SET_ACTIVE_CREATE_PAYMENT_MODAL =
  'SET_ACTIVE_CREATE_PAYMENT_MODAL';
export const CLOSE_CREATE_SECTION_MODAL = 'CLOSE_CREATE_SECTION_MODAL';
export const SET_VIDEO_MODAL = 'SET_VIDEO_MODAL';
// Slideouts
export const SET_ACTIVE_TRANSFER_THREAD_SLIDEOUT =
  'SET_ACTIVE_TRANSFER_THREAD_SLIDEOUT';
export const SET_ACTIVE_CUSTOMER_INFO_SLIDEOUT =
  'SET_ACTIVE_CUSTOMER_INFO_SLIDEOUT';
export const TOGGLE_CUSTOMER_INFO_SLIDEOUT = 'TOGGLE_CUSTOMER_INFO_SLIDEOUT';
export const SET_ACTIVE_MERGE_CUSTOMER_SLIDEOUT =
  'SET_ACTIVE_MERGE_CUSTOMER_SLIDEOUT';
export const CLOSE_MERGE_CUSTOMER_SLIDEOUT = 'CLOSE_MERGE_CUSTOMER_SLIDEOUT';
export const SET_ACTIVE_FORWARD_MESSAGE_SLIDEOUT =
  'SET_ACTIVE_FORWARD_MESSAGE_SLIDEOUT';
export const SET_ACTIVE_CREATE_INTERNAL_MODAL =
  'SET_ACTIVE_CREATE_INTERNAL_MODAL';

export const CLOSE_ALL_SLIDEOUTS = 'CLOSE_ALL_SLIDEOUTS';

export const SET_USERS_FILTER = 'SET_USERS_FILTER';
export const SET_ANNOUNCEMENT_FILTER = 'SET_ANNOUNCEMENT_FILTER';
export const SET_GROUP_FILTER = 'SET_GROUP_FILTER';

export const SET_GROUP_FILTER_USERS_SETTING = 'SET_GROUP_FILTER_USERS_SETTING';

export const SET_TEMPLATE_FILTER = 'SET_TEMPLATE_FILTER';

export const SET_GROUP_FILTER_TEMPLATES_SETTING =
  'SET_GROUP_FILTER_TEMPLATES_SETTING';

export const SET_BACKUP_LANGUAGE = 'SET_BACKUP_LANGUAGE';

export const SET_SLO_URI = 'SET_SLO_URI';

export const SET_ANNOUNCEMENT_STATUS = 'SET_ANNOUNCEMENT_STATUS';

export const SET_LISTS_SEARCH_FILTER = 'SET_LISTS_SEARCH_FILTER';

export const SET_TARGETED_AUDIENCE_FILTER = 'SET_TARGETED_AUDIENCE_FILTER';

export const SET_BANNERS = 'SET_BANNERS';

export const SET_UPGRADE_BLURB = 'UPGRADE_BLURB';

export const SET_ACTIVE_FORWARD_MESSAGE_ID = 'SET_ACTIVE_FORWARD_MESSAGE_ID';

export const SET_CONTACT_FILTER = 'SET_CONTACT_FILTER';

export const SET_CONTACT_FILTER_SETTING = 'SET_CONTACT_FILTER_SETTING';

export const SET_EXPORT_CONTACTS_MODAL = 'SET_EXPORT_CONTACTS_MODAL';

export const SET_UPDATE_CONTACTS_DATA = 'SET_UPDATE_CONTACTS_DATA';

export const SET_SUBMIT_MESSAGE_INPUT_HEIGHT =
  'SET_SUBMIT_MESSAGE_INPUT_HEIGHT';

export const SET_CONTACT_SLIDEOUT_AUTOFOCUS_FIELD =
  'SET_CONTACT_SLIDEOUT_AUTOFOCUS_FIELD';

export const SET_REGION_FILTER = 'SET_REGION_FILTER';

export const TRIGGER_REFETCH_BROADCAST = 'TRIGGER_REFETCH_BROADCAST';

export const SET_PREVIOUS_ACTIVE_THREAD_ID = 'SET_PREVIOUS_ACTIVE_THREAD_ID';

export const SET_ACTIVE_LOSE_PROGRESS_STATE = 'SET_ACTIVE_LOSE_PROGRESS_STATE';

export const TRIGGER_REFETCH_ANNOUNCEMENT = 'TRIGGER_REFETCH_ANNOUNCEMENT';

export const setActiveLoseProgressState = (state) => ({
  type: SET_ACTIVE_LOSE_PROGRESS_STATE,
  state,
});

export const setPreviousActiveThreadId = (previousActiveThreadId) => ({
  type: SET_PREVIOUS_ACTIVE_THREAD_ID,
  previousActiveThreadId,
});
export const setUpdateContactsData = (updateContactsData) => ({
  type: SET_UPDATE_CONTACTS_DATA,
  updateContactsData,
});
export const UPDATE_ENTITY_SLIDEOUT_DATA = 'UPDATE_ENTITY_SLIDEOUT_DATA';

export const setActiveWizard = (activeWizard = '') => ({
  type: SET_ACTIVE_WIZARD,
  activeWizard,
});

export const setExportContactsModal = (exportContactsModal) => ({
  type: SET_EXPORT_CONTACTS_MODAL,
  exportContactsModal,
});

export const setActiveCustomerSource = (activeCustomerSource) => ({
  type: SET_ACTIVE_CUSTOMER_SOURCE,
  activeCustomerSource,
});

export const openSnackbar = (
  snackMessage,
  snackType = 'success',
  snackAutoHide = true,
  snackButtonLink = ''
) => ({
  type: OPEN_SNACKBAR,
  snackMessage,
  snackType,
  snackAutoHide,
  snackButtonLink,
});

export const closeSnackbar = () => ({
  type: CLOSE_SNACKBAR,
});

export const setActiveSidebar = (activeSidebar = '') => ({
  type: SET_ACTIVE_SIDEBAR,
  activeSidebar,
});

export const setActiveCreateCustomerSection = () => ({
  type: SET_ACTIVE_CREATE_CUSTOMER_MODAL,
});

export const setActiveCreateAnnouncementSection = (
  id,
  dontTriggerCreateSidebar = false
) => ({
  id,
  dontTriggerCreateSidebar,
  type: SET_ACTIVE_CREATE_ANNOUNCEMENT_MODAL,
});

export const setActiveCreateBroadcastListSection = ({ listId }) => ({
  dontTriggerCreateSidebar: true,
  type: SET_ACTIVE_CREATE_BROADCASTLIST_MODAL,
  listId,
});

export const setActiveCreateThreadSection = () => ({
  type: SET_ACTIVE_CREATE_THREAD_MODAL,
});

export const setActiveCreateFaxSection = () => ({
  type: SET_ACTIVE_CREATE_FAX_MODAL,
});

export const setActiveCreateInternalSection = () => ({
  type: SET_ACTIVE_CREATE_INTERNAL_MODAL,
});

export const setActiveCreateEmailSection = () => ({
  type: SET_ACTIVE_CREATE_EMAIL_MODAL,
});

export const setActiveCreatePaymentSection = () => ({
  type: SET_ACTIVE_CREATE_PAYMENT_MODAL,
});

export const closeCreateSectionModal = () => ({
  type: CLOSE_CREATE_SECTION_MODAL,
});

export const setVideoPlayerModal = (activeVideoPlayerModal) => ({
  type: SET_VIDEO_MODAL,
  activeVideoPlayerModal,
});

/* Slideouts actions */
export const setActiveCustomerInfoSlideout = (value) => ({
  type: SET_ACTIVE_CUSTOMER_INFO_SLIDEOUT,
  value,
});
export const setActiveTransferThreadSlideOut = () => ({
  type: SET_ACTIVE_TRANSFER_THREAD_SLIDEOUT,
});
export const closeAllSlideouts = () => ({
  type: CLOSE_ALL_SLIDEOUTS,
});
export const toggleCustomerInfoSlideout = (value) => ({
  type: TOGGLE_CUSTOMER_INFO_SLIDEOUT,
  value,
});
export const setActiveMergeCustomerSlideout = () => ({
  type: SET_ACTIVE_MERGE_CUSTOMER_SLIDEOUT,
});
export const closeMergeCustomerSlideout = () => ({
  type: CLOSE_MERGE_CUSTOMER_SLIDEOUT,
});
export const setActiveForwardMessageSlideout = () => ({
  type: SET_ACTIVE_FORWARD_MESSAGE_SLIDEOUT,
});

export const setContactSlideoutAutofocusField = (value) => ({
  type: SET_CONTACT_SLIDEOUT_AUTOFOCUS_FIELD,
  value,
});

export const setUsersFilter = (value) => ({
  type: SET_USERS_FILTER,
  value,
});
export const setAnnouncementFilter = (value) => ({
  type: SET_ANNOUNCEMENT_FILTER,
  value,
});
export const setGroupFilter = (value) => ({
  type: SET_GROUP_FILTER,
  value,
});
export const setRegionFilter = (value) => ({
  type: SET_REGION_FILTER,
  value,
});
export const setGroupFilterUsersSetting = (value) => ({
  type: SET_GROUP_FILTER_USERS_SETTING,
  value,
});
export const setTemplateFilter = (value) => ({
  type: SET_TEMPLATE_FILTER,
  value,
});

export const setManageContactFilter = (value) => ({
  type: SET_CONTACT_FILTER,
  value,
});
export const setGroupFilterTemplatesSetting = (value) => ({
  type: SET_GROUP_FILTER_TEMPLATES_SETTING,
  value,
});

export const setManageContactGroupFilter = (value) => ({
  type: SET_CONTACT_FILTER_SETTING,
  value,
});

export const setBackupLanguage = (value) => ({
  type: SET_BACKUP_LANGUAGE,
  value,
});

export const setSloUri = (value) => ({
  type: SET_SLO_URI,
  value,
});

export const setAnnouncementStatus = (value) => ({
  type: SET_ANNOUNCEMENT_STATUS,
  value,
});

export const setListsSearchFilter = (value) => ({
  type: SET_LISTS_SEARCH_FILTER,
  value,
});

export const setTargetedAudienceFilter = (value) => ({
  type: SET_TARGETED_AUDIENCE_FILTER,
  value,
});

export const setBanners = (banners) => ({
  type: SET_BANNERS,
  banners,
});

export const setUpgradeBlurb = (upgradeBlurb) => ({
  type: SET_UPGRADE_BLURB,
  upgradeBlurb,
});

export const setActiveForwardMessageId = (activeForwardMessageId) => ({
  type: SET_ACTIVE_FORWARD_MESSAGE_ID,
  activeForwardMessageId,
});

export const updateEntitySlideoutData = (value) => ({
  type: UPDATE_ENTITY_SLIDEOUT_DATA,
  value,
});

export const setSubmitMessageInputHeight = (value) => ({
  type: SET_SUBMIT_MESSAGE_INPUT_HEIGHT,
  value,
});

export const triggerRefetchBroadcast = (value) => ({
  type: TRIGGER_REFETCH_BROADCAST,
  value,
});

export const triggerRefetchAnnouncement = (value) => ({
  type: TRIGGER_REFETCH_ANNOUNCEMENT,
  value,
});
