import {
  PK_TYPENAMES,
  thread_utils,
  useReadThreadsFromApolloCache,
} from 'client-lib';
import {
  setActiveCreateInternalSection,
  setActiveCreateThreadSection,
} from '../../actions/general';
import { setDefaultContact } from '../../actions/uploadModal';

const useStartContactThread = ({
  client,
  threadsActiveGroupIds,
  currentUser,
  dispatch,
  history,
  includeInternal,
  entity,
  channelType,
  inActiveThreadGroup,
}) => {
  const { contactId } = currentUser;

  const { inboxThreads, myOpenThreads, allOpenThreads } =
    useReadThreadsFromApolloCache({
      client,
      threadsActiveGroupIds,
      contactId,
      includeInternal,
    });

  const inboxRedirectThreadId = inboxThreads.find((thread) =>
    thread_utils.isEntityRecipient(thread, currentUser, entity)
  )?.id;
  const myOpenRedirectThreadId = myOpenThreads.find((thread) =>
    thread_utils.isEntityRecipient(thread, currentUser, entity)
  )?.id;
  const allOpenRedirectThreadId = allOpenThreads.find((inboxThread) =>
    thread_utils.isCustomerContactRecipient(inboxThread, entity)
  )?.id;

  const startContactThread = () => {
    if (channelType !== 'sms' && inActiveThreadGroup) {
      dispatch(setDefaultContact(entity));
      if (
        entity.__typename === PK_TYPENAMES.USER ||
        entity.__typename === PK_TYPENAMES.GROUP
      ) {
        dispatch(setActiveCreateInternalSection());
      } else {
        dispatch(setActiveCreateThreadSection());
      }
      return;
    }
    if (inboxRedirectThreadId) {
      history.push(`/threads/inbox/${inboxRedirectThreadId}`);
      return;
    }
    if (myOpenRedirectThreadId || allOpenRedirectThreadId) {
      history.push(
        `/threads/open/${myOpenRedirectThreadId || allOpenRedirectThreadId}`
      );
      return;
    }

    dispatch(setDefaultContact(entity));
    if (
      entity.__typename === PK_TYPENAMES.USER ||
      entity.__typename === PK_TYPENAMES.GROUP
    ) {
      dispatch(setActiveCreateInternalSection());
    } else {
      dispatch(setActiveCreateThreadSection());
    }
  };

  return {
    startContactThread,
    hasThreadOpen: !!(
      inboxRedirectThreadId ||
      myOpenRedirectThreadId ||
      allOpenRedirectThreadId
    ),
  };
};

export default useStartContactThread;
