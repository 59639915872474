import { useMutation } from "@apollo/client";
import FORWARD_MESSAGE_MUTATION from "../../graphql/mutation/ForwardMessageMutation.graphql";

/*

DOCUMENTATION
  generic hook for using forwardMessageMutation

  args:
    client: (obj) required. apollo client instance

  returns:
    [0] forwardMessageMessage: func that will call forwardMessageMutation.
      args:
        externalContactId: (str) required (empty string if giving externalContactEmailAddress), contact id of who user is forwarding message to
        fromChannelId: (str) required, channel id of the user group and email address the message is forwarded from
        externalContactEmailAddress: (str) required (empty string if giving externalContactId), email address user is forwarding message to
        includeAttachments: (bool) required, true if sending message attachments
        text: (str) required, contents of forwarded message
        subject: (str) required, title of forwarded message
        messageId: (str) required, id of message being forwarded
        onSuccess: (func) optional, will call on mutation success
        onError: (func) optional, will call on mutation failure

*/

const useForwardMessageMutation = ({ client }) => {
  const [forwardMessage] = useMutation(FORWARD_MESSAGE_MUTATION, { client });

  const handleForwardMessage = async ({
    externalContactId,
    fromChannelId,
    externalContactEmailAddress,
    includeAttachments,
    text,
    subject,
    messageId,
    cc,
    onSuccess,
    onError,
  }) => {
    const { data } = await forwardMessage({
      variables: {
        input: {
          externalContactId,
          fromChannelId,
          externalContactEmailAddress,
          includeAttachments,
          text,
          emailMetadata: {
            subject,
            cc,
          },
          messageId,
        },
      },
    }).catch((err) => {
      if (onError) {
        onError(err);
      }
    });

    if (data?.forwardMessage?.errors) {
      if (onError) onError(data.forwardMessage.errors[0]);
    } else if (onSuccess) onSuccess();
  };

  return [handleForwardMessage];
};

export default useForwardMessageMutation;
