import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { IconButton } from '../../elements';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButtonsWrapper = ({ handlePrevious, handleNext, pageInfo }) => (
  <ButtonsWrapper data-testid="pagination-buttons-wrapper">
    <IconButton
      size="lg"
      onClick={handlePrevious}
      disabled={!pageInfo.hasPreviousPage}
    >
      <i
        className="ri-arrow-left-s-line"
        data-testid="pagination-buttons-arrow"
      />
    </IconButton>

    <IconButton size="lg" onClick={handleNext} disabled={!pageInfo.hasNextPage}>
      <i
        className="ri-arrow-right-s-line"
        data-testid="pagination-buttons-arrow"
      />
    </IconButton>
  </ButtonsWrapper>
);

PaginationButtonsWrapper.propTypes = {
  pageInfo: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
};

export default PaginationButtonsWrapper;
