import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTruncateEmail } from 'client-lib';
import styled from 'styled-components';
import HtmlMap from '../HtmlMap/HtmlMap';
import ShowMoreShowLess from './ShowMoreShowLess';
import THEMES from '../../styles/themes/app';

const HtmlWrapper = styled.div`
  div,
  body,
  blockquote {
    color: ${THEMES.FOREGROUND_HIGH};
  }

  pre {
    white-space: pre-wrap !important;
  }
`;

const HtmlMessageText = ({ html, attachments }) => {
  const [threadedEmailsOpen, setThreadedEmailsOpen] = useState(false);

  const parsedHtml = html
    .replaceAll('\n', '')
    .replaceAll('\r', '')
    .replaceAll('class=', 'className=');

  const { truncated, removed, isTruncationMatch } =
    useTruncateEmail(parsedHtml);

  if (isTruncationMatch) {
    return (
      <HtmlWrapper>
        <HtmlMap
          parsedHtml={truncated}
          originalHtml={html}
          attachments={attachments}
        />

        {threadedEmailsOpen ? (
          <HtmlMap
            parsedHtml={removed}
            originalHtml={html}
            attachments={attachments}
          />
        ) : null}

        <ShowMoreShowLess
          isOpen={threadedEmailsOpen}
          setIsOpen={setThreadedEmailsOpen}
        />
      </HtmlWrapper>
    );
  }

  return (
    <HtmlWrapper>
      <HtmlMap
        parsedHtml={parsedHtml}
        originalHtml={html}
        attachments={attachments}
      />
    </HtmlWrapper>
  );
};

HtmlMessageText.propTypes = {
  html: PropTypes.string.isRequired,
  attachments: PropTypes.array,
};

HtmlMessageText.defaultProps = {
  attachments: null,
};

const areEqual = (oldProps, props) => oldProps.html === props.html;

export default memo(HtmlMessageText, areEqual);
