import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import theme from 'styled-theming';
import { CLICK_BANNER_MUTATION } from 'client-lib';
import { useMutation } from '@apollo/client';
import i18n from 'i18n-js';
import {
  openSnackbar,
  setBanners,
  setVideoPlayerModal,
} from '../../actions/general';
import VideoPlayer from '../Modals/VideoPlayer';
import THEMES from '../../styles/themes/app';
import COLORS from '../../styles/colors';

// I didn't use the library Button component in this file because this is a pretty specialized button set :) -Dallin

const BACKGROUND_COLOR = theme('mode', {
  classic: COLORS.PK_CLASSIC.TERTIARY_GREEN,
  light: COLORS.PK.BLUE_60,
  dark: COLORS.PK.BLUE_60,
  holiday: COLORS.PK.BLUE_60,
});

const BACKGROUND_COLOR_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.SECONDARY_GREEN,
  light: COLORS.PK.BLUE_40,
  dark: COLORS.PK.BLUE_40,
  holiday: COLORS.PK.BLUE_40,
});

const BANNER_FONTSIZE = theme('fontSize', {
  default: '13px',
  large: '17px',
});

const BannerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${BACKGROUND_COLOR};
  align-items: stretch;
  height: 48px;
`;

const LinkContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  flex: 1;
  cursor: pointer;
  padding: 10px;
  padding-left: 20px;
  color: ${THEMES.COLORED_FOREGROUND_HIGH};
  font-weight: 500;
  font-size: ${BANNER_FONTSIZE};
  font-family: 'Barlow', sans-serif;
  text-align: left;
  outline: none;

  :hover {
    text-decoration: underline;
    background-color: ${BACKGROUND_COLOR_HOVER};
    color: ${THEMES.FOREGROUND_HIGH_COLOR};
  }

  :active {
    background-color: ${BACKGROUND_COLOR_HOVER};
  }

  :visited {
    color: ${THEMES.COLORED_FOREGROUND_HIGH};
  }
`;

const CloseContainer = styled.button`
  padding: 15px;
  cursor: pointer;
  height: 100%;
  font-size: 20px;
  color: ${THEMES.COLORED_FOREGROUND_HIGH};

  :focus {
    outline: none;
  }

  :hover {
    background-color: ${BACKGROUND_COLOR_HOVER};
    color: ${THEMES.FOREGROUND_HIGH_COLOR};
  }

  :active {
    background-color: ${BACKGROUND_COLOR_HOVER};
  }
`;

const BANNER_TYPES = {
  VIDEO: 'VIDEO',
  INFO: 'INFO',
  LINK: 'LINK',
};
const LANGUAGES = {
  EN_US: 'EN_US',
  FR_CA: 'FR_CA',
  ES_MX: 'ES_MX',
};

const Banner = () => {
  // let playerRef = React.createRef();
  const dispatch = useDispatch();
  const defaultVideoUrl = 'https://d8q4vd3i33fd7.cloudfront.net/';

  const banner = useSelector((state) => state.general.banners[0]);
  const userLanguage = useSelector(
    (state) => state.session?.currentUser?.language
  );
  const activeVideoPlayerModal = useSelector(
    (state) => state.general.activeVideoPlayerModal
  );

  // import banner clicked mutation
  const [clickBannerMutation] = useMutation(CLICK_BANNER_MUTATION);

  // when a banner is dismissed, send mutation to back end to discard that banner.
  // the backend then returns the left over banners, if any, which we use to replace the current banners in redux.
  // This is used for all banner types
  const dismissBanner = async () => {
    const onError = () => {
      dispatch(
        openSnackbar(
          i18n.t('slideouts-CreateCustomerForm-genericError'),
          'error'
        )
      );
    };

    const { data } = await clickBannerMutation({
      variables: { id: banner?.id },
    }).catch((e) => {
      console.error(e);
      onError();
    });

    if (data?.clickBanner?.errors) {
      onError();
    } else if (data?.clickBanner?.banners) {
      dispatch(setBanners(data?.clickBanner?.banners));
    }
  };

  // handles clicking a link type banner
  const handleClickLinkBanner = () => {
    let url = banner?.url;
    if (url) {
      if (!url.match(/^https?:\/\//i)) url = 'http://' + url;
      window.open(url);
    }
    dismissBanner();
  };

  // handles clicking a video type banner
  const handleClickVideoBanner = () => {
    dispatch(setVideoPlayerModal(true));
  };

  // handles clicking a info type banner (info is just a message, does nothing)
  const handleClickInfoBanner = () => {
    dismissBanner();
  };

  // routes when user clicks banner to correct handler depending on type.
  const clickBanner = () => {
    switch (banner?.type) {
      case BANNER_TYPES.INFO:
        handleClickInfoBanner();
        break;

      case BANNER_TYPES.VIDEO:
        handleClickVideoBanner(true);
        break;

      case BANNER_TYPES.LINK:
        handleClickLinkBanner();
        break;

      default:
        break;
    }
  };

  // closes video modal by updating redux
  const handleVideoClose = () => {
    dispatch(setVideoPlayerModal(false));
    dismissBanner();
    // playerRef.getCurrentTime();
    // const videoTimePlayed = Math.floor(playerRef.getCurrentTime());
    // track('Video View Exited', {
    //   video_uri: videoUri,
    //   video_timestamp: videoTimePlayed,
    // });
  };

  // const handleVideoStart = () => {
  //   // track('Video View Launched', { video_uri: videoUri });
  // };

  // const handleVideoEnded = () => {
  //   // track('Video View Completed', { video_uri: videoUri });
  // };

  // const setPlayerRef = (element) => {
  //   playerRef = element;
  // };

  // gives correct text for banner title based on what language user has selected.
  const retrieveText = (banner, userLanguage) => {
    switch (userLanguage) {
      case LANGUAGES.ES_MX:
        return banner?.textEs;

      case LANGUAGES.FR_CA:
        return banner?.textFr;

      default:
        return banner?.textEn;
    }
  };

  const retrieveTitle = (banner, userLanguage) => {
    switch (userLanguage) {
      case LANGUAGES.ES_MX:
        return banner?.nameEs;

      case LANGUAGES.FR_CA:
        return banner?.nameFr;

      default:
        return banner?.nameEn;
    }
  };

  return (
    <>
      {banner ? (
        <BannerContainer>
          <LinkContainer target="_blank" onClick={clickBanner}>
            {retrieveText(banner, userLanguage)}
          </LinkContainer>
          <CloseContainer
            onClick={dismissBanner}
            data-testid="close-banner-button"
          >
            <i className="ri-close-line" />
          </CloseContainer>
        </BannerContainer>
      ) : null}
      <VideoPlayer
        open={activeVideoPlayerModal}
        videoUrl={
          banner?.type === BANNER_TYPES.VIDEO ? banner.url : defaultVideoUrl
        }
        onClose={handleVideoClose}
        // videoReference={setPlayerRef}
        modalTitle={retrieveTitle(banner, userLanguage)}
      />
    </>
  );
};

export default Banner;
