import { useMutation } from "@apollo/client";
import { openThreadSubscriptionHandler } from "../../configs/apollo/setupSubscriptions";
import CLAIM_AND_CLOSE_THREAD_MUTATION from "../../graphql/mutation/ClaimAndCloseThreadMutation.graphql";

/*

DOCUMENTATION
  hook for claiming and then immediately closing a thread (such as a fax which doesn't require a response)

  args:
    client: (obj) required. apollo client instance

  returns:
    [0] handleClaimAndCloseThread: func that will call claim / close thread mutation.
      args:
        threadId: (str) required. id of thread to close.
        onSuccess: (func) optional, will call on mutation success
        onError: (func) optional, will call on mutation failure

*/

const useClaimAndCloseThreadMutation = ({ client, store }) => {
  const [claimAndCloseThread] = useMutation(CLAIM_AND_CLOSE_THREAD_MUTATION, {
    client,
  });

  const handleClaimAndCloseThread = async ({
    threadId,
    onSuccess = () => {},
    onError = () => {},
  }) => {
    const { data } = await claimAndCloseThread({
      variables: {
        input: {
          threadId,
        },
      },
    }).catch((err) => {
      onError(err);
      console.error(err);
    });

    if (data && data.claimAndCloseThread && data.claimAndCloseThread.errors) {
      onError(data.claimAndCloseThread.errors[0]);
    } else {
      const thread = data?.claimAndCloseThread?.thread;
      onSuccess();
      if (store) openThreadSubscriptionHandler(store, client, thread);
    }
  };

  return [handleClaimAndCloseThread];
};

export default useClaimAndCloseThreadMutation;
