import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
} from 'client-lib';
import { arrayToObject } from 'client-lib/src/lib/utils/helpers';
import THEMES from '../../../styles/themes/app';
import {
  CustomerContactAccountRep,
  CustomerContactEmail,
  CustomerContactFax,
  CustomerContactGroups,
  CustomerContactNotes,
  CustomerContactOptIn,
  CustomerContactPhoneNumber,
} from '../CustomerInfo/CustomerInfoFields/CustomerInfoFields';
import useGetUserAccountPolicies from '../../../hooks/customer/useGetUserAccountPolicies';

const Wrapper = styled.div`
  height: 100%;
  overflow-y: auto;
`;

const InfoItemWrapper = styled.div`
  padding: 1rem;
  padding-bottom: calc(1rem + 1px);
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
`;

const CustomerInfoReference = ({ customerContact }) => {
  const allGroups = useSelector((state) => state?.accountData?.allGroups);
  const currentUser = useSelector((state) => state?.session?.currentUser);

  useGetUserAccountPolicies({
    userId: currentUser?.userId,
    actionList: [
      AVAILABLE_PERMISSIONS.EDIT_CONTACT_EMAIL_OPT_OUT,
      AVAILABLE_PERMISSIONS.EDIT_CONTACT_FAX_OPT_OUT,
      AVAILABLE_PERMISSIONS.VIEW_PRIORITY_NOTIFICATIONS_STATE,
      AVAILABLE_PERMISSIONS.EDIT_CONTACT_OPT_OUT,
    ],
  });

  const accountGroups = allGroups ? arrayToObject(allGroups, 'id') : null;

  const allowEmailsOptOut = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.EDIT_CONTACT_EMAIL_OPT_OUT,
    currentUser?.accountPolicies,
    currentUser?.groupPolicies
  );

  const allowFaxOptOut = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.EDIT_CONTACT_FAX_OPT_OUT,
    currentUser?.accountPolicies,
    currentUser?.groupPolicies
  );

  const priorityNotificationsViewPermission = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.VIEW_PRIORITY_NOTIFICATIONS_STATE,
    currentUser?.accountPolicies,
    currentUser?.groupPolicies
  );

  const optInOutEditPermission = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.EDIT_CONTACT_OPT_OUT,
    currentUser?.accountPolicies,
    currentUser?.groupPolicies
  );

  return (
    <Wrapper>
      <InfoItemWrapper>
        <CustomerContactPhoneNumber customerContact={customerContact} />
      </InfoItemWrapper>
      <InfoItemWrapper>
        <CustomerContactFax customerContact={customerContact} />
      </InfoItemWrapper>
      <InfoItemWrapper>
        <CustomerContactEmail customerContact={customerContact} />
      </InfoItemWrapper>
      <InfoItemWrapper>
        <CustomerContactGroups
          customerContact={customerContact}
          accountGroups={accountGroups}
        />
      </InfoItemWrapper>
      <InfoItemWrapper>
        <CustomerContactAccountRep
          customerContact={customerContact}
          priorityNotificationsViewPermission={
            priorityNotificationsViewPermission
          }
        />
      </InfoItemWrapper>
      <InfoItemWrapper>
        <CustomerContactNotes customerContact={customerContact} />
      </InfoItemWrapper>
      <InfoItemWrapper>
        <CustomerContactOptIn
          customerContact={customerContact}
          optInOutEditPermission={optInOutEditPermission}
          allowEmailsOptOut={allowEmailsOptOut}
          allowFaxOptOut={allowFaxOptOut}
        />
      </InfoItemWrapper>
    </Wrapper>
  );
};

CustomerInfoReference.propTypes = {
  customerContact: PropTypes.object.isRequired,
};

export default CustomerInfoReference;
