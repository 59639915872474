import { useState } from "react";
import { useMutation } from "@apollo/client";
import useForm from "../../../utils/hooks/useForm";
import createCustomerAccountMutation from "../../../graphql/mutation/CreateCustomerAccountMutation.graphql";
import updateCustomerContactMutation from "../../../graphql/mutation/UpdateCustomerContactMutation.graphql";
import {
  COMMON_NORMALIZERS,
  contactName,
  sortLabels,
} from "../../../utils/helpers";
import SEARCH_CUSTOMERS_VALIDATE_QUERY from "../../../graphql/query/SearchCustomersQuery.graphql";
import SEARCH_FAX_CONTACTS_QUERY from "../../../graphql/query/SearchFaxContactsQuery.graphql";
import useAccountQuery from "../../../api/query/useAccountQuery";
import {
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
} from "../../../utils/roles";
import { emailRegex } from "../../../utils/regex";

const defaultFormValues = {
  id: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
  faxNumber: "",
  email: "",
  company: {
    value: "",
    text: "",
    addCompanySuggestion: false,
  },
  accountPhoneNumber: "",
  accountEmail: "",
  accountNumber: "",
  group: {
    value: [],
    text: "",
  },
  notes: "",
  notesUpdatedAt: "",
  apiOptOut: false,
  announcementsOptOut: null, // setting to undefined because truthy value overrides incoming customer data
  promotionalBroadcastOptOut: null,
  conversationsOptOut: false,
  emailsOptOut: false,
  faxOptOut: false,
  accountRep: {
    value: "",
    id: "",
    lastSelectedName: "",
    selectedUser: {},
  },
  prioritizeRep: false,
  jobTitle: "",
  labels: {
    value: [],
    text: "",
    color: "",
  },
};

const useEditCustomerDetails = ({
  currentUser,
  client,
  handleOnClose,
  onHandleError,
  i18n,
  initialFormValues = null,
  apiMessageFeatureEnabled,
  editContactLabelsPermissions = false,
}) => {
  const { accountPolicies, groupPolicies } = currentUser;
  let accountName = "";

  const announcementsAccess = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.EDIT_CONTACT_OPT_OUT,
    accountPolicies,
    groupPolicies
  );

  const allowEmailsOptOut = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.EDIT_CONTACT_EMAIL_OPT_OUT,
    accountPolicies,
    groupPolicies
  );

  const allowFaxOptOut = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.EDIT_CONTACT_FAX_OPT_OUT,
    accountPolicies,
    groupPolicies
  );

  const accountRepEditPermission = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.EDIT_CUSTOMER_REP,
    accountPolicies,
    groupPolicies
  );

  const priorityNotificationsTogglePermission = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.TOGGLE_PRIORITY_NOTIFICATIONS,
    accountPolicies,
    groupPolicies
  );

  const userCanEditCompanies = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.EDIT_COMPANIES,
    accountPolicies,
    groupPolicies
  );

  const { data } = useAccountQuery({
    client,
    variables: { id: currentUser.accountId },
  });

  const [createCustomerAccount, { loading: createCustomerAccountLoading }] =
    useMutation(createCustomerAccountMutation, {
      client,
    });

  const [updateCustomerContact, { loading: updateCustomerContactLoading }] =
    useMutation(updateCustomerContactMutation, {
      client,
    });

  if (data?.account?.name) {
    accountName = data.account.name;
  }

  const validationOverrideAction = (field, fields, currentValidity) => {
    if (fields?.company?.otherProps?.addCompanySuggestion) {
      field.setError("");
      return true;
    }
    if (!field.otherProps.required) {
      field.setError("");
      return true;
    }

    return currentValidity;
  };

  const requiredValidation = {
    type: "required",
    errorMessage: i18n.t("slideouts-CreateCustomerForm-required"),
  };
  const phoneNumberValidation = {
    type: "phoneFormat",
    errorMessage: i18n.t("slideouts-CreateCustomerForm-invalidPhone"),
  };
  const faxNumberValidation = {
    type: "phoneFormat",
    errorMessage: i18n.t("slideouts-CreateCustomerForm-invalidFax", {
      defaultValue: "Invalid fax number format.",
    }),
  };
  const emailAddressValidation = {
    type: "emailFormat",
    errorMessage: i18n.t("slideouts-CreateCustomerForm-invalidEmail"),
  };
  const firstNameMinLengthValidation = {
    type: "minLength",
    minLength: 2,
    errorMessage: i18n.t("slideouts-CreateCustomerForm-firstNameError"),
    optional: true,
  };

  const lastNameMinLengthValidation = {
    type: "minLength",
    minLength: 2,
    errorMessage: i18n.t("slideouts-CreateCustomerForm-lastNameError"),
    optional: true,
  };

  const firstNameMaxLengthValidation = {
    type: "maxLength",
    maxLength: 50,
    errorMessage: i18n.t("slideouts-CreateCustomerForm-firstNameMaxError"),
    optional: true,
  };

  const lastNameMaxLengthValidation = {
    type: "maxLength",
    maxLength: 50,
    errorMessage: i18n.t("slideouts-CreateCustomerForm-lastNameMaxError"),
    optional: true,
  };

  const accountNumberValidation = {
    type: "maxLength",
    maxLength: 255,
    errorMessage: i18n.t("slideouts-CreateCustomerForm-acctLengthError"),
    optional: true,
  };

  const initialValues = {
    id: {
      // id is used to determine if updateCustomerContact mutation or createCustomerContact mutation should be triggered
      // if id exists then update otherwise create
      value: initialFormValues?.id
        ? initialFormValues.id
        : defaultFormValues.id,
    },
    firstName: {
      value: initialFormValues?.firstName
        ? initialFormValues.firstName
        : defaultFormValues.firstName,
      validations: [
        requiredValidation,
        firstNameMinLengthValidation,
        firstNameMaxLengthValidation,
      ],
      otherProps: {
        validationOverrideAction,
      },
    },
    lastName: {
      value: initialFormValues?.lastName
        ? initialFormValues.lastName
        : defaultFormValues.lastName,
      validations: [
        requiredValidation,
        lastNameMinLengthValidation,
        lastNameMaxLengthValidation,
      ],
      otherProps: {
        validationOverrideAction,
      },
    },
    phoneNumber: {
      value: initialFormValues?.phoneNumber
        ? initialFormValues.phoneNumber
        : defaultFormValues.phoneNumber,
      otherProps: {
        text: initialFormValues?.phoneNumber
          ? COMMON_NORMALIZERS.normalizePhone(initialFormValues.phoneNumber)
          : defaultFormValues.phoneNumber,
      },
      validations: [phoneNumberValidation],
      optional: true,
    },
    faxNumber: {
      value: initialFormValues?.faxNumber
        ? initialFormValues.faxNumber
        : defaultFormValues.faxNumber,
      otherProps: {
        text: initialFormValues?.faxNumber
          ? COMMON_NORMALIZERS.normalizePhone(initialFormValues.faxNumber)
          : defaultFormValues.faxNumber,
      },
      validations: [faxNumberValidation],
      optional: true,
    },
    email: {
      value: initialFormValues?.email
        ? initialFormValues.email
        : defaultFormValues.email,
      validations: [emailAddressValidation],
      optional: true,
    },
    company: {
      value: initialFormValues?.company
        ? initialFormValues.company.value
        : defaultFormValues.company.value,
      otherProps: {
        name: initialFormValues?.company
          ? initialFormValues.company.text
          : defaultFormValues.company.text,
        addCompanySuggestion: initialFormValues?.company
          ? initialFormValues.company.addCompanySuggestion
          : defaultFormValues.company.addCompanySuggestion,
      },
      showDependencies: (field) => field.otherProps.addCompanySuggestion,
    },
    accountPhoneNumber: {
      value: initialFormValues?.accountPhoneNumber
        ? initialFormValues.accountPhoneNumber
        : defaultFormValues.accountPhoneNumber,
      otherProps: {
        text: initialFormValues?.accountPhoneNumber
          ? COMMON_NORMALIZERS.normalizePhone(
              initialFormValues.accountPhoneNumber
            )
          : defaultFormValues.accountPhoneNumber,
        validationOverrideAction,
      },
      validations: [phoneNumberValidation],
      optional: true,
    },
    accountEmail: {
      value: initialFormValues?.accountEmail
        ? initialFormValues.accountEmail
        : defaultFormValues.accountEmail,
      validations: [emailAddressValidation],
      optional: true,
      otherProps: {
        validationOverrideAction,
      },
    },
    accountNumber: {
      value: initialFormValues?.accountNumber
        ? initialFormValues.accountNumber
        : defaultFormValues.accountNumber,
      validations: [accountNumberValidation],
      optional: true,
    },
    group: {
      value:
        initialFormValues?.group?.value.length > 0
          ? initialFormValues?.group?.value
          : defaultFormValues.group.value,
      otherProps: {
        text: initialFormValues?.group
          ? initialFormValues.group.text
          : defaultFormValues.group.text,
      },
      validations: [requiredValidation],
    },
    accountRep: {
      // in this case, the value is what is being shown in the input. The rest of the inputs are the opposite of this, but I feel it's a cleaner way to write the code in this case.
      value: initialFormValues?.accountRep
        ? initialFormValues?.accountRep.value
        : defaultFormValues.accountRep.value,
      otherProps: {
        id: initialFormValues?.accountRep
          ? initialFormValues?.accountRep?.id
          : defaultFormValues?.accountRep.id,
        lastSelectedName: initialFormValues?.accountRep
          ? initialFormValues?.accountRep?.lastSelectedName
          : defaultFormValues?.accountRep.lastSelectedName,
        selectedUser: initialFormValues?.accountRep
          ? initialFormValues?.accountRep?.selectedUser
          : defaultFormValues?.accountRep.selectedUser,
      },
      validations: [],
    },
    prioritizeRep: {
      value: initialFormValues?.prioritizeRep
        ? initialFormValues.prioritizeRep
        : defaultFormValues.prioritizeRep,
    },
    notes: {
      value: initialFormValues?.notes
        ? initialFormValues?.notes
        : defaultFormValues.notes,
    },
    notesUpdatedAt: {
      value: initialFormValues?.notesUpdatedAt
        ? initialFormValues?.notesUpdatedAt
        : defaultFormValues.notesUpdatedAt,
    },
    apiOptOut: {
      value: initialFormValues?.apiOptOut
        ? initialFormValues?.apiOptOut
        : defaultFormValues.apiOptOut,
    },
    announcementsOptOut: {
      value:
        "announcementsOptOut" in initialFormValues
          ? initialFormValues.announcementsOptOut
          : defaultFormValues.announcementsOptOut,
    },
    promotionalBroadcastOptOut: {
      value:
        "promotionalBroadcastOptOut" in initialFormValues
          ? initialFormValues.promotionalBroadcastOptOut
          : defaultFormValues.promotionalBroadcastOptOut,
    },
    conversationsOptOut: {
      value: initialFormValues?.conversationsOptOut
        ? initialFormValues?.conversationsOptOut
        : defaultFormValues.conversationsOptOut,
    },
    emailsOptOut: {
      value: initialFormValues?.emailsOptOut
        ? initialFormValues?.emailsOptOut
        : defaultFormValues.emailsOptOut,
    },
    faxOptOut: {
      value: initialFormValues?.faxOptOut
        ? initialFormValues?.faxOptOut
        : defaultFormValues.faxOptOut,
    },
    jobTitle: {
      value: initialFormValues?.jobTitle
        ? initialFormValues?.jobTitle
        : defaultFormValues.jobTitle,
    },
    labels: {
      value:
        initialFormValues?.labels?.value.length > 0
          ? initialFormValues?.labels?.value
          : defaultFormValues.labels.value,
      otherProps: {
        text: initialFormValues?.labels
          ? initialFormValues.labels.text
          : defaultFormValues.labels.text,
      },
    },
  };

  const [existingPhoneNumberId, setExistingPhoneNumberId] = useState("");
  const [existingFaxNumberId, setExistingFaxNumberId] = useState("");
  const [existingEmailId, setExistingEmailId] = useState("");

  const { fields, updateField, onSubmit, isVisitedForm } = useForm(
    initialValues,
    async (currentFields) => {
      const {
        id,
        firstName,
        lastName,
        email,
        phoneNumber,
        faxNumber,
        company,
        group,
        accountRep,
        prioritizeRep,
        faxOptOut,
        emailsOptOut,
        apiOptOut,
        announcementsOptOut,
        promotionalBroadcastOptOut,
        conversationsOptOut,
        notes,
        jobTitle,
        labels,
      } = currentFields;

      let customerContactResponse;
      const customerContactValues = {
        id: id.value,
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        phoneNumber: phoneNumber.value,
        faxNumber: faxNumber.value,
        company: company.value,
        group: group.value,
        repUserId: accountRep.otherProps.id,
        prioritizeRep: prioritizeRep.value,
        faxOptOut: faxOptOut.value,
        emailsOptOut: emailsOptOut.value,
        apiOptOut: apiOptOut.value,
        announcementsOptOut: announcementsOptOut.value,
        promotionalBroadcastOptOut: promotionalBroadcastOptOut.value,
        conversationsOptOut: conversationsOptOut.value,
        notes: notes.value,
        jobTitle: jobTitle.value,
        labels: labels.value,
      };

      // Validate non existance of phone or fax number to execute query.
      if (!existingPhoneNumberId && !existingFaxNumberId && !existingEmailId) {
        if (currentFields.company.otherProps.addCompanySuggestion) {
          const { company, accountEmail, accountNumber, accountPhoneNumber } =
            currentFields;

          const customerAccountValues = {
            name: company.value,
            phoneNumber: accountPhoneNumber.value,
            emailAddress: accountEmail.value,
            accountNumber: accountNumber.value,
          };
          const customerAccountResponse = await createAccount(
            customerAccountValues
          );

          if (customerAccountResponse?.createCustomerAccount?.errors) {
            onHandleError(i18n.t("slideouts-CreateCustomerForm-genericError"));
          } else {
            customerContactResponse = await updateContact(
              customerContactValues,
              customerAccountResponse.createCustomerAccount.customerAccount.id
            );
            handleMutationResponse(
              customerContactResponse.updateCustomerContact.customerContact,
              customerContactResponse.updateCustomerContact.errors
            );
          }
        } else {
          customerContactResponse = await updateContact(
            customerContactValues,
            company?.value
          );
          handleMutationResponse(
            customerContactResponse.updateCustomerContact.customerContact,
            customerContactResponse.updateCustomerContact.errors
          );
        }
      }
    }
  );

  const cleanUp = () => {
    // need to reset addCompanySuggestion to reverse input fields
    updateField({
      name: "company",
      ...fields.company,
      otherProps: { ...fields.company.otherProps, addCompanySuggestion: false },
    });
  };

  const handleMutationResponse = (mutationData, mutationErrors) => {
    if (mutationErrors !== null && mutationErrors.length > 0) {
      if (mutationErrors[0]?.reason === "phone number in use") {
        fields.phoneNumber.setError(
          i18n.t("settings-ProfileFormContainer-phoneNumberError")
        );
      } else {
        onHandleError(
          i18n.t("slideouts-CreateCustomerForm-genericError"),
          mutationErrors
        );
      }
    } else {
      handleOnClose(cleanUp);
    }
  };

  const handleOnDeleteGroup = (groupToBeDeleted) => {
    const {
      group: { value, otherProps },
    } = fields;
    const newArray = value.filter(
      (selection) => selection.value !== groupToBeDeleted
    );
    updateField({
      value: newArray,
      name: "group",
      otherProps: { text: otherProps.text },
    });
  };

  const handleOnSelectGroup = (groupSelected) => {
    const {
      group: { value },
    } = fields;
    const existentSelection = value.find(
      (selection) => selection.value === groupSelected.value
    );
    if (!existentSelection) {
      updateField({
        value: [...value, groupSelected],
        name: "group",
        otherProps: { text: "" },
      });
    }
  };

  const handleOnBlurGroup = (e) => {
    const {
      group: { value },
    } = fields;
    updateField({
      value: [...value],
      name: "group",
      otherProps: { text: "" },
    });
    e.target.value = "";
  };

  const handleOnBlurLabels = (e) => {
    const {
      labels: { value },
    } = fields;
    updateField({
      value: [...value],
      name: "labels",
      otherProps: { text: "" },
    });
    e.target.value = "";
  };

  const handlePhoneNumberChange = (ev, fieldNumberType, contactId) => {
    const desnormalizedPhone = ev.target.value.replace(/\D+/g, "");
    const otherPropsObject = {
      text: COMMON_NORMALIZERS.normalizePhone(ev.target.value),
    };

    if (fieldNumberType === "account") {
      fields.accountPhoneNumber.setValue(desnormalizedPhone);
      fields.accountPhoneNumber.setOtherProps(otherPropsObject);
      fields.accountPhoneNumber.setVisited(true);
    } else if (fieldNumberType === "contact") {
      fields.phoneNumber.setValue(desnormalizedPhone);
      fields.phoneNumber.setOtherProps(otherPropsObject);
      fields.phoneNumber.setVisited(true);
      setExistingPhoneNumberId("");
      serverPhoneValidation(desnormalizedPhone, contactId);
    }
  };

  const serverPhoneValidation = async (desnormalizedPhone, contactId) => {
    if (desnormalizedPhone.length === 10) {
      const response = await client.query({
        query: SEARCH_CUSTOMERS_VALIDATE_QUERY,
        fetchPolicy: "network-only",
        variables: {
          typeFilter: "CUSTOMER_CONTACT",
          query: desnormalizedPhone.replace(/[^\d]/g, ""),
          first: 1,
        },
      });
      const matches = response.data.searchCustomers.edges;

      if (
        matches.length > 0 &&
        matches?.[0]?.node?.firstName !== null &&
        matches?.[0]?.node?.id !== contactId
      ) {
        setExistingPhoneNumberId(matches[0].node.id);
      }
    }
  };

  const handleFaxNumberChange = (ev, contactId) => {
    const desnormalizedFax = ev.target.value.replace(/\D+/g, "");
    const otherPropsObject = {
      text: COMMON_NORMALIZERS.normalizePhone(ev.target.value),
    };
    fields.faxNumber.setValue(desnormalizedFax);
    fields.faxNumber.setOtherProps(otherPropsObject);
    fields.faxNumber.setVisited(true);
    setExistingFaxNumberId("");
    serverFaxValidation(desnormalizedFax, contactId);
  };

  const serverFaxValidation = async (desnormalizedFax, contactId) => {
    if (desnormalizedFax.length === 10) {
      const response = await client.query({
        query: SEARCH_FAX_CONTACTS_QUERY,
        fetchPolicy: "network-only",
        variables: {
          query: desnormalizedFax.replace(/[^\d]/g, ""),
          first: 1,
        },
      });
      const matches = response.data.searchFaxContacts.edges;
      if (
        matches.length > 0 &&
        matches?.[0]?.node?.firstName !== null &&
        matches?.[0]?.node?.id !== contactId
      ) {
        setExistingFaxNumberId(matches[0].node.id);
      }
    }
  };

  const handleEmailChange = (ev, contactId) => {
    const { value } = ev.target;
    fields.email.setValue(value);
    fields.email.setVisited(true);
    setExistingEmailId("");
    serverEmailValidation(value, contactId);
  };

  const serverEmailValidation = async (emailValue, contactId) => {
    if (emailRegex.test(emailValue)) {
      const response = await client.query({
        query: SEARCH_CUSTOMERS_VALIDATE_QUERY,
        fetchPolicy: "network-only",
        variables: {
          query: emailValue,
          first: 1,
        },
      });
      const matches = response.data.searchCustomers.edges;
      if (
        matches.length > 0 &&
        matches?.[0]?.node?.firstName !== null &&
        matches?.[0]?.node?.id !== contactId
      ) {
        const exactMatch = matches.find(
          (match) =>
            match.node.emailAddress.toLowerCase() === emailValue.toLowerCase()
        );
        if (exactMatch) {
          setExistingEmailId(exactMatch.node.id);
        }
      }
    }
  };

  const handleCompanyChange = (ev) => {
    const textField = ev.target.value;
    updateField({
      value: "",
      otherProps: { name: textField },
      name: "company",
    });

    if (fields.accountPhoneNumber) {
      updateField({
        value: "",
        name: "accountPhoneNumber",
        otherProps: { text: "" },
        unforceVisit: true,
      });
    }
    if (fields.accountEmail) {
      updateField({ value: "", name: "accountEmail", unforceVisit: true });
    }
    if (fields.accountNumber) {
      updateField({ value: "", name: "accountNumber", unforceVisit: true });
    }
  };

  const handleOnCompanySelect = (companyObject) => {
    const {
      addCompanySuggestion,
      name,
      id,
      accountNumber,
      emailAddress,
      phoneNumber,
    } = companyObject;

    if (addCompanySuggestion) {
      updateField({
        value: name,
        name: "company",
        otherProps: {
          name,
          addCompanySuggestion,
        },
      });
    } else {
      updateField({
        value: id,
        name: "company",
        otherProps: { addCompanySuggestion, name },
      });
      updateField({
        value: accountNumber !== null ? accountNumber : "",
        name: "accountNumber",
      });
      updateField({
        name: "accountEmail",
        value: emailAddress !== null ? emailAddress : "",
      });
      updateField({
        name: "accountPhoneNumber",
        value: phoneNumber !== null ? phoneNumber : "",
      });
    }
  };

  const handleAccountRepSelect = (suggestion) => {
    const { id } = suggestion;

    updateField({
      name: "accountRep",
      value: contactName(suggestion),
      otherProps: {
        id,
        lastSelectedName: contactName(suggestion),
        selectedUser: suggestion,
      },
    });
  };

  const handleAccountRepBlur = (e) => {
    const { selectedUser, lastSelectedName, id } = fields.accountRep.otherProps;
    if (e.target.value) {
      updateField({
        name: "accountRep",
        value: lastSelectedName,
        otherProps: {
          id,
          lastSelectedName,
          selectedUser,
        },
      });
    } else {
      updateField({
        name: "accountRep",
        value: "",
        otherProps: {
          id: "",
          lastSelectedName: "",
          selectedUser: {},
        },
      });

      // prioritize rep cannot be set to true if there is no account rep
      fields.prioritizeRep.setValue(false);
    }
  };

  const handleOnSelectLabel = (labelSelected) => {
    const {
      labels: { value },
    } = fields;

    const existentSelection = value.find(
      (selection) => selection.value === labelSelected.value
    );

    const renderLabels = value?.map((label) => {
      return { color: label.color, text: label.text, value: label.value };
    });

    const newLabels = sortLabels(renderLabels, labelSelected);

    if (!existentSelection) {
      updateField({
        value: newLabels,
        name: "labels",
        otherProps: { text: "" },
      });
    }
  };

  const handleOnDeleteLabel = (labelToBeDeleted) => {
    const {
      labels: { value, otherProps },
    } = fields;
    const newArray = value.filter(
      (selection) => selection.value !== labelToBeDeleted
    );
    updateField({
      value: newArray,
      name: "labels",
      otherProps: { text: otherProps.text },
    });
  };

  const handleBroadcastPreferenceChange = (field) => {
    // If you turn on either switch, they both turn on
    if (field.value) {
      updateField({
        value: false,
        name: "announcementsOptOut",
      });
      updateField({
        value: false,
        name: "promotionalBroadcastOptOut",
      });
      // If you turn off announcements, then both turn off
    } else if (field.name === "announcementsOptOut") {
      updateField({
        value: true,
        name: "announcementsOptOut",
      });
      updateField({
        value: true,
        name: "promotionalBroadcastOptOut",
      });
      // Otherwise flip toggle
    } else {
      updateField({
        value: !field.value,
        name: field.name,
      });
    }
  };

  const createAccount = async (values) => {
    const { accountId: prokeepAccountId } = currentUser;
    const variables = {
      input: {
        ...values,
        prokeepAccountId,
      },
    };
    try {
      const response = await createCustomerAccount({ variables });
      return response.data;
    } catch (e) {
      console.warn(e);
    }

    return null;
  };

  const updateContact = async (values, accountNumber) => {
    const groupIds = values.group.map(
      (individualGroup) => individualGroup.value
    );

    const variables = {
      input: {
        id: values.id,
        accountId: accountNumber,
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        faxNumber: values.faxNumber,
        emailAddress: values.email,
        repUserId: values.repUserId,
        prioritizeRep: values.prioritizeRep,
        conversationsOptOut: values.conversationsOptOut,
        notes: values.notes,
        groupIds,
        jobTitle: values.jobTitle,
      },
    };

    if (apiMessageFeatureEnabled) {
      variables.input.apiOptOut = values.apiOptOut;
    }
    if (announcementsAccess) {
      variables.input.announcementsOptOut = values.announcementsOptOut;
      variables.input.promotionalBroadcastOptOut =
        values.promotionalBroadcastOptOut;
    }
    if (allowFaxOptOut) {
      variables.input.faxOptOut = values.faxOptOut;
    }
    if (allowEmailsOptOut) {
      variables.input.emailsOptOut = values.emailsOptOut;
    }

    if (editContactLabelsPermissions) {
      variables.input.labelIds = values.labels.map((label) => label.value);
    }

    if (!priorityNotificationsTogglePermission)
      delete variables.input.prioritizeRep;
    if (!accountRepEditPermission) delete variables.input.repUserId;

    try {
      const response = await updateCustomerContact({ variables });
      return response.data;
    } catch (e) {
      console.warn(e);
    }

    return null;
  };

  return {
    onSubmit,
    handleCompanyChange,
    isVisitedForm,
    handleOnCompanySelect,
    handleAccountRepSelect,
    handleAccountRepBlur,
    handleBroadcastPreferenceChange,
    handlePhoneNumberChange,
    handleFaxNumberChange,
    handleEmailChange,
    handleOnSelectGroup,
    handleOnBlurGroup,
    handleOnBlurLabels,
    handleOnDeleteGroup,
    handleOnSelectLabel,
    handleOnDeleteLabel,
    fields,
    updateField,
    existingPhoneNumberId,
    existingFaxNumberId,
    existingEmailId,
    accountName,
    accountRepEditPermission,
    priorityNotificationsTogglePermission,
    mutationLoading:
      createCustomerAccountLoading || updateCustomerContactLoading,
    announcementsAccess,
    allowFaxOptOut,
    allowEmailsOptOut,
    userCanEditCompanies,
  };
};

export default useEditCustomerDetails;
