import React, { useContext } from 'react';
import { useTransition, animated } from 'react-spring';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18n-js';
import { useCustomerGroups, AVAILABLE_PERMISSIONS } from 'client-lib';
import styled, { ThemeContext } from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import CreateThread from './CreateThread';
import CreateAnnouncement from './CreateAnnouncement';
import CreateInternal from './CreateInternal';
import CreateCustomerFormContainer from '../../containers/CreateCustomerForm/CreateCustomerFormContainer';
import {
  closeCreateSectionModal,
  setActiveLoseProgressState,
  setActiveSidebar,
} from '../../actions/general';

import { clearCreateSection } from '../../actions/createSection';

import THEMES from '../../styles/themes/app';
import { IconButton, Modal, Text } from '../../elements';
import CreateFax from './CreateFax';
import CreateEmail from './CreateEmail';
import PaymentWizard from '../Wizard2/payment/PaymentWizard';
import useGetUserAccountPolicies from '../../hooks/customer/useGetUserAccountPolicies';
import CreateBroadcastList from './CreateBroadcastList';

const CloseButtonWrap = styled.div`
  position: absolute;
  top: 24px;
  right: 64px;
  z-index: 3;
`;

const CloseIcon = styled.i``;

const CreateSection = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const allGroups = useSelector((state) => state?.accountData?.allGroups);
  const {
    activeCreateCustomerModal,
    activeCreateAnnouncementModal,
    activeCreateBroadcastListModal,
    activeCreateThreadModal,
    activeCreateFaxModal,
    activeCreateEmailModal,
    activeCreateInternalModal,
    activeCreatePaymentModal,
    activeLoseProgressModal,
  } = useSelector((state) => state?.general);
  const threadsActiveGroupIds = useSelector(
    (state) => state?.session?.threadsActiveGroupIds
  );
  const currentUser = useSelector((state) => state?.session?.currentUser);
  const createSection = useSelector((state) => state?.createSection);

  const styledTheme = useContext(ThemeContext);

  useGetUserAccountPolicies({
    actionList: [AVAILABLE_PERMISSIONS.EDIT_CUSTOMERS],
    userId: currentUser?.userId,
  });

  const { getActiveGroup } = useCustomerGroups({
    threadsActiveGroupIds,
    accountPolicies: currentUser.accountPolicies,
    groupPolicies: currentUser.groupPolicies,
    permission: AVAILABLE_PERMISSIONS.EDIT_CUSTOMERS,
    groupIds: currentUser?.groupIds ?? [],
    allGroups,
  });

  const activeGroup = getActiveGroup();

  let ActiveCreateSectionComponent = () => null;
  if (activeCreateAnnouncementModal)
    ActiveCreateSectionComponent = CreateAnnouncement;
  else if (activeCreateThreadModal) ActiveCreateSectionComponent = CreateThread;
  else if (activeCreateFaxModal) ActiveCreateSectionComponent = CreateFax;
  else if (activeCreateEmailModal) ActiveCreateSectionComponent = CreateEmail;
  else if (activeCreateCustomerModal) {
    ActiveCreateSectionComponent = CreateCustomerFormContainer;
  } else if (activeCreatePaymentModal) {
    ActiveCreateSectionComponent = PaymentWizard;
  } else if (activeCreateInternalModal) {
    ActiveCreateSectionComponent = CreateInternal;
  } else if (activeCreateBroadcastListModal) {
    ActiveCreateSectionComponent = CreateBroadcastList;
  }

  const activeSection =
    activeCreateCustomerModal ||
    activeCreateAnnouncementModal ||
    activeCreateThreadModal ||
    activeCreateFaxModal ||
    activeCreateEmailModal ||
    activeCreatePaymentModal ||
    activeCreateInternalModal ||
    activeCreateBroadcastListModal;

  const transitions = useTransition(activeSection, null, {
    from: {
      width: '100%',
      height: '100%',
      transform: 'translate3d(-100%, 0, 0)',
      display: 'flex',
      background: THEMES.BACKGROUND_PRIMARY({ theme: styledTheme }),
      position: 'fixed',
      left: 0,
      zIndex: 10,
      overflowY: 'auto',
      paddingLeft: 72,
      boxSizing: 'border-box',
    },
    enter: { transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0 },
    config: { duration: 200 },
  });

  const handleOnClose = () => {
    dispatch(setActiveLoseProgressState(false));
    dispatch(closeCreateSectionModal());
    dispatch(clearCreateSection());
    dispatch(setActiveSidebar('default'));
    if (location.pathname.includes('edit')) history.goBack();
  };

  const handleOpenModal = () => {
    dispatch(setActiveLoseProgressState(true));
  };

  const renderTransition = transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div key={key} style={props}>
          {!activeCreateAnnouncementModal ? (
            <CloseButtonWrap>
              <IconButton onClick={handleOpenModal} size="lg">
                <CloseIcon className="ri-close-line" />
              </IconButton>
            </CloseButtonWrap>
          ) : null}
          <ActiveCreateSectionComponent
            activeGroup={activeGroup}
            createSection={createSection}
          />
        </animated.div>
      )
  );

  const handleCancel = () => {
    dispatch(setActiveLoseProgressState(false));
    if (activeCreateBroadcastListModal) {
      if (location.pathname !== 'broadcasts/lists')
        history.replace('/broadcasts/lists');
    }
  };

  return (
    <>
      <Modal
        size="sm"
        isOpen={activeLoseProgressModal}
        secondaryButtonText={i18n.t('slideouts-GroupMessageOverview-cancel', {
          defaultValue: 'Cancel',
        })}
        secondaryButtonOnClick={handleCancel}
        onRequestClose={handleCancel}
        primaryButtonText={i18n.t('slideouts-GroupMessageOverview-confirm', {
          defaultValue: 'Confirm',
        })}
        primaryButtonOnClick={handleOnClose}
        modalTitle={i18n.t('slideouts-Modal-closeModalHeader', {
          defaultValue: 'Are you sure you want to exit?',
        })}
        customCardProps={{
          customChildrenStyle: () => `
          display: flex;
          align-items: flex-start;
          div {
            display: flex;
            align-items: flex-start;
          }
        `,
        }}
      >
        <Text>
          {i18n.t('slideouts-Modal-closeModalBody', {
            defaultValue: 'Any unsaved progress to this point will be lost.',
          })}
        </Text>
      </Modal>
      {renderTransition}
    </>
  );
};

export default CreateSection;
