import React from 'react';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { EntityCard } from '../../../elements';
import THEMES from '../../../styles/themes/app';

const GroupMemberListCard = ({ entity }) => {
  const presenceTrackingAvailable = useSelector(
    (state) => state.accountData.account?.ff_presence_tracking
  );
  const to = {
    pathname: `/contacts/team/${entity.id}/${entity.__typename.toLowerCase()}`,
  };
  return (
    <NavLink to={to}>
      <EntityCard
        maintext={contactName(entity)}
        subtext={entity?.title}
        backgroundColor={THEMES.BACKGROUND_PRIMARY}
        customContainerStyle={() =>
          'height: 44px; flex: 1; cursor: pointer; padding-left: 8px;'
        }
        avatarProps={{
          avatarUrl: entity?.avatarUrl,
          icon: 'user',
          type: 'internal',
          displayPresence: presenceTrackingAvailable,
          active: entity?.online,
        }}
      />
    </NavLink>
  );
};

GroupMemberListCard.propTypes = {
  entity: PropTypes.object.isRequired,
};

export default GroupMemberListCard;
