import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const AttachmentButton = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;
const PhotoPreview = styled.span`
  margin: 10px 0 0 1rem;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FileReaderInput = ({
  children,
  WrapperComponent,
  name,
  accept,
  handleFileReaderInputChange,
  messageAttachment,
  disabled,
  multiple,
}) => (
  <WrapperComponent>
    {children}
    <AttachmentButton
      multiple={multiple}
      type="file"
      id={name}
      name={name}
      accept={accept}
      onChange={handleFileReaderInputChange}
      disabled={disabled}
    />
    {messageAttachment !== null && (
      <PhotoPreview key={messageAttachment.originalFilename}>
        File Attached: {messageAttachment.originalFilename}
      </PhotoPreview>
    )}
  </WrapperComponent>
);

FileReaderInput.propTypes = {
  children: PropTypes.any.isRequired,
  WrapperComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.object,
  ]),
  name: PropTypes.string.isRequired,
  accept: PropTypes.string,
  handleFileReaderInputChange: PropTypes.func.isRequired,
  messageAttachment: PropTypes.object,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
};

FileReaderInput.defaultProps = {
  WrapperComponent: 'div',
  accept: '',
  messageAttachment: null,
  disabled: false,
  multiple: false,
};

export default FileReaderInput;
