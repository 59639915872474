import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Clipboard from 'clipboard';
import i18n from 'i18n-js';
import useCopyCodeClipboard from './hooks/useCopyCodeClipboard';
import { Text, Card } from '../../../elements';
import THEMES from '../../../styles/themes/app';

const Container = styled.div`
  min-width: 685px;
  max-width: 960px;
  margin-bottom: 15px;
`;

export const CodeWrapper = styled.pre`
  border: 1px solid ${THEMES.BORDER_COLOR};
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  border-radius: 4px;
  white-space: pre-line;
  padding: 20px;
`;

export const WidgetSnippetDashboard = ({ widgetId, widgetType }) => {
  let clipboard = null;
  const codeRef = useRef(null);
  const buttonRef = useRef(null);

  const { handleOnClick, successButtonValue, generateCodeSnippet } =
    useCopyCodeClipboard();

  useEffect(() => {
    clipboard = new Clipboard(buttonRef.current, {
      target: () => codeRef.current,
    });

    return () => clipboard.destroy();
  }, []);

  const CODE_SNIPPET = generateCodeSnippet(widgetId, widgetType);

  return (
    <Container>
      <Card
        headerText={i18n.t('settings-WebConnect-snippetName')}
        primaryFooterButtonText={successButtonValue}
        primaryFooterButtonOnClick={handleOnClick}
        primaryFooterButtonProps={{ disabled: false, ref: buttonRef }}
      >
        <Text customStyle={() => 'margin-top: -24px;'}>
          {i18n.t('settings-WebConnect-copyCodeEmbed')}
        </Text>
        <CodeWrapper>
          <code ref={codeRef}>{CODE_SNIPPET}</code>
        </CodeWrapper>
      </Card>
    </Container>
  );
};

WidgetSnippetDashboard.propTypes = {
  widgetId: PropTypes.string.isRequired,
  widgetType: PropTypes.string,
};
WidgetSnippetDashboard.defaultProps = {
  widgetType: 'webconnect',
};

export default WidgetSnippetDashboard;
