import React from 'react';
import PropTypes from 'prop-types';
import { useBlockChannel } from 'client-lib';
import { useApolloClient } from '@apollo/client';
import i18n from 'i18n-js';
import { checkBlockedChannelType } from 'client-lib/src/lib/utils/helpers';
import { Text, Modal } from '../../elements';

const BlockChannel = ({
  accountId,
  channelToBeBlocked,
  onClose,
  openSnackbar,
  blockChannelConfirm,
  onBlock,
  open,
}) => {
  const client = useApolloClient();

  const onBlockChannelError = () => {
    openSnackbar(
      i18n.t('modals-BlockNumber-couldNotBlock', {
        number: checkBlockedChannelType(channelToBeBlocked),
      }),
      'error'
    );
    onClose();
  };

  const onBlockChannelSuccess = () => {
    openSnackbar(
      i18n.t('modals-BlockNumber-success', {
        number: checkBlockedChannelType(channelToBeBlocked),
      })
    );
    blockChannelConfirm();
    onClose();
    if (onBlock) onBlock();
  };

  const handleOnClose = (e) => {
    onClose(e);
  };

  const { blockChannel } = useBlockChannel({
    accountId,
    client,
    address: channelToBeBlocked,
    onError: onBlockChannelError,
    onSuccess: onBlockChannelSuccess,
  });

  const handleSubmit = () => {
    try {
      blockChannel();
    } catch (err) {
      console.warn(err);
    }
  };

  const number = checkBlockedChannelType(channelToBeBlocked);
  return (
    <Modal
      size="sm"
      onRequestClose={(ev) => handleOnClose(ev)}
      modalTitle={i18n.t('modals-BlockNumber-block', { number })}
      primaryButtonText={i18n.t('modals-BlockNumber-yesBlock')}
      secondaryButtonText={i18n.t('modals-BlockNumber-cancel')}
      primaryButtonOnClick={handleSubmit}
      secondaryButtonOnClick={handleOnClose}
      isOpen={open}
    >
      <Text dataTestId="block-number">
        {i18n.t('modals-BlockNumber-manageBlocked')}
      </Text>
    </Modal>
  );
};

BlockChannel.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  accountId: PropTypes.string,
  channelToBeBlocked: PropTypes.string.isRequired,
  openSnackbar: PropTypes.func,
  blockChannelConfirm: PropTypes.func,
  onBlock: PropTypes.func,
};

BlockChannel.defaultProps = {
  accountId: '',
  openSnackbar: () => {},
  blockChannelConfirm: () => {},
  onBlock: () => {},
};

export default BlockChannel;
