import { useMutation } from "@apollo/client";
import ADD_WATCHER_MUTATION from "../../../graphql/mutation/AddWatcherMutation.graphql";
import REMOVE_WATCHER_MUTATION from "../../../graphql/mutation/RemoveWatcherMutation.graphql";

function useWatchThread(threadId, onSuccess, onError, onMutationTrigger) {
  const [addWatcherMutation] = useMutation(ADD_WATCHER_MUTATION);
  const [removeWatcherMutation] = useMutation(REMOVE_WATCHER_MUTATION);

  const handleWatchThreads = async () => {
    onMutationTrigger(false);
    const { data } = await addWatcherMutation({
      variables: { input: { threadId } },
    }).catch((e) => {
      console.error(e);
      onError?.("add");
    });
    if (data?.addWatcher?.errors) {
      onError?.("add");
    } else {
      onSuccess?.("add");
    }
  };

  const handleRemoveWatchThreads = async () => {
    onMutationTrigger(false);
    const { data } = await removeWatcherMutation({
      variables: { input: { threadId } },
    }).catch((e) => {
      console.error(e);
      onError?.("remove");
    });
    if (data?.removeWatcher?.errors) {
      onError?.("remove");
    } else {
      onSuccess?.("remove");
    }
  };
  return {
    handleWatchThreads,
    handleRemoveWatchThreads,
  };
}

useWatchThread.propTypes = {};

export default useWatchThread;
