import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconButton from '../Button/IconButton';
import THEMES from '../../styles/themes/library/textInput';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';

const ListWrap = styled.ul`
  border: 1px solid ${THEMES.BORDER};
  background-color: ${THEMES.OPTION_BACKGROUND};
  border-radius: 4px;
  padding: 0;

  & > li:not(:last-child) {
    border-bottom: 1px solid ${THEMES.BORDER};
  }

  & li:first-child {
    border-radius: 4px 4px 0 0;
  }
  & li:last-child {
    border-radius: 0 0 4px 4px;
  }

  ${(props) => props?.customContainerStyle?.()}
`;

const ListItem = styled.li`
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Barlow', sans-serif;
  font-size: ${FONTSIZE_THEMES.TEXT};
  font-weight: 500;
  background-color: ${THEMES.OPTION_BACKGROUND};
  color: ${THEMES.OPTION_TEXT};
  && i {
    color: ${THEMES.OPTION_TEXT};
  }
  ${(props) => props?.customListItemStyle?.()}
`;

const ListItemText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const AppendageList = ({
  list,
  displayDelete,
  onDelete,
  listItemRender,
  customContainerStyle,
  customListItemStyle,
  customDeleteButtonStyle,
  dataTestId,
}) =>
  list.length ? (
    <ListWrap customContainerStyle={customContainerStyle}>
      {list.map((listItem) => (
        <ListItem
          key={listItem.id}
          customListItemStyle={customListItemStyle}
          data-testid={dataTestId}
        >
          <ListItemText>{listItemRender(listItem)}</ListItemText>
          {displayDelete(listItem) && (
            <IconButton
              size="md"
              onClick={() => onDelete(listItem.id)}
              customStyle={customDeleteButtonStyle}
            >
              <i className="ri-close-line" />
            </IconButton>
          )}
        </ListItem>
      ))}
    </ListWrap>
  ) : null;

AppendageList.propTypes = {
  list: PropTypes.array.isRequired,
  displayDelete: PropTypes.func,
  listItemRender: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  customContainerStyle: PropTypes.func,
  customListItemStyle: PropTypes.func,
  customDeleteButtonStyle: PropTypes.func,
  dataTestId: PropTypes.string,
};

AppendageList.defaultProps = {
  onDelete: null,
  displayDelete: () => false,
  customContainerStyle: null,
  customListItemStyle: null,
  customDeleteButtonStyle: null,
  dataTestId: null,
};

export default AppendageList;
