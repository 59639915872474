import { useEffect } from 'react';
import i18n from 'i18n-js';
import useMultiSelectItems from './useMultiSelectItems';

/*
DOCUMENTATION
  this hook was designed to use with the MultiSelectTableItems component. 

  args:
    items: (arr) required. An array of objects representing the elements we want to select/unselect all.
    queryFilter: (str) optional. represents the selected filter.
    paginated: (obj) optional. Object with only one key 'allItemIds' that represents an array with all the ids from the whole dataset. This is needed to handle the SelectAll when we are dealing with paginated queries.
  returns:
    disabled: (bool) to marks the main checkbox as disabled or not.
    indeterminate: (bool) to marks the main checkbox as indeterminate or not.
    allItemsSelected (bool) to marks the main checkbox as checked or not.
    shouldCheckItem: (func) callback function to pass to the Row element to determine if we should check it or not. This is needed for the 'checked' prop of the Checkbox component.
    multiSelectOptions: (arr) representing the available options of the MultiSelectTableItems component..
    handleCheckItem: (func) Callback function to handle the event whenever any Row checkbox is checked..
*/

const useMultiSelectTableItems = ({
  items,
  paginated = null,
  queryFilter = '',
}) => {
  const {
    handleCheckItem,
    shouldCheckItem,
    indeterminate,
    allItemsSelected,
    handleSelectAll: handleSelectAllMultiItems,
    handleSelectNone,
    filterSelect,
    setFilterSelect,
    selectedItems,
    setSelectItems,
    disabled,
  } = useMultiSelectItems({ items });

  useEffect(() => {
    if (filterSelect === 'page') {
      const actualItemsPage = items.map((item) => item.id);
      const mergedArr = [
        ...selectedItems,
        ...actualItemsPage.filter(
          (actualItem) => !selectedItems.includes(actualItem)
        ),
      ];
      setSelectItems(mergedArr);
    }
  }, [filterSelect]);

  // Reseting the array when the query includes a filter
  useEffect(() => {
    setSelectItems([]);
  }, [queryFilter]);

  const handleSelectPage = () => {
    setFilterSelect('page');
  };

  const handleSelectAll = () => {
    let mergedArr;

    if (paginated) {
      mergedArr = [
        ...selectedItems,
        ...paginated.allItemIds.filter(
          (allItemId) => !selectedItems.includes(allItemId)
        ),
      ];
      setFilterSelect('all');
      setSelectItems(mergedArr);
    } else {
      handleSelectAllMultiItems();
    }
  };

  const multiSelectOptions = [
    {
      label: i18n.t('settings-multiSelectTableItems-options-none', {
        number: 0,
      }),
      value: 'none',
      handleSelect: handleSelectNone,
    },
    {
      label: i18n.t('settings-multiSelectTableItems-options-all', {
        number: paginated ? paginated?.allItemIds?.length : items.length,
      }),
      value: 'all',
      handleSelect: handleSelectAll,
    },
  ];

  if (paginated) {
    multiSelectOptions.splice(1, 0, {
      label: i18n.t('settings-multiSelectTableItems-options-page', {
        number: items.length,
      }),
      value: 'page',
      handleSelect: handleSelectPage,
    });
  }
  return {
    allItemsSelected:
      allItemsSelected ||
      (paginated && paginated?.allItemIds?.length === selectedItems.length),
    disabled,
    handleCheckItem,
    handleSelectNone,
    indeterminate,
    multiSelectOptions,
    selectedItems,
    shouldCheckItem,
    setSelectItems,
  };
};

export default useMultiSelectTableItems;
