import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useApolloClient } from '@apollo/client';
import { useRouteMatch, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18n-js';
import { thread_utils, useThreadSummary } from 'client-lib';
import { blockChannelConfirm } from '../../actions';
import FormattedDateTime from '../Common/FormattedDateTime';
import useEventTracker from '../Common/useEventTracker';
import THEMES from '../../styles/themes/app';
import { EntityCard, SquareBadge, Badge, Avatar } from '../../elements';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';
import { closeMergeCustomerSlideout } from '../../actions/general';
import { populateAvatarIcon } from '../../elements/Avatar/Avatar';

const Timestamp = styled.span`
  display: inline-block;
  font-size: ${FONTSIZE_THEMES.TIMESTAMP};
  margin-left: 8px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  * {
    color: ${THEMES.FOREGROUND_LOW};
  }
`;

const NameWrap = styled.span`
  max-width: 180px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) =>
    props.darkenText
      ? THEMES.FOREGROUND_HIGH(props)
      : THEMES.FOREGROUND_LOW(props)};
`;

const MaintextWrap = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
`;

const TextWrap = styled.div`
  display: flex;
  align-items: baseline;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BadgeWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SubtextWrap = styled.div`
  position: relative;
  display: flex;
  min-height: 17px;
`;

const OwnerAvatarWrap = styled.div`
  position: absolute;
  right: 0;
  bottom: -8px;
`;

const MessageText = styled.div`
  max-width: 23vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) =>
    props.darkenText
      ? THEMES.FOREGROUND_HIGH(props)
      : THEMES.FOREGROUND_LOW(props)};
`;

const ActivityBar = styled.div`
  width: 4px;
  background-color: ${(props) =>
    props.isActive
      ? THEMES.FOREGROUND_MED_COLOR(props)
      : THEMES.BACKGROUND_PRIMARY(props)};
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  color: ${THEMES.FOREGROUND_LOW}!important;
  font-size: 18px;
`;

const IndicatorWrapper = styled.div`
  height: 76px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
  background-color: ${(props) =>
    props.isActive
      ? THEMES.BACKGROUND_SECONDARY(props)
      : THEMES.BACKGROUND_PRIMARY(props)};
`;

const badgeCustomStyles = () => `
    min-width: 8px;
    max-width: 8px;
    height: 8px;
`;

const ThreadSummary = ({
  thread,
  includeUnreadIndicator,
  showOwnerAvatar,
  presenceTrackingAvailable,
  isTest,
}) => {
  const client = useApolloClient();
  const match = useRouteMatch();
  const isSmsThread = thread_utils.isTypeSms(thread);
  const isFaxThread = thread_utils.isTypeFax(thread);
  const isEmailThread = thread_utils.isTypeEmail(thread);
  const isWebchatThread = thread_utils.isTypeWebchat(thread);
  const faxPageCount = thread?.latestMessage?.metadata?.pageCount;
  const isGroupSmsThread = thread_utils.isTypeGroupSms(thread);
  const isFsmThread = thread_utils.isTypeFsm(thread);
  let faxMessageText;
  if (faxPageCount > 1) {
    faxMessageText = i18n.t('threads-Message-faxPages', {
      pageCount: faxPageCount,
      defaultValue: 'Fax attachment (%{pageCount} pages)',
    });
  } else if (faxPageCount === 1) {
    faxMessageText = i18n.t('threads-Message-faxPage', {
      pageCount: faxPageCount,
      defaultValue: 'Fax attachment (%{pageCount} page)',
    });
  } else {
    faxMessageText = i18n.t('threads-Message-fax', {
      defaultValue: 'Fax attachment',
    });
  }

  const { track } = useEventTracker();

  const dispatch = useDispatch();
  const currentContactId = useSelector(
    (state) => state?.session?.currentUser?.contactId
  );
  const userId = useSelector((state) => state?.session?.currentUser?.userId);
  const activeGroupIds = useSelector(
    (state) => state?.session?.threadsActiveGroupIds
  );
  const mergeContactSlideoutOpen = useSelector(
    (state) => state?.general?.activeMergeCustomerSlideout
  );
  const currentUser = useSelector((state) => state?.session?.currentUser);
  const isFollowing =
    !thread_utils.isHeldByCurrentUser(thread, currentUser) &&
    (thread_utils.isInMyOpen(thread, currentUser) ||
      thread_utils.isInMyClosed(thread, currentUser));

  const {
    name,
    avatarUrl,
    messageText,
    unread,
    handleMarkReadMutation,
    isDirectMessage,
    isInternalGroupThread,
    isInternal,
    active,
  } = useThreadSummary({
    client,
    thread,
    currentContactId,
    userId,
    track,
    isTest,
    i18n,
  });

  const node = useRef();

  const onThreadClick = (thread, unread) => {
    if (unread) {
      handleMarkReadMutation(thread.id);
    }
    if (mergeContactSlideoutOpen) {
      dispatch(closeMergeCustomerSlideout());
    }
    dispatch(blockChannelConfirm(false));
  };

  const scrollToTarget = () => {
    if (!node || !node.current) return;
    setTimeout(() => {
      node.current.scrollIntoView();
    }, 0);
  };

  const isInViewport = (offset = 0) => {
    if (!node || !node.current) return null;
    const top = node.current.getBoundingClientRect().top;
    return top + offset >= 0 && top - offset <= window.innerHeight;
  };

  let url;

  if (match.url.startsWith('/threads')) {
    url = `/threads/${match.params.filter}/${thread.id}`;
  } else if (thread.archivedAt !== null) {
    url = `/contacts/all/${thread.externalContact.id}/customercontact/threads/${thread.id}`;
  } else if (thread.ownerContact === null) {
    url = `/threads/inbox/${thread.id}`;
  } else if (thread.ownerContact.id === currentContactId) {
    url = `/threads/open/${thread.id}`;
  } else {
    url = `/threads/open/${thread.id}`;
  }

  const showGroupChip = thread.group
    ? !isInternalGroupThread &&
      (activeGroupIds.length > 1 || activeGroupIds[0] !== thread.group.id)
    : false;

  const isActive = thread.id === match.params?.activeThreadId;
  const internalThreadOwnerAvatar = thread?.claimedByContact?.user?.avatarUrl;

  const carbonCopyListCount = isEmailThread
    ? thread?.otherExternalContactIds?.length
    : 0;

  let maintext;
  let subtext;

  if (isEmailThread) {
    maintext = name;
  } else if (isGroupSmsThread) {
    if (thread?.otherExternalContactIds?.length > 1) {
      maintext = i18n.t('threads-ThreadSummary-others', {
        name,
        count: thread?.otherExternalContactIds?.length,
      });
    } else {
      maintext = i18n.t('threads-ThreadSummary-other', { name });
    }
  } else {
    maintext = name;
  }

  if (isEmailThread) {
    subtext = thread?.subject;
  } else if (isFsmThread) {
    subtext =
      thread?.subject === null
        ? `(${i18n.t('threads-message-noSubject', {
            defaultValue: '(No Subject)',
          })})`
        : thread?.subject;
  } else if (isFaxThread) {
    subtext = faxMessageText;
  } else {
    subtext = messageText;
  }

  const isExternalThreadOwnedByUserAndUnread =
    thread_utils.isOwnedByCurrentUser(thread, currentUser) && unread;

  let avatarType;

  if (isInternal) {
    avatarType = 'internal';
  } else if (isActive) {
    avatarType = 'threadActive';
  } else if (isExternalThreadOwnedByUserAndUnread) {
    avatarType = 'threadUnread';
  } else {
    avatarType = 'threadRead';
  }

  const darkenText =
    (thread_utils.isHeldByCurrentUser(thread, currentUser) && unread) ||
    isActive;

  return (
    <NavLink to={url} style={{ display: 'block' }}>
      <div ref={node} data-testid="thread-summary" />{' '}
      {/* DOM target for .scrollIntoView() */}
      {match.params.activeThreadId === thread.id && !isInViewport()
        ? scrollToTarget()
        : null}
      <div style={{ display: 'flex' }}>
        <ActivityBar isActive={isActive} />
        <IndicatorWrapper isActive={isActive}>
          {includeUnreadIndicator &&
          unread &&
          thread_utils.isHeldByCurrentUser(thread, currentUser) ? (
            <Badge
              dataTestId="ts-unread-indicator"
              badgeBorderColor={
                isActive
                  ? THEMES.BACKGROUND_SECONDARY
                  : THEMES.BACKGROUND_PRIMARY
              }
              customStyle={badgeCustomStyles}
            />
          ) : null}
        </IndicatorWrapper>
        <EntityCard
          dataTestId="ts-message-wrapper"
          onClick={() => onThreadClick(thread, unread)}
          avatarChildren={name}
          avatarProps={{
            avatarUrl: avatarUrl || thread?.avatarUrl,
            icon: populateAvatarIcon({
              isUser: isInternal,
              isGroup: isInternalGroupThread,
              isSms: isSmsThread,
              isFax: isFaxThread,
              isEmail: isEmailThread,
              isWebchat: isWebchatThread,
              isFsm: isFsmThread,
            }),
            type: avatarType,
            displayPresence:
              presenceTrackingAvailable && !isInternalGroupThread,
            active,
          }}
          backgroundColor={
            isActive ? THEMES.BACKGROUND_SECONDARY : THEMES.BACKGROUND_PRIMARY
          }
          maintext={
            <MaintextWrap>
              <TextWrap>
                <NameWrap data-testid="ts-name" darkenText={darkenText}>
                  {maintext}
                </NameWrap>
                <Timestamp>
                  {thread.archivedAt === null ? (
                    thread.latestMessage?.createdAt && (
                      <FormattedDateTime
                        value={thread.latestMessage.createdAt}
                      />
                    )
                  ) : (
                    <FormattedDateTime value={thread.archivedAt} />
                  )}
                </Timestamp>
              </TextWrap>
              <BadgeWrap>
                {isFollowing ? (
                  <IconWrapper>
                    <i className="ri-notification-4-line" />
                  </IconWrapper>
                ) : null}
                {isDirectMessage && !isInternalGroupThread ? (
                  <SquareBadge
                    badgeBorderColor={
                      isActive
                        ? THEMES.BACKGROUND_SECONDARY
                        : THEMES.BACKGROUND_PRIMARY
                    }
                  >
                    DM
                  </SquareBadge>
                ) : null}
                {showGroupChip ? (
                  <SquareBadge
                    dataTestId="ts-group-chip"
                    badgeBorderColor={
                      isActive
                        ? THEMES.BACKGROUND_SECONDARY
                        : THEMES.BACKGROUND_PRIMARY
                    }
                    customStyle={() => `
                    max-width: 180px;
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  `}
                  >
                    {thread.group.name}
                  </SquareBadge>
                ) : null}
              </BadgeWrap>
            </MaintextWrap>
          }
          subtext={
            <SubtextWrap>
              <MessageText
                data-testid="ts-message-text"
                darkenText={darkenText}
              >
                {subtext}
              </MessageText>
              {(isInternalGroupThread && thread.claimedByContact) ||
              showOwnerAvatar ? (
                <OwnerAvatarWrap>
                  <Avatar
                    size="sm"
                    type="internal"
                    data-testid="owner-avatar"
                    avatarUrl={internalThreadOwnerAvatar}
                  />
                </OwnerAvatarWrap>
              ) : null}
            </SubtextWrap>
          }
          customContainerStyle={() =>
            'height: 44px; flex: 1; padding-left: 0px; padding-right: 12px;'
          }
          customTextContainerStyle={() => 'max-width: 100%; overflow: visible;'}
          smallAvatarChildren={`+${carbonCopyListCount}`}
          showOverlapAvatar={carbonCopyListCount >= 1}
          overlapAvatarProps={{
            customStyle: (props) =>
              `background-color: ${THEMES.OVERLAY_AVATAR_BACKGROUND(
                props
              )}; color: ${THEMES.OVERLAY_AVATAR_PRIMARY(
                props
              )}; border: none;`,
            hasCustomChildren: true,
          }}
        />
      </div>
    </NavLink>
  );
};

ThreadSummary.propTypes = {
  // mutate: PropTypes.func.isRequired,
  thread: PropTypes.object.isRequired,
  includeUnreadIndicator: PropTypes.bool,
  showOwnerAvatar: PropTypes.bool,
  presenceTrackingAvailable: PropTypes.bool,
  isTest: PropTypes.bool,
};

ThreadSummary.defaultProps = {
  includeUnreadIndicator: false,
  presenceTrackingAvailable: false,
  showOwnerAvatar: false,
  isTest: false,
};

export default ThreadSummary;
