import { useMutation } from "@apollo/client";
import START_THREAD_MUTATION from "../../graphql/mutation/StartThreadMutation.graphql";

/*

DOCUMENTATION
  generic hook for using start thread mutation

  args:
    client: (obj) required. apollo client instance

  returns:
    [0] handleStartThread: func that will call poseMessageMutation.
      args:
        senderGroupId: (str) required. group id of user group.
        externalContactId: (str) required (empty string if giving externalPhoneNumber). contact id of who user is messaging.
        externalPhoneNumber: (str) required (empty string if giving externalContactId). phone num user is messaging.
        message: (obj) required. consists of { text (str), attachments (arr) }
        onSuccess: (func) optional, will call on mutation success
        onError: (func) optional, will call on mutation failure

*/

const useStartThreadMutation = ({ client }) => {
  const [startThread] = useMutation(START_THREAD_MUTATION, { client });

  const handleStartThread = async ({
    senderGroupId = null,
    externalContactId = null,
    externalPhoneNumber = null,
    message = "",
    tags = null,
    userShareIds = null,
    groupShareIds = null,
    groupTextRecipients = null,
    onSuccess = () => {},
    onError = () => {},
  }) => {
    const { data } = await startThread({
      variables: {
        input: {
          senderGroupId,
          externalContactId,
          externalPhoneNumber,
          message,
          tags,
          userShareIds,
          groupShareIds,
          groupTextRecipients,
        },
      },
    }).catch((err) => {
      onError(err);
    });

    if (data && data.startThread && data.startThread.errors) {
      onError(data.startThread.errors[0]);
    } else {
      onSuccess(data);
    }
  };

  return [handleStartThread];
};

export default useStartThreadMutation;
