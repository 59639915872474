import { useEffect } from "react";
import { useSubscription } from "@apollo/client";
import OPEN_THREAD_SUBSCRIPTION from "../../../graphql/subscription/OpenThreadSubscription.graphql";
import MY_INBOX_THREADS from "../../../graphql/query/MyInboxThreadsQuery.graphql";
import INBOX_SPAM_THREADS from "../../../graphql/query/InboxSpamThreadsQuery.graphql";
import MY_OPEN_THREADS from "../../../graphql/query/MyOpenThreadsQuery.graphql";
import MY_CLOSED_THREADS from "../../../graphql/query/MyClosedThreadsQuery.graphql";
import ALL_CLOSED_THREADS from "../../../graphql/query/AllClosedThreadsQuery.graphql";
import ALL_OPEN_THREADS from "../../../graphql/query/AllOpenThreadsQuery.graphql";
import usePaginated from "../../../api/query/usePaginated";
import { openThreadSubscriptionHandler } from "../../../configs/apollo/setupSubscriptions";
import threadsQueryVars from "../../../utils/threadsQueryVars";
import { useApolloSyncUpdate } from "../../../configs/apollo/ApolloSyncContext";
import { genRandId } from "../../../utils/helpers";
import usePrevious from "../../../utils/hooks/usePrevious";

/*

  useQueryThreads is to be used at the top level of the app. it's the logic that initially queries the active/open threads.

  args:
    client: (obj) required. apollo client instance
    currentUser: (obj) required. current user instance found in global state (session.currentUser)
    threadsActiveGroupIds: (arr) required. active groups for threads, should be held in global state (session.threadsActiveGroupIds)

  returns:
    undefined

*/

const useQueryThreads = ({
  currentUser,
  threadsActiveGroupIds,
  store,
  socketConnectionTimestamp,
  includeInternal = false, // client
  includeSpam = false,
}) => {
  const contactId = currentUser?.contactId;

  const updateApolloSyncId = useApolloSyncUpdate();
  const queryOptions = {
    onCompleted: () => updateApolloSyncId(genRandId()),
  };

  const skip = !(contactId && threadsActiveGroupIds?.length);

  const {
    genMyInboxThreadsVars,
    genInboxSpamThreadsVars,
    genMyOpenThreadsVars,
    genAllOpenThreadsVars,
    genMyClosedThreadVars,
    genAllClosedThreadVars,
  } = threadsQueryVars();

  const {
    handleFetchMore: myInboxHandleFetchMore,
    pageInfo: myInboxPageInfo,
    loading: myInboxLoading,
    refetch: myInboxRefetch,
  } = usePaginated({
    queryVariables: genMyInboxThreadsVars({
      threadsActiveGroupIds,
      includeInternal,
    }),
    query: MY_INBOX_THREADS,
    resultsNumber: 30,
    key: "myInboxThreads",
    loadAllPages: false,
    skip,
    nextPolicy: "cache-only",
    queryOptions,
  });

  const myInboxThreadsQueryHandling = {
    handleFetchMore: myInboxHandleFetchMore,
    pageInfo: myInboxPageInfo,
    loading: myInboxLoading,
  };

  const {
    handleFetchMore: inboxSpamHandleFetchMore,
    pageInfo: inboxSpamThreadsPageInfo,
    loading: inboxSpamThreadsLoading,
    refetch: inboxSpamThreadsRefetch,
  } = usePaginated({
    queryVariables: genInboxSpamThreadsVars({
      threadsActiveGroupIds,
      contactId,
      includeInternal,
    }),
    query: INBOX_SPAM_THREADS,
    resultsNumber: 30,
    key: "inboxSpamThreads",
    loadAllPages: false,
    skip: skip || !includeSpam,
    nextPolicy: "cache-only",
    queryOptions,
  });

  let inboxSpamThreadsQueryHandling = {};
  if (includeSpam) {
    inboxSpamThreadsQueryHandling = {
      handleFetchMore: inboxSpamHandleFetchMore,
      pageInfo: inboxSpamThreadsPageInfo,
      loading: inboxSpamThreadsLoading,
    };
  }

  const {
    handleFetchMore: myOpenHandleFetchMore,
    pageInfo: myOpenPageInfo,
    loading: myOpenLoading,
    refetch: myOpenRefetch,
  } = usePaginated({
    queryVariables: genMyOpenThreadsVars({
      threadsActiveGroupIds,
      contactId,
      includeInternal,
    }),
    query: MY_OPEN_THREADS,
    resultsNumber: 30,
    key: "myOpenThreads",
    skip,
    nextPolicy: "cache-only",
    queryOptions,
  });

  const myOpenThreadsQueryHandling = {
    handleFetchMore: myOpenHandleFetchMore,
    pageInfo: myOpenPageInfo,
    loading: myOpenLoading,
  };

  const {
    handleFetchMore: allOpenHandleFetchMore,
    pageInfo: allOpenPageInfo,
    loading: allOpenLoading,
    refetch: allOpenRefetch,
  } = usePaginated({
    queryVariables: genAllOpenThreadsVars({
      threadsActiveGroupIds,
      contactId,
      includeInternal,
    }),
    query: ALL_OPEN_THREADS,
    resultsNumber: 30,
    key: "allOpenThreads",
    loadAllPages: false,
    skip,
    nextPolicy: "cache-only",
    queryOptions,
  });
  const allOpenThreadsQueryHandling = {
    handleFetchMore: allOpenHandleFetchMore,
    pageInfo: allOpenPageInfo,
    loading: allOpenLoading,
  };

  const {
    handleFetchMore: myClosedHandleFetchMore,
    pageInfo: myClosedPageInfo,
    loading: myClosedLoading,
    refetch: myClosedRefetch,
  } = usePaginated({
    queryVariables: genMyClosedThreadVars({
      threadsActiveGroupIds,
      contactId,
      includeInternal,
    }),
    query: MY_CLOSED_THREADS,
    resultsNumber: 30,
    key: "myClosedThreads",
    skip,
    nextPolicy: "cache-only",
    queryOptions,
  });

  const myClosedThreadsQueryHandling = {
    handleFetchMore: myClosedHandleFetchMore,
    pageInfo: myClosedPageInfo,
    loading: myClosedLoading,
  };

  const {
    handleFetchMore: allClosedHandleFetchMore,
    pageInfo: allClosedPageInfo,
    loading: allClosedLoading,
    refetch: allClosedRefetch,
  } = usePaginated({
    queryVariables: genAllClosedThreadVars({
      threadsActiveGroupIds,
      contactId,
      includeInternal,
    }),
    query: ALL_CLOSED_THREADS,
    resultsNumber: 30,
    key: "allClosedThreads",
    skip,
    nextPolicy: "cache-only",
    queryOptions,
  });

  const allClosedThreadsQueryHandling = {
    handleFetchMore: allClosedHandleFetchMore,
    pageInfo: allClosedPageInfo,
    loading: allClosedLoading,
  };

  const refetchAllThreads = () => {
    myInboxRefetch();
    inboxSpamThreadsRefetch();
    myOpenRefetch();
    allOpenRefetch();
    myClosedRefetch();
    allClosedRefetch();
  };

  // watches for disconnection from server, if given a new socket connection, make sure
  // to refetch to retreive the most recent data.
  const prevSocConTs = usePrevious(socketConnectionTimestamp);
  useEffect(() => {
    if (socketConnectionTimestamp !== prevSocConTs && prevSocConTs) {
      refetchAllThreads();
    }
  }, [socketConnectionTimestamp]);

  // threads subscription
  useSubscription(OPEN_THREAD_SUBSCRIPTION, {
    variables: { groupIds: threadsActiveGroupIds, socketConnectionTimestamp },
    onData: async ({ client: apolloClient, data: response }) => {
      const thread = response?.data?.openThread;
      openThreadSubscriptionHandler(store, apolloClient, thread);
      updateApolloSyncId(genRandId());
    },
  });

  return {
    allClosedThreadsQueryHandling,
    allOpenThreadsQueryHandling,
    myClosedThreadsQueryHandling,
    myInboxThreadsQueryHandling,
    inboxSpamThreadsQueryHandling,
    myOpenThreadsQueryHandling,
  };
};

export default useQueryThreads;
