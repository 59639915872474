import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { EntityCard } from '../../elements';

const PrintGroupHeader = ({ thread }) => {
  const mainText = thread?.group?.name;
  const subtext =
    (thread?.group?.member_count ?? 0) > 1
      ? i18n.t('slideouts-GroupMessageRecipients-members', {
          number: thread?.group?.member_count,
        })
      : i18n.t('slideouts-GroupMessageRecipients-member', {
          number: thread?.group?.member_count,
        });

  return (
    <EntityCard
      maintext={mainText}
      subtext={subtext}
      avatarProps={{
        icon: 'group',
        type: 'internal',
      }}
    />
  );
};

PrintGroupHeader.propTypes = {
  thread: PropTypes.object.isRequired,
};

export default PrintGroupHeader;
