import React from 'react';
import { default as ReactSelectAsync } from 'react-select/async'; // eslint-disable-line import/no-named-default
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { slugify } from 'client-lib/src/lib/utils/helpers';
import THEMES from '../../styles/themes/library/textInput';
import CHIP_THEMES from '../../styles/themes/library/chip';
import APP_THEMES from '../../styles/themes/app';
import InputLabel from '../inputCommonElements/InputLabel';
import InputError from '../inputCommonElements/InputError';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';
import Checkbox from '../Checkbox/Checkbox';
import LABEL_THEMES from '../../styles/themes/library/label';

const Container = styled.div`
  width: 100%;
`;

export const baseSelectStyles = css`
  & .Select__input {
    color: ${THEMES.TEXT} !important;
  }

  & .Select__control {
    border-radius: 4px;
    min-height: 40px;
    font-size: ${FONTSIZE_THEMES.SELECT_TEXT};
    font-weight: 500;
    font-family: 'Barlow', sans-serif;

    border-color: ${(props) => (props.error ? THEMES.ERROR : THEMES.BORDER)};

    background-color: ${(props) =>
      props.disabled ? THEMES.DISABLED_BACKGROUND : THEMES.BACKGROUND};
    cursor: ${(props) => (props.disabled ? 'default' : 'auto')};

    ${(props) => props?.customInputStyle?.(props)}
  }

  & .Select__single-value {
    color: ${THEMES.TEXT};
  }

  & .Select__control:hover {
    border-color: ${(props) => (props.error ? THEMES.ERROR : THEMES.BORDER)};
  }

  & .Select__control--is-focused {
    border-color: ${(props) => (props.error ? THEMES.ERROR : THEMES.FOCUS)};
    box-shadow: 0 0 0 1px
      ${(props) => (props.error ? THEMES.ERROR : THEMES.FOCUS)};
  }

  & .Select__control--is-focused:hover {
    border-color: ${(props) => (props.error ? THEMES.ERROR : THEMES.FOCUS)};
    box-shadow: 0 0 0 1px
      ${(props) => (props.error ? THEMES.ERROR : THEMES.FOCUS)};
  }

  & .Select__indicator-separator {
    display: ${(props) => (props.isClearable ? 'default' : 'none')};
    background-color: ${THEMES.BORDER};
  }

  & .Select__menu {
    margin-top: 1px;
    border: 1px solid ${THEMES.BORDER};
    background-color: ${THEMES.BACKGROUND};
  }

  & .Select__menu-list {
    padding: 0;
  }

  & .Select__option {
    height: 40px;
    font-size: ${FONTSIZE_THEMES.SELECT_TEXT};
    font-weight: 500;
    font-family: 'Barlow', sans-serif;
    color: ${THEMES.OPTION_TEXT};
    background-color: ${THEMES.OPTION_BACKGROUND};
    display: flex;
    align-items: center;
    padding: 0 8px;
    ${(props) => props?.customOptionStyle?.(props)};
  }

  & .Select__option:nth-of-type(1) {
    border-radius: 4px 4px 0 0;
  }

  & .Select__option:nth-last-of-type(1) {
    border-radius: 0 0 4px 4px;
  }

  & .Select__option--is-focused,
  .Select__option--is-focused:active {
    color: ${THEMES.OPTION_TEXT_HOVER};
    background-color: ${THEMES.OPTION_BACKGROUND_HOVER};
  }

  & .Select__option--is-selected {
    color: ${THEMES.OPTION_TEXT_ACTIVE};
    background-color: ${THEMES.OPTION_BACKGROUND_ACTIVE};
  }
  & .Select__indicators {
    display: ${(props) => (props.disabled ? 'none!important' : 'flex')};
  }

  & .Select__multi-value {
    background-color: ${CHIP_THEMES.BACKGROUND};
    color: ${APP_THEMES.FOREGROUND_MED};
    &.color-label {
      &,
      .Select__multi-value__label,
      .Select__multi-value__remove:hover {
        color: ${LABEL_THEMES.LABEL_TEXT};
      }
    }
    &.color-label.bg-color-red {
      &,
      .Select__multi-value__remove:hover {
        background-color: ${LABEL_THEMES.RED_LABEL};
      }
    }
    &.color-label.bg-color-orange {
      &,
      .Select__multi-value__remove:hover {
        background-color: ${LABEL_THEMES.ORANGE_LABEL};
      }
    }
    &.color-label.bg-color-violet {
      &,
      .Select__multi-value__remove:hover {
        background-color: ${LABEL_THEMES.PURPLE_LABEL};
      }
    }
    &.color-label.bg-color-blue {
      &,
      .Select__multi-value__remove:hover {
        background-color: ${LABEL_THEMES.BLUE_LABEL};
      }
    }
    &.color-label.bg-color-green {
      &,
      .Select__multi-value__remove:hover {
        background-color: ${LABEL_THEMES.GREEN_LABEL};
      }
    }
  }
  & .Select__multi-value__label {
    color: ${APP_THEMES.FOREGROUND_MED};
  }
  & .Select__multi-value__remove:hover {
    background-color: ${CHIP_THEMES.BACKGROUND};
    color: ${APP_THEMES.FOREGROUND_MED};
  }
  & .Select__clear-indicator,
  .Select__clear-indicator:hover {
    color: ${APP_THEMES.FOREGROUND_MED};
  }
`;

const StyledSelect = styled(ReactSelectAsync)`
  ${baseSelectStyles}
`;

const SelectDropdownArrow = styled.i`
  font-size: 32px;
  color: ${THEMES.TEXT};
  ${(props) => (props.menuIsOpen ? 'transform: scaleY(-1)' : '')};
`;

export const DropdownIndicator = ({ selectProps, dataTestId }) => (
  <SelectDropdownArrow
    className="ri-arrow-drop-down-line"
    menuIsOpen={selectProps?.menuIsOpen}
    data-testid={`${dataTestId}-dropdown-indicator`}
  />
);

DropdownIndicator.propTypes = {
  selectProps: PropTypes.object.isRequired,
  dataTestId: PropTypes.string.isRequired,
};

const SelectClose = styled.i`
  font-size: 18px;
  color: ${THEMES.TEXT};
`;

// we're not currently using this as we couldn't quite get it to work, leaving it here in case we decide we want customization
// for the icon and would like to get this working
export const CloseIndicator = ({ dataTestId }) => (
  <SelectClose
    className="ri-close-line"
    data-testid={`${dataTestId}-close-indicator`}
  />
);

CloseIndicator.propTypes = {
  dataTestId: PropTypes.string.isRequired,
};

const StyledOption = styled.div`
  height: 40px;
  font-size: ${FONTSIZE_THEMES.SELECT_TEXT};
  font-weight: 500;
  font-family: 'Barlow', sans-serif;
  color: ${(props) =>
    props.isSelected ? THEMES.OPTION_TEXT_ACTIVE : THEMES.OPTION_TEXT};
  background-color: ${(props) =>
    props.isSelected
      ? THEMES.OPTION_BACKGROUND_ACTIVE
      : THEMES.OPTION_BACKGROUND};
  display: flex;
  text-transform: capitalize;
  align-items: center;
  padding: 0 8px;
  gap: 8px;
  ${(props) => props?.customOptionStyle?.(props)};

  :hover {
    color: ${(props) => (props.isSelected ? null : THEMES.OPTION_TEXT_HOVER)};
    background-color: ${(props) =>
      props.isSelected ? null : THEMES.OPTION_BACKGROUND_HOVER};
  }

  :nth-of-type(1) {
    border-radius: 4px 4px 0 0;
  }

  :nth-last-of-type(1) {
    border-radius: 0 0 4px 4px;
  }
`;

const EllipsisOverflow = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  text-overflow: ellipsis;
`;

const ColorSquare = styled.div`
  flex: none;
  background: ${(props) => props.labelColor};
  width: 16px;
  height: 16px;
  border-radius: 2px;
`;

export const Option = ({
  innerRef,
  innerProps,
  children,
  isSelected,
  isCheckable,
  customOptionStyle,
  dataTestId,
  applyLabelColor,
}) => {
  return (
    <StyledOption
      ref={innerRef}
      isSelected={isSelected}
      customOptionStyle={customOptionStyle}
      data-testid={`${dataTestId}-option-${slugify(children)}`}
      {...innerProps}
    >
      {isCheckable ? <Checkbox checked={isSelected} /> : null}
      <EllipsisOverflow>{children}</EllipsisOverflow>
      {applyLabelColor ? <ColorSquare labelColor={applyLabelColor} /> : null}
    </StyledOption>
  );
};

Option.propTypes = {
  innerRef: PropTypes.func,
  innerProps: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  isSelected: PropTypes.bool.isRequired,
  customOptionStyle: PropTypes.func,
  dataTestId: PropTypes.string.isRequired,
  isCheckable: PropTypes.bool,
  applyLabelColor: PropTypes.func,
};

Option.defaultProps = {
  innerRef: undefined,
  children: '',
  customOptionStyle: null,
  isCheckable: false,
  applyLabelColor: null,
};

const AsyncSelect = React.forwardRef(
  (
    {
      label,
      loadOptions,
      onChange,
      placeholder,
      error,
      helperText,
      value,
      disabled,
      customContainerStyle,
      customLabelStyle,
      customInputStyle,
      customOptionStyle,
      dataTestId,
      hideBottomSpace,
      isMulti,
      isCheckable,
      noComponents,
      ...otherProps
    },
    ref
  ) => {
    const createDropdownIndicator = (props) => (
      <DropdownIndicator dataTestId={dataTestId} {...props} />
    );

    const createOption = (props) => (
      <Option
        customOptionStyle={customOptionStyle}
        dataTestId={dataTestId}
        isCheckable={isCheckable}
        {...props}
      />
    );

    return (
      <Container
        error={error}
        disabled={disabled}
        customContainerStyle={customContainerStyle}
        css={(props) => props?.customContainerStyle?.(props)}
      >
        {label ? (
          <InputLabel
            error={error}
            disabled={disabled}
            customLabelStyle={customLabelStyle}
            css={(props) => props?.customLabelStyle?.(props)}
          >
            {label}
          </InputLabel>
        ) : null}
        <StyledSelect
          loadOptions={loadOptions}
          value={value}
          onChange={onChange}
          error={error}
          disabled={disabled}
          isDisabled={disabled}
          placeholder={placeholder}
          menuPlacement="auto"
          classNamePrefix="Select"
          components={
            noComponents
              ? null
              : {
                  DropdownIndicator: createDropdownIndicator,
                  Option: createOption,
                }
          }
          customInputStyle={customInputStyle}
          customOptionStyle={customOptionStyle}
          isMulti={isMulti}
          closeMenuOnSelect={!isCheckable}
          hideSelectedOptions={!isCheckable}
          defaultOptions
          cacheOptions
          {...otherProps}
          ref={ref}
        />
        {!hideBottomSpace && (
          <InputError error={error} helperText={helperText} />
        )}
      </Container>
    );
  }
);

AsyncSelect.propTypes = {
  loadOptions: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  helperText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  disabled: PropTypes.bool,
  customContainerStyle: PropTypes.func,
  customLabelStyle: PropTypes.func,
  customInputStyle: PropTypes.func,
  customOptionStyle: PropTypes.func,
  dataTestId: PropTypes.string,
  hideBottomSpace: PropTypes.bool,
  isMulti: PropTypes.bool,
  isCheckable: PropTypes.bool,
  noComponents: PropTypes.bool,
};

AsyncSelect.defaultProps = {
  label: null,
  placeholder: null,
  error: null,
  helperText: '',
  value: null,
  disabled: false,
  customContainerStyle: null,
  customLabelStyle: null,
  customInputStyle: null,
  customOptionStyle: null,
  dataTestId: 'asyncselect',
  hideBottomSpace: false,
  isMulti: false,
  isCheckable: false,
  noComponents: false,
};

AsyncSelect.displayName = 'AsyncSelect';

export default AsyncSelect;
