import { useState } from "react";
import { useMutation } from "@apollo/client";
import CREATE_LIST_MUTATION from "../../../graphql/mutation/CreateTagMutation.graphql";
import DELETE_LIST_MUTATION from "../../../graphql/mutation/DeleteTagMutation.graphql";
import TAGS_QUERY from "../../../graphql/query/TagsQuery.graphql";
import usePaginated from "../../../api/query/usePaginated";

const NAME_ALREADY_TAKEN = "has already been taken";

const useAnnouncementList = ({
  client,
  accountId,
  setError,
  setSuccess,
  i18n,
  listsSearchFilter,
}) => {
  const filter = listsSearchFilter || "";
  const { handleFetchMore, handlePrevious, pageInfo, tags, loading, refetch } =
    usePaginated({
      client,
      query: TAGS_QUERY,
      key: "tags",
      filter,
    });

  const [list, setList] = useState(null);

  const [createListMutation] = useMutation(CREATE_LIST_MUTATION, { client });
  const [deleteListMutation] = useMutation(DELETE_LIST_MUTATION, { client });

  const handleCreateTag = async (
    newListName,
    newListDescription,
    newListGroups,
    setListError,
    handleOnClose,
    clearState
  ) => {
    const response = await createListMutation({
      variables: {
        input: {
          name: newListName,
          description: newListDescription,
          accountId,
          groupShareIds: newListGroups?.map((group) => group.value),
        },
      },
    }).catch(() => {
      setError(i18n.t("slideouts-CreateCustomerForm-genericError"));
    });

    if (response?.data?.createTag?.errors !== null) {
      const {
        createTag: { errors },
      } = response.data;
      if (errors[0].reason === NAME_ALREADY_TAKEN) {
        setListError(i18n.t("broadcasts-duplicateName-error"));
      } else {
        setError(i18n.t("slideouts-CreateCustomerForm-genericError"));
      }
    } else {
      if (handleOnClose) handleOnClose();
      if (clearState) clearState();
      refetch();
      setSuccess(i18n.t("settings-TagList-createdSuccessfully"));
    }
    return response?.data;
  };

  const handleDeleteTag = async () => {
    const response = await deleteListMutation({
      variables: {
        id: list.id,
      },
    }).catch(() => {
      setError(i18n.t("slideouts-CreateCustomerForm-genericError"));
    });

    if (response?.data?.deleteTag?.errors) {
      setError(i18n.t("slideouts-CreateCustomerForm-genericError"));
    } else {
      refetch();
      setSuccess(i18n.t("settings-TagList-deletedSuccessfully"));
    }
  };

  return {
    handleCreateTag,
    handleDeleteTag,
    handleFetchMore,
    handlePrevious,
    setList,
    lists: tags,
    pageInfo,
    loading,
  };
};

export default useAnnouncementList;
