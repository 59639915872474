import theme from 'styled-theming';
import COLORS from '../../colors';

const RED_LABEL = theme('mode', {
  classic: COLORS.PK_CLASSIC.RED_LABEL,
  light: COLORS.PK_CLASSIC.RED_LABEL,
  dark: COLORS.PK_CLASSIC.RED_LABEL,
  holiday: COLORS.PK_CLASSIC.RED_LABEL,
});

const RED_LABEL_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.RED_LABEL_HOVER,
  light: COLORS.PK_CLASSIC.RED_LABEL_HOVER,
  dark: COLORS.PK_CLASSIC.RED_LABEL_HOVER,
  holiday: COLORS.PK_CLASSIC.RED_LABEL_HOVER,
});

const RED_LABEL_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.RED_LABEL_TEXT,
  light: COLORS.PK_CLASSIC.RED_LABEL_TEXT,
  dark: COLORS.PK_CLASSIC.RED_LABEL_TEXT,
  holiday: COLORS.PK_CLASSIC.RED_LABEL_TEXT,
});

const BLUE_LABEL = theme('mode', {
  classic: COLORS.PK_CLASSIC.BLUE_LABEL,
  light: COLORS.PK_CLASSIC.BLUE_LABEL,
  dark: COLORS.PK_CLASSIC.BLUE_LABEL,
  holiday: COLORS.PK_CLASSIC.BLUE_LABEL,
});

const BLUE_LABEL_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.BLUE_LABEL_HOVER,
  light: COLORS.PK_CLASSIC.BLUE_LABEL_HOVER,
  dark: COLORS.PK_CLASSIC.BLUE_LABEL_HOVER,
  holiday: COLORS.PK_CLASSIC.BLUE_LABEL_HOVER,
});

const BLUE_LABEL_TEXT = theme('mode', {
  classic: COLORS.PK.BLUE_LABEL_TEXT,
  light: COLORS.PK.BLUE_LABEL_TEXT,
  dark: COLORS.PK.BLUE_LABEL_TEXT,
  holiday: COLORS.PK.BLUE_LABEL_TEXT,
});

const ORANGE_LABEL = theme('mode', {
  classic: COLORS.PK_CLASSIC.ORANGE_LABEL,
  light: COLORS.PK_CLASSIC.ORANGE_LABEL,
  dark: COLORS.PK_CLASSIC.ORANGE_LABEL,
  holiday: COLORS.PK_CLASSIC.ORANGE_LABEL,
});

const ORANGE_LABEL_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.ORANGE_LABEL_HOVER,
  light: COLORS.PK_CLASSIC.ORANGE_LABEL_HOVER,
  dark: COLORS.PK_CLASSIC.ORANGE_LABEL_HOVER,
  holiday: COLORS.PK_CLASSIC.ORANGE_LABEL_HOVER,
});

const ORANGE_LABEL_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.ORANGE_LABEL_TEXT,
  light: COLORS.PK_CLASSIC.ORANGE_LABEL_TEXT,
  dark: COLORS.PK_CLASSIC.ORANGE_LABEL_TEXT,
  holiday: COLORS.PK_CLASSIC.ORANGE_LABEL_TEXT,
});

const GREEN_LABEL = theme('mode', {
  classic: COLORS.PK_CLASSIC.GREEN_LABEL,
  light: COLORS.PK_CLASSIC.GREEN_LABEL,
  dark: COLORS.PK_CLASSIC.GREEN_LABEL,
  holiday: COLORS.PK_CLASSIC.GREEN_LABEL,
});

const GREEN_LABEL_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.GREEN_LABEL_HOVER,
  light: COLORS.PK_CLASSIC.GREEN_LABEL_HOVER,
  dark: COLORS.PK_CLASSIC.GREEN_LABEL_HOVER,
  holiday: COLORS.PK_CLASSIC.GREEN_LABEL_HOVER,
});

const GREEN_LABEL_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.GREEN_LABEL_TEXT,
  light: COLORS.PK_CLASSIC.GREEN_LABEL_TEXT,
  dark: COLORS.PK_CLASSIC.GREEN_LABEL_TEXT,
  holiday: COLORS.PK_CLASSIC.GREEN_LABEL_TEXT,
});

const GREY_LABEL = theme('mode', {
  classic: COLORS.PK.GREY_20,
  light: COLORS.PK.GREY_20,
  dark: COLORS.PK.GREY_20,
  holiday: COLORS.PK.GREY_20,
});

const GREY_TEXT = theme('mode', {
  classic: COLORS.PK.GREY_90,
  light: COLORS.PK.GREY_90,
  dark: COLORS.PK.GREY_90,
  holiday: COLORS.PK.GREY_90,
});

const PURPLE_LABEL = theme('mode', {
  classic: COLORS.PK_CLASSIC.PURPLE_LABEL,
  light: COLORS.PK_CLASSIC.PURPLE_LABEL,
  dark: COLORS.PK_CLASSIC.PURPLE_LABEL,
  holiday: COLORS.PK_CLASSIC.PURPLE_LABEL,
});

const PURPLE_LABEL_HOVER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PURPLE_LABEL_HOVER,
  light: COLORS.PK_CLASSIC.PURPLE_LABEL_HOVER,
  dark: COLORS.PK_CLASSIC.PURPLE_LABEL_HOVER,
  holiday: COLORS.PK_CLASSIC.PURPLE_LABEL_HOVER,
});

const PURPLE_LABEL_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.PURPLE_LABEL_TEXT,
  light: COLORS.PK_CLASSIC.PURPLE_LABEL_TEXT,
  dark: COLORS.PK_CLASSIC.PURPLE_LABEL_TEXT,
  holiday: COLORS.PK_CLASSIC.PURPLE_LABEL_TEXT,
});

const LABEL_TEXT = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_WHITE,
  light: COLORS.PK.WHITE,
  dark: COLORS.PK.WHITE,
  holiday: COLORS.PK.WHITE,
});

const LABEL_DARK_TEXT = theme('mode', {
  classic: COLORS.PK.GREY_90,
  light: COLORS.PK.GREY_90,
  dark: COLORS.PK.GREY_90,
  holiday: COLORS.PK.GREY_90,
});

const THEMES = {
  RED_LABEL,
  GREEN_LABEL,
  BLUE_LABEL,
  PURPLE_LABEL,
  GREY_LABEL,
  ORANGE_LABEL,
  LABEL_TEXT,
  LABEL_DARK_TEXT,
  RED_LABEL_HOVER,
  BLUE_LABEL_HOVER,
  ORANGE_LABEL_HOVER,
  GREEN_LABEL_HOVER,
  PURPLE_LABEL_HOVER,
  BLUE_LABEL_TEXT,
  RED_LABEL_TEXT,
  PURPLE_LABEL_TEXT,
  GREY_TEXT,
  GREEN_LABEL_TEXT,
  ORANGE_LABEL_TEXT,
};

export default THEMES;
