import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import theme from 'styled-theming';
import THEMES from '../../styles/themes/app';

const PROGRESS_BAR_FONTSIZE = theme('fontSize', {
  default: '12px',
  large: '16px',
});

const BAR_HEIGHT = '2';

const progressBarStripes = keyframes`
  from { background-position: ${BAR_HEIGHT}rem 0; }
  to { background-position: 0 0; }
`;

const ProgressBar = styled.div`
  padding: 0 ${BAR_HEIGHT / 4}rem;
  height: ${BAR_HEIGHT}rem;
  box-sizing: border-box;
  overflow: hidden;
  font-size: ${PROGRESS_BAR_FONTSIZE};
  line-height: ${BAR_HEIGHT}rem;
  background-color: ${(props) =>
    props.success
      ? `${THEMES.THEME_GREEN(props)}`
      : `${THEMES.THEME_RED(props)}`};
  border-radius: 5px;
  color: ${THEMES.COLORED_FOREGROUND_HIGH};
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: ${BAR_HEIGHT}rem ${BAR_HEIGHT}rem;
  transition: width 0.6s ease;
  animation: ${progressBarStripes} 1s linear infinite;
`;

export const UploadingBar = (props) => {
  if (props.success) {
    return (
      <ProgressBar {...props}>
        {props.caption}
        ...
      </ProgressBar>
    );
  }
  return <ProgressBar {...props}>Failure. Try again.</ProgressBar>;
};

UploadingBar.propTypes = {
  success: PropTypes.bool.isRequired,
  caption: PropTypes.string.isRequired,
};
