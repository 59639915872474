import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import i18n from 'i18n-js';
import { setCreateCustomerFormValues } from '../../../actions/createSection';
import {
  setActiveCreateCustomerSection,
  setActiveMergeCustomerSlideout,
} from '../../../actions/general';
import { OptionButton } from '../../../elements';

const CreateContactOptionButton = ({ activeThread }) => {
  const dispatch = useDispatch();

  const optionButtonOptions = [
    {
      value: i18n.t('customers-MergeCustomerSlideout-addToContact', {
        defaultValue: 'Add to Contact',
      }),
      onClick: () => dispatch(setActiveMergeCustomerSlideout()),
    },
  ];

  return (
    <OptionButton
      type="secondaryLight"
      onClick={() => {
        dispatch(
          setCreateCustomerFormValues({
            phoneNumber: activeThread?.externalContact?.phoneNumber,
            id: activeThread?.externalContact?.id,
            email: activeThread?.externalContact?.emailAddress,
            faxNumber: activeThread?.externalContact?.faxNumber,
            updateContact: true,
            updateContactType: activeThread?.channel?.type,
          })
        );
        dispatch(setActiveCreateCustomerSection());
      }}
      options={optionButtonOptions}
      customDropdownButtonStyle={() => `min-width: 164px;`}
    >
      {i18n.t('threads-ThreadDetailsHeader-createContact')}
    </OptionButton>
  );
};

CreateContactOptionButton.propTypes = {
  activeThread: PropTypes.object.isRequired,
};

export default CreateContactOptionButton;
