import { useMutation } from "@apollo/client";
import {
  contactName,
  isThreadUnread,
  resolveAvatarUrl,
} from "../../../utils/helpers";
import MARK_THREAD_READ_MUTATION from "../../../graphql/mutation/MarkThreadReadMutation.graphql";

/*

DOCUMENTATION
  this hook is designed to be used with ThreadSummary. It handles business logic for the component making things simple on the UI side. Things like
  the thread's contact's name, the threadOwnerName, the exact messaging text provided, if it is unread, and also handling markingRead are provided.

  args:
    client: (obj) required. Apollo Client instance
    thread: (obj) required. Thread instance
    currentContactId: (str) required. contact ID of current user. Found in global state, session.currentUser.contactId
    userId: (str) required. user ID of current user. Found in global state, session.currentUser.userId
    isTest: (bool) optional. handy helper for matching variables when Date/Time is involved, only use during unit tests.

  returns:
    name: (str) thread's customer contact/customer account name
    threadOwnerName: (str) thread owner's name
    messageText: (str) message text that will be shown in ThreadSummary
    unread: (bool) true if message is unread
    handleMarkReadMutation: (func) handles markReadMutation, provided thread ID, will mark thread as read if called
    isDirectMessage: (bool) if true the message is a direct message to user

*/

// track usage has been disabled - fix in a future update when mobile can be handled as well
const useThreadSummary = ({
  client,
  thread,
  currentContactId,
  userId,
  track, // eslint-disable-line no-unused-vars
  isTest,
  i18n,
}) => {
  const isInternal = thread.type === "internal"; // +

  const isInternalGroupThread = !!thread?.group && !thread.externalContact;
  const internalGroupThreadStartedByUser =
    isInternalGroupThread && thread?.claimedByContact?.user?.id === userId;

  const [markThreadReadMutation] = useMutation(MARK_THREAD_READ_MUTATION, {
    client,
  });

  const handleMarkReadMutation = (threadId) => {
    markThreadReadMutation({
      variables: {
        input: {
          readAt: isTest
            ? "2019-10-02T18:14:39.636Z"
            : new Date().toISOString(),
          threadId,
        },
      },
    });
  };

  const findInternalDMUser = (userShares, userId) => {
    const externalUsers = userShares.filter(
      (userShare) => userShare.userId !== userId
    );
    return externalUsers?.[0]?.contact;
  };

  const deduceName = ({ thread, isInternal, isInternalGroupThread }) => {
    if (isInternal) {
      if (isInternalGroupThread) {
        return thread?.group.name;
      }
      return contactName(findInternalDMUser(thread?.userShares, userId));
    }

    return contactName(thread.externalContact);
  };

  const deduceAvatarUrl = ({ thread, isInternal }) => {
    if (isInternal) {
      return resolveAvatarUrl(
        findInternalDMUser(thread?.userShares, userId)?.user
      );
    }
    return null;
  };

  const deduceThreadOwnerName = ({ thread }) =>
    thread.ownerContact !== null ? contactName(thread.ownerContact) : null;

  const deduceMessageText = ({ thread }) => {
    let messageText = thread?.latestMessage?.text; // +

    if (messageText === null || messageText?.length === 0 || !messageText) {
      if (thread?.latestMessage?.attachments?.length > 0) {
        const length = thread.latestMessage.attachments.length;
        if (length > 1) {
          messageText = i18n.t("threads-ThreadSummary-attachments", {
            photoLength: length,
          });
        } else {
          messageText = i18n.t("threads-ThreadSummary-attachment");
        }
      } else {
        messageText = "";
      }
    }

    return messageText;
  };

  const deduceIsDirectMessage = ({ thread }) =>
    !!thread.userShares.find((userShare) => userShare.userId === userId);

  const deduceActivity = ({
    thread,
    isInternal,
    isInternalGroupThread,
    userId,
  }) => {
    if (isInternal && !isInternalGroupThread) {
      const contact = findInternalDMUser(thread?.userShares, userId);
      return contact?.user?.online;
    }

    return false;
  };

  const deduceClaimedByContactAvatarUrl = ({ thread }) =>
    thread?.claimedByContact?.user?.avatarUrl;

  const name = deduceName({
    thread,
    isInternal,
    isInternalGroupThread,
    internalGroupThreadStartedByUser,
  });
  const avatarUrl = deduceAvatarUrl({ thread, isInternal });
  const threadOwnerName = deduceThreadOwnerName({ thread });
  const messageText = deduceMessageText({ thread });
  const unread = isThreadUnread(thread, currentContactId);
  const isDirectMessage = deduceIsDirectMessage({ thread });
  const active = deduceActivity({
    thread,
    isInternal,
    isInternalGroupThread,
    userId,
  });
  const claimedAvatarUrl = deduceClaimedByContactAvatarUrl({ thread });

  return {
    name,
    avatarUrl,
    threadOwnerName,
    messageText,
    unread,
    handleMarkReadMutation,
    isDirectMessage,
    isInternalGroupThread,
    isInternal,
    active,
    claimedAvatarUrl,
  };
};

export default useThreadSummary;
