import { useState } from "react";
import { useMutation } from "@apollo/client";
import addThreadToWorkOrderMutation from "../../../graphql/mutation/AddThreadToWorkOrderMutation.graphql";
import removeThreadFromWorkOrderMutation from "../../../graphql/mutation/RemoveThreadFromWorkOrderMutation.graphql";

/*

DOCUMENTATION
  useAddOrRemoveTag hook was created to be used with MultiTagDisplay. it handles the tag/workOrders state and mutation

  args:
    onSubmit: (func) optional. callback fired when user submits new referenceNumber mutation
    thread: (obj) required. thread object instance
    client: (obj) required. Apollo Client instance
    onMutationSuccess: (func) function that will trigger when the mutation suceeds.
    onMutationError: (func) function that will trigger when the mutation fails.

  returns:
    [0] tagInputValue (str): current tag input state value. meant to be placed as the value of the input
    [1] setTagInputValue (func): sets state of tag input.
    [2] tags (arr): current work orders state value. meant to used as the array to create tags
    [3] setTags (func): sets state of work orders. Included just in case, but the logic for adding/removing from the state array is already built into the hook.
    [4] handleAddOrRemoveThreadFromWorkOrder (func): call when ready to call addThreadToWorkOrder (or removeThreadFromWorkOrder - by providing removeTag prop) mutation on thread object.
*/

const TAG_ALREADY_TAKEN = "has already been taken";

const useAddOrRemoveTag = ({
  onSubmit,
  thread,
  client,
  i18n,
  onMutationSuccess,
  onMutationError,
}) => {
  const [tagInputValue, setTagInputValue] = useState("");

  const [tags, setTags] = useState(thread?.workOrders);

  const [addThreadToWorkOrder] = useMutation(addThreadToWorkOrderMutation, {
    client,
  });
  const [removeThreadFromWorkOrder] = useMutation(
    removeThreadFromWorkOrderMutation,
    { client }
  );

  const handleAddOrRemoveThreadFromWorkOrder = (
    referenceNumber,
    removeTag = false
  ) => {
    let existingWorkOrderIndex = -1;
    if (!removeTag) {
      existingWorkOrderIndex = tags.findIndex(
        (workOrder) => workOrder.referenceNumber === referenceNumber
      );
    }

    onSubmit();

    // Fire failure message when tag already exists
    // Mocks response you'd receive from successful mutation
    if (existingWorkOrderIndex !== -1) {
      onMutationError?.(i18n.t("threads-Tag-existsError"));
    }

    if (removeTag || existingWorkOrderIndex === -1) {
      handleMutationAddOrRemoveThread(referenceNumber, removeTag);
    }
  };

  const handleMutationAddOrRemoveThread = async (
    referenceNumber,
    removeTag
  ) => {
    const mutation = removeTag
      ? removeThreadFromWorkOrder
      : addThreadToWorkOrder;
    const { data } = await mutation({
      variables: {
        input: {
          referenceNumber,
          threadId: thread.id,
        },
      },
    }).catch((e) => {
      onMutationError?.();
      console.error(e);
    });

    const key = removeTag
      ? "removeThreadFromWorkOrder"
      : "addThreadToWorkOrder";

    if (data?.[key]?.errors?.length) {
      if (data?.[key]?.errors[0].reason === TAG_ALREADY_TAKEN) {
        onMutationError?.(i18n.t("threads-Tag-existsError"));
      } else {
        onMutationError?.();
      }
    } else if (data?.[key]?.workOrder) {
      onMutationSuccess?.(data, tags);
      if (removeTag) {
        setTags((prev) => [
          ...prev.filter(
            (workOrder) => workOrder.referenceNumber !== referenceNumber
          ),
        ]);
      } else {
        setTags((prev) => [...prev, data?.[key]?.workOrder]);
      }
    }
  };

  return {
    tagInputValue,
    setTagInputValue,
    tags,
    setTags,
    handleAddOrRemoveThreadFromWorkOrder,
  };
};

export default useAddOrRemoveTag;
