import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
} from 'client-lib';
import useGetUserAccountPolicies from '../../hooks/customer/useGetUserAccountPolicies';

const PublicRoute = ({ children, allowAuthenticatedUsers, ...rest }) => {
  const currentUser = useSelector((state) => state?.session?.currentUser);
  useGetUserAccountPolicies({
    actionList: [AVAILABLE_PERMISSIONS.VIEW_DASHBOARD],
    userId: currentUser?.userId,
  });
  const hasDashboardAccess = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.VIEW_DASHBOARD,
    currentUser?.accountPolicies || [], // add empty arrays for logged out users
    currentUser?.groupPolicies || []
  );
  const route = hasDashboardAccess ? '/dashboard' : '/threads/inbox';
  return (
    <Route
      {...rest}
      render={(props) =>
        currentUser && !allowAuthenticatedUsers ? (
          <Redirect exact to={route} />
        ) : (
          children(props)
        )
      }
    />
  );
};

PublicRoute.propTypes = {
  allowAuthenticatedUsers: PropTypes.bool,
  children: PropTypes.func.isRequired,
};

PublicRoute.defaultProps = {
  allowAuthenticatedUsers: false,
};

export default PublicRoute;
