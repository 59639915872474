import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { useHistory } from 'react-router';
import theme from 'styled-theming';
import i18n from 'i18n-js';
import { useDispatch } from 'react-redux';
import { Button, IconLabel } from '../../elements';
import THEMES from '../../styles/themes/app';
import Bubble from '../Common/Bubble';
import { closeCreateSectionModal } from '../../actions/general';

const BUBBLE_WIDTH = theme('fontSize', {
  default: 169,
  large: 200,
});

const HorizontalRule = styled.div`
  border-top: 1px solid ${THEMES.BORDER_COLOR};
`;

const VerticalSeparator = styled.div`
  border-left: 2px solid ${THEMES.BORDER_COLOR};
  height: 24px;
  margin: 0 5px;

  ${(props) => props?.customStyle?.()}
`;

const OptionsButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const borderRadiusViaPosition = (position) => {
  switch (position) {
    case 'top':
      return '4px 4px 0 0';

    case 'single':
      return '4px';

    case 'middle':
      return '0px';

    default:
      return '0px 0px 4px 4px';
  }
};

const bubbleButtonHoverStyle = (props) => `
  @media print {display: none;}
  :hover {
    background-color: ${THEMES.COLORED_BACKGROUND_SECONDARY(props)};
    color: ${THEMES.COLORED_FOREGROUND_HIGH(props)};
    border-radius: ${borderRadiusViaPosition(props?.bubbleButtonPosition)};
  }
`;

const EmailSignature = ({
  handleAddSignature,
  emailSigOn,
  messageRef,
  withVerticalSeparator,
  bubbleOptions,
  ...otherProps
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const styledTheme = useContext(ThemeContext);
  const [optionBubbleOpen, setOptionBubbleOpen] = useState(false);

  const handleManageSubscriptions = () => {
    dispatch(closeCreateSectionModal());
    history.push('/settings/my-account');
  };

  const onAddSignature = () => {
    setOptionBubbleOpen(false);
    const selectionStart = messageRef.current.selectionStart; // cursor group in input
    const selectionEnd = messageRef.current.selectionEnd; // cursor group in input
    setTimeout(() => {
      messageRef?.current?.focus();
      messageRef?.current?.setSelectionRange?.(selectionStart, selectionEnd);
    }, 0);
  };

  return (
    <>
      {withVerticalSeparator && <VerticalSeparator {...otherProps} />}
      <IconLabel
        contrast="highColor"
        onClick={() => setOptionBubbleOpen(true)}
        htmlFor="signatureInput"
        dataTestId="smi-signature-button"
        {...otherProps}
      >
        <i className="ri-ball-pen-line" />
      </IconLabel>
      <Bubble
        isOpen={optionBubbleOpen}
        onClickOutside={() => setOptionBubbleOpen(false)}
        moveBubbleRightVal={45}
        moveLeftVal={bubbleOptions?.moveLeftVal || 26}
        bubbleMinWidth={BUBBLE_WIDTH({ theme: styledTheme })}
        bubbleMaxWidth={BUBBLE_WIDTH({ theme: styledTheme })}
        top="22px"
        zIndex={2}
        flipYAxis
        id="options_bubble_signature"
        {...bubbleOptions}
      >
        <OptionsButtonContainer>
          <Button
            type="noStyle"
            noOutline
            iconAndText
            customStyle={bubbleButtonHoverStyle}
            bubbleButtonPosition="top"
            onClick={handleManageSubscriptions}
          >
            {i18n.t('threads-EmailSignature-manageSignature', {
              defaultValue: 'Manage Signature',
            })}
          </Button>
          <HorizontalRule />
          <Button
            type="noStyle"
            noOutline
            iconAndText
            customStyle={bubbleButtonHoverStyle}
            bubbleButtonPosition="bottom"
            disabled={!emailSigOn}
            onClick={() => handleAddSignature(onAddSignature)}
          >
            {i18n.t('threads-EmailSignature-addSignature', {
              defaultValue: 'Add Signature',
            })}
          </Button>
        </OptionsButtonContainer>
      </Bubble>
    </>
  );
};

EmailSignature.propTypes = {
  handleAddSignature: PropTypes.func.isRequired,
  emailSigOn: PropTypes.bool.isRequired,
  messageRef: PropTypes.object.isRequired,
  withVerticalSeparator: PropTypes.bool,
  bubbleOptions: PropTypes.object,
};

EmailSignature.defaultProps = {
  withVerticalSeparator: false,
  bubbleOptions: null,
};

export default EmailSignature;
