import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';
import FileReaderInput from '../Inputs/FileReaderInput';
import EmojiMenu from '../Threads/EmojiMenu';
import { IconLabel, Button } from '../../elements';
import TemplateModal from '../Modals/TemplateModal';
import EmailSignature from '../Threads/EmailSignature';

const FileReaderInputWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ButtonRow = styled.div`
  margin: 8px 0;
  display: flex;
  position: relative;
`;

const AttachmentButtonWrap = styled.div`
  margin: 0 0 0 0px;
`;

const labelStyle = () => `
  margin-top: 8px;
  margin-left: 8px;
`;

const CreateSectionButtons = ({
  attachment,
  enableSubmissionWithAttachment,
  enableSubmissionWithMessage,
  handleMutationStartThread,
  checkTemplateDisabled,
  hasSmsChannel,
  handleTemplateButtonClick,
  handleFileReaderInputChange,
  handleEmojiSelection,
  emojiMenuOpen,
  setEmojiMenuOpen,
  setMessageInputValue,
  setAttachment,
  templateModalOpen,
  setTemplateModalOpen,
  multiple,
  handleAddSignature,
  emailSigOn,
  emailFeatureAvailable,
  messageInput,
  emailSignature,
  contactId,
  senderGroupId,
  channelType,
}) => {
  // Needs to be able to handle checks against arrays OR objects including null
  const hasAttachments =
    !!(Array.isArray(attachment) && attachment?.length) ||
    !!(
      attachment &&
      typeof attachment === 'object' &&
      Object.keys(attachment).length > 0
    );
  return (
    <ButtonRow>
      <Button
        disabled={
          hasAttachments
            ? !enableSubmissionWithAttachment
            : !enableSubmissionWithMessage
        }
        onClick={handleMutationStartThread}
        data-testid="send-message-button"
      >
        {i18n.t('slideouts-CreateThread-send')}
      </Button>
      <AttachmentButtonWrap>
        <IconLabel
          customStyle={labelStyle}
          noOutline
          disabled={checkTemplateDisabled || !hasSmsChannel}
          contrast="medColor"
          onClick={handleTemplateButtonClick}
        >
          <i className="ri-file-list-3-line" />
        </IconLabel>
      </AttachmentButtonWrap>
      <AttachmentButtonWrap>
        <FileReaderInput
          name="modalAttachmentInput"
          WrapperComponent={FileReaderInputWrapper}
          handleFileReaderInputChange={handleFileReaderInputChange}
          disabled={!hasSmsChannel}
          multiple={multiple}
        >
          <IconLabel
            htmlFor="modalAttachmentInput"
            customStyle={labelStyle}
            contrast="medColor"
            disabled={!hasSmsChannel}
          >
            <i className="ri-attachment-2" />
          </IconLabel>
        </FileReaderInput>
      </AttachmentButtonWrap>
      <AttachmentButtonWrap>
        <EmojiMenu
          labelType="grey"
          handleEmojiSelection={handleEmojiSelection}
          open={emojiMenuOpen}
          onClickOutside={() => setEmojiMenuOpen(false)}
          disabled={!hasSmsChannel}
        >
          {({ emojiTouchTap }) => (
            <IconLabel
              onClick={(e) => {
                if (hasSmsChannel) {
                  emojiTouchTap(e);
                  setEmojiMenuOpen(true);
                }
              }}
              title={i18n.t('slideouts-CreateThread-insertEmoji')}
              htmlFor="emojiInput"
              customStyle={labelStyle}
              contrast="medColor"
              disabled={!hasSmsChannel}
            >
              <i className="ri-emotion-line" />
            </IconLabel>
          )}
        </EmojiMenu>
      </AttachmentButtonWrap>
      {emailSignature && (
        <EmailSignature
          handleAddSignature={handleAddSignature}
          emailSigOn={emailSigOn}
          messageRef={messageInput}
          customStyle={labelStyle}
          withVerticalSeparator
          bubbleOptions={{ moveLeftVal: 35 }}
          disabled={!emailFeatureAvailable}
        />
      )}
      {!checkTemplateDisabled && (
        <TemplateModal
          channelType={channelType}
          selectTemplate={setMessageInputValue}
          addAttachment={setAttachment}
          isOpen={templateModalOpen}
          onRequestClose={() => setTemplateModalOpen(false)}
          contactId={contactId}
          senderGroupId={senderGroupId}
        />
      )}
    </ButtonRow>
  );
};

CreateSectionButtons.propTypes = {
  attachment: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  enableSubmissionWithAttachment: PropTypes.bool,
  enableSubmissionWithMessage: PropTypes.bool,
  handleMutationStartThread: PropTypes.func.isRequired,
  checkTemplateDisabled: PropTypes.bool.isRequired,
  hasSmsChannel: PropTypes.bool.isRequired,
  handleTemplateButtonClick: PropTypes.func.isRequired,
  handleFileReaderInputChange: PropTypes.func.isRequired,
  handleEmojiSelection: PropTypes.func.isRequired,
  emojiMenuOpen: PropTypes.bool.isRequired,
  setEmojiMenuOpen: PropTypes.func.isRequired,
  setMessageInputValue: PropTypes.func.isRequired,
  setAttachment: PropTypes.func.isRequired,
  templateModalOpen: PropTypes.bool.isRequired,
  setTemplateModalOpen: PropTypes.func.isRequired,
  multiple: PropTypes.bool.isRequired,
  handleAddSignature: PropTypes.func,
  emailSigOn: PropTypes.bool,
  emailFeatureAvailable: PropTypes.bool,
  messageInput: PropTypes.object,
  emailSignature: PropTypes.bool,
  contactId: PropTypes.string,
  senderGroupId: PropTypes.string,
  channelType: PropTypes.string,
};

CreateSectionButtons.defaultProps = {
  attachment: {},
  enableSubmissionWithAttachment: null,
  enableSubmissionWithMessage: null,
  handleAddSignature: () => {},
  emailSigOn: false,
  emailFeatureAvailable: null,
  messageInput: {},
  emailSignature: null,
  contactId: '',
  senderGroupId: 'string',
  channelType: 'SMS',
};

export default CreateSectionButtons;
