import React, { useState, useRef, useEffect, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import {
  useBlockChannel,
  useDeleteCustomer,
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
} from 'client-lib';
import i18n from 'i18n-js';
import {
  contactName,
  formatPhoneNumber,
} from 'client-lib/src/lib/utils/helpers';
import { emailRegex } from 'client-lib/src/lib/utils/regex';
import DetectClickOutside from '../Common/DetectClickOutside';
import { openSnackbar } from '../../actions/general';
import THEMES from '../../styles/themes/app';
import { EmphasisText, Text, Button, IconButton } from '../../elements';
import useGetUserAccountPolicies from '../../hooks/customer/useGetUserAccountPolicies';

const CARROT_PLACEMENT = 70;

const OptionsBubble = styled.div`
  position: absolute;
  top: ${(props) => props.top + 25}px;
  left: ${(props) => props.left - CARROT_PLACEMENT}px;
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  border-radius: 10px;
  box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid ${THEMES.BORDER_COLOR};
  z-index: 1;
`;

const Carrot = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-width: 11px;
  border-top-width: 0;
  border-color: transparent;
  border-style: solid;
  left: ${(props) =>
    props.adjustLeft
      ? props.adjustLeft + CARROT_PLACEMENT
      : CARROT_PLACEMENT}px;
  top: ${(props) => props.top};
  border-bottom-color: ${(props) => props.backgroundColor};
`;

const HorizontalRule = styled.div`
  height: 1px;
  background-color: ${THEMES.BORDER_COLOR};
`;

const VerticalRule = styled.div`
  width: 1px;
  background-color: ${THEMES.BORDER_COLOR};
`;

const customLeftButtonStyle = (props) => `
  width: 50%;
  color: ${THEMES.THEME_RED(props)};
  position: relative;
  :hover { color: ${THEMES.THEME_RED(props)}; }
`;

const BubbleMessageWrap = styled.div`
  padding: 12px;
  width: 260px;
`;
const BubbleSubTextWrap = styled.div`
  margin-top: 8px;
`;

const ContactTextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const CustomerInfoOptions = ({
  customer,
  hasThreadOpen,
  onBlock,
  onDelete,
  adjustLeftOnScaleMeasure,
  adjustCarrotOverride,
  optionsBubbleOverride,
}) => {
  const [displayOptions, setDisplayOptions] = useState(false);
  const [bubbleContentMode, setBubbleContentMode] = useState('options');
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
  const [buttonOffsetTopToCenter, setButtonOffsetTopToCenter] = useState(0);
  const [buttonOffsetLeftToCenter, setButtonOffsetLeftToCenter] = useState(0);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const styledTheme = useContext(ThemeContext);

  const componentIsMounted = useRef(true);

  const dispatch = useDispatch();
  const accountId = useSelector(
    (state) => state?.session?.currentUser?.accountId
  );
  const currentUser = useSelector((state) => state?.session?.currentUser);

  useEffect(() => {
    window.addEventListener('resize', updateBrowserWidth);

    return () => {
      window.removeEventListener('resize', updateBrowserWidth);
      componentIsMounted.current = false;
    };
  }, []);

  const updateBrowserWidth = () => setBrowserWidth(window.innerWidth);

  const closeOptions = (cb) => {
    // todo see if there's any way to trigger callback _after_ state gets set

    setBubbleContentMode('options');
    setDisplayOptions(false);

    if (cb) cb();
    // this.setState({ bubbleContentMode: 'options', displayOptions: false }, cb)
  };

  useGetUserAccountPolicies({
    userId: currentUser?.userId,
    actionList: [
      AVAILABLE_PERMISSIONS.BLOCK_CHANNEL,
      AVAILABLE_PERMISSIONS.DELETE_CUSTOMER,
    ],
  });

  const blockCustomerAccess = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.BLOCK_CHANNEL,
    currentUser?.accountPolicies,
    currentUser?.groupPolicies
  );

  const deleteMessageAccess = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.DELETE_CUSTOMER,
    currentUser?.accountPolicies,
    currentUser?.groupPolicies
  );

  const openOptions = () => !displayOptions && setDisplayOptions(true);

  const giveBlockConfirmation = () => setBubbleContentMode('block');

  const giveDeleteConfirmation = () => setBubbleContentMode('delete');

  const onBlockContactSuccess = () => {
    const { phoneNumber, emailAddress } = customer;
    dispatch(
      openSnackbar(
        i18n.t('slideouts-CustomerInfoOptions-blockSuccess', {
          number: formatPhoneNumber(phoneNumber) || emailAddress,
        })
      )
    );

    closeOptions(onBlock); // onBlock is from props
  };

  const handleDeleteCustomer = () => {
    if (hasThreadOpen) {
      dispatch(
        openSnackbar(
          i18n.t('slideouts-CustomerInfoOptions-closeOpenThreads'),
          'error'
        )
      );
      closeOptions();
    } else {
      deleteContact();
    }
  };

  const onBlockContactError = () => {
    const { phoneNumber, emailAddress } = customer;
    dispatch(
      openSnackbar(
        i18n.t('slideouts-CustomerInfoOptions-blockFailure', {
          number: formatPhoneNumber(phoneNumber) || emailAddress,
        }),
        'error'
      )
    );
    setBubbleContentMode('options');
  };

  const onDeleteContactSuccess = () => {
    dispatch(
      openSnackbar(
        i18n.t('slideouts-CustomerInfoOptions-deleteAccountSuccess', {
          name: contactName(customer),
        })
      )
    );

    closeOptions(onDelete); // onDelete is from props
  };

  const onDeleteContactError = () => {
    const { firstName, lastName } = customer;

    if (firstName && lastName) {
      dispatch(
        openSnackbar(
          i18n.t('slideouts-CustomerInfoOptions-deleteContactFailure', {
            firstName,
            lastName,
          }),
          'error'
        )
      );
    } else {
      dispatch(
        openSnackbar(i18n.t('slideouts-GroupMessageName-genericError'), 'error')
      );
    }
    setBubbleContentMode('options');
  };

  const onDeleteAccountSuccess = () => {
    const { name } = customer;
    dispatch(
      openSnackbar(
        i18n.t('slideouts-CustomerInfoOptions-deleteAccountSuccess', {
          name,
        })
      )
    );
    closeOptions(onDelete); // onDelete is from props
  };

  const onDeleteAccountError = () => {
    const { name } = customer;
    dispatch(
      openSnackbar(
        i18n.t('slideouts-CustomerInfoOptions-deleteAccountFailure', {
          name,
        }),
        'error'
      )
    );
    setBubbleContentMode('options');
  };

  const client = useApolloClient();

  const { blockChannel } = useBlockChannel({
    accountId,
    client,
    address: customer.phoneNumber || customer.emailAddress,
    onError: onBlockContactError,
    onSuccess: onBlockContactSuccess,
  });

  const { deleteContact, deleteAccount } = useDeleteCustomer({
    client,
    customer,
    setDeleteLoading,
    onDeleteAccountError,
    onDeleteAccountSuccess,
    onDeleteContactError,
    onDeleteContactSuccess,
    i18n,
  });

  const isCustomerAccount = customer.__typename === 'CustomerAccount';

  const adjustLeft =
    bubbleContentMode !== 'options' &&
    browserWidth < 1080 &&
    adjustLeftOnScaleMeasure;

  let bubbleContent;
  if (bubbleContentMode === 'block') {
    bubbleContent = (
      <>
        <BubbleMessageWrap>
          <EmphasisText dataTestId="cio-block-text">
            {i18n.t('slideouts-CustomerInfoOptions-blockNumber', {
              number:
                formatPhoneNumber(customer.phoneNumber) ||
                customer.emailAddress,
            })}
          </EmphasisText>
          <BubbleSubTextWrap>
            <Text>
              {i18n.t('slideouts-CustomerInfoOptions-adminsCanManageBlocked')}
            </Text>
          </BubbleSubTextWrap>
        </BubbleMessageWrap>
        <HorizontalRule />
        <div style={{ display: 'flex' }}>
          <Button
            type="noStyle"
            noOutline
            onClick={blockChannel}
            dataTestId="cio-block"
            customStyle={customLeftButtonStyle}
          >
            {i18n.t('slideouts-CustomerInfoOptions-block')}
          </Button>
          <VerticalRule />
          <Button
            type="noStyle"
            noOutline
            dataTestId="cio-block-cancel"
            onClick={() => closeOptions()}
            customStyle={() => 'width: 50%;'}
          >
            {i18n.t('slideouts-CustomerInfoOptions-cancel')}
          </Button>
        </div>
      </>
    );
  } else if (bubbleContentMode === 'delete') {
    let contactText = i18n.t('slideouts-CustomerInfoOptions-thisContact');

    if (isCustomerAccount) {
      if (customer.name) contactText = customer.name;
    } else if (customer.firstName && customer.lastName)
      contactText = `${customer.firstName} ${customer.lastName}`;

    bubbleContent = (
      <>
        <BubbleMessageWrap>
          <EmphasisText dataTestId="cio-delete-text">
            {i18n.t('slideouts-CustomerInfoOptions-areYouSureDelete', {
              name: '',
            })}
          </EmphasisText>
          <ContactTextContainer>
            <EmphasisText maxWidth="270px">{contactText}</EmphasisText>
            <EmphasisText>?</EmphasisText>
          </ContactTextContainer>
          <BubbleSubTextWrap>
            <Text>
              {i18n.t('slideouts-CustomerInfoOptions-actionPermanentlyDelete')}
            </Text>
          </BubbleSubTextWrap>
        </BubbleMessageWrap>
        <HorizontalRule />
        <div style={{ display: 'flex' }}>
          {deleteMessageAccess && (
            <Button
              type="noStyle"
              noOutline
              onClick={isCustomerAccount ? deleteAccount : handleDeleteCustomer}
              dataTestId="cio-delete"
              customStyle={customLeftButtonStyle}
              loadingSpinner={deleteLoading}
            >
              {i18n.t('slideouts-CustomerInfoOptions-delete')}
            </Button>
          )}
          <VerticalRule />
          <Button
            type="noStyle"
            noOutline
            onClick={() => closeOptions()}
            dataTestId="cio-delete-cancel"
            customStyle={() => 'width: 50%'}
          >
            {i18n.t('slideouts-CustomerInfoOptions-cancel')}
          </Button>
        </div>
      </>
    );
  } else {
    bubbleContent = (
      <>
        {!isCustomerAccount &&
        (customer.phoneNumber || emailRegex.test(contactName(customer))) &&
        blockCustomerAccess ? (
          <>
            <Button
              type="noStyle"
              noOutline
              customStyle={(props) =>
                `color: ${THEMES.FOREGROUND_HIGH(
                  props
                )}; width: 160px; :hover { color: ${THEMES.FOREGROUND_HIGH(
                  props
                )}; }`
              }
              onClick={giveBlockConfirmation}
              dataTestId="cio-block-option"
            >
              {i18n.t('slideouts-CustomerInfoOptions-block')}
            </Button>
            <HorizontalRule />
          </>
        ) : null}
        {deleteMessageAccess ? (
          <Button
            type="noStyle"
            noOutline
            onClick={giveDeleteConfirmation}
            customStyle={(props) =>
              `color: ${THEMES.THEME_RED(
                props
              )}; width: 160px; :hover { color: ${THEMES.THEME_RED(props)}; }`
            }
            dataTestId="cio-delete-option"
          >
            {i18n.t('slideouts-CustomerInfoOptions-delete')}
          </Button>
        ) : null}
      </>
    );
  }

  const showBlockOption =
    blockCustomerAccess && !isCustomerAccount && customer.phoneNumber;

  const shouldDisplayOptions =
    showBlockOption ||
    deleteMessageAccess ||
    bubbleContentMode === 'block' ||
    bubbleContentMode === 'delete';

  const currentAdjustment = adjustLeft
    ? buttonOffsetLeftToCenter - adjustLeft
    : buttonOffsetLeftToCenter;

  const bubblePadding =
    bubbleContentMode !== 'options'
      ? optionsBubbleOverride?.contentMode
      : optionsBubbleOverride?.displayOptions;

  const carrotPadding =
    bubbleContentMode !== 'options'
      ? adjustCarrotOverride?.contentMode
      : adjustCarrotOverride?.displayOptions;

  const useOverRideCarrotPadding = carrotPadding || adjustLeft;
  const useOverRideBubblePadding = bubblePadding || currentAdjustment;
  return (
    <div
      data-testid="customer-info-options"
      ref={(el) => {
        if (!el || buttonOffsetTopToCenter || buttonOffsetLeftToCenter) return;
        setTimeout(() => {
          if (!componentIsMounted.current) return;
          const { offsetHeight, offsetTop, offsetLeft } = el; // offsetWidth,

          const halfwayHeight = offsetHeight / 2;
          const currentOffsetTopToCenter = offsetTop + halfwayHeight;
          const currentOffsetLeftToCenter = offsetLeft + 2;

          setButtonOffsetTopToCenter(currentOffsetTopToCenter);
          setButtonOffsetLeftToCenter(currentOffsetLeftToCenter);
        }, 100);
      }}
    >
      {shouldDisplayOptions ? (
        <IconButton
          contrast="highColor"
          onClick={openOptions}
          noOutline
          dataTestId="cio-options-button"
        >
          <i className="ri-more-fill" />
        </IconButton>
      ) : null}

      {displayOptions && (
        <DetectClickOutside onClickOutside={() => closeOptions()}>
          <OptionsBubble
            top={buttonOffsetTopToCenter}
            left={useOverRideBubblePadding}
            data-testid="cio-options-bubble"
          >
            <Carrot
              top="-12px"
              backgroundColor={THEMES.BORDER_COLOR({ theme: styledTheme })}
              adjustLeft={useOverRideCarrotPadding}
            />
            <Carrot
              top="-11px"
              backgroundColor={THEMES.BACKGROUND_PRIMARY({
                theme: styledTheme,
              })}
              adjustLeft={useOverRideCarrotPadding}
            />
            {bubbleContent}
          </OptionsBubble>
        </DetectClickOutside>
      )}
    </div>
  );
};

CustomerInfoOptions.propTypes = {
  customer: PropTypes.instanceOf(Object).isRequired,
  hasThreadOpen: PropTypes.bool,
  onBlock: PropTypes.func,
  onDelete: PropTypes.func,
  adjustCarrotOverride: PropTypes.object,
  optionsBubbleOverride: PropTypes.object,
  adjustLeftOnScaleMeasure: PropTypes.number,
};

CustomerInfoOptions.defaultProps = {
  hasThreadOpen: false,
  onBlock: () => {},
  onDelete: () => {},
  adjustLeftOnScaleMeasure: null,
  adjustCarrotOverride: {},
  optionsBubbleOverride: {},
};

export default CustomerInfoOptions;
