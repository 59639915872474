import { useEffect } from "react";
import MY_OPEN_THREADS from "../../../graphql/query/MyOpenThreadsQuery.graphql";
import MY_INBOX_THREADS from "../../../graphql/query/MyInboxThreadsQuery.graphql";
import usePaginated from "../../../api/query/usePaginated";
import threadsQueryVars from "../../../utils/threadsQueryVars";
import { createFuzzIntervalMsFromMins } from "../../../utils/helpers";

const useOverwriteThreadQuery = ({
  loading,
  pageInfo,
  originalData,
  variables,
  client,
  key,
  query,
}) => {
  useEffect(() => {
    if (!loading && pageInfo && pageInfo.hasNextPage === false) {
      client.writeQuery({
        query,
        data: originalData,
        variables: { ...variables, first: 30 },
      });
    }
  }, [
    loading,
    pageInfo?.hasNextPage,
    originalData?.[key]?.edges?.length,
    originalData?.[key]?.edges?.[0]?.node?.id,
  ]);
};

const MIN_INTERVAL = 8;
const MAX_INTERVAL = 14;
const POLL_INTERVAL = createFuzzIntervalMsFromMins(MIN_INTERVAL, MAX_INTERVAL);

const useBackupQueryThreads = ({
  client,
  currentUser,
  threadsActiveGroupIds,
  includeInternal = false,
}) => {
  const queryOptions = {
    pollInterval: POLL_INTERVAL,
    fetchPolicy: "network-only",
  };

  const contactId = currentUser?.contactId;

  const { genMyInboxThreadsVars, genMyOpenThreadsVars } = threadsQueryVars({
    isBackupQuery: true,
  });

  const myInboxThreads = genMyInboxThreadsVars({
    threadsActiveGroupIds,
    includeInternal,
  });

  const {
    loading: myInboxThreadsLoading,
    pageInfo: myInboxThreadsPageInfo,
    originalData: myInboxThreadsOGData,
  } = usePaginated({
    client,
    queryVariables: myInboxThreads,
    query: MY_INBOX_THREADS,
    resultsNumber: 29,
    key: "myInboxThreads",
    skip: !contactId,
    queryOptions,
  });

  useOverwriteThreadQuery({
    client,
    loading: myInboxThreadsLoading,
    pageInfo: myInboxThreadsPageInfo,
    originalData: myInboxThreadsOGData,
    variables: myInboxThreads,
    key: "myInboxThreads",
    query: MY_INBOX_THREADS,
  });

  const myOpenThreadsQueryVariables = genMyOpenThreadsVars({
    threadsActiveGroupIds,
    contactId,
    includeInternal,
  });

  const {
    loading: myOpenThreadsLoading,
    pageInfo: myOpenThreadsPageInfo,
    originalData: myOpenThreadsOGData,
  } = usePaginated({
    client,
    queryVariables: myOpenThreadsQueryVariables,
    query: MY_OPEN_THREADS,
    resultsNumber: 29,
    key: "myOpenThreads",
    skip: !contactId,
    queryOptions,
  });

  useOverwriteThreadQuery({
    client,
    loading: myOpenThreadsLoading,
    pageInfo: myOpenThreadsPageInfo,
    originalData: myOpenThreadsOGData,
    variables: myOpenThreadsQueryVariables,
    key: "myOpenThreads",
    query: MY_OPEN_THREADS,
  });
};

export default useBackupQueryThreads;
