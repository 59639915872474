import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
// import { updateApplicationState } from '../../actions';
// import { SignInContext } from '../../containers/App';

const useConnectApolloSocket = () => {
  const client = useApolloClient();
  // const dispatch = useDispatch();

  // const { setSessionLoading } = useContext(SignInContext);
  const currentUserId = useSelector(
    (state) => state.session.currentUser?.userId
  );
  const currentUserAccountId = useSelector(
    (state) => state.session.currentUser?.accountId
  );

  // connects client-side socket on reception of user data
  useEffect(() => {
    if (currentUserId) {
      // const absintheLink =
      client.connectSocket(currentUserId, currentUserAccountId);
      // absintheLink.onPresenceJoin(() => {
      //   // Handling this in the useCheckUserLoaded hook
      //   // to ensure the latest user state is loaded before setting the session as loaded
      //   // if (!user.ff_beta_authorization) {
      //   //   setSessionLoading(false);
      //   // }
      //   // This is defined after the onPresenceJoin in
      //   dispatch(
      //     updateApplicationState({
      //       userLoadedFromDistributor: true,
      //       userLoadTime: Date.now(),
      //     })
      //   );
      // });
    }
  }, [currentUserId]);
};

export default useConnectApolloSocket;
