export const SET_CREATE_CUSTOMER_FIELD = 'SET_CREATE_CUSTOMER_FIELD';

export const SET_CREATE_CUSTOMER_FIELDS = 'SET_CREATE_CUSTOMER_FIELDS';

export const CLEAR_CREATE_SECTION = 'CLEAR_CREATE_SECTION';

export const SET_CREATE_CUSTOMER = 'SET_CREATE_CUSTOMER';

export const SET_CREATE_THREAD = 'SET_CREATE_THREAD';

export const SET_CREATE_INTERNAL = 'SET_CREATE_INTERNAL';

export const SET_CREATE_FAX_THREAD = 'SET_CREATE_FAX_THREAD';

export const SET_CREATE_EMAIL = 'SET_CREATE_EMAIL';

export const setCreateCustomerFormValue = ({ key, value }) => ({
  type: SET_CREATE_CUSTOMER_FIELD,
  key,
  value,
});

export const setCreateCustomerFormValues = (value) => ({
  type: SET_CREATE_CUSTOMER_FIELDS,
  value,
});

export const clearCreateSection = () => ({
  type: CLEAR_CREATE_SECTION,
});

export const setCreateCustomer = (value) => ({
  type: SET_CREATE_CUSTOMER,
  value,
});

export const setCreateThread = (value) => ({
  type: SET_CREATE_THREAD,
  value,
});

export const setCreateInternal = (value) => ({
  type: SET_CREATE_INTERNAL,
  value,
});

export const setCreateFaxThread = (value) => ({
  type: SET_CREATE_FAX_THREAD,
  value,
});

export const setCreateEmail = (value) => ({
  type: SET_CREATE_EMAIL,
  value,
});
