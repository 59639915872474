import { useState, useEffect } from "react";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import threadHistoryQuery from "../../../graphql/query/ThreadHistoryQuery.graphql";
import customerContactThreadsHistoryQuery from "../../../graphql/query/CustomerContactThreadsHistoryQuery.graphql";
import previousInternalThreadQuery from "../../../graphql/query/PreviousInternalThreadQuery.graphql";
import customerContactQuery from "../../../graphql/query/CustomerContactQuery.graphql";
import threadQuery from "../../../graphql/query/ThreadQuery.graphql";
import MarkThreadReadMutation from "../../../graphql/mutation/MarkThreadReadMutation.graphql";
import THREAD_HISTORY_SUBSCRIPTION from "../../../graphql/subscription/ThreadHistorySubscription.graphql";
import { messageSubscriptionHandler } from "../../../configs/apollo/setupSubscriptions";
import useClaimOrMarkNotSpamThread from "./useClaimOrMarkNotSpamThread";
import useIgnoreThreadMutation from "./useIgnoreThreadMutation";

/**
 * Custom hook to manage thread history
 *
 * **Note:** Track usage has been disabled - fix in a future update when mobile can be handled as well.
 *
 * @param {Object} params - Parameters for the hook
 * @param {Object} params.thread - Required. Thread instance
 * @param {Function} [params.track] - Optional. Track function provided by withEventTracker HOC
 * @param {Object} params.client - Required. Apollo Client instance
 * @param {Function} [params.onHandleClaimThread] - Optional. Callback function to be executed during claim thread
 * @param {Function} [params.onLoadingThreadHistory] - Optional. Callback function to be executed before thread history query, designed to show current "loading" state
 * @param {Function} [params.onLoadingThreadHistoryComplete] - Optional. Callback function to be executed after thread history query, designed to show current "loading" state
 * @param {Function} [params.beforeLoadThreadHistory] - Optional. Callback function to be executed before loading thread history
 * @param {boolean} [params.isTest=false] - Optional. Used for unit testing ThreadHistory
 * @param {number} [params.socketConnectionTimestamp] - Optional. Timestamp for socket connection
 * @param {Object} params.store - Required. Redux store
 * @param {Function} [params.onHandleClaimThreadFailure] - Optional. Callback function to handle claim thread failure
 * @param {Function} [params.onHandleMarkNotSpamThread] - Optional. Callback function to handle mark not spam thread
 * @param {Function} [params.onHandleClamAndMarkNotSpam] - Optional. Callback function to handle claim and mark not spam
 *
 * @returns {{
 *   threads: Array, - Array of thread instances, stored in local state
 *   messages: Object, - Object containing messages keyed by thread ID
 *   hasMoreThreads: boolean, - Indicates whether or not the customer has more threads beyond what is currently in state
 *   customerContactData: Object, - The data for archivedByContactId returned from customerContactQuery, will only have data if the thread has been archived
 *   customerContactLoading: boolean, - Indicates if customerContactData is loading
 *   handleLoadPreviousThread: Function, - Will load the previous thread
 *   handleClaimThread: Function, - Will run ClaimThreadMutation assigning it to the current user
 *   handleClaimThreadAndMarkNotSpam: Function, - Will handle claiming and marking the thread as not spam
 *   handleMarkNotSpam: Function, - Will handle marking a thread as not spam
 *   handleMarkThreadReadMutation: Function, - Will call MarkThreadReadMutation on the thread
 *   handleIgnoreThread: Function, - Will handle ignoring a thread
 *   IgnoreReason: string, - Reason for ignoring a thread
 * }}
 */
const useThreadHistory = ({
  thread,
  track, // eslint-disable-line no-unused-vars
  client,
  store,
  onHandleClaimThread,
  onHandleClaimThreadFailure,
  onHandleMarkNotSpamThread = null,
  onHandleClamAndMarkNotSpam = null,
  onLoadingThreadHistoryComplete,
  onLoadingThreadHistory,
  beforeLoadThreadHistory,
  isTest = false,
  socketConnectionTimestamp,
}) => {
  const isMobile = process.env.PLATFORM === "mobile";
  let threadHistorySubscription = null;
  let activeThreadSubscription = null;
  const historicalThreadSubscriptions = {};

  const [hasMoreThreads, setHasMoreThreads] = useState(false);
  const [threads, setThreads] = useState([]);
  const [messages, setMessages] = useState({});

  const [markThreadReadMutation] = useMutation(MarkThreadReadMutation, {
    client,
  });

  const {
    handleClaimThread,
    handleClaimThreadAndMarkNotSpam,
    handleMarkNotSpam,
  } = useClaimOrMarkNotSpamThread({
    client,
    onHandleClaimThreadSuccess: onHandleClaimThread,
    onHandleClaimThreadFailure,
    onHandleClamAndMarkNotSpam,
    onHandleMarkNotSpamThread,
    store,
  });

  const { handleIgnoreThread, IgnoreReason } = useIgnoreThreadMutation({
    client,
  });

  const { data: customerContactData, loading: customerContactLoading } =
    useQuery(customerContactQuery, {
      client,
      skip: !thread.archivedByContactId,
      variables: {
        id: thread.archivedByContactId || "",
      },
    });

  useSubscription(THREAD_HISTORY_SUBSCRIPTION, {
    client,
    variables: { threadId: thread.id, socketConnectionTimestamp },
    onData: ({ client: apolloClient, data: response }) => {
      messageSubscriptionHandler(store, apolloClient, response);
    },
  });

  const activeThreadQuery = async () => {
    if (activeThreadSubscription) {
      activeThreadSubscription.unsubscribe();
      activeThreadSubscription = null;
    }

    const activeThreadObservable = client.watchQuery({
      query: threadQuery,
      variables: { id: thread.id },
    });

    activeThreadSubscription = activeThreadObservable.subscribe({
      next: ({ data }) => {
        if (data?.thread?.id) {
          setThreads((x) => {
            const threads = x.valueOf();

            const newThreads = threads.map((thread) =>
              thread.id === data.thread.id ? data.thread : thread
            );

            return newThreads;
          });
        }
      },
      error: (e) => console.warn(e),
    });
  };

  const loadHistoricalInternalThread = async (
    thread,
    onlyCheckForMoreThreads
  ) => {
    const { data } = await client
      .query({
        query: previousInternalThreadQuery,
        fetchPolicy: "no-cache",
        variables: {
          userShareIds: thread?.userShares?.map(
            (userShare) => userShare.userId
          ),
          before: thread?.id,
        },
      })
      .catch(console.error);

    if (onlyCheckForMoreThreads) {
      setHasMoreThreads(!!data.previousInternalThread?.edges?.[0]?.cursor);
    } else {
      const newThread = data.previousInternalThread.edges[0].node;

      const newThreads = isMobile
        ? [newThread, ...threads]
        : [...threads, newThread]; // .sort((a, b) => new Date(a.insertedAt) - new Date(b.insertedAt))

      setThreads(newThreads);

      setHasMoreThreads(data.previousInternalThread.pageInfo.hasNextPage);
    }

    return data;
  };

  const loadHistoricalExternalThread = async (
    thread,
    onlyCheckForMoreThreads
  ) => {
    const { data } = await client
      .query({
        query: customerContactThreadsHistoryQuery,
        fetchPolicy: "no-cache",
        variables: {
          customerContactId: thread?.externalContact?.id,
          after: `thread:${thread?.id}`,
          first: 1,
          eventTypes: ["THREAD"],
        },
      })
      .catch(console.error);

    if (onlyCheckForMoreThreads) {
      setHasMoreThreads(!!data.customerContactThreadsHistory?.edges?.length);
    } else {
      const newThread = data.customerContactThreadsHistory.edges[0].node;

      const newThreads = isMobile
        ? [newThread, ...threads]
        : [...threads, newThread]; // .sort((a, b) => new Date(a.insertedAt) - new Date(b.insertedAt))

      setThreads(newThreads);

      setHasMoreThreads(
        data.customerContactThreadsHistory.pageInfo.hasNextPage
      );
    }

    return data;
  };

  const loadHistoricalThread = async (thread, onlyCheckForMoreThreads) => {
    if (thread.type === "internal") {
      const historicalInternalThreadData = await loadHistoricalInternalThread(
        thread,
        onlyCheckForMoreThreads
      );
      return historicalInternalThreadData;
    }

    const historicalExternalThreadData = await loadHistoricalExternalThread(
      thread,
      onlyCheckForMoreThreads
    );
    return historicalExternalThreadData;
  };

  const loadPreviousThreadAndMessages = async () => {
    if (onLoadingThreadHistory) onLoadingThreadHistory();
    const prevIndex = isMobile ? 0 : threads.length - 1;
    const previousThreadData = await loadHistoricalThread(
      threads[prevIndex],
      false
    );

    const node =
      threads[0].type === "internal"
        ? previousThreadData?.previousInternalThread?.edges?.[0]?.node
        : previousThreadData?.customerContactThreadsHistory?.edges?.[0]?.node;

    if (node?.id) {
      loadThreadHistory(node, false);
    }
  };

  const loadThreadHistory = (thread, isActiveThread) => {
    if (thread === null) {
      return;
    }

    const threadHistoryVariables = {
      threadId: thread.id,
    };

    if (beforeLoadThreadHistory) beforeLoadThreadHistory(thread);

    if (isActiveThread) {
      if (threadHistorySubscription) {
        threadHistorySubscription.unsubscribe();
        threadHistorySubscription = null;
      }

      const threadHistoryObservable = client.watchQuery({
        query: threadHistoryQuery,
        variables: threadHistoryVariables,
        fetchPolicy: "cache-and-network", // this will let system messages get updated for the user. (i.e. thread transfer to someone, and back again)
        nextFetchPolicy: "cache-only",
      });

      threadHistorySubscription = threadHistoryObservable.subscribe({
        next: (res) => {
          if (res?.data?.threadHistory?.length > 0) {
            setMessages((x) => {
              const messages = x.valueOf();

              const newMessages = { ...messages };
              newMessages[thread.id] = res.data.threadHistory;

              return newMessages;
            });
          }
        },
        error: (e) => console.warn(e),
      });
    } else {
      if (onLoadingThreadHistory) onLoadingThreadHistory();

      client
        .query({
          query: threadHistoryQuery,
          variables: threadHistoryVariables,
        })
        .then((res) => {
          if (res?.data?.threadHistory?.length > 0) {
            const newMessages = { ...messages };

            newMessages[thread.id] = res.data.threadHistory;
            if (onLoadingThreadHistoryComplete)
              onLoadingThreadHistoryComplete();

            setMessages(newMessages);
          }
        });

      historicalThreadSubscriptions[thread.id] = client
        .subscribe({
          query: THREAD_HISTORY_SUBSCRIPTION,
          variables: threadHistoryVariables,
        })
        .subscribe(
          (res) => {
            if (res?.data?.threadHistory?.id) {
              setMessages((x) => {
                const messages = x.valueOf();

                const updatedMessage = res.data.threadHistory;

                const newMessages = { ...messages };

                const messageIndex = newMessages[thread.id].findIndex(
                  (msg) => msg.id === updatedMessage.id
                );

                if (messageIndex >= 0) {
                  newMessages[thread.id][messageIndex] = updatedMessage;
                }

                return newMessages;
              });
            }
          },
          (e) => {
            console.error(e);
          }
        );
    }
  };

  useEffect(() => {
    setHasMoreThreads(false);
    loadThreadHistory(thread, true);
    loadHistoricalThread(thread, true);
    activeThreadQuery();
    setThreads([thread]);

    return () => {
      if (activeThreadSubscription) {
        activeThreadSubscription.unsubscribe();
        activeThreadSubscription = null;
      }

      if (threadHistorySubscription) {
        threadHistorySubscription.unsubscribe();
        threadHistorySubscription = null;
      }

      if (Object.keys(historicalThreadSubscriptions).length) {
        Object.keys(historicalThreadSubscriptions).forEach((key) => {
          historicalThreadSubscriptions[key].unsubscribe();
          delete historicalThreadSubscriptions[key];
        });
      }
    };
  }, [thread?.id, socketConnectionTimestamp]);

  // workaround for previousInternalThreadQuery, it doesn't provide accurate hasNextPage data, so we need to query in advance
  // every time the user loads a new thread.
  useEffect(() => {
    if (threads?.[0]?.type === "internal") {
      loadHistoricalThread(threads?.[0], true);
    }
  }, [threads?.[0]?.id]);

  const handleMarkThreadReadMutation = async (threadId) => {
    markThreadReadMutation({
      variables: {
        input: {
          readAt: isTest
            ? "2019-10-01T22:48:04.414Z"
            : new Date().toISOString(),
          threadId,
        },
      },
    });
  };

  return {
    threads,
    messages,
    hasMoreThreads,
    customerContactData,
    customerContactLoading,
    handleLoadPreviousThread: loadPreviousThreadAndMessages,
    handleClaimThread,
    handleClaimThreadAndMarkNotSpam,
    handleMarkNotSpam,
    handleMarkThreadReadMutation,
    handleIgnoreThread,
    IgnoreReason,
  };
};

export default useThreadHistory;
