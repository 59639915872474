export const PROKEEP_SUPPORT_LINK = 'https://www.prokeep.com/support';
export const PROKEEP_KNOWLEDGE_CENTER_LINK =
  'https://knowledge.prokeep.com/knowledge';
export const CARRIERS = {
  BANDWIDTH: 'BANDWIDTH',
};
export const BREAKPOINT_LG = '1400px';

// Time in ms
export const FIVE_MIN = 300000;
export const ONE_HOUR = 3600000;
export const FOUR_HOURS = 14400000;
export const EIGHT_HOURS = 28800000;

export const MAX_LIST_LENGTH = 255;
