import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { thread_utils, useWatchThread } from 'client-lib';
import { useDispatch, useSelector } from 'react-redux';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import { Button, IconButton } from '../../../elements';
import Bubble from '../../Common/Bubble';
import RouteFilter from '../../Common/RouteFilter';
import THEMES from '../../../styles/themes/app';
import {
  openSnackbar,
  setActiveTransferThreadSlideOut,
} from '../../../actions/general';

const OptionsButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0px;

  button:first-of-type {
    /* background-color: red !important; */
    border-radius: 8px 8px 0 0;
  }
  button:last-of-type {
    /* background-color: green !important; */
    border-radius: 0 0 8px 8px;
  }
  button:only-of-type {
    border-radius: 8px;
  }
`;

const HorizontalRule = styled.div`
  border-top: 1px solid ${THEMES.BORDER_COLOR};
`;

const ButtonAndBubbleContainer = styled.div`
  display: flex;
`;

const OptionsBubbleContainer = styled.div`
  position: relative;
  width: 0;
  z-index: 2;
`;

const TruncateOverflowWrapper = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 200px;
`;

const BellButton = styled.i`
  font-size: 22px;
`;

export const bubbleButtonHoverStyle = (props) => `
  @media print {display: none;}
  padding: 0 8px;
  justify-content: flex-start;
  :hover {
    background-color: ${THEMES.ELLIPSIS_THREAD_HEADER(props)};
    color: ${THEMES.FOREGROUND_HIGH(props)};
  }
`;

const WatchButton = ({
  userWatchingCurrThread,
  handleRemoveWatchThreads,
  handleWatchThreads,
}) => {
  return userWatchingCurrThread ? (
    <>
      <HorizontalRule />
      <Button
        type="noStyle"
        noOutline
        iconAndText
        title={i18n.t('threads-ThreadDetailsHeader-disable', {
          defaultValue: 'Turn Notifications Off',
        })}
        onClick={handleRemoveWatchThreads}
        customStyle={bubbleButtonHoverStyle}
      >
        <i className="ri-notification-off-fill" />
        <TruncateOverflowWrapper>
          {i18n.t('threads-ThreadDetailsHeader-disable', {
            defaultValue: 'Turn Notifications Off',
          })}
        </TruncateOverflowWrapper>
      </Button>
    </>
  ) : (
    <>
      <HorizontalRule />
      <Button
        type="noStyle"
        noOutline
        iconAndText
        title={i18n.t('threads-ThreadDetailsHeader-enable', {
          defaultValue: 'Turn Notifications On',
        })}
        onClick={handleWatchThreads}
        customStyle={bubbleButtonHoverStyle}
      >
        <i className="ri-notification-4-fill" />
        <TruncateOverflowWrapper>
          {i18n.t('threads-ThreadDetailsHeader-enable', {
            defaultValue: 'Turn Notifications On',
          })}
        </TruncateOverflowWrapper>
      </Button>
    </>
  );
};

WatchButton.propTypes = {
  userWatchingCurrThread: PropTypes.bool.isRequired,
  handleRemoveWatchThreads: PropTypes.func.isRequired,
  handleWatchThreads: PropTypes.func.isRequired,
};

const HeaderOptionsButton = ({ activeThread }) => {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state?.session?.currentUser);
  const activeGroupIds = useSelector(
    (state) => state?.session?.threadsActiveGroupIds
  );

  const isThreadInInbox = thread_utils.isInInbox(
    activeThread,
    currentUser,
    activeGroupIds
  );

  const isActive = thread_utils.isActive(activeThread);
  const isInternal = thread_utils.isTypeInternal(activeThread);

  const watcherIsThreadOwner = thread_utils.isHeldByCurrentUser(
    activeThread,
    currentUser
  );

  const userWatchingCurrThread =
    activeThread?.watchers?.some(
      (watcher) => watcher?.user?.id === currentUser?.userId
    ) && !watcherIsThreadOwner;

  const displayThreadWatchOption =
    !watcherIsThreadOwner && !isThreadInInbox && isActive;

  const bubbleWidth = displayThreadWatchOption ? 220 : 140;

  const handleWatchThreadSuccess = (watchStatus) => {
    if (watchStatus === 'add') {
      // setWatchingThread(true);
      // setUserWatchingCurrThread(true);
      dispatch(
        openSnackbar(
          i18n.t('threads-ThreadDetailsHeader-addedWatch', {
            contact: contactName(activeThread?.externalContact),
          })
        )
      );
    } else if (watchStatus === 'remove') {
      // setUserWatchingCurrThread(false);
      dispatch(
        openSnackbar(
          i18n.t('threads-ThreadDetailsHeader-removedWatch', {
            contact: contactName(activeThread?.externalContact),
          })
        )
      );
    }
  };

  const handleWatchThreadFail = () => {
    dispatch(openSnackbar(i18n.t('settings-AddOrEditGroup-error'), 'error'));
  };

  const onMutationTrigger = () => setOptionsOpen(false);

  const { handleWatchThreads, handleRemoveWatchThreads } = useWatchThread(
    activeThread?.id,
    handleWatchThreadSuccess,
    handleWatchThreadFail,
    onMutationTrigger
  );

  const handlePrint = () => {
    setOptionsOpen(false);
    window.print();
  };

  const onTransferThreadClick = () => {
    setOptionsOpen(false);
    dispatch(setActiveTransferThreadSlideOut(true));
  };

  return (
    <>
      {userWatchingCurrThread && !watcherIsThreadOwner ? (
        <BellButton className="ri-notification-4-fill" />
      ) : null}
      <ButtonAndBubbleContainer>
        <IconButton
          contrast="highColor"
          noOutline
          onClick={() => setOptionsOpen(!optionsOpen)}
          dataTestId="threadMoreButton"
        >
          <i className="ri-more-fill" />
        </IconButton>
        <OptionsBubbleContainer>
          <Bubble
            isOpen={optionsOpen}
            onClickOutside={() => setOptionsOpen(!optionsOpen)}
            moveLeftVal={23}
            moveBubbleRightVal={-35}
            bubbleMinWidth={bubbleWidth}
            bubbleMaxWidth={bubbleWidth}
            borderRadius={8}
            hasCarrot={false}
            horizontallyStatic
            top="25px"
            fixedPosition
          >
            <OptionsButtonContainer>
              <Button
                type="noStyle"
                noOutline
                onClick={handlePrint}
                customStyle={bubbleButtonHoverStyle}
                iconAndText
                dataTestId="threadPrintButton"
              >
                <i className="ri-printer-fill" />
                {i18n.t('threads-ThreadDetailsHeader-print')}
              </Button>
              {!isInternal ? (
                <RouteFilter
                  exact
                  path={[
                    '/threads/open/:activeThreadId',
                    '/threads/inbox/:activeThreadId',
                  ]}
                >
                  <>
                    <HorizontalRule />
                    <Button
                      type="noStyle"
                      noOutline
                      onClick={onTransferThreadClick}
                      iconAndText
                      dataTestId="transfer-btn"
                      customStyle={bubbleButtonHoverStyle}
                    >
                      <i className="ri-reply-fill" />
                      {i18n.t('threads-ThreadDetailsHeader-transfer')}
                    </Button>
                  </>
                </RouteFilter>
              ) : null}
              {displayThreadWatchOption && (
                <WatchButton
                  userWatchingCurrThread={userWatchingCurrThread}
                  handleRemoveWatchThreads={handleRemoveWatchThreads}
                  handleWatchThreads={handleWatchThreads}
                />
              )}
            </OptionsButtonContainer>
          </Bubble>
        </OptionsBubbleContainer>
      </ButtonAndBubbleContainer>
    </>
  );
};

HeaderOptionsButton.propTypes = {
  activeThread: PropTypes.object.isRequired,
};

export default HeaderOptionsButton;
