import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  contactName,
  accountName,
  isCustomerContactUnknown,
  accountNumber,
} from 'client-lib/src/lib/utils/helpers';
import i18n from 'i18n-js';
import {
  InlineTextButton,
  EntityCard,
  Text,
  EmphasisText,
  Button,
} from '../../../elements';

const CustomerContactContainer = styled.div`
  overflow: hidden;
  max-height: 75px;
`;

const CompanyWrapper = styled.div`
  display: flex;
  cursor: ${(props) => (props.companyId ? 'pointer' : 'auto')};
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TruncateOverflowWrapper = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 450px;
`;

const SubtextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CustomerContactHeader = ({
  activeCustomerData,
  onAvatarNameClick,
  onAssignJobTitle,
  onAssignCompany,
  onOpenCompany,
  companyId,
  ...props
}) => {
  const fullName = contactName(activeCustomerData);
  const jobTitle = activeCustomerData?.jobTitle;
  const companyName = accountName(activeCustomerData);
  const companyAccountNumber = accountNumber(activeCustomerData);

  const unknownContact = isCustomerContactUnknown(activeCustomerData);

  const maintextComponent = (
    <HeadingWrapper>
      <TruncateOverflowWrapper>
        <Button
          type="link"
          customStyle={() =>
            'padding: 0; color: unset; height: 24px; font-size: unset;'
          }
          noOutline
        >
          {fullName}
        </Button>
      </TruncateOverflowWrapper>
    </HeadingWrapper>
  );

  const handleOpenCompany = (companyId) => {
    if (companyId) {
      onOpenCompany(companyId);
    }
  };

  const subtextComponent = (
    <SubtextWrapper>
      {jobTitle ? (
        <Text contrast="med" maxWidth="450px">
          {jobTitle}
        </Text>
      ) : (
        <InlineTextButton
          noOutline
          textType="EmphasisText"
          onClick={onAssignJobTitle}
        >
          {i18n.t('customers-Contacts-assignTitle', {
            defaultValue: 'Assign Job Title',
          })}
        </InlineTextButton>
      )}
      &nbsp;
      <Text>{i18n.t('customers-Contacts-at', { defaultValue: 'at' })}</Text>
      &nbsp;
      {companyName ? (
        <CompanyWrapper companyId={companyId}>
          <Button
            onClick={() => handleOpenCompany(companyId)}
            type="link"
            customStyle={() =>
              'max-width: 450px; padding: 0; height: 17px; color: unset'
            }
            noOutline
          >
            {companyName}
          </Button>
          &nbsp;
          {companyAccountNumber && (
            <EmphasisText>{` - #${companyAccountNumber}`}</EmphasisText>
          )}
        </CompanyWrapper>
      ) : (
        <InlineTextButton
          noOutline
          onClick={onAssignCompany}
          textType="EmphasisText"
        >
          {i18n.t('customers-Contacts-assignCompany', {
            defaultValue: 'Assign Company',
          })}
        </InlineTextButton>
      )}
    </SubtextWrapper>
  );

  return (
    <CustomerContactContainer>
      <EntityCard
        {...props}
        avatarChildren={fullName}
        maintext={maintextComponent}
        maintextProps={{ onClick: onAvatarNameClick }}
        customMaintextStyle={() => `margin: 0;`}
        subtext={!unknownContact && subtextComponent}
        customContainerStyle={() => 'border-width: 0;'}
        avatarProps={{
          onClick: onAvatarNameClick,
        }}
      />
    </CustomerContactContainer>
  );
};

CustomerContactHeader.propTypes = {
  activeCustomerData: PropTypes.object,
  onAssignJobTitle: PropTypes.func.isRequired,
  onAssignCompany: PropTypes.func.isRequired,
  onAvatarNameClick: PropTypes.func,
  onOpenCompany: PropTypes.func,
  companyId: PropTypes.string,
};

CustomerContactHeader.defaultProps = {
  onAvatarNameClick: undefined,
  activeCustomerData: null,
  onOpenCompany: () => {},
  companyId: '',
};

export default CustomerContactHeader;
