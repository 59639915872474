import { useMutation } from "@apollo/client";
import debounce from "lodash/debounce";
import POST_MESSAGE_MUTATION from "../../graphql/mutation/PostMessageMutation.graphql";

/*

DOCUMENTATION
  generic hook for using postMessageMutation

  args:
    client: (obj) required. apollo client instance

  returns:
    [0] handlePostMessage: func that will call poseMessageMutation.
      args:
        text: (str) required. text you want to send in message,
        threadId: (str) required. thread id of thread user wants to post to
        attachments: (arr) optional. attachments
        onSuccess: (func) optional, will call on mutation success
        onError: (func) optional, will call on mutation failure

*/

const usePostMessage = ({ client }) => {
  const [postMessage] = useMutation(POST_MESSAGE_MUTATION, { client });

  const handlePostMessage = debounce(
    async ({ text, threadId, attachments = [], onSuccess, onError }) => {
      const { data } = await postMessage({
        variables: {
          input: {
            text,
            threadId,
            attachments,
          },
        },
      }).catch((err) => {
        if (onError) onError(err);
      });

      if (data && data.postMessage && data.postMessage.errors) {
        if (onError) onError(data.postMessage.errors[0]);
      } else if (onSuccess) onSuccess();
    },
    1000,
    { leading: true }
  );

  return [handlePostMessage];
};

export default usePostMessage;
