import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import i18n from "i18n-js";
import debounce from "lodash/debounce";
import MESSAGE_QUERY from "../../../graphql/query/MessageQuery.graphql";
import useForwardMessageMutation from "../../../api/mutations/useForwardMessageMutation";
import { contactName } from "../../../utils/helpers";

function useForwardMessage({
  client,
  activeEmailChannels,
  activeForwardMessageId,
  onMutationSuccess,
  onMutationError,
  setMutationLoading,
}) {
  const [selectedChannelId, setSelectedChannelId] = useState("");
  const [contact, setContact] = useState(null);
  const [carbonCopyContacts, setCarbonCopyContacts] = useState([]);
  const [subjectInputValue, setSubjectInputValue] = useState("");
  const [messageInputValue, setMessageInputValue] = useState("");
  const [includeAttachments, setIncludeAttachments] = useState(false);
  const [contactEmail, setContactEmail] = useState("");
  const [hasAttachments, setHasAttachments] = useState(false);

  const [handleForwardMessage] = useForwardMessageMutation({ client });

  const { data: messageData } = useQuery(MESSAGE_QUERY, {
    client,
    variables: {
      id: activeForwardMessageId,
    },
  });

  const groupsDropdownList = activeEmailChannels.map((channel) => {
    const label = `${channel.group.name}, ${channel.emailConfig.emailAddress}`;
    return {
      label,
      value: channel.id,
    };
  });

  // Set default email channel id if user only has one email channel
  useEffect(() => {
    if (activeEmailChannels?.length === 1) {
      setSelectedChannelId(activeEmailChannels[0].id);
    }
  }, []);

  useEffect(() => {
    setHasAttachments(!!messageData?.message?.attachments?.length);
  }, [messageData?.message?.id]);

  useEffect(() => {
    if (messageData?.message) {
      const insertedByContact = messageData?.message?.insertedByContact;
      const createdAt = messageData?.message?.createdAt;
      const dateValue = new Date(createdAt);
      const nameAndTimeStamp = `Fwd: ${contactName(
        insertedByContact
      )} ${i18n.strftime(dateValue, "%-m/%-d/%Y")} ${i18n.strftime(
        dateValue,
        "%l:%M %p"
      )}`;
      setSubjectInputValue(nameAndTimeStamp);
    }
  }, [messageData?.message?.id]);

  useEffect(() => {
    if (messageData?.message) {
      setMessageInputValue(
        `\n\n--------- ${i18n.t(
          "slideouts-ForwardMessageSlideout-forwardedMessage"
        )} ----------\n\n${messageData?.message?.text || ""}`
      );
    }
  }, [messageData?.message?.text]);

  const carbonCopyIds = [];
  const anonymousCcList = [];
  if (carbonCopyContacts.length >= 1) {
    carbonCopyContacts.forEach((contact) => {
      if (contact?.id) {
        carbonCopyIds.push(contact?.id);
      }
      if (!contact?.id && contact?.emailAddress) {
        anonymousCcList.push(contact?.emailAddress);
      }
    });
  }

  const startForwardMessageMutation = debounce(
    () => {
      setMutationLoading(true);
      const mutationArgs = {
        externalContactId: contact?.id,
        fromChannelId: selectedChannelId,
        externalContactEmailAddress: contactEmail,
        includeAttachments,
        text: messageInputValue,
        subject: subjectInputValue,
        cc: carbonCopyIds,
        anonymousCc: anonymousCcList,
        messageId: activeForwardMessageId,
        onSuccess: onMutationSuccess,
        onError: onMutationError,
      };
      handleForwardMessage(mutationArgs);
    },
    1000,
    { leading: true }
  );

  return {
    selectedChannelId,
    setSelectedChannelId,
    groupsDropdownList,
    contact,
    setContact,
    carbonCopyContacts,
    setCarbonCopyContacts,
    subjectInputValue,
    setSubjectInputValue,
    messageInputValue,
    setMessageInputValue,
    includeAttachments,
    setIncludeAttachments,
    contactEmail,
    setContactEmail,
    startForwardMessageMutation,
    hasAttachments,
    setHasAttachments,
  };
}

export default useForwardMessage;
