import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import i18n from 'i18n-js';
import { Heading1, Heading3, Anchor, InputLabel } from './elements';
import { PROKEEP_KNOWLEDGE_CENTER_LINK } from './utils/helpers/constants';
import ExternalScreenTemplate from './containers/Login/ExternalScreenTemplate';

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 70%;
  flex-wrap: wrap;
`;

const SectionWrapper = styled.div`
  margin-bottom: 2em;
  text-align: center;
`;

const Content = styled.div`
  margin-bottom: 1em;
`;

const InputLabelWrapper = styled(InputLabel)`
  font-size: 16px;
  margin-right: 10px;
`;

const AnchorWrapper = styled(Anchor)`
  font-size: 16px;
`;

class GlobalErrorBoundary extends Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error) {
    Sentry.withScope((scope) => {
      scope.setLevel('fatal');
    });
    Sentry.captureException(`fatal error: ${error}`);
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <ThemeProvider theme={{ mode: 'light' }}>
          <ExternalScreenTemplate screenType="maintenance">
            <ErrorWrapper>
              <SectionWrapper>
                <Heading1>
                  {i18n.t('signin-GlobalErrorBoundary-error')}
                </Heading1>
              </SectionWrapper>
              <SectionWrapper>
                <Heading3>
                  {i18n.t('signin-GlobalErrorBoundary-refresh')}
                </Heading3>
              </SectionWrapper>
              <SectionWrapper>
                <Content>
                  <InputLabelWrapper>
                    {i18n.t('signin-GlobalErrorBoundary-phone')}
                  </InputLabelWrapper>
                  <AnchorWrapper href="tel:1-504-226-7756">
                    1-504-226-7756
                  </AnchorWrapper>
                </Content>
                <Content>
                  <InputLabelWrapper>
                    {i18n.t('signin-GlobalErrorBoundary-support')}
                  </InputLabelWrapper>
                  <AnchorWrapper href="mailto:support@prokeep.com">
                    support@prokeep.com
                  </AnchorWrapper>
                </Content>
                <Content>
                  <InputLabelWrapper>
                    {i18n.t('signin-GlobalErrorBoundary-helpCenter')}
                  </InputLabelWrapper>
                  <AnchorWrapper
                    href={PROKEEP_KNOWLEDGE_CENTER_LINK}
                    target="_blank"
                  >
                    {i18n.t('signin-GlobalErrorBoundary-knowledgeBase')}
                  </AnchorWrapper>
                </Content>
              </SectionWrapper>
            </ErrorWrapper>
          </ExternalScreenTemplate>
        </ThemeProvider>
      );
    }
    return this.props.children;
  }
}

GlobalErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
};

export default GlobalErrorBoundary;
