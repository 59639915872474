import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';
import { Button } from '../../../elements';
import THEMES from '../../../styles/themes/app';

const WrapSaveCancel = styled.div`
  background-color: ${THEMES.BACKGROUND_SECONDARY};
  display: flex;
  justify-content: flex-end;
  padding: 0.2rem 1rem;
  border-bottom: 2px solid ${THEMES.BORDER_COLOR};
`;

const Pad = styled.div`
  width: 4px;
`;

const EditSaveCancel = ({ onSave, onCancel }) => (
  <WrapSaveCancel data-testid="edit-save-cancel">
    <Button size="sm" onClick={onSave} dataTestId="save-contact-edit-button">
      {i18n.t('slideouts-EditSaveCancel-save')}
    </Button>
    <Pad />
    <Button
      size="sm"
      type="secondary"
      onClick={onCancel}
      dataTestId="cancel-contact-edit-button"
    >
      {i18n.t('slideouts-EditSaveCancel-cancel')}
    </Button>
  </WrapSaveCancel>
);

EditSaveCancel.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditSaveCancel;
