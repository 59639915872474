import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '../../elements';

const SearchBar = ({ value, setValue, placeholder, ...otherProps }) => (
  <TextInput
    value={value}
    onChange={(e) => setValue(e.target.value)}
    placeholder={placeholder}
    iconRight
    icon={<i className="ri-search-line" />}
    {...otherProps}
  />
);

SearchBar.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

SearchBar.defaultProps = {
  placeholder: '',
};

export default SearchBar;
