import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';
import { Span } from '../../elements/index';

const DateDisplay = styled(Span)`
  margin-right: 5px;
`;

const FormattedDateTime = ({ value, showDate }) => {
  const dateValue = new Date(value);
  const today = new Date();
  const isSameDate =
    dateValue.getFullYear() === today.getFullYear() &&
    dateValue.getMonth() === today.getMonth() &&
    dateValue.getDate() === today.getDate();

  if (isSameDate && !showDate) {
    return <Span>{i18n.strftime(dateValue, '%l:%M %p')}</Span>;
  }

  return (
    <span>
      <DateDisplay>{i18n.strftime(dateValue, '%-m/%-d/%Y')}</DateDisplay>
      <span>&nbsp;</span>
      <Span>{i18n.strftime(dateValue, '%l:%M %p')}</Span>
    </span>
  );
};

FormattedDateTime.propTypes = {
  value: PropTypes.string.isRequired,
  showDate: PropTypes.bool,
};

FormattedDateTime.defaultProps = {
  showDate: false,
};

export default FormattedDateTime;
