import React from 'react';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import THEMES from '../../../../styles/themes/app';
import { TextInput, TextArea } from '../../../../elements';
import CustomerSlideoutBottomFooter from '../../CustomerSlideoutBottomFooter';

const SectionContainer = styled.div`
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
`;

const Footer = styled.div`
  background: ${THEMES.BACKGROUND_SECONDARY};
  width: 100%;
  min-height: 60px;
  position: absolute;
  left: 0;
  bottom: 0px;
  box-shadow: ${THEMES.BOX_SHADOW};
`;

const FooterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
`;

const BottomPadding = styled.div`
  padding-bottom: 60px;
`;

const CustomerAccountEditContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const CustomerAccountEditDetails = ({
  accountDetailProps,
  mutationLoading,
  submitDisabled,
  onSubmit,
  handleClickOnMode,
  focus,
  handleSetFocus,
}) => {
  const { handlePhoneNumberChange, fields, updateField } = accountDetailProps;
  const { name, accountNumber, phoneNumber, email, notes } = fields;
  const handleNameChange = (e) => {
    const value = e.target.value;
    updateField({ name: 'name', value });
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    updateField({ name: 'email', value });
  };

  const handleAccountNumberChange = (e) => {
    const value = e.target.value;
    updateField({ name: 'accountNumber', value });
  };

  const handleNotesChange = (e) => {
    const value = e.target.value;
    updateField({ name: 'notes', value });
  };

  return (
    <CustomerAccountEditContainer>
      <SectionContainer>
        <TextInput
          label={i18n.t('slideouts-CreateCustomerForm-companyName')}
          value={name?.value}
          error={name.error}
          onChange={handleNameChange}
        />
      </SectionContainer>
      <SectionContainer>
        <TextInput
          label={i18n.t('slideouts-CreateCustomerForm-accountNumber')}
          value={accountNumber?.value}
          error={accountNumber.error}
          onChange={handleAccountNumberChange}
          autoFocus={focus}
          onBlur={() => handleSetFocus(false)}
        />
      </SectionContainer>
      <SectionContainer>
        <TextInput
          label={i18n.t('slideouts-CreateCustomerForm-companyEmail')}
          value={email?.value}
          error={email?.error}
          onChange={handleEmailChange}
        />
      </SectionContainer>
      <SectionContainer>
        <TextInput
          label={i18n.t('slideouts-CreateCustomerForm-companyPhone')}
          value={phoneNumber?.otherProps?.text}
          error={phoneNumber?.error}
          onChange={(e) => handlePhoneNumberChange(e)}
        />
      </SectionContainer>
      <SectionContainer>
        <BottomPadding>
          <TextArea
            label={i18n.t('slideouts-CustomerInfo-notes')}
            value={notes?.value}
            onChange={handleNotesChange}
            rows={4}
          />
        </BottomPadding>
      </SectionContainer>
      <Footer>
        <FooterContainer>
          <CustomerSlideoutBottomFooter
            handleClickOnMode={handleClickOnMode}
            mutationLoading={mutationLoading}
            submitDisabled={submitDisabled}
            onSubmit={onSubmit}
          />
        </FooterContainer>
      </Footer>
    </CustomerAccountEditContainer>
  );
};

CustomerAccountEditDetails.propTypes = {
  accountDetailProps: PropTypes.object.isRequired,
  mutationLoading: PropTypes.bool.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleClickOnMode: PropTypes.func.isRequired,
  focus: PropTypes.bool.isRequired,
  handleSetFocus: PropTypes.func.isRequired,
};

export default CustomerAccountEditDetails;
