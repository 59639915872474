import { useMutation } from "@apollo/client";
import IGNORE_THREADS_MUTATION from "../../../graphql/mutation/IgnoreMessageMutation.graphql";

/**
 * Options for ignoring a thread
 *
 * @enum {string} IgnoreReason
 */
const IgnoreReason = {
  SPAM: "SPAM",
  NO_REPLY_NEEDED: "NO_REPLY_NEEDED",
  ACCIDENTALLY_SENT: "ACCIDENTALLY_SENT",
  AUTOMATED: "AUTOMATED",
};

/**
 * @typedef IgnoreThreadMutationParams
 * @type {object}
 * @property {import("@apollo/client").ApolloClient} client Apollo client instance
 * @property {import("react-i18next").TFunction} i18n Optional, options for display in dropdown of IgnoreThread modal (should be moved to modal component)
 */

/**
 * useIgnoreThreadMutation
 * Get handlers for ignoring a thread
 *
 * @param {IgnoreThreadMutationParams} param0
 * @returns {{
 *   handleIgnoreThread: (params: IgnoreThreadParams) => void,
 *   options: { value: string, label: string }[],
 *   IgnoreReason: IgnoreReason
 * }}
 */
const useIgnoreThreadMutation = ({ client, i18n = false }) => {
  const [ignoreThread] = useMutation(IGNORE_THREADS_MUTATION, { client });
  const options = i18n
    ? [
        { value: IgnoreReason.SPAM, label: i18n.t("threads-Ignore-spam") },
        {
          value: IgnoreReason.NO_REPLY_NEEDED,
          label: i18n.t("threads-ignoreThread-noNeedResponse"),
        },
        {
          value: IgnoreReason.ACCIDENTALLY_SENT,
          label: i18n.t("threads-ignoreThread-mistake"),
        },
        {
          value: IgnoreReason.AUTOMATED,
          label: i18n.t("threads-ignoreThread-automated"),
        },
      ]
    : [];

  /**
   * @typedef IgnoreThreadParams
   * @type {object}
   * @property {string} messageId
   * @property {IgnoreReason} ignoreReason
   * @property {Function} onError
   * @property {Function} onSuccess
   */

  /**
   * handleIgnoreThread
   * Ignore a thread based on the given message ID
   *
   * @param {IgnoreThreadParams} param0
   * @returns {void}
   */
  const handleIgnoreThread = async ({
    messageId,
    ignoreReason,
    onError = () => {},
    onSuccess = () => {},
  }) => {
    const { data } = await ignoreThread({
      variables: {
        input: {
          messageId,
          ignoreReason,
        },
      },
    }).catch((err) => {
      console.error(err);
      onError(err);
    });

    if (data?.ignoreMessage?.errors?.length) {
      onError(data.ignoreMessage.errors[0]);
    } else {
      onSuccess();
    }
  };

  return {
    handleIgnoreThread,
    options,
    IgnoreReason,
  };
};

export default useIgnoreThreadMutation;
