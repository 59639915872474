import { useMutation } from "@apollo/client";
import BlockChannelMutation from "../../../../graphql/mutation/BlockChannelMutation.graphql";

/*
DOCUMENTATION

this hook is used to block a channel

args:
  client: (obj) required. apolloClient instance
  address: (str) the number or email to block
  onError: (func) optional. callback that will fire on error
  onSuccess: (func) optional. callback that will fire on success

returns:
  blockChannel: (func) will send mutation to block the channel
*/

function useBlockChannel({ client, address, onError, onSuccess }) {
  const [blockChannelMutation] = useMutation(BlockChannelMutation, { client });

  const blockChannel = async () => {
    const blockData = await blockChannelMutate({ onError });

    if (!blockData) {
      onError();
      return;
    }

    onSuccess();
  };

  const blockChannelMutate = async () => {
    const data = await blockChannelMutation({
      variables: {
        address,
      },
    }).catch(console.warn);

    if (!data?.data?.blockChannel?.errors) return true;
    return false;
  };

  return {
    blockChannel,
  };
}

export default useBlockChannel;
