import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18n-js';
import { isAnimatedGif } from 'client-lib/src/lib/utils/helpers';
import { Button, Anchor, Loading, Modal } from '../../elements';
import {
  openAnnotationsModal,
  pushAttachment,
  setAnnotationAttachmentIndex,
} from '../../actions/uploadModal';

const FullPhotoWrapper = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 70vh;
  cursor: move;
`;
const FullPhoto = styled.img`
  margin: ${({ attachmentLoaded }) => (attachmentLoaded ? 'auto' : '0')};
  transition: 0.25s ease-in-out;
  width: auto;
  min-height: ${({ height }) => `${height}%`};
  transform: ${({ rotation }) => `rotate(${rotation}deg)`};
`;

const SpinnerWrapper = styled.div`
  margin: auto;
  position: absolute;
  top: 45%;
  left: 45%;
  z-index: 99;
`;

const PhotoFooter = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 10%;
  width: 100%;
  margin: 0.5rem;
`;
const Controls = styled.div`
  display: flex;
  width: 50%;
`;

const AnnotateButton = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-start;
`;

const ModalContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const AnchorWrapper = styled.div`
  margin-left: auto;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const AttachmentAnchor = styled(Anchor)`
  :hover,
  :visited,
  :active {
    text-decoration: none;
  }
`;
const DownloadIcon = styled.i`
  font-size: 28px;
`;

const ButtonWrapper = styled.div`
  margin-left: 8px;
  margin-right: 8px;
`;

const PhotoModal = ({
  openAttachment,
  closeAttachment,
  attachmentLoaded,
  handleAttachmentLoaded,
  allowAnnotateAndReply,
}) => {
  const imgRef = useRef();
  const imgElement = useRef();

  const [height, setHeight] = useState(100);
  const [width, setWidth] = useState(100);
  const [rotation, setRotation] = useState(0);
  const [isGifAttachment, setIsGifAttachment] = useState(false);

  const attachmentsLen = useSelector(
    (state) => state.uploadModal.attachments.length
  );

  // sendAttachmentAsLink={sendAttachmentAsLink}
  // onMethodChange={handleAttachmentMethodChange}

  const [photoWrapper, setPhotoWrapper] = useState({
    dragging: false,
    elHeight: null,
    elWidth: null,
    startX: null,
    startY: null,
    scrollX: null,
    scrollY: null,
    maxX: null,
    maxY: null,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    isAnimatedGif(
      `/attachments/${openAttachment.id}/original/${openAttachment.originalFilename}`,
      (isAnimated) => setIsGifAttachment(isAnimated)
    );
  }, [openAttachment.id]);

  useEffect(() => {
    window.addEventListener('mousedown', onDragStart);
    window.addEventListener('mousemove', onDragMove);
    window.addEventListener('mouseup', onDragStop);

    return () => {
      window.removeEventListener('mousedown', onDragStart);
      window.removeEventListener('mousemove', onDragMove);
      window.removeEventListener('mouseup', onDragStop);
    };
  });

  const onDragStart = (e) => {
    // We want to be able to pan around inside the container even when the
    // mouse is on the outside of the element (as long as the mouse button
    // is still being pressed) - this is why we're attaching to the window
    // window.addEventListener('mousemove', onDragMove);
    // window.addEventListener('mouseup', onDragStop);

    // If we have multiple child nodes, use the scroll[Height/Width]
    // If we have no child-nodes, use bounds to find size of inner content
    let bounds;
    const target = e.currentTarget || e.target;

    if (!target?.childNodes) return;

    if (target.childNodes.length > 0) {
      bounds = { width: target.scrollWidth, height: target.scrollHeight };
    } else {
      bounds = e.target.getBoundingClientRect();
    }

    // Find start position of drag based on mouse coordinates
    const startX =
      typeof e.clientX === 'undefined'
        ? e.changedTouches[0].clientX
        : e.clientX;
    const startY =
      typeof e.clientY === 'undefined'
        ? e.changedTouches[0].clientY
        : e.clientY;

    if (imgRef?.current) {
      setPhotoWrapper({
        dragging: true,

        elHeight: imgRef.current.clientHeight,
        elWidth: imgRef.current.clientWidth,

        startX,
        startY,

        scrollX: imgRef.current.scrollLeft,
        scrollY: imgRef.current.scrollTop,

        maxX: bounds.width,
        maxY: bounds.height,
      });
    }
  };

  const onDragMove = (e) => {
    e.preventDefault();

    if (!photoWrapper.dragging) {
      return;
    }

    const x =
      typeof e.clientX === 'undefined'
        ? e.changedTouches[0].clientX
        : e.clientX;
    const y =
      typeof e.clientY === 'undefined'
        ? e.changedTouches[0].clientY
        : e.clientY;

    // Letting the browser automatically stop on scrollHeight
    // gives weird bugs where some extra pixels are showing.
    // Substracting the height/width of the container from the
    // inner content seems to do the trick.
    imgRef.current.scrollLeft = Math.min(
      photoWrapper.maxX - photoWrapper.elWidth,
      photoWrapper.scrollX - (x - photoWrapper.startX)
    );
    imgRef.current.scrollTop = Math.min(
      photoWrapper.maxY - photoWrapper.elHeight,
      photoWrapper.scrollY - (y - photoWrapper.startY)
    );
  };

  const onDragStop = () => {
    setPhotoWrapper({ ...photoWrapper, dragging: false });
  };

  const zoomIn = () => {
    setWidth(width === 500 ? 500 : width + 10);
    setHeight(height === 500 ? 500 : height + 10);
  };

  const zoomOut = () => {
    setWidth(width === 20 ? 20 : width - 10);
    setHeight(height === 20 ? 20 : height - 10);
  };

  const onMouseWheel = (e) => {
    if (e.deltaY > 0) {
      zoomIn(10);
    } else if (e.deltaY < 0) {
      zoomOut(10);
    }
  };

  const rotateRight = () => {
    setRotation(rotation + 90);
  };

  const rotateLeft = () => {
    setRotation(rotation - 90);
  };

  return (
    <Modal
      isOpen={openAttachment !== null}
      onRequestClose={closeAttachment}
      size="lg"
      modalTitle={i18n.t('modals-PhotoModal-photoAttachment')}
    >
      <ModalContentsWrapper>
        <FullPhotoWrapper
          ref={imgRef}
          onMouseDown={onDragStart}
          onWheel={onMouseWheel}
          onDragStart={(e) => {
            e.preventDefault();
          }}
          data-testid="photo-modal"
        >
          {attachmentLoaded ? null : (
            <SpinnerWrapper>
              <Loading size="sm" contrast="high" />
            </SpinnerWrapper>
          )}
          <FullPhoto
            ref={imgElement}
            originalHeight={0}
            originialWidth={0}
            attachmentLoaded={attachmentLoaded}
            width={width}
            height={height}
            rotation={rotation}
            src={`/attachments/${openAttachment.id}/original/${openAttachment.originalFilename}`}
            onLoad={handleAttachmentLoaded}
          />
        </FullPhotoWrapper>
        <PhotoFooter>
          <Controls>
            <ButtonWrapper>
              <Button type="secondary" onClick={zoomOut}>
                <i className="ri-zoom-out-line" aria-hidden="true" />
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button type="secondary" onClick={zoomIn}>
                <i className="ri-zoom-in-line" aria-hidden="true" />
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button type="secondary" onClick={rotateLeft}>
                <i className="ri-arrow-go-back-line" aria-hidden="true" />
              </Button>
            </ButtonWrapper>
            <ButtonWrapper>
              <Button type="secondary" onClick={rotateRight}>
                <i className="ri-arrow-go-forward-line" aria-hidden="true" />
              </Button>
            </ButtonWrapper>
          </Controls>
          {attachmentLoaded && allowAnnotateAndReply && !isGifAttachment ? (
            <AnnotateButton>
              <Button
                iconAndText
                iconLeft
                type="secondary"
                disabled={false}
                onClick={() => {
                  dispatch(pushAttachment(openAttachment));
                  dispatch(setAnnotationAttachmentIndex(attachmentsLen));
                  closeAttachment();
                  dispatch(openAnnotationsModal());
                }}
              >
                <i className="ri-markup-line" />
                {i18n.t('modals-PhotoModal-annotateReply')}
              </Button>
            </AnnotateButton>
          ) : (
            ''
          )}
          <AnchorWrapper>
            <AttachmentAnchor
              href={`/attachments/${openAttachment.id}/original/${openAttachment.originalFilename}`}
              download={openAttachment.originalFilename}
            >
              <DownloadIcon className="ri-download-2-fill" />
            </AttachmentAnchor>
          </AnchorWrapper>
        </PhotoFooter>
      </ModalContentsWrapper>
    </Modal>
  );
};

PhotoModal.propTypes = {
  openAttachment: PropTypes.object.isRequired,
  closeAttachment: PropTypes.func.isRequired,
  attachmentLoaded: PropTypes.bool,
  handleAttachmentLoaded: PropTypes.func.isRequired,
  threadId: PropTypes.string.isRequired,
  allowAnnotateAndReply: PropTypes.bool,
};

PhotoModal.defaultProps = {
  attachmentLoaded: false,
  allowAnnotateAndReply: false,
};

export default PhotoModal;
