import { useState } from "react";
import { useMutation } from "@apollo/client";
import useForm from "../../../../utils/hooks/useForm";
import createCustomerAccountMutation from "../../../../graphql/mutation/CreateCustomerAccountMutation.graphql";
import createCustomerContactMutation from "../../../../graphql/mutation/CreateCustomerContactMutation.graphql";
import updateCustomerContactMutation from "../../../../graphql/mutation/UpdateCustomerContactMutation.graphql";
import mergeCustomerContactsMutation from "../../../../graphql/mutation/MergeCustomerContactsMutation.graphql";
import CONTACTS_QUERY from "../../../../graphql/query/ContactsQuery.graphql";
import {
  COMMON_NORMALIZERS,
  contactName,
  sortLabels,
} from "../../../../utils/helpers";
import SEARCH_CUSTOMERS_VALIDATE_QUERY from "../../../../graphql/query/SearchCustomersQuery.graphql";
import SEARCH_FAX_CONTACTS_QUERY from "../../../../graphql/query/SearchFaxContactsQuery.graphql";
import useAccountQuery from "../../../../api/query/useAccountQuery";
import {
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
} from "../../../../utils/roles";
import { emailRegex } from "../../../../utils/regex";

const defaultFormValues = {
  id: "", // id of externalContact of a thread
  firstName: "",
  lastName: "",
  jobTitle: "",
  phoneNumber: "",
  faxNumber: "",
  email: "",
  welcomeMessage: true,
  company: {
    value: "",
    text: "",
    addCompanySuggestion: false,
  },
  accountPhoneNumber: "",
  accountEmail: "",
  accountNumber: "",
  group: {
    value: [],
    text: "",
  },
  accountRep: {
    value: "",
    id: "",
    lastSelectedName: "",
    selectedUser: {},
  },
  prioritizeRep: false,
  updateContact: false,
  isMergeContact: false,
  mergeOn: "",
  mergeContactId: "",
  updateContactType: "",
  newPhoneNumber: "",
  newEmail: "",
  newFaxNumber: "",
  phoneSelectValue: "",
  labels: {
    value: [],
    text: "",
    color: "",
  },
};

const useCreateCustomer = ({
  currentUser,
  client,
  handleOnClose,
  onHandleError,
  i18n,
  initialFormValues = null,
  activeGroup,
  editContactLabelsPermissions = false,
}) => {
  const { accountPolicies, groupPolicies } = currentUser;

  let accountName = "";

  const accountRepEditPermission = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.EDIT_CUSTOMER_REP,
    accountPolicies,
    groupPolicies
  );
  const priorityNotificationsTogglePermission = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.TOGGLE_PRIORITY_NOTIFICATIONS,
    accountPolicies,
    groupPolicies
  );

  const { data } = useAccountQuery({
    client,
    variables: { id: currentUser.accountId },
  });

  const [createCustomerAccount, { loading: createCustomerAccountLoading }] =
    useMutation(createCustomerAccountMutation, {
      client,
    });

  const [createCustomerContact, { loading: createCustomerContactLoading }] =
    useMutation(createCustomerContactMutation, {
      client,
      refetchQueries: [
        {
          query: CONTACTS_QUERY,
          variables: { first: 25, after: null },
        },
      ],
    });

  const [updateCustomerContact] = useMutation(updateCustomerContactMutation, {
    client,
    refetchQueries: [
      {
        query: CONTACTS_QUERY,
        variables: { first: 25, after: null },
      },
    ],
  });

  const [mergeCustomerContacts] = useMutation(mergeCustomerContactsMutation, {
    client,
  });

  if (data && data.account && data.account.name) {
    accountName = data.account.name;
  }

  const validationOverrideAction = (field, fields, currentValidity) => {
    if (
      fields &&
      fields.company &&
      fields.company.value &&
      fields.company.otherProps &&
      fields.company.otherProps.addCompanySuggestion
    ) {
      field.setError("");
      return true;
    }

    return currentValidity;
  };

  const requiredValidation = {
    type: "required",
    errorMessage: i18n.t("slideouts-CreateCustomerForm-required"),
  };
  const phoneNumberValidation = {
    type: "phoneFormat",
    errorMessage: i18n.t("slideouts-CreateCustomerForm-invalidPhone"),
  };
  const faxNumberValidation = {
    type: "phoneFormat",
    errorMessage: i18n.t("slideouts-CreateCustomerForm-invalidFax", {
      defaultValue: "Invalid fax number format.",
    }),
  };
  const emailAddresValidation = {
    type: "emailFormat",
    errorMessage: i18n.t("slideouts-CreateCustomerForm-invalidEmail"),
  };
  const firstNameMinLengthValidation = {
    type: "minLength",
    minLength: 2,
    errorMessage: i18n.t("slideouts-CreateCustomerForm-firstNameError"),
    optional: true,
  };

  const lastNameMinLengthValidation = {
    type: "minLength",
    minLength: 2,
    errorMessage: i18n.t("slideouts-CreateCustomerForm-lastNameError"),
    optional: true,
  };
  const firstNameMaxLengthValidation = {
    type: "maxLength",
    maxLength: 50,
    errorMessage: i18n.t("slideouts-CreateCustomerForm-firstNameMaxError"),
    optional: true,
  };

  const lastNameMaxLengthValidation = {
    type: "maxLength",
    maxLength: 50,
    errorMessage: i18n.t("slideouts-CreateCustomerForm-lastNameMaxError"),
    optional: true,
  };

  const jobTitleMaxLengthValidation = {
    type: "maxLength",
    maxLength: 255,
    errorMessage: i18n.t("slideouts-CreateCustomerForm-jobTitleError"),
    optional: true,
  };

  const accountNumberValidation = {
    type: "maxLength",
    maxLength: 255,
    errorMessage: i18n.t("slideouts-CreateCustomerForm-acctLengthError"),
    optional: true,
  };

  const initialValues = {
    id: {
      // id is used to determine if updateCustomerContact mutation or createCustomerContact mutation should be triggered
      // if id exists then update otherwise create
      value: initialFormValues?.id
        ? initialFormValues.id
        : defaultFormValues.id,
    },
    firstName: {
      value: initialFormValues?.firstName
        ? initialFormValues.firstName
        : defaultFormValues.firstName,
      validations: [
        requiredValidation,
        firstNameMinLengthValidation,
        firstNameMaxLengthValidation,
      ],
      otherProps: {
        validationOverrideAction,
      },
    },
    lastName: {
      value: initialFormValues?.lastName
        ? initialFormValues.lastName
        : defaultFormValues.lastName,
      validations: [
        requiredValidation,
        lastNameMinLengthValidation,
        lastNameMaxLengthValidation,
      ],
      otherProps: {
        validationOverrideAction,
      },
    },
    jobTitle: {
      value: initialFormValues?.jobTitle
        ? initialFormValues.jobTitle
        : defaultFormValues.jobTitle,
      optional: true,
      validations: [jobTitleMaxLengthValidation],
    },
    phoneNumber: {
      value: initialFormValues?.phoneNumber
        ? initialFormValues.phoneNumber
        : defaultFormValues.phoneNumber,
      otherProps: {
        text: initialFormValues?.phoneNumber
          ? COMMON_NORMALIZERS.normalizePhone(initialFormValues.phoneNumber)
          : defaultFormValues.phoneNumber,
      },
      validations: [phoneNumberValidation],
      optional: true,
    },
    faxNumber: {
      value: initialFormValues?.faxNumber
        ? initialFormValues.faxNumber
        : defaultFormValues.faxNumber,
      otherProps: {
        text: initialFormValues?.faxNumber
          ? COMMON_NORMALIZERS.normalizePhone(initialFormValues.faxNumber)
          : defaultFormValues.faxNumber,
      },
      validations: [faxNumberValidation],
      optional: true,
    },
    email: {
      value: initialFormValues?.email
        ? initialFormValues.email
        : defaultFormValues.email,
      validations: [emailAddresValidation],
      optional: true,
    },
    welcomeMessage: {
      value: initialFormValues?.welcomeMessage
        ? initialFormValues.welcomeMessage
        : defaultFormValues.welcomeMessage,
    },
    company: {
      value: initialFormValues?.company
        ? initialFormValues.company.value
        : defaultFormValues.company.value,
      otherProps: {
        name: initialFormValues?.company
          ? initialFormValues.company.text
          : defaultFormValues.company.text,
        addCompanySuggestion: initialFormValues?.company
          ? initialFormValues.company.addCompanySuggestion
          : defaultFormValues.company.addCompanySuggestion,
      },
      showDependencies: (field) => field.otherProps.addCompanySuggestion,
    },
    accountPhoneNumber: {
      value: initialFormValues?.accountPhoneNumber
        ? initialFormValues.accountPhoneNumber
        : defaultFormValues.accountPhoneNumber,
      otherProps: {
        text: initialFormValues?.accountPhoneNumber
          ? COMMON_NORMALIZERS.normalizePhone(
              initialFormValues.accountPhoneNumber
            )
          : defaultFormValues.accountPhoneNumber,
      },
      depends: "company",
      validations: [phoneNumberValidation],
      optional: true,
    },
    accountEmail: {
      value: initialFormValues?.accountEmail
        ? initialFormValues.accountEmail
        : defaultFormValues.accountEmail,
      depends: "company",
      validations: [emailAddresValidation],
      optional: true,
    },
    accountNumber: {
      value: initialFormValues?.accountNumber
        ? initialFormValues.accountNumber
        : defaultFormValues.accountNumber,
      depends: "company",
      validations: [accountNumberValidation],
      optional: true,
    },
    group: {
      // value: [activeGroup],
      value:
        initialFormValues?.group?.value.length > 0
          ? initialFormValues?.group?.value
          : activeGroup
          ? [activeGroup]
          : defaultFormValues.group.value,
      otherProps: {
        text: initialFormValues?.group
          ? initialFormValues.group.text
          : defaultFormValues.group.text,
      },
      validations: [requiredValidation],
    },
    accountRep: {
      // in this case, the value is what is being shown in the input. The rest of the inputs are the opposite of this, but I feel it's a cleaner way to write the code in this case.
      value: initialFormValues?.accountRep
        ? initialFormValues?.accountRep.value
        : defaultFormValues.accountRep.value,
      otherProps: {
        id: initialFormValues?.accountRep
          ? initialFormValues?.accountRep?.id
          : defaultFormValues?.accountRep.id,
        lastSelectedName: initialFormValues?.accountRep
          ? initialFormValues?.accountRep?.lastSelectedName
          : defaultFormValues?.accountRep.lastSelectedName,
        selectedUser: initialFormValues?.accountRep
          ? initialFormValues?.accountRep?.selectedUser
          : defaultFormValues?.accountRep.selectedUser,
      },
      validations: [],
    },
    prioritizeRep: {
      value: initialFormValues?.prioritizeRep
        ? initialFormValues.prioritizeRep
        : defaultFormValues.prioritizeRep,
    },
    updateContact: {
      value: initialFormValues?.updateContact
        ? initialFormValues.updateContact
        : defaultFormValues.updateContact,
      optional: true,
    },
    updateContactType: {
      value: initialFormValues?.updateContactType
        ? initialFormValues.updateContactType
        : defaultFormValues.updateContactType,
      optional: true,
    },
    isMergeContact: {
      value: initialFormValues?.isMergeContact
        ? initialFormValues.isMergeContact
        : defaultFormValues.isMergeContact,
      optional: true,
    },
    mergeContactId: {
      value: initialFormValues?.mergeContactId
        ? initialFormValues.mergeContactId
        : defaultFormValues.mergeContactId,
    },
    mergeOn: {
      value: initialFormValues?.mergeOn
        ? initialFormValues.mergeOn
        : defaultFormValues.mergeOn,
      optional: true,
    },
    newPhoneNumber: {
      value: initialFormValues?.newPhoneNumber
        ? initialFormValues.newPhoneNumber
        : defaultFormValues.newPhoneNumber,
      otherProps: {
        text: initialFormValues?.newPhoneNumber
          ? COMMON_NORMALIZERS.normalizePhone(initialFormValues.newPhoneNumber)
          : defaultFormValues.newPhoneNumber,
      },
      validations: [phoneNumberValidation],
      optional: true,
    },
    newFaxNumber: {
      value: initialFormValues?.newFaxNumber
        ? initialFormValues.newFaxNumber
        : defaultFormValues.newFaxNumber,
      otherProps: {
        text: initialFormValues?.newFaxNumber
          ? COMMON_NORMALIZERS.normalizePhone(initialFormValues.newFaxNumber)
          : defaultFormValues.newFaxNumber,
      },
      validations: [faxNumberValidation],
      optional: true,
    },
    newEmail: {
      value: initialFormValues?.newEmail
        ? initialFormValues.newEmail
        : defaultFormValues.newEmail,
      validations: [emailAddresValidation],
      optional: true,
    },
    phoneSelectValue: {
      value: initialFormValues?.phoneSelectValue
        ? initialFormValues?.phoneSelectValue
        : defaultFormValues.phoneSelectValue,
      otherProps: {
        text: initialFormValues?.phoneSelectValue
          ? COMMON_NORMALIZERS.normalizePhone(
              initialFormValues.phoneSelectValue
            )
          : defaultFormValues.phoneSelectValue,
      },
      optional: true,
    },
    faxSelectValue: {
      value: initialFormValues?.faxSelectValue
        ? initialFormValues?.faxSelectValue
        : defaultFormValues.faxSelectValue,
      otherProps: {
        text: initialFormValues?.faxSelectValue
          ? COMMON_NORMALIZERS.normalizePhone(initialFormValues.faxSelectValue)
          : defaultFormValues.faxSelectValue,
      },
      optional: true,
    },
    emailSelectValue: {
      value: initialFormValues?.emailSelectValue
        ? initialFormValues?.emailSelectValue
        : defaultFormValues.emailSelectValue,
      otherProps: {
        text: initialFormValues?.emailSelectValue
          ? initialFormValues.emailSelectValue
          : defaultFormValues.emailSelectValue,
      },
      optional: true,
    },
    labels: {
      value:
        initialFormValues?.labels?.value.length > 0
          ? initialFormValues?.labels?.value
          : defaultFormValues.labels.value,
      otherProps: {
        text: initialFormValues?.labels
          ? initialFormValues.labels.text
          : defaultFormValues.labels.text,
      },
    },
  };

  const [existingPhoneNumberId, setExistingPhoneNumberId] = useState("");
  const [existingFaxNumberId, setExistingFaxNumberId] = useState("");
  const [existingEmailId, setExistingEmailId] = useState("");

  const { fields, updateField, onSubmit, isVisitedForm } = useForm(
    initialValues,
    async (currentFields) => {
      const {
        id,
        firstName,
        lastName,
        jobTitle,
        email,
        phoneNumber,
        faxNumber,
        company,
        group,
        welcomeMessage,
        accountRep,
        prioritizeRep,
        phoneSelectValue,
        emailSelectValue,
        faxSelectValue,
        isMergeContact,
        mergeContactId,
        labels,
      } = currentFields;

      let customerContactResponse;
      const customerContactValues = {
        id: id.value,
        firstName: firstName.value,
        lastName: lastName.value,
        jobTitle: jobTitle.value,
        email: email.value,
        phoneNumber: phoneNumber.value,
        faxNumber: faxNumber.value,
        company: company.value,
        group: group.value,
        welcomeMessage: welcomeMessage.value,
        repUserId: accountRep.otherProps.id,
        prioritizeRep: prioritizeRep.value,
        labels: labels.value,
      };

      const mergeContactValues = {
        contactToKeepId: id.value,
        contactToDeleteId: mergeContactId.value,
        firstName: firstName.value,
        lastName: lastName.value,
        jobTitle: jobTitle.value,
        prioritizeRep: prioritizeRep.value,
        repUserId: accountRep.otherProps.id,
        group: group.value,
        phoneNumber: phoneNumber.value,
        faxNumber: faxNumber.value,
        emailAddress: email.value,
        labels: labels.value,
      };

      // Validate non existance of phone or fax number to execute query.
      if (!existingPhoneNumberId && !existingFaxNumberId && !existingEmailId) {
        if (currentFields.company.otherProps.addCompanySuggestion) {
          const { company, accountEmail, accountNumber, accountPhoneNumber } =
            currentFields;

          const customerAccountValues = {
            name: company.value,
            phoneNumber: accountPhoneNumber.value,
            emailAddress: accountEmail.value,
            accountNumber: accountNumber.value,
          };
          const customerAccountResponse = await createAccount(
            customerAccountValues
          );

          if (customerAccountResponse?.createCustomerAccount?.errors) {
            onHandleError(i18n.t("slideouts-CreateCustomerForm-genericError"));
          } else if (
            !firstName.value &&
            !lastName.value &&
            !isMergeContact.value
          ) {
            // If both fields are blank, we are creating JUST a company
            handleMutationResponse(
              customerAccountResponse.createCustomerAccount.customerAccount,
              customerAccountResponse.createCustomerAccount.errors
            );
          } else if (id.value && !isMergeContact.value) {
            // If there is an external contact id, then run updateContact mutation
            customerContactResponse = await updateContact(
              customerContactValues,
              customerAccountResponse.createCustomerAccount.customerAccount.id
            );
            handleMutationResponse(
              customerContactResponse.updateCustomerContact.customerContact,
              customerContactResponse.updateCustomerContact.errors
            );
          } else if (isMergeContact.value) {
            if (
              phoneSelectValue.value &&
              phoneNumber.value !== phoneSelectValue.value
            ) {
              mergeContactValues.phoneNumber = phoneSelectValue.value;
            }
            if (
              faxSelectValue.value &&
              faxNumber.value !== faxSelectValue.value
            ) {
              mergeContactValues.faxNumber = faxSelectValue.value;
            }
            if (
              emailSelectValue.value &&
              email.value !== emailSelectValue.value
            ) {
              mergeContactValues.emailAddress = emailSelectValue.value;
            }

            customerContactResponse = await mergeContacts(
              mergeContactValues,
              customerAccountResponse.createCustomerAccount.customerAccount.id
            );

            handleMutationResponse(
              customerContactResponse.mergeCustomerContacts.customerContact,
              customerContactResponse.mergeCustomerContacts.errors
            );
          } else {
            customerContactResponse = await createContact(
              customerContactValues,
              customerAccountResponse.createCustomerAccount.customerAccount.id
            );
            handleMutationResponse(
              customerContactResponse.createCustomerContact.customerContact,
              customerContactResponse.createCustomerContact.errors
            );
          }
        } else if (id.value && !isMergeContact.value) {
          // If there is an external contact id, then run updateContact mutation
          customerContactResponse = await updateContact(
            customerContactValues,
            company.value
          );
          handleMutationResponse(
            customerContactResponse.updateCustomerContact.customerContact,
            customerContactResponse.updateCustomerContact.errors
          );
        } else if (isMergeContact.value) {
          if (
            phoneSelectValue.value &&
            phoneNumber.value !== phoneSelectValue.value
          ) {
            mergeContactValues.phoneNumber = phoneSelectValue.value;
          }
          if (
            faxSelectValue.value &&
            faxNumber.value !== faxSelectValue.value
          ) {
            mergeContactValues.faxNumber = faxSelectValue.value;
          }
          if (
            emailSelectValue.value &&
            email.value !== emailSelectValue.value
          ) {
            mergeContactValues.emailAddress = emailSelectValue.value;
          }

          customerContactResponse = await mergeContacts(
            mergeContactValues,
            company.value
          );

          handleMutationResponse(
            customerContactResponse.mergeCustomerContacts.customerContact,
            customerContactResponse.mergeCustomerContacts.errors
          );
        } else {
          customerContactResponse = await createContact(
            customerContactValues,
            company.value
          );
          handleMutationResponse(
            customerContactResponse?.createCustomerContact?.customerContact,
            customerContactResponse?.createCustomerContact?.errors
          );
        }
      }
    }
  );

  const handleMutationResponse = (mutationData, mutationErrors) => {
    const jobTitleErrorField = "job_title";
    const charErrorReason = "should be at most 255 character(s)";
    if (mutationErrors !== null && mutationErrors.length > 0) {
      if (
        mutationErrors[0]?.field?.[0] === jobTitleErrorField &&
        mutationErrors[0].reason === charErrorReason
      ) {
        onHandleError(i18n.t("slideouts-CreateCustomerForm-jobTitleError"));
      } else {
        onHandleError(i18n.t("slideouts-CreateCustomerForm-genericError"));
      }
    } else {
      handleOnClose(mutationData?.id);
    }
  };

  const handleOnDeleteGroup = (groupToBeDeleted) => {
    const {
      group: { value, otherProps },
    } = fields;
    const newArray = value.filter(
      (selection) => selection.value !== groupToBeDeleted
    );
    updateField({
      value: newArray,
      name: "group",
      otherProps: { text: otherProps.text },
    });
  };

  const handleOnSelectGroup = (groupSelected) => {
    if (Array.isArray(groupSelected)) {
      updateField({
        value: [...groupSelected],
        name: "group",
        otherProps: { text: "" },
      });
    } else {
      const {
        group: { value },
      } = fields;
      const existentSelection = value.find(
        (selection) => selection.value === groupSelected.value
      );
      if (!existentSelection) {
        updateField({
          value: [...value, groupSelected],
          name: "group",
          otherProps: { text: "" },
        });
      }
    }
  };

  const handleOnBlurGroup = (e) => {
    const {
      group: { value },
    } = fields;
    updateField({
      value: [...value],
      name: "group",
      otherProps: { text: "" },
    });
    e.target.value = "";
  };

  const handleOnSelectLabel = (labelSelected) => {
    const {
      labels: { value },
    } = fields;

    const existentSelection = value.find(
      (selection) => selection.value === labelSelected.value
    );

    if (!existentSelection) {
      const renderLabels = value?.map((label) => {
        return { color: label.color, text: label.text, value: label.value };
      });
      const newLabels = sortLabels(renderLabels, labelSelected);
      updateField({
        value: newLabels,
        name: "labels",
        otherProps: { text: "" },
      });
    }
  };

  const handleOnDeleteLabel = (labelToBeDeleted) => {
    const {
      labels: { value, otherProps },
    } = fields;
    const newArray = value.filter(
      (selection) => selection.value !== labelToBeDeleted
    );
    updateField({
      value: newArray,
      name: "labels",
      otherProps: { text: otherProps.text },
    });
  };

  const handleOnBlurLabels = (e) => {
    const {
      labels: { value },
    } = fields;
    updateField({
      value: [...value],
      name: "labels",
      otherProps: { text: "" },
    });
    e.target.value = "";
  };

  const handlePhoneNumberChange = (ev, fieldNumberType) => {
    const desnormalizedPhone = ev.target.value.replace(/\D+/g, "");
    const otherPropsObject = {
      text: COMMON_NORMALIZERS.normalizePhone(ev.target.value),
    };

    if (fieldNumberType === "account") {
      fields.accountPhoneNumber.setValue(desnormalizedPhone);
      fields.accountPhoneNumber.setOtherProps(otherPropsObject);
    } else if (fieldNumberType === "contact") {
      fields.phoneNumber.setValue(desnormalizedPhone);
      fields.phoneNumber.setOtherProps(otherPropsObject);
      setExistingPhoneNumberId("");
      serverPhoneValidation(desnormalizedPhone);
    }
  };

  const serverPhoneValidation = async (desnormalizedPhone) => {
    if (desnormalizedPhone.length === 10) {
      const response = await client.query({
        query: SEARCH_CUSTOMERS_VALIDATE_QUERY,
        fetchPolicy: "network-only",
        variables: {
          typeFilter: "CUSTOMER_CONTACT",
          query: desnormalizedPhone.replace(/[^\d]/g, ""),
          first: 1,
        },
      });
      const matches = response.data.searchCustomers.edges;
      if (matches.length > 0 && matches?.[0]?.node?.firstName !== null) {
        setExistingPhoneNumberId(matches[0].node.id);
      }
    }
  };

  const handleFaxNumberChange = (ev) => {
    const desnormalizedFax = ev.target.value.replace(/\D+/g, "");
    const otherPropsObject = {
      text: COMMON_NORMALIZERS.normalizePhone(ev.target.value),
    };
    fields.faxNumber.setValue(desnormalizedFax);
    fields.faxNumber.setOtherProps(otherPropsObject);
    setExistingFaxNumberId("");
    serverFaxValidation(desnormalizedFax);
  };

  const serverFaxValidation = async (desnormalizedFax) => {
    if (desnormalizedFax.length === 10) {
      const response = await client.query({
        query: SEARCH_FAX_CONTACTS_QUERY,
        fetchPolicy: "network-only",
        variables: {
          query: desnormalizedFax.replace(/[^\d]/g, ""),
          first: 1,
        },
      });
      const matches = response.data.searchFaxContacts.edges;
      if (matches.length > 0 && matches?.[0]?.node?.firstName !== null) {
        setExistingFaxNumberId(matches[0].node.id);
      }
    }
  };

  const handleEmailChange = (ev) => {
    const { value } = ev.target;
    fields.email.setValue(value);
    setExistingEmailId("");
    serverEmailValidation(value);
  };

  const serverEmailValidation = async (emailValue) => {
    if (emailRegex.test(emailValue)) {
      const response = await client.query({
        query: SEARCH_CUSTOMERS_VALIDATE_QUERY,
        fetchPolicy: "network-only",
        variables: {
          query: emailValue,
          first: 1,
        },
      });
      const matches = response.data.searchCustomers.edges;
      if (matches.length > 0 && matches?.[0]?.node?.firstName !== null) {
        const exactMatch = matches.find(
          (match) =>
            match.node.emailAddress.toLowerCase() === emailValue.toLowerCase()
        );
        if (exactMatch) {
          setExistingEmailId(exactMatch.node.id);
        }
      }
    }
  };

  const handleCompanyChange = (ev) => {
    const textField = ev.target.value;
    updateField({ otherProps: { name: textField }, name: "company" });

    if (fields.accountPhoneNumber) {
      updateField({
        value: "",
        name: "accountPhoneNumber",
        otherProps: { text: "" },
        unforceVisit: true,
      });
    }
    if (fields.accountEmail) {
      updateField({ value: "", name: "accountEmail", unforceVisit: true });
    }
    if (fields.accountNumber) {
      updateField({ value: "", name: "accountNumber", unforceVisit: true });
    }
  };

  const handleOnCompanySelect = (companyObject) => {
    const { addCompanySuggestion, name, id } = companyObject;
    if (addCompanySuggestion) {
      updateField({
        value: name,
        name: "company",
        otherProps: {
          name,
          addCompanySuggestion,
        },
      });
    } else {
      updateField({
        value: id,
        name: "company",
        otherProps: { addCompanySuggestion, name },
      });
    }
  };

  const handleAccountRepSelect = (suggestion) => {
    const { id } = suggestion;

    updateField({
      name: "accountRep",
      value: contactName(suggestion),
      otherProps: {
        id,
        lastSelectedName: contactName(suggestion),
        selectedUser: suggestion,
      },
    });
  };

  const handleAccountRepDelete = () => {
    updateField({
      name: "accountRep",
      value: defaultFormValues?.accountRep?.value,
      otherProps: {
        id: defaultFormValues?.accountRep?.id,
        lastSelectedName: defaultFormValues?.accountRep?.lastSelectedName,
        selectedUser: defaultFormValues?.accountRep?.selectedUser,
      },
    });
  };

  const handleAccountRepBlur = (e) => {
    const { selectedUser, lastSelectedName, id } = fields.accountRep.otherProps;
    if (e.target.value) {
      updateField({
        name: "accountRep",
        value: lastSelectedName,
        otherProps: {
          id,
          lastSelectedName,
          selectedUser,
        },
      });
    } else {
      updateField({
        name: "accountRep",
        value: "",
        otherProps: {
          id: "",
          lastSelectedName: "",
          selectedUser: {},
        },
      });

      // prioritize rep cannot be set to true if there is no account rep
      fields.prioritizeRep.setValue(false);
    }
  };

  const createAccount = async (values) => {
    const { accountId: prokeepAccountId } = currentUser;
    const variables = {
      input: {
        ...values,
        prokeepAccountId,
      },
    };
    try {
      const response = await createCustomerAccount({ variables });
      return response.data;
    } catch (e) {
      console.warn(e);
    }

    return null;
  };

  const createContact = async (values, accountNumber) => {
    const { accountId: prokeepAccountId } = currentUser;
    const labelIds = values.labels.map((label) => label.value);
    const variables = {
      input: {
        accountId: accountNumber,
        firstName: values.firstName,
        lastName: values.lastName,
        jobTitle: values.jobTitle,
        phoneNumber: values.phoneNumber,
        emailAddress: values.email,
        faxNumber: values.faxNumber,
        sendWelcomeMessage: values.welcomeMessage,
        prokeepAccountId,
        groupIds: values.group.map((group) => group.value),
        repUserId: values.repUserId,
        prioritizeRep: values.prioritizeRep,
        labelIds,
      },
    };
    if (editContactLabelsPermissions) {
      variables.input.labelIds = values.labels.map((label) => label.value);
    }

    if (!priorityNotificationsTogglePermission)
      delete variables.input.prioritizeRep;
    if (!accountRepEditPermission) delete variables.input.repUserId;

    try {
      const response = await createCustomerContact({ variables });
      return response.data;
    } catch (e) {
      onHandleError();
      console.warn(e);
    }

    return null;
  };

  /**
   * updateContact is almost identical to createContact
   * however note the different variables and the way prokeepAccountId is replaced
   * with the customerContact id.
   * The main use case for this is when a contact is being "created" from an incoming thread
   * of an unknown number
   */
  const updateContact = async (values, accountNumber) => {
    const labelIds = values.labels.map((label) => label.value);

    const variables = {
      input: {
        id: values.id,
        accountId: accountNumber,
        firstName: values.firstName,
        lastName: values.lastName,
        jobTitle: values.jobTitle,
        phoneNumber: values.phoneNumber,
        faxNumber: values.faxNumber,
        emailAddress: values.email,
        repUserId: values.repUserId,
        prioritizeRep: values.prioritizeRep,
        labelIds,
        sendWelcomeMessage: values.welcomeMessage,
        groupIds: values.group.map((group) => group.value),
      },
    };

    if (editContactLabelsPermissions) {
      variables.input.labelIds = values.labels.map((label) => label.value);
    }

    if (!priorityNotificationsTogglePermission)
      delete variables.input.prioritizeRep;
    if (!accountRepEditPermission) delete variables.input.repUserId;

    try {
      const response = await updateCustomerContact({ variables });
      return response.data;
    } catch (e) {
      console.warn(e);
    }

    return null;
  };

  const mergeContacts = async (values, accountNumber) => {
    const labelIds = values.labels.map((label) => label.value);
    const variables = {
      input: {
        contactToKeepId: values.contactToKeepId,
        contactToDeleteId: values.contactToDeleteId,
        firstName: values.firstName,
        lastName: values.lastName,
        jobTitle: values.jobTitle,
        prioritizeRep: values.prioritizeRep,
        repUserId: values.repUserId,
        emailAddress: values.emailAddress,
        phoneNumber: values.phoneNumber,
        faxNumber: values.faxNumber,
        companyId: accountNumber,
        labelIds,
      },
    };

    if (editContactLabelsPermissions) {
      variables.input.labelIds = values.labels.map((label) => label.value);
    }

    if (!priorityNotificationsTogglePermission)
      delete variables.input.prioritizeRep;
    if (!accountRepEditPermission) delete variables.input.repUserId;

    try {
      const response = await mergeCustomerContacts({ variables });
      return response.data;
    } catch (e) {
      console.warn(e);
    }

    return null;
  };

  return {
    onSubmit,
    handleCompanyChange,
    isVisitedForm,
    handleOnCompanySelect,
    handleAccountRepSelect,
    handleAccountRepDelete,
    handleAccountRepBlur,
    handlePhoneNumberChange,
    handleFaxNumberChange,
    handleEmailChange,
    handleOnSelectGroup,
    handleOnBlurGroup,
    handleOnDeleteGroup,
    handleOnSelectLabel,
    handleOnDeleteLabel,
    handleOnBlurLabels,
    fields,
    updateField,
    existingPhoneNumberId,
    existingFaxNumberId,
    existingEmailId,
    accountName,
    accountRepEditPermission,
    priorityNotificationsTogglePermission,
    createMutationLoading:
      createCustomerAccountLoading || createCustomerContactLoading,
  };
};

export default useCreateCustomer;
