import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
  PK_TYPENAMES,
} from 'client-lib';
import { isCustomerContactUnknown } from 'client-lib/src/lib/utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import THEMES from '../../styles/themes/app';
import CustomerContactHeader from './EntityHeaders/CustomerContactHeader';
import CustomerAccountHeader from './EntityHeaders/CustomerAccountHeader';
import UserHeader from './EntityHeaders/UserHeader';
import GroupHeader from './EntityHeaders/GroupHeader';
import RouteFilter from '../Common/RouteFilter';
import CloseThreadButton from '../Threads/CloseThreadButton';
import IgnoreThreadButton from './HeaderComponents/IgnoreThreadButton';
import HeaderOptionsButton from './HeaderComponents/HeaderOptionsButton';
import BlockButton from './HeaderComponents/BlockButton';
import CreateContactOptionButton from './HeaderComponents/CreateContactOptionButton';
import {
  setActiveCustomerInfoSlideout,
  setActiveCustomerSource,
  setContactSlideoutAutofocusField,
} from '../../actions/general';
import useGetUserAccountPolicies from '../../hooks/customer/useGetUserAccountPolicies';

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  max-height: 75px;
  min-height: 75px;
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
  font-family: 'Barlow', sans-serif;
  padding-right: 16px;
  @media print {
    visibility: visible;
    position: relative;
    left: 0;
  }
`;

const FloatedRightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
  flex: auto;
  column-gap: 8px;
`;

const NextToEntityContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

const ThreadEntitySummary = ({ activeCustomerData, activeThread }) => {
  const dispatch = useDispatch();

  const isEmail = activeThread?.type === 'email';
  const currentUser = useSelector((state) => state?.session?.currentUser);

  useGetUserAccountPolicies({
    userId: currentUser?.userId,
    actionList: [AVAILABLE_PERMISSIONS.BLOCK_CHANNEL],
  });

  const blockCustomerAccess = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.BLOCK_CHANNEL,
    currentUser?.accountPolicies,
    currentUser?.groupPolicies
  );

  const handleOpenSlideout = () => {
    dispatch(
      setActiveCustomerInfoSlideout({
        activeSlideoutEntityId: activeCustomerData.id,
        activeSlideoutEntityType: activeCustomerData.__typename,
      })
    );
  };

  const handleAssignJobTitle = () => {
    handleOpenSlideout();
    dispatch(setContactSlideoutAutofocusField('jobTitle'));
  };

  const handleAssignCompany = () => {
    handleOpenSlideout();
    dispatch(setContactSlideoutAutofocusField('company'));
  };

  const handleOpenCompanySlideout = (companyId) => {
    dispatch(
      setActiveCustomerInfoSlideout({
        activeSlideoutEntityId: companyId,
        activeSlideoutEntityType: 'CustomerAccount',
      })
    );
    dispatch(setActiveCustomerSource('search'));
  };

  if (!activeCustomerData || !activeThread) return <NavWrapper />;

  return (
    <NavWrapper>
      {{
        [PK_TYPENAMES.CUSTOMER_CONTACT]: (
          <>
            <CustomerContactHeader
              activeCustomerData={activeCustomerData}
              onAvatarNameClick={handleOpenSlideout}
              onAssignJobTitle={handleAssignJobTitle}
              onAssignCompany={handleAssignCompany}
              onOpenCompany={handleOpenCompanySlideout}
              companyId={activeCustomerData?.account?.id}
            />
            {isCustomerContactUnknown(activeThread?.externalContact) ? (
              <NextToEntityContainer>
                <CreateContactOptionButton activeThread={activeThread} />
                {blockCustomerAccess && !isEmail ? (
                  <BlockButton activeThread={activeThread} />
                ) : null}
              </NextToEntityContainer>
            ) : null}
          </>
        ),
        [PK_TYPENAMES.CUSTOMER_ACCOUNT]: (
          <CustomerAccountHeader activeCustomerData={activeCustomerData} />
        ),
        [PK_TYPENAMES.USER]: (
          <UserHeader
            activeCustomerData={activeCustomerData}
            onAvatarNameClick={handleOpenSlideout}
          />
        ),
        [PK_TYPENAMES.GROUP]: (
          <GroupHeader
            activeCustomerData={activeCustomerData}
            onAvatarNameClick={handleOpenSlideout}
          />
        ),
      }?.[activeCustomerData?.__typename] || null}

      <FloatedRightContainer>
        <HeaderOptionsButton activeThread={activeThread} />

        <RouteFilter exact path="/threads/open/:activeThreadId">
          <CloseThreadButton threadType={activeThread?.type} />
        </RouteFilter>

        <RouteFilter exact path="/threads/inbox/:activeThreadId">
          <IgnoreThreadButton activeThread={activeThread} />
        </RouteFilter>
      </FloatedRightContainer>
    </NavWrapper>
  );
};

ThreadEntitySummary.propTypes = {
  activeCustomerData: PropTypes.object,
  activeThread: PropTypes.object,
};

ThreadEntitySummary.defaultProps = {
  activeCustomerData: {},
  activeThread: {},
};

export default ThreadEntitySummary;
