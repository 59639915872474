export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_SIGNOUT = 'AUTH_SIGNOUT';
export const RESET_STORE = 'RESET_STORE';
export const SET_THREADS_FILTER = 'SET_THREADS_FILTER';
export const SET_ACTIVE_THREAD = 'SET_ACTIVE_THREAD';
export const SET_ACTIVE_UNCLAIMED_THREAD = 'SET_ACTIVE_UNCLAIMED_THREAD';
export const SET_ACTIVE_CLOSED_THREAD = 'SET_ACTIVE_CLOSED_THREAD';
export const RESET_ACTIVE_THREAD = 'RESET_ACTIVE_THREAD';
export const OPEN_GROUP_SELECT = 'OPEN_GROUP_SELECT';
export const CLOSE_GROUP_SELECT = 'CLOSE_GROUP_SELECT';
export const SET_CUSTOMERS_FILTER = 'SET_CUSTOMERS_FILTER';
export const SET_CUSTOMERS_FILTER_TEXT = 'SET_CUSTOMERS_FILTER_TEXT';
export const SET_ACTIVE_CUSTOMER = 'SET_ACTIVE_CUSTOMER';
export const SET_ACTIVE_CUSTOMER_ALL = 'SET_ACTIVE_CUSTOMER_ALL';
export const SET_ACTIVE_CUSTOMER_RECENT = 'SET_ACTIVE_CUSTOMER_RECENT';
export const SET_ACTIVE_CUSTOMER_TEAM = 'SET_ACTIVE_CUSTOMER_TEAM';
export const SET_SEARCH_TERMS = 'SET_SEARCH_TERMS';
export const SET_SEARCH_RESULT_TYPE = 'SET_SEARCH_RESULT_TYPE';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const OPEN_SEARCH_RESULT_TYPE_NAV = 'OPEN_SEARCH_RESULT_TYPE_NAV';
export const CLOSE_SEARCH_RESULT_TYPE_NAV = 'CLOSE_SEARCH_RESULT_TYPE_NAV';
export const TOTAL_UNREAD_THREADS = 'TOTAL_UNREAD_THREADS';
export const TOGGLE_BLOCK_CHANNEL_PROMPT_THREAD =
  'TOGGLE_BLOCK_CHANNEL_PROMPT_THREAD';
export const TOGGLE_BLOCK_CHANNEL_PROMPT_EDIT_CUSTOMER =
  'TOGGLE_BLOCK_CHANNEL_PROMPT_EDIT_CUSTOMER';
export const TOGGLE_BLOCK_CHANNEL_CONFIRM = 'TOGGLE_BLOCK_CHANNEL_CONFIRM';
export const ACTIVE_CUSTOMER_SELECTED = 'ACTIVE_CUSTOMER_SELECTED';
export const SET_THREAD_COUNTS = 'SET_THREAD_COUNTS';
export const SET_LATEST_CLOSED_THREAD = 'SET_LATEST_CLOSED_THREAD';
export const UPDATE_APPLICATION_STATE = 'UPDATE_APPLICATION_STATE';

export const signIn = (sessionUser) => ({
  type: AUTH_SIGNIN,
  userId: sessionUser.userId || sessionUser.id,
  username: sessionUser.username,
  contactId: sessionUser.contactId,
  accountId: sessionUser.accountId,
  groupIds: sessionUser.groupIds,
  accountPolicies: sessionUser.accountPolicies || [],
  groupPolicies: sessionUser.groupPolicies || [],
  firstName: sessionUser.firstName,
  lastName: sessionUser.lastName,
  role: sessionUser.role,
  stockMessagesConfig:
    sessionUser.stockMessagesConfig || sessionUser.stockMessages,
  notificationInterval: sessionUser.notificationInterval,
  notificationSound: sessionUser.notificationSound,
  notificationSettingsLocked: sessionUser.notificationSettingsLocked,
  groupInboxNotificationsEnabled: sessionUser.groupInboxNotificationsEnabled,
  announcementsAccess: sessionUser.announcementsAccess,
  theme: sessionUser.theme,
  emailNotificationsEnabled: sessionUser.emailNotificationsEnabled,
  limitEmailNotifications: sessionUser.limitNotificationsEnabled,
  emailNotificationDelayMins: sessionUser.emailNotificationDelayMins,
  unclaimedThreadEmailNotificationsEnabled:
    sessionUser.unclaimedThreadEmailNotificationsEnabled,
  unreadThreadEmailNotificationsEnabled:
    sessionUser.unreadThreadEmailNotificationsEnabled,
  unclaimedThreadMobileNotificationsEnabled:
    sessionUser.unclaimedThreadMobileNotificationsEnabled,
  unreadThreadMobileNotificationsEnabled:
    sessionUser.unreadThreadMobileNotificationsEnabled,
  avatarUrl: sessionUser.avatarUrl,
  language: sessionUser.language,
  title: sessionUser.title,
});

export const signOut = () => ({
  type: AUTH_SIGNOUT,
});
export const resetStore = () => ({
  type: RESET_STORE,
});
export const updateApplicationState = (applicationState) => ({
  type: UPDATE_APPLICATION_STATE,
  applicationState,
});
export const setApplicationLoaded = () =>
  updateApplicationState({
    applicationLoaded: true,
    applicationLoadTime: Date.now(),
  });
export const setThreadsFilter = (filter) => ({
  type: SET_THREADS_FILTER,
  filter,
});
export const setActiveThread = (activeThreadId) => ({
  type: SET_ACTIVE_THREAD,
  activeThreadId,
});
export const setActiveUnclaimedThread = (activeUnclaimedThreadId) => ({
  type: SET_ACTIVE_UNCLAIMED_THREAD,
  activeUnclaimedThreadId,
});
export const setActiveClosedThread = (activeClosedThreadId) => ({
  type: SET_ACTIVE_CLOSED_THREAD,
  activeClosedThreadId,
});
export const resetActiveThread = () => ({
  type: RESET_ACTIVE_THREAD,
  activeThreadId: '',
  threadSelected: false,
});
export const openGroupSelect = () => ({
  type: OPEN_GROUP_SELECT,
  groupSelectOpen: true,
});
export const closeGroupSelect = () => ({
  type: CLOSE_GROUP_SELECT,
  groupSelectOpen: false,
});
export const setCustomersFilter = (filter) => ({
  type: SET_CUSTOMERS_FILTER,
  filter,
});
export const setCustomersFilterText = (filterText) => ({
  type: SET_CUSTOMERS_FILTER_TEXT,
  filterText,
});
export const setActiveCustomer = (activeCustomerId) => ({
  type: SET_ACTIVE_CUSTOMER,
  activeCustomerId,
});
export const setActiveCustomerAll = ({ id, entityType }) => ({
  type: SET_ACTIVE_CUSTOMER_ALL,
  id,
  entityType,
});
export const setActiveCustomerRecent = ({ id, entityType }) => ({
  type: SET_ACTIVE_CUSTOMER_RECENT,
  id,
  entityType,
});

export const setActiveCustomerTeam = ({ id, entityType }) => ({
  type: SET_ACTIVE_CUSTOMER_TEAM,
  id,
  entityType,
});

export const setSearchTerms = (terms) => ({
  type: SET_SEARCH_TERMS,
  terms,
});
export const setSearchResultType = (text, value) => ({
  type: SET_SEARCH_RESULT_TYPE,
  text,
  value,
});
export const setSearchResults = ({ customers = [], threads = [] }) => ({
  type: SET_SEARCH_RESULTS,
  customers,
  threads,
});
export const openSearchResultTypeNav = (e) => ({
  type: OPEN_SEARCH_RESULT_TYPE_NAV,
  e,
});
export const closeSearchResultTypeNav = () => ({
  type: CLOSE_SEARCH_RESULT_TYPE_NAV,
});
export const totalUnreadThreads = (total) => ({
  type: TOTAL_UNREAD_THREADS,
  total,
});
export const openBlockChannelModalThread = (
  toggleValue,
  channelToBeBlocked
) => ({
  type: TOGGLE_BLOCK_CHANNEL_PROMPT_THREAD,
  toggleValue,
  channelToBeBlocked,
});
export const openBlockChannelModalEditCustomer = (
  toggleValue,
  channelToBeBlocked
) => ({
  type: TOGGLE_BLOCK_CHANNEL_PROMPT_EDIT_CUSTOMER,
  toggleValue,
  channelToBeBlocked,
});
export const blockChannelConfirm = (toggleValue) => ({
  type: TOGGLE_BLOCK_CHANNEL_CONFIRM,
  blockChannelConfirm: toggleValue,
});

export const setActiveCustomerSelected = (lastActiveCustomer) => ({
  type: ACTIVE_CUSTOMER_SELECTED,
  lastActiveCustomer,
});

export const setThreadCounts = (threadCounts) => ({
  type: SET_THREAD_COUNTS,
  threadCounts,
});

export const setLatestClosedThread = (latestClosedThread) => ({
  type: SET_LATEST_CLOSED_THREAD,
  latestClosedThread,
});
