import { useState } from 'react';

/*
DOCUMENTATION
  this hook was designed to be used inside useMultiSelectTableItems and useSelectAll hooks. 
*/

const useMultiSelectItems = ({ items }) => {
  const [selectedItems, setSelectItems] = useState([]);
  const [filterSelect, setFilterSelect] = useState('none');

  const handleCheckItem = (itemId) => {
    const updatedSelectedContacts = [...selectedItems];

    if (updatedSelectedContacts.includes(itemId)) {
      const index = updatedSelectedContacts.indexOf(itemId);
      updatedSelectedContacts.splice(index, 1);
      setFilterSelect('none');
    } else {
      updatedSelectedContacts.push(itemId);
    }
    setSelectItems(updatedSelectedContacts);
  };

  const shouldCheckItem = (itemId) => {
    return selectedItems.includes(itemId);
  };

  const handleCheck = () => {
    if (filterSelect === 'all') {
      handleSelectNone();
    }
    if (filterSelect === 'none') {
      handleSelectAll();
    }
  };

  const handleSelectNone = () => {
    setSelectItems([]);
    setFilterSelect('none');
  };

  const handleSelectAll = () => {
    const mergedArr = generateAllArray();
    setFilterSelect('all');
    setSelectItems(mergedArr);
  };

  const generateAllArray = () => {
    const allIds = items.map((item) => item.id);
    return [
      ...selectedItems,
      ...allIds.filter((id) => !selectedItems.includes(id)),
    ];
  };

  return {
    handleCheck,
    handleCheckItem,
    shouldCheckItem,
    indeterminate: filterSelect !== 'all' && selectedItems.length > 0,
    allItemsSelected: filterSelect === 'all',
    handleSelectAll,
    handleSelectNone,
    filterSelect,
    setFilterSelect,
    selectedItems,
    setSelectItems,
    generateAllArray,
    disabled: items.length === 0,
  };
};

export default useMultiSelectItems;
