import { useQuery } from "@apollo/client";
import { useEffect, useRef } from "react";

const DEFAULT_NUMBER = 25;
const usePaginated = ({
  queryVariables,
  filter,
  query,
  resultsNumber,
  key,
  skip = false,
  loadAllPages = false,
  queryOptions = {},
  policy = "cache-and-network",
  nextPolicy = "cache-only",
  customMapNode = null,
}) => {
  const variablesTrackRef = useRef(null);
  const otherQueryVariables = queryVariables || {};
  const initialVariables = {
    ...otherQueryVariables,
    first: resultsNumber || DEFAULT_NUMBER,
    filter: filter || "",
  };

  const {
    data: originalData,
    fetchMore,
    loading,
    networkStatus,
    refetch,
  } = useQuery(query, {
    variables: initialVariables,
    fetchPolicy: policy,
    nextFetchPolicy: nextPolicy,
    notifyOnNetworkStatusChange: true,
    skip,
    ...queryOptions,
  });

  const defaultMapNode = (edgeNode) => edgeNode.node;
  const { edges, pageInfo, ...extraParameters } = originalData?.[key] || {
    pageInfo: {},
  };
  const results = edges?.length
    ? edges.map(customMapNode || defaultMapNode)
    : [];

  const handleFetchMore = async () => {
    const edges = originalData[key].edges;
    const variables = {
      ...initialVariables,
      after: edges[edges.length - 1].cursor,
      before: null,
    };
    variablesTrackRef.current = variables;
    const { data } = await fetchMore({ variables });

    if (data.errors) {
      console.error(data.errors);
    }
  };

  const handlePrevious = (args) => {
    const edges = originalData[key].edges;
    const variables = {
      ...initialVariables,
      before: edges[0].cursor,
      after: null,
      first: args?.first || null,
      last: resultsNumber || DEFAULT_NUMBER,
    };
    variablesTrackRef.current = variables;
    fetchMore({ variables });
  };

  /**
   * This useEffect was specifically built
   * for the threads section's infiniteScrolls
   * to fetch all threads in the background.
   * We have moved away from that pattern, but if loadAllPages
   * is leveraged, note that this will fetch ALL pages
   */
  useEffect(() => {
    if (loadAllPages && pageInfo?.hasNextPage) {
      handleFetchMore();
    }
  }, [results?.length]);

  return {
    handleFetchMore,
    handlePrevious,
    pageInfo,
    extraParameters,
    [key]: results,
    loading,
    networkStatus,
    originalData,
    refetch,
    variables: variablesTrackRef.current || initialVariables,
  };
};
export default usePaginated;
