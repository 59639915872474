import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { debounce } from "lodash";
import MARK_THREAD_READ_MUTATION from "../../../graphql/mutation/MarkThreadReadMutation.graphql";

export const useWatchAndReadMessage = ({
  hasUnreadMessage,
  messageVal,
  threadId,
  client,
}) => {
  const [markThreadReadMutation] = useMutation(MARK_THREAD_READ_MUTATION, {
    client,
  });

  const executeMutation = debounce(
    (handleSetMessageAsRead) => {
      handleSetMessageAsRead();
    },
    1000,
    { leading: true }
  );

  const handleSetMessageAsRead = async () => {
    await markThreadReadMutation({
      variables: {
        input: {
          readAt: new Date().toISOString(),
          threadId,
        },
      },
    });
  };

  useEffect(() => {
    if (hasUnreadMessage && messageVal?.length) {
      executeMutation(handleSetMessageAsRead);
    }
  }, [hasUnreadMessage, messageVal]);
};

export default useWatchAndReadMessage;
