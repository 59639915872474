import { useState, useEffect } from 'react';
import i18n from 'i18n-js';

const timer = null;

const WEBCONNECT_SCRIPT_URLS = {
  dev: 'https://foobar.dev.prokeep.com:8080/wc/widget-init.js',
  int: 'https://cdn.int.prokeep.com/wc/widget-init.js',
  client1: 'https://cdn.client1.prokeep.com/wc/widget-init.js',
  qa: 'https://cdn.qa.prokeep.com/wc/widget-init.js',
  prod: 'https://cdn.prokeep.com/wc/widget-init.js',
  staging: 'https://cdn.staging.prokeep.com/wc/widget-init.js',
  mobile: 'https://cdn.mobile.prokeep.com/wc/widget-init.js',
  canada: 'https://cdn-canada.prokeep.com/wc/widget-init.js',
  'pk-alpine': 'https://cdn.pk-alpine.prokeep.com/wc/widget-init.js',
  'pk-mercedes': 'https://cdn.pk-mercedes.prokeep.com/wc/widget-init.js',
  'pk-redbull': 'https://cdn.pk-redbull.prokeep.com/wc/widget-init.js',
  'pk-mclaren': 'https://cdn.pk-mclaren.prokeep.com/wc/widget-init.js',
  'pk-ferrari': 'https://cdn.pk-ferrari.prokeep.com/wc/widget-init.js',
  'pk-alphatauri': 'https://cdn.pk-alphatauri.prokeep.com/wc/widget-init.js',
  'pk-audi': 'https://cdn.pk-audi.prokeep.com/wc/widget-init.js',
  'pk-haas': 'https://cdn.pk-haas.prokeep.com/wc/widget-init.js',
  'pk-aston': 'https://cdn.pk-aston.prokeep.com/wc/widget-init.js',
  'pk-williams': 'https://cdn.pk-williams.prokeep.com/wc/widget-init.js',
};

const WEBCHAT_SCRIPT_URLS = {
  dev: './webchat-init.js',
  int: 'https://cdn.int.prokeep.com/webchat/webchat-init.js',
  client1: 'https://cdn.client1.prokeep.com/webchat/widget-init.js',
  qa: 'https://cdn.qa.prokeep.com/webchat/webchat-init.js',
  prod: 'https://cdn.prokeep.com/webchat/webchat-init.js',
  staging: 'https://cdn.staging.prokeep.com/webchat/webchat-init.js',
  mobile: 'https://cdn.mobile.prokeep.com/webchat/webchat-init.js',
  canada: 'https://cdn-canada.prokeep.com/webchat/webchat-init.js',
  'pk-alpine': 'https://cdn.pk-alpine.prokeep.com/webchat/webchat-init.js',
  'pk-mercedes': 'https://cdn.pk-mercedes.prokeep.com/webchat/webchat-init.js',
  'pk-redbull': 'https://cdn.pk-redbull.prokeep.com/webchat/webchat-init.js',
  'pk-mclaren': 'https://cdn.pk-mclaren.prokeep.com/webchat/webchat-init.js',
  'pk-ferrari': 'https://cdn.pk-ferrari.prokeep.com/webchat/webchat-init.js',
  'pk-alphatauri':
    'https://cdn.pk-alphatauri.prokeep.com/webchat/webchat-init.js',
  'pk-audi': 'https://cdn.pk-audi.prokeep.com/webchat/webchat-init.js',
  'pk-haas': 'https://cdn.pk-haas.prokeep.com/webchat/webchat-init.js',
  'pk-aston': 'https://cdn.pk-aston.prokeep.com/webchat/webchat-init.js',
  'pk-williams': 'https://cdn.pk-williams.prokeep.com/webchat/webchat-init.js',
};

export default function useCopyCodeClipboard() {
  const generateCodeSnippet = (widgetId, widgetType = 'webconnect') => {
    const isWebConnect = widgetType === 'webconnect';
    const SCRIPT_URLS = isWebConnect
      ? WEBCONNECT_SCRIPT_URLS
      : WEBCHAT_SCRIPT_URLS;
    const scriptId = isWebConnect ? 'webconnect' : 'prokeep-webchat';

    const SCRIPT_URL = SCRIPT_URLS[process.env.PK_ENV];
    const CODE_SNIPPET = `<script id="${scriptId}" data-env="${process.env.PK_ENV}" data-widgetid="${widgetId}" src="${SCRIPT_URL}"></script>`;
    return CODE_SNIPPET;
  };
  const [successButtonValue, setSuccessButtonValue] = useState(
    i18n.t('settings-WebConnect-copyCode')
  );

  useEffect(
    () => () => {
      if (timer !== null) {
        clearTimeout(timer);
      }
    },
    []
  );

  const handleOnClick = (e) => {
    e.preventDefault();
    if (timer !== null) {
      clearTimeout(timer);
    }
    setSuccessButtonValue(i18n.t('settings-Support-copied'));

    setTimeout(() => {
      setSuccessButtonValue(i18n.t('settings-WebConnect-copyCode'));
    }, 3000);
  };

  return {
    handleOnClick,
    successButtonValue,
    generateCodeSnippet,
  };
}
