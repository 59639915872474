import React from 'react';
import i18n from 'i18n-js';
import { formatPhoneNumber } from 'client-lib/src/lib/utils/helpers';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'styled-theming';
import THEMES from '../../../../styles/themes/app';
import { Heading5, Text, MetaText, IconButton } from '../../../../elements';
import CustomerAccountHeader from './CustomerAccountHeader';

const SectionContainer = styled.div`
  padding: 1rem;
  padding-bottom: calc(1rem + 1px);
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  min-height: 32px;
`;

const ContactsContainer = styled.div`
  padding: 1rem;
  padding-bottom: calc(1rem + 1px);
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
  display: flex;
  flex-direction: row;
  row-gap: 4px;
`;

const NOTES_FONTSIZE = theme('fontSize', {
  default: '12px',
  large: '16px',
});

const NotesUpdatedAt = styled.span`
  color: ${THEMES.FOREGROUND_LOW};
  text-transform: none;
  font-weight: 500;
  padding-top: 8px;
  font-size: ${NOTES_FONTSIZE};
  font-family: 'Barlow', sans-serif;
  padding-left: 4px;
`;

const ContactsInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContactIconContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const CustomerAccountCardStatic = ({
  customerAccount,
  handleClickOnMode,
  userCanEditCompanies,
  closeCustomerInfo,
}) => {
  return (
    <div>
      <CustomerAccountHeader
        customerAccount={customerAccount}
        handleClickOnMode={handleClickOnMode}
        userCanEditCompanies={userCanEditCompanies}
        closeCustomerInfo={closeCustomerInfo}
      />
      <SectionContainer backgroundColor={THEMES.BACKGROUND_PRIMARY}>
        <Heading5 maxWidth="250px" contrast="high">
          {i18n.t('slideouts-CreateCustomerForm-companyEmail', {
            defaultValue: 'email',
          })}
        </Heading5>
        <Text maxWidth="400px" contrast="mid">
          {customerAccount?.emailAddress}
        </Text>
      </SectionContainer>
      <SectionContainer backgroundColor={THEMES.BACKGROUND_PRIMARY}>
        <Heading5 contrast="high">
          {i18n.t('slideouts-CreateCustomerForm-companyPhone', {
            defaultValue: 'Company Phone',
          })}
        </Heading5>
        <Text contrast="mid">
          {formatPhoneNumber(customerAccount?.phoneNumber)}
        </Text>
      </SectionContainer>
      <SectionContainer backgroundColor={THEMES.BACKGROUND_PRIMARY}>
        <Heading5 contrast="high">
          {i18n.t('slideouts-CustomerInfo-notes', { defaultValue: 'notes' })}
          <NotesUpdatedAt>
            {customerAccount?.notesUpdatedAt
              ? i18n.t('slideouts-CustomerInfo-lastModified', {
                  date: i18n.strftime(
                    new Date(customerAccount?.notesUpdatedAt),
                    '%-m/%d/%Y'
                  ),
                })
              : null}
          </NotesUpdatedAt>
        </Heading5>
        {customerAccount?.notes ? (
          <Text
            customStyle={() => `overflow-wrap: break-word;`}
            width="400px"
            contrast="mid"
          >
            {customerAccount?.notes}
          </Text>
        ) : (
          <MetaText contrast="low">
            {i18n.t('slideouts-CustomerInfo-nothingToSee')}
          </MetaText>
        )}
      </SectionContainer>
      <ContactsContainer backgroundColor={THEMES.BACKGROUND_PRIMARY}>
        <ContactsInfoContainer>
          <Heading5 contrast="high">
            {i18n.t('customers-CustomerList-contacts', {
              defaultValue: 'contacts',
            })}
          </Heading5>
          <Text contrast="mid">{customerAccount?.contacts.length}</Text>
        </ContactsInfoContainer>
        <ContactIconContainer>
          <IconButton
            size="lg"
            onClick={() => {
              handleClickOnMode('contact');
            }}
          >
            <i className="ri-arrow-drop-right-line" />
          </IconButton>
        </ContactIconContainer>
      </ContactsContainer>
    </div>
  );
};

CustomerAccountCardStatic.propTypes = {
  userCanEditCompanies: PropTypes.bool.isRequired,
  handleClickOnMode: PropTypes.func.isRequired,
  customerAccount: PropTypes.object.isRequired,
  closeCustomerInfo: PropTypes.func.isRequired,
};

export default CustomerAccountCardStatic;
