import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import { EntityCard } from '../../elements';

const PrintInternalHeader = ({ thread }) => {
  const currentUser = useSelector((state) => state?.session?.currentUser);

  const internalContact = thread?.userShares?.filter((user) => {
    return currentUser?.userId !== user?.userId;
  })?.[0]?.contact;

  const jobTitle = internalContact?.user?.title;
  const displayName = contactName(internalContact);

  return (
    <EntityCard
      avatarChildren={displayName}
      maintext={displayName}
      subtext={jobTitle}
      avatarProps={{
        type: 'internal',
        avatarUrl: internalContact?.user?.avatarUrl,
      }}
    />
  );
};

PrintInternalHeader.propTypes = {
  thread: PropTypes.object.isRequired,
};

export default PrintInternalHeader;
