import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';
import {
  useThreads,
  usePrevious,
  isThreadUnread,
  useMultiGroupFeature,
  useUpdateSelectedGroups,
  thread_utils,
} from 'client-lib';
import { diff } from 'deep-diff';
import i18n from 'i18n-js';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import TransferThread from './TransferThread';
import ThreadsListColumnHeader from '../Common/ThreadsListColumnHeader';
import ScopeTabBar from '../ScopeTabBar/ScopeTabBar';
import ThreadHistory from './ThreadHistory';
import SubmitMessageInput from './SubmitMessageInput';
import { UploadingBar } from './UploadingBar';
import {
  Body,
  Row,
  Column,
  TwoThirdsColumn,
  OverflowYWrap,
  CustomerSlideInColumn,
} from '../Common';
import CustomerInfo from '../Customers/CustomerInfo/CustomerInfo';
import RouteFilter from '../Common/RouteFilter';
import {
  setActiveThread,
  resetActiveThread,
  setActiveUnclaimedThread,
  setActiveClosedThread,
  setThreadsFilter,
  openGroupSelect,
  closeGroupSelect,
  setThreadCounts,
} from '../../actions';
import {
  closeAllSlideouts,
  updateEntitySlideoutData,
} from '../../actions/general';
import { setThreadsActiveGroupIds } from '../../actions/session';
import { openUploadModal, closeUploadModal } from '../../actions/uploadModal';
import Banner from '../Common/Banner';
import Accordion from '../Animations/Accordion';
import THEMES from '../../styles/themes/app';
import InfiniteScroll from '../InfiniteScroll/InfiniteScroll';
import ThreadSummary from './ThreadSummary';
import useMeasure from '../Animations/useMeasure';
import { Heading3, Text, Heading5 } from '../../elements';
import useWatchForReturnToInbox from './useWatchForReturnToInbox';
import ForwardMessageSlideout from './ForwardMessageSlideout';
import MergeCustomerSlideout from '../Customers/MergeCustomerSlideout';
import ThreadEntitySummary from '../Navbar/ThreadEntitySummary';

export const useSetThreadCounts = ({
  contactId,
  inboxThreads,
  myOpenThreads,
  setThreadCounts,
}) => {
  const unclaimedThreadCount = inboxThreads.length;
  const unreadThreadCount = myOpenThreads.filter((thread) =>
    isThreadUnread(thread, contactId)
  ).length;

  useEffect(() => {
    setThreadCounts({ unclaimedThreadCount, unreadThreadCount });
  }, [unclaimedThreadCount, unreadThreadCount]);

  return {
    unclaimedThreadCount,
    unreadThreadCount,
  };
};

const BorderlessColumn = styled(Column)`
  border-right: none;
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  position: sticky;
  height: calc(100vh - 75px); /* 75px = height of Header */
  top: 0;
  width: ${(props) => (props.shrink ? '50%' : '100%')};
`;

const UploadingBarWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  left: 8px;
  z-index: 3;
`;

const NullStateWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) =>
    props.isWithinAccordion ? 'calc(33% - 34px)' : '33%'};
  height: 100vh;
`;

const NullIcon = styled.i`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 7rem;
  color: ${THEMES.FOREGROUND_LOW};
`;

const ArrowIcon = styled.i`
  font-size: 24px;
  display: flex;
  color: ${THEMES.FOREGROUND_MED};
`;

const NullTextWrapper = styled.div`
  margin-bottom: 8px;
`;

const AccordionHead = styled.div`
  padding: 8px;
  display: flex;
  background-color: ${THEMES.BACKGROUND_TERTIARY};
  cursor: pointer;
`;

const AccordionTitle = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const AccordionCount = styled.div`
  margin-right: 7px;
  display: flex;
  align-items: center;
`;

const ChevronContainer = styled.div`
  transform: ${(props) => (props.open ? 'rotate(90deg)' : 'rotate(0deg)')};
`;

const OpenThreadsAccordion = ({
  children,
  initialOpenState,
  accordionTitle,
  accordionCount,
  accessSetAccordion,
}) => {
  const [open, setOpen] = useState(initialOpenState);
  accessSetAccordion?.(setOpen);

  const styledTheme = useContext(ThemeContext);

  const accordionCustomStyles = {
    borderBottom: `1px solid ${THEMES.BACKGROUND_SECONDARY({
      theme: styledTheme,
    })}`,
  };

  return (
    <>
      <AccordionHead onClick={() => setOpen(!open)}>
        <AccordionTitle>
          <Heading5 contrast="med">{accordionTitle}</Heading5>
        </AccordionTitle>
        {accordionCount || accordionCount === 0 ? (
          <AccordionCount>
            <Heading5 contrast="med">{accordionCount}</Heading5>
          </AccordionCount>
        ) : null}
        <ChevronContainer open={open}>
          <ArrowIcon className="ri-arrow-drop-right-line" />
        </ChevronContainer>
      </AccordionHead>
      <Accordion
        customClassName="accordion-hide-scroll"
        open={open}
        noMeasure
        customStyles={accordionCustomStyles}
      >
        {children}
      </Accordion>
    </>
  );
};

OpenThreadsAccordion.propTypes = {
  children: PropTypes.func.isRequired,
  accordionTitle: PropTypes.string.isRequired,
  initialOpenState: PropTypes.bool,
  accordionCount: PropTypes.number,
  accessSetAccordion: PropTypes.func,
};

OpenThreadsAccordion.defaultProps = {
  initialOpenState: true,
  accordionCount: null,
  accessSetAccordion: null,
};

const MIN_THREADS_ON_VIEWPORT = 5;

// scroll to index logic 👇
const useScrollToIndexLogic = ({
  inboxThreads,
  myOpenThreads,
  allOpenThreads,
  myClosedThreads,
  allClosedThreads,
  filter,
}) => {
  const [activeScrollToIndex, setActiveScrollToIndex] = useState(false);

  // this logic will turn off scroll to index, ensuring it's only used once and not being annoying to the user afterward.
  useEffect(() => {
    if (activeScrollToIndex) setActiveScrollToIndex(false);
  }, [activeScrollToIndex]);

  // scroll to index on mount (veer from /threads and then come back)
  useEffect(() => setActiveScrollToIndex(true), []);

  // scroll to index when _loading_ the page and there are no threads yet. i.e. user refreshes page. This should work for all route filters.
  const prevInboxThreadsLength = usePrevious(inboxThreads?.length);
  const prevMyOpenThreadsLength = usePrevious(myOpenThreads?.length);
  const prevAllOpenThreadsLength = usePrevious(allOpenThreads?.length);
  const prevMyClosedThreadsLength = usePrevious(myClosedThreads?.length);
  const prevClosedThreadsLength = usePrevious(allClosedThreads?.length);

  useEffect(() => {
    switch (filter) {
      case 'inbox':
        if (
          (prevInboxThreadsLength === 0 ||
            prevInboxThreadsLength === undefined) &&
          inboxThreads?.length
        ) {
          setActiveScrollToIndex(true);
        } else if (
          prevInboxThreadsLength &&
          inboxThreads.length - prevInboxThreadsLength > MIN_THREADS_ON_VIEWPORT
        ) {
          setActiveScrollToIndex(true);
        }
        break;

      case 'open':
        if (
          (prevMyOpenThreadsLength === 0 ||
            prevMyOpenThreadsLength === undefined) &&
          myOpenThreads?.length
        ) {
          setActiveScrollToIndex(true);
        } else if (
          prevMyOpenThreadsLength &&
          myOpenThreads.length - prevMyOpenThreadsLength >
            MIN_THREADS_ON_VIEWPORT
        ) {
          setActiveScrollToIndex(true);
        }

        if (
          (prevAllOpenThreadsLength === 0 ||
            prevAllOpenThreadsLength === undefined) &&
          allOpenThreads?.length
        ) {
          setActiveScrollToIndex(true);
        } else if (
          prevAllOpenThreadsLength &&
          allOpenThreads.length - prevAllOpenThreadsLength >
            MIN_THREADS_ON_VIEWPORT
        ) {
          setActiveScrollToIndex(true);
        }
        break;

      case 'closed':
        if (
          (prevMyClosedThreadsLength === 0 ||
            prevMyClosedThreadsLength === undefined) &&
          myClosedThreads?.length
        ) {
          setActiveScrollToIndex(true);
        } else if (
          prevMyClosedThreadsLength &&
          myClosedThreads.length - prevMyClosedThreadsLength >
            MIN_THREADS_ON_VIEWPORT
        ) {
          setActiveScrollToIndex(true);
        }

        if (
          (prevClosedThreadsLength === 0 ||
            prevClosedThreadsLength === undefined) &&
          allClosedThreads?.length
        ) {
          setActiveScrollToIndex(true);
        } else if (
          prevClosedThreadsLength &&
          allClosedThreads.length - prevClosedThreadsLength >
            MIN_THREADS_ON_VIEWPORT
        ) {
          setActiveScrollToIndex(true);
        }
        break;

      default:
        break;
    }
  }, [
    inboxThreads?.length,
    myOpenThreads?.length,
    allOpenThreads?.length,
    myClosedThreads.length,
    allClosedThreads.length,
  ]);

  // scroll to index when switching route filters
  useEffect(() => {
    setActiveScrollToIndex(true);
  }, [filter]);

  return activeScrollToIndex;
};

const Threads = ({
  allClosedThreadsQueryHandling,
  allOpenThreadsQueryHandling,
  myClosedThreadsQueryHandling,
  myOpenThreadsQueryHandling,
  myInboxThreadsQueryHandling,
  inboxSpamThreadsQueryHandling,
  includeInternal,
}) => {
  const { activeThreadId = '', filter } = useParams();
  const history = useHistory();

  const [isWarningOpen, setIsWarningOpen] = useState(false);
  const [initialAccordionOpenState, setInitialAccordionState] = useState('');
  const [watchingThread, setWatchingThread] = useState(false);
  const [selectedGroupsLoading, setSelectedGroupsLoading] = useState(false);

  let setAllOpenThreadsAccordion;
  let setMyOpenThreadsAccordion;

  const hasOpenedAndClosedThreadCreator = useRef(false);

  const client = useApolloClient();

  const dispatch = useDispatch();
  const contactId = useSelector(
    (state) => state?.session?.currentUser?.contactId
  );
  const currentUser = useSelector((state) => state?.session?.currentUser);
  const allGroups = useSelector((state) => state?.accountData?.allGroups);
  const threadsActiveGroupIds = useSelector(
    (state) => state?.session?.threadsActiveGroupIds
  );
  const groupSelectOpen = useSelector(
    (state) => state?.threadInfo?.groupSelectOpen
  );
  const activeCustomerInfoSlideout = useSelector(
    (state) => state?.general?.activeCustomerInfoSlideout
  );
  const activeMergeCustomerSlideout = useSelector(
    (state) => state?.general?.activeMergeCustomerSlideout
  );
  const modalOpen = useSelector((state) => state?.uploadModal?.modalOpen);
  const uploadFailed = useSelector((state) => state?.uploadModal?.uploadFailed);
  const uploadProgressBarOpen = useSelector(
    (state) => state?.uploadModal?.uploadProgressBarOpen
  );
  const attachments = useSelector((state) => state?.uploadModal?.attachments);
  const blockChannelConfirm = useSelector(
    (state) => state?.threadInfo?.blockChannelConfirm
  );
  const storedActiveThreadId = useSelector(
    (state) => state?.threadInfo?.activeThreadId
  );
  const storedActiveUnclaimedThreadId = useSelector(
    (state) => state?.threadInfo?.activeUnclaimedThreadId
  );
  const storedActiveClosedThreadId = useSelector(
    (state) => state?.threadInfo?.activeClosedThreadId
  );
  const activeTransferThreadSlideout = useSelector(
    (state) => state?.general?.activeTransferThreadSlideout
  );
  const activeForwardMessageSlideout = useSelector(
    (state) => state?.general?.activeForwardMessageSlideout
  );
  const activeCreateThreadModal = useSelector(
    (state) => state?.general?.activeCreateThreadModal
  );
  const latestClosedThread = useSelector(
    (state) => state?.threadInfo?.latestClosedThread
  );
  const ff_spam_filtering = useSelector(
    (state) => state?.accountData?.account.ff_spam_filtering
  );

  // measuring height of banner section to calculate correct height for infinite scroll
  const [bind, bannerMeasure] = useMeasure();

  const { multiGroup } = useMultiGroupFeature({ client, currentUser });

  const {
    inboxThreads,
    inboxSpamThreads,
    myOpenThreads,
    allOpenThreads,
    myClosedThreads,
    allClosedThreads,
    groups,
    activeThread,
    externalContactDetails,
    internalContactDetails,
    groupContactDetails,
    presenceTrackingAvailable,
  } = useThreads({
    client,
    currentUser,
    threadsActiveGroupIds,
    activeThreadId,
    includeInternal,
    allGroups,
  });

  /**
   * Determine if a filter view should show a particular thread.
   *
   * @param {string} filter - The filter value.
   * @param {object} thread - The thread object.
   * @returns {{ correctFilter: string, shouldShowThread: boolean }} - An object containing a determination of if the thread belongs in a filter view and the correct filter.
   */
  const filterThreadStatus = (filter, thread) => {
    const status = {
      correctFilter: 'unknown',
      shouldShowThread: false,
    };

    if (!thread || !filter) return status;

    const where = thread_utils.whereDoesThisThreadGo(
      thread,
      currentUser,
      threadsActiveGroupIds,
      ff_spam_filtering
    );

    if (where === 'allClosed' || where === 'myClosed') {
      status.correctFilter = 'closed';
      status.shouldShowThread = filter === 'closed';

      return status;
    }

    if (where === 'allOpen' || where === 'myOpen') {
      status.correctFilter = 'open';
      status.shouldShowThread = filter === 'open';

      return status;
    }

    if (where === 'inbox' || where === 'spam') {
      status.correctFilter = 'inbox';
      status.shouldShowThread = filter === 'inbox';

      return status;
    }

    return status;
  };

  useEffect(() => {
    if (!activeThread || !activeThreadId || !filter) {
      return;
    }

    const { correctFilter, shouldShowThread } = filterThreadStatus(
      filter,
      activeThread
    );

    if (
      shouldShowThread ||
      correctFilter === filter ||
      correctFilter === 'unknown'
    ) {
      return;
    }

    history.push(`/threads/${correctFilter}/${activeThreadId}`);
  }, [activeThread, activeThreadId, filter]);

  const { updateSelectedGroupsHander } = useUpdateSelectedGroups({
    client,
  });

  useEffect(() => {
    if (!groups.length) {
      setSelectedGroupsLoading(true);
    } else {
      setSelectedGroupsLoading(false);
    }
  }, [groups.length]);

  const onUnmount = () => {
    document.removeEventListener('drop', closeModalIfNotDropzone);
    document.removeEventListener('mouseleave', closeModalIfDragOutsideBrowser);

    // Leaving this page, so close group select, so it won't be open on next page.
    if (groupSelectOpen) {
      dispatch(closeGroupSelect());
    }
    dispatch(closeAllSlideouts());
  };

  useEffect(() => {
    const { shouldShowThread } = filterThreadStatus(filter, activeThread);

    if (shouldShowThread) {
      if (filter === 'open') dispatch(setActiveThread(activeThreadId));
      else if (filter === 'closed')
        dispatch(setActiveClosedThread(activeThreadId));
      else if (filter === 'inbox')
        dispatch(setActiveUnclaimedThread(activeThreadId));
    }

    document.addEventListener('drop', closeModalIfNotDropzone);
    document.addEventListener('mouseleave', closeModalIfDragOutsideBrowser);

    return () => onUnmount();
  }, []);

  useEffect(() => {
    dispatch(setThreadsFilter(filter));
  }, [filter]);

  useEffect(() => {
    const { shouldShowThread } = filterThreadStatus(filter, activeThread);

    if (shouldShowThread) {
      if (filter === 'inbox')
        dispatch(setActiveUnclaimedThread(activeThreadId));
      else if (filter === 'closed')
        dispatch(setActiveClosedThread(activeThreadId));
      else dispatch(setActiveThread(activeThreadId));
    }
  }, [activeThread, activeThreadId, filter]);

  useEffect(() => {
    if (externalContactDetails !== null) {
      dispatch(
        updateEntitySlideoutData({
          activeSlideoutEntityId: externalContactDetails?.id,
          activeSlideoutEntityType: externalContactDetails?.__typename,
        })
      );
    }
    if (internalContactDetails !== null) {
      dispatch(
        updateEntitySlideoutData({
          activeSlideoutEntityId: internalContactDetails?.contact?.user?.id,
          activeSlideoutEntityType:
            internalContactDetails?.contact?.user?.__typename,
        })
      );
    }
    if (groupContactDetails !== null) {
      dispatch(
        updateEntitySlideoutData({
          activeSlideoutEntityId: groupContactDetails?.id,
          activeSlideoutEntityType: groupContactDetails?.__typename,
        })
      );
    }
  }, [
    externalContactDetails?.id,
    internalContactDetails?.contact?.user?.id,
    groupContactDetails?.id,
  ]);

  const isActiveInAllSection = () => {
    const ownedByUser = myOpenThreads.some(
      (thread) => thread.id === activeThreadId
    );
    const activeInAll = allOpenThreads.some(
      (thread) => thread.id === activeThreadId
    );

    return !ownedByUser && activeInAll;
  };

  const prevOpenThreadsLength = usePrevious(allOpenThreads.length);
  useEffect(() => {
    if (
      (allOpenThreads.length || myOpenThreads.length) &&
      !prevOpenThreadsLength
    ) {
      if (isActiveInAllSection()) {
        setInitialAccordionState('both');
      } else {
        setInitialAccordionState('mine');
      }
    }
  }, [allOpenThreads.length, myOpenThreads.length]);

  // this is for proper accordion state after a user creates a new thread. In the case of a current thread already started by someone else,
  // we need to make sure that the accordion "all" view is open.
  // generally we only provide the initial state for the accordion and let the user interact with it as they want, but this is the exception.
  const prevActiveCreateThreadModal = usePrevious(activeCreateThreadModal);
  useEffect(() => {
    if (prevActiveCreateThreadModal && !activeCreateThreadModal) {
      hasOpenedAndClosedThreadCreator.current = true;
    }
  }, [activeCreateThreadModal]);

  useEffect(() => {
    if (hasOpenedAndClosedThreadCreator.current) {
      hasOpenedAndClosedThreadCreator.current = false;
      if (isActiveInAllSection()) {
        setAllOpenThreadsAccordion(true);
      }
    }
  }, [activeThreadId]);

  useEffect(() => {
    if (watchingThread) {
      setMyOpenThreadsAccordion(true);
    }
    setWatchingThread(false);
  }, [watchingThread]);

  // this useWatchForReturnToInbox is designed to watch for a "return to inbox" event. In which case,
  // if the user has the thread open, we will re-route them to show a blank screen inside of /open
  // instead of showing the old thread that has been returned to inbox.
  const onReturnToInbox = (returnedToInboxEvent) => {
    if (returnedToInboxEvent && returnedToInboxEvent.id === activeThreadId) {
      history.push('/threads/open');
    }
  };
  useWatchForReturnToInbox({
    threadsActiveGroupIds,
    contactId,
    onReturnToInbox,
  });

  useEffect(() => {
    // if user is looking at this thread when closes, push them to a blank threads/open/
    if (filter === 'open' && activeThreadId === latestClosedThread?.id) {
      history.push('/threads/open');
    }
  }, [latestClosedThread?.id]);

  const handleOnDragEnter = (e) => {
    e.preventDefault();
    dispatch(openUploadModal());
  };

  const handleOnDragLeave = (e) => {
    e.preventDefault();
  };

  const handleDropAttachment = () => {};

  const handleGroupSelectClick = () => {
    if (groupSelectOpen) {
      dispatch(closeGroupSelect());
    } else {
      dispatch(openGroupSelect());
    }
  };

  const handleGroupsUpdate = (selectedGroupIds) => {
    if (
      groupSelectOpen &&
      selectedGroupIds.length &&
      diff(selectedGroupIds, threadsActiveGroupIds)
    ) {
      const onSuccess = (groupIds) => {
        setSelectedGroupsLoading(false);
        // if the user is _closing_ group select,
        dispatch(resetActiveThread());
        dispatch(setActiveUnclaimedThread(''));

        dispatch(setThreadsActiveGroupIds(groupIds));
        history.push('/threads/inbox');
      };

      const onError = (err) => {
        setSelectedGroupsLoading(false);
        console.error('An error occurred', err);
      };

      setSelectedGroupsLoading(true);
      updateSelectedGroupsHander({
        groupIds: selectedGroupIds,
        onSuccess,
        onError,
      });
    }
  };

  const handleThreadButtonClick = () => {
    setIsWarningOpen(false);
    dispatch(closeGroupSelect());
    // TODO update route
    history.push('/threads/open');
  };

  const handleCloseWarningClick = () => {
    setIsWarningOpen(false);
  };

  const closeModalIfDragOutsideBrowser = (e) => {
    e.preventDefault();
    if (modalOpen && !attachments?.length) {
      dispatch(closeUploadModal());
    }
  };

  const closeModalIfNotDropzone = (e) => {
    e.preventDefault();
    if (
      e.target.className ===
      'ReactModal__Overlay ReactModal__Overlay--after-open'
    ) {
      dispatch(closeUploadModal());
    }
  };

  const activeScrollToIndex = useScrollToIndexLogic({
    inboxThreads,
    myOpenThreads,
    allOpenThreads,
    myClosedThreads,
    allClosedThreads,
    filter,
  });

  let listContent = null;

  if (filter === 'inbox') {
    const activeThreadIndex =
      activeScrollToIndex && activeThreadId
        ? inboxThreads.findIndex((thread) => thread.id === activeThreadId)
        : null;
    const scrollToIndex = activeThreadIndex >= 0 ? activeThreadIndex : null;

    listContent = (
      <>
        <InfiniteScroll
          bidirectionalScroll={false}
          scrollableList={inboxThreads}
          noRowsElement={
            <NullStateWrap isWithinAccordion={inboxSpamThreads.length}>
              <NullIcon className="ri-cup-line" />
              <NullTextWrapper>
                <Heading3 contrast="low">
                  {i18n.t('threads-Threads-coffeeBreak')}
                </Heading3>
              </NullTextWrapper>
              <NullTextWrapper>
                <Text contrast="low">
                  {i18n.t('threads-Threads-inboxEmpty')}
                </Text>
              </NullTextWrapper>
            </NullStateWrap>
          }
          loading={myInboxThreadsQueryHandling?.loading}
          renderRow={({ list, index }) => (
            <ThreadSummary
              key={list[index].id}
              thread={list[index]}
              presenceTrackingAvailable={presenceTrackingAvailable}
            />
          )}
          hasNextPage={myInboxThreadsQueryHandling?.pageInfo?.hasNextPage}
          loadingBorderBottom={false}
          loadingHeight={77}
          listItemHeight={77}
          loadMoreRows={myInboxThreadsQueryHandling?.handleFetchMore}
          scrollToIndex={scrollToIndex}
        />
        {ff_spam_filtering && inboxSpamThreads.length > 0 ? (
          <OpenThreadsAccordion
            accordionTitle={i18n.t('threads-spam-label', {
              defaultValue: 'Spam',
            })}
            accordionCount={
              inboxSpamThreads.filter((thread) =>
                isThreadUnread(thread, contactId)
              ).length
            }
            initialOpenState={false}
            data-testid="spam_threads"
          >
            {({ measure }) => {
              const activeSpamThreadIndex =
                activeScrollToIndex && activeThreadId
                  ? inboxSpamThreads.findIndex(
                      (thread) => thread.id === activeThreadId
                    )
                  : null;
              const scrollIndexForSpam =
                activeSpamThreadIndex >= 0 ? activeSpamThreadIndex : null;

              return (
                <InfiniteScroll
                  bidirectionalScroll={false}
                  scrollableList={inboxSpamThreads}
                  height={measure.height}
                  noRowsElement={<div />}
                  loading={inboxSpamThreadsQueryHandling?.loading}
                  renderRow={({ list, index }) => (
                    <ThreadSummary
                      key={list[index].id}
                      thread={list[index]}
                      presenceTrackingAvailable={presenceTrackingAvailable}
                    />
                  )}
                  hasNextPage={
                    inboxSpamThreadsQueryHandling?.pageInfo?.hasNextPage
                  }
                  loadingBorderBottom={false}
                  loadingHeight={77}
                  listItemHeight={77}
                  scrollToIndex={scrollIndexForSpam}
                  loadMoreRows={inboxSpamThreadsQueryHandling?.handleFetchMore}
                />
              );
            }}
          </OpenThreadsAccordion>
        ) : null}
      </>
    );
  } else if (filter === 'open') {
    const activeMyOpenThreadIndex =
      activeScrollToIndex && activeThreadId
        ? myOpenThreads.findIndex((thread) => thread.id === activeThreadId)
        : null;
    const scrollToMyOpenIndex =
      activeMyOpenThreadIndex >= 0 ? activeMyOpenThreadIndex : null;

    const activeAllOpenThreadIndex =
      activeScrollToIndex && activeThreadId
        ? allOpenThreads.findIndex((thread) => thread.id === activeThreadId)
        : null;
    const scrollToAllOpenIndex =
      activeAllOpenThreadIndex >= 0 ? activeAllOpenThreadIndex : null;

    const emptyOpenThreadsState = (isWithinAccordion) => (
      <NullStateWrap isWithinAccordion={isWithinAccordion}>
        <NullIcon className="ri-inbox-fill" />
        <NullTextWrapper>
          <Heading3 contrast="low">
            {i18n.t('threads-Threads-noOpenThreads')}
          </Heading3>
        </NullTextWrapper>
        <NullTextWrapper>
          <Text contrast="low">
            {i18n.t('threads-Threads-grabUnclaimedThread')}
          </Text>
        </NullTextWrapper>
      </NullStateWrap>
    );

    listContent =
      (myOpenThreads.length || allOpenThreads.length) &&
      initialAccordionOpenState ? (
        <>
          <OpenThreadsAccordion
            accordionTitle={i18n.t('threads-Threads-myThreads')}
            initialOpenState
            accessSetAccordion={(setAccordion) => {
              setMyOpenThreadsAccordion = setAccordion;
            }}
          >
            {({ measure }) =>
              myOpenThreads.length ? (
                <InfiniteScroll
                  bidirectionalScroll={false}
                  scrollableList={myOpenThreads}
                  height={measure.height}
                  noRowsElement={<div />}
                  loading={myOpenThreadsQueryHandling?.loading}
                  renderRow={({ list, index }) => (
                    <ThreadSummary
                      key={list[index].id}
                      thread={list[index]}
                      presenceTrackingAvailable={presenceTrackingAvailable}
                    />
                  )}
                  hasNextPage={
                    myOpenThreadsQueryHandling?.pageInfo?.hasNextPage
                  }
                  loadingBorderBottom={false}
                  loadingHeight={77}
                  listItemHeight={77}
                  scrollToIndex={scrollToMyOpenIndex}
                  loadMoreRows={myOpenThreadsQueryHandling?.handleFetchMore}
                />
              ) : (
                emptyOpenThreadsState(true)
              )
            }
          </OpenThreadsAccordion>
          <OpenThreadsAccordion
            accordionTitle={i18n.t('threads-Threads-AllClaimedThreads')}
            accordionCount={allOpenThreads.length}
            initialOpenState={initialAccordionOpenState === 'both'}
            accessSetAccordion={(setAccordion) => {
              setAllOpenThreadsAccordion = setAccordion;
            }}
          >
            {({ measure }) => (
              <InfiniteScroll
                bidirectionalScroll={false}
                scrollableList={allOpenThreads}
                height={measure.height}
                noRowsElement={<div />}
                loading={allOpenThreadsQueryHandling?.loading}
                renderRow={({ list, index }) => (
                  <ThreadSummary
                    key={list[index].id}
                    thread={list[index]}
                    presenceTrackingAvailable={presenceTrackingAvailable}
                    showOwnerAvatar
                  />
                )}
                hasNextPage={allOpenThreadsQueryHandling?.pageInfo?.hasNextPage}
                loadingBorderBottom={false}
                loadingHeight={77}
                listItemHeight={77}
                scrollToIndex={scrollToAllOpenIndex}
                loadMoreRows={allOpenThreadsQueryHandling?.handleFetchMore}
              />
            )}
          </OpenThreadsAccordion>
        </>
      ) : (
        emptyOpenThreadsState(false)
      );
  } else if (filter === 'closed') {
    const activeMyClosedThreadIndex =
      activeScrollToIndex && activeThreadId
        ? myClosedThreads.findIndex((thread) => thread.id === activeThreadId)
        : null;
    const myClosedScrollToIndex =
      activeMyClosedThreadIndex >= 0 ? activeMyClosedThreadIndex : null;

    const activeAllClosedThreadIndex =
      activeScrollToIndex && activeThreadId
        ? allClosedThreads.findIndex((thread) => thread.id === activeThreadId)
        : null;
    const allClosedScrollToIndex =
      activeAllClosedThreadIndex >= 0 ? activeAllClosedThreadIndex : null;

    listContent = (
      <>
        <OpenThreadsAccordion
          accordionTitle={i18n.t('threads-Threads-myThreads')}
        >
          {({ measure }) => (
            <InfiniteScroll
              bidirectionalScroll={false}
              scrollableList={myClosedThreads}
              height={measure.height}
              noRowsElement={<div />}
              loading={myClosedThreadsQueryHandling?.loading}
              renderRow={({ list, index }) => (
                <ThreadSummary key={list[index].id} thread={list[index]} />
              )}
              hasNextPage={myClosedThreadsQueryHandling?.pageInfo?.hasNextPage}
              loadingBorderBottom={false}
              loadingHeight={77}
              listItemHeight={77}
              loadMoreRows={myClosedThreadsQueryHandling?.handleFetchMore}
              scrollToIndex={myClosedScrollToIndex}
            />
          )}
        </OpenThreadsAccordion>
        <OpenThreadsAccordion
          accordionTitle={i18n.t('threads-Threads-allThreads')}
          initialOpenState={false}
        >
          {({ measure }) => (
            <InfiniteScroll
              bidirectionalScroll={false}
              scrollableList={allClosedThreads}
              height={measure.height}
              noRowsElement={<div />}
              loading={allClosedThreadsQueryHandling?.loading}
              renderRow={({ list, index }) => (
                <ThreadSummary
                  key={list[index].id}
                  thread={list[index]}
                  showOwnerAvatar
                />
              )}
              hasNextPage={allClosedThreadsQueryHandling?.pageInfo?.hasNextPage}
              loadingBorderBottom={false}
              loadingHeight={77}
              listItemHeight={77}
              loadMoreRows={allClosedThreadsQueryHandling?.handleFetchMore}
              scrollToIndex={allClosedScrollToIndex}
            />
          )}
        </OpenThreadsAccordion>
      </>
    );
  }

  const { unclaimedThreadCount, unreadThreadCount } = useSetThreadCounts({
    contactId,
    inboxThreads,
    myOpenThreads,
    setThreadCounts: (obj) => dispatch(setThreadCounts(obj)),
  });

  const inboxFilterLabel = i18n.t('threads-Threads-inbox');
  const openFilterLabel = i18n.t('threads-Threads-open');
  const closeFilterLabel = i18n.t('threads-Threads-close');

  const links = [
    {
      route: `/threads/inbox/${storedActiveUnclaimedThreadId}`,
      filter: 'inbox',
      label: inboxFilterLabel,
      badgeValue: unclaimedThreadCount,
    },
    {
      route: `/threads/open/${storedActiveThreadId}`,
      filter: 'open',
      label: openFilterLabel,
      badgeValue: unreadThreadCount,
    },
    {
      route: `/threads/closed/${storedActiveClosedThreadId}`,
      filter: 'closed',
      label: closeFilterLabel,
      badgeValue: null,
    },
  ];

  const scrollContainerRef = useRef();

  const threadHistoryViewportRef = useRef();

  const activeMainContactEntity = thread_utils.determineMainContact(
    activeThread,
    currentUser
  );

  const contactDetails =
    externalContactDetails || internalContactDetails || groupContactDetails;

  const infoSlideoutOpen = activeCustomerInfoSlideout && contactDetails;
  return (
    <Body>
      <Column style={{ maxWidth: '33.333%' }}>
        <div {...bind}>
          <Banner />
        </div>
        <ThreadsListColumnHeader
          groups={groups}
          groupSelectOpen={groupSelectOpen}
          onSelectClick={handleGroupSelectClick}
          onThreadsButtonClick={handleThreadButtonClick}
          onCloseWarningClick={handleCloseWarningClick}
          isWarningOpen={isWarningOpen}
          multiGroup={multiGroup}
          activeGroupIds={threadsActiveGroupIds}
          heightDeduction={bannerMeasure?.height}
          onSelectionFinal={(groupArr) => {
            handleGroupsUpdate(groupArr);
          }}
          loading={selectedGroupsLoading}
        />
        {!groupSelectOpen && (
          <>
            <ScopeTabBar
              links={links}
              onTabClick={() => dispatch(closeAllSlideouts())}
              threeTabs
            />
            {listContent}
          </>
        )}
      </Column>
      <RouteFilter exact path="/threads/:filter">
        <TwoThirdsColumn>
          <div style={{ position: 'relative' }}>
            <OverflowYWrap>
              {uploadProgressBarOpen && (
                <UploadingBarWrapper>
                  <UploadingBar
                    success={!uploadFailed}
                    caption={i18n.t('modals-Uploader-uploading')}
                  />
                </UploadingBarWrapper>
              )}
            </OverflowYWrap>
          </div>
        </TwoThirdsColumn>
      </RouteFilter>
      <RouteFilter exact path="/threads/:filter/:activeThreadId">
        {!blockChannelConfirm ? (
          <TwoThirdsColumn>
            <ThreadEntitySummary
              activeCustomerData={activeMainContactEntity}
              activeThread={activeThread}
            />
            <Dropzone
              disableClick
              multiple={false}
              onDrop={handleDropAttachment}
              onDragEnter={handleOnDragEnter}
              onDragLeave={handleOnDragLeave}
              style={{
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                minHeight: 'calc(100% - 77px)',
                maxHeight: 'calc(100% - 77px)',
              }}
            >
              <Row style={{ flex: '1' }}>
                <BorderlessColumn
                  ref={threadHistoryViewportRef}
                  shrink={infoSlideoutOpen || activeTransferThreadSlideout}
                >
                  {uploadProgressBarOpen && (
                    <UploadingBarWrapper>
                      <UploadingBar
                        success={!uploadFailed}
                        caption={i18n.t('modals-Uploader-uploading')}
                      />
                    </UploadingBarWrapper>
                  )}
                  {activeThread === null ? null : (
                    <ThreadHistory
                      thread={activeThread}
                      canLoadPastThreads={
                        filter === 'open' || filter === 'inbox'
                      }
                      ref={scrollContainerRef}
                    />
                  )}
                </BorderlessColumn>
                {infoSlideoutOpen && (
                  <CustomerSlideInColumn>
                    <OverflowYWrap>
                      <CustomerInfo
                        customerAccountId={
                          contactDetails?.__typename === 'CustomerAccount'
                            ? contactDetails?.id
                            : null
                        }
                        customerContactId={
                          contactDetails?.__typename === 'CustomerContact'
                            ? contactDetails?.id
                            : null
                        }
                        teamMemberData={
                          contactDetails?.__typename === 'ThreadUserShare'
                            ? internalContactDetails?.contact?.user
                            : null
                        }
                        internalGroupData={
                          contactDetails?.__typename === 'Group'
                            ? groupContactDetails
                            : null
                        }
                        closeCustomerInfo={() => dispatch(closeAllSlideouts())}
                        channelType={activeThread?.type}
                      />
                    </OverflowYWrap>
                  </CustomerSlideInColumn>
                )}
                {activeMergeCustomerSlideout && contactDetails && (
                  <CustomerSlideInColumn>
                    <MergeCustomerSlideout
                      customerContactId={
                        contactDetails?.__typename === 'CustomerContact'
                          ? contactDetails?.id
                          : null
                      }
                    />
                  </CustomerSlideInColumn>
                )}
                {activeForwardMessageSlideout ? (
                  <CustomerSlideInColumn>
                    <OverflowYWrap>
                      <ForwardMessageSlideout />
                    </OverflowYWrap>
                  </CustomerSlideInColumn>
                ) : null}
                {activeTransferThreadSlideout ? (
                  <CustomerSlideInColumn>
                    <OverflowYWrap>
                      <TransferThread />
                    </OverflowYWrap>
                  </CustomerSlideInColumn>
                ) : null}
              </Row>
              {activeThread &&
                activeThread.archivedAt === null &&
                filter === 'open' && (
                  <SubmitMessageInput
                    contact={contactName(activeThread?.externalContact)}
                    channelType={activeThread?.type?.toUpperCase()}
                    groupId={activeThread?.groupId}
                    contactId={contactDetails?.id}
                    hasUnreadMessage={isThreadUnread(activeThread, contactId)}
                  />
                )}
            </Dropzone>
          </TwoThirdsColumn>
        ) : (
          <TwoThirdsColumn />
        )}
      </RouteFilter>
    </Body>
  );
};

Threads.propTypes = {
  allClosedThreadsQueryHandling: PropTypes.object.isRequired,
  allOpenThreadsQueryHandling: PropTypes.object.isRequired,
  myClosedThreadsQueryHandling: PropTypes.object.isRequired,
  myOpenThreadsQueryHandling: PropTypes.object.isRequired,
  myInboxThreadsQueryHandling: PropTypes.object.isRequired,
  inboxSpamThreadsQueryHandling: PropTypes.object,
  includeInternal: PropTypes.bool,
};

Threads.defaultProps = {
  inboxSpamThreadsQueryHandling: null,
  includeInternal: false,
};

export default Threads;
