import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import TRANSFER_THREAD_MUTATION from "../../../graphql/mutation/TransferThreadMutation.graphql";
import THREAD_QUERY from "../../../graphql/query/ThreadQuery.graphql";
/*

DOCUMENTATION:
  useTransferThread is a hook designed to be used with TransferThread. it gives transferToUserId state, message state, the state setters, and the function to
  handle the actual mutation

  args:
    client: (obj) required. Apollo Client instance
    threadId: (string) required. the thread Id that you desire to transfer :D
    onTransferThreadSuccess: (func) optional. will call function on success of transfer thread mutation

  return:
    transferTarget(obj) user or group object the thread will be transferred to.
    messageInputValue: (str) message user would like to send with thread transfer.
    setMessageInputValue: (func) set messageInputValue state
    handleMutationTransferThread: (func) will run transferThread mutation.

*/

const useTransferThread = ({
  client,
  threadId,
  onTransferThreadSuccess,
  onTransferThreadError,
}) => {
  const [transferThreadMutation, { loading }] = useMutation(
    TRANSFER_THREAD_MUTATION,
    {
      client,
    }
  );
  const { data: threadData, refetch } = useQuery(THREAD_QUERY, {
    client,
    variables: {
      id: threadId,
    },
  });

  const [messageInputValue, setMessageInputValue] = useState("");
  const [transferTarget, setTransferTarget] = useState(null);
  const [stayNotified, setStayNotified] = useState(true);

  const handleMutationTransferThread = async () => {
    const variables = {
      input: {
        text: messageInputValue,
        threadId,
        stayNotified,
      },
    };

    if (transferTarget?.id && transferTarget?.__typename === "Group") {
      variables.input.toGroupId = transferTarget?.id;
    }
    if (transferTarget?.contactId && transferTarget?.__typename === "User") {
      variables.input.ownerContactId = transferTarget?.contactId;
    }

    const { data } = await transferThreadMutation({ variables }).catch(
      (err) => {
        onTransferThreadError();
        console.warn(err);
      }
    );

    if (data && data.transferThread && !data.transferThread.errors) {
      onTransferThreadSuccess?.(stayNotified);
      if (process.env.PLATFORM === "mobile") refetch?.();
    } else {
      onTransferThreadError?.();
    }
  };

  return {
    messageInputValue,
    setMessageInputValue,
    stayNotified,
    setStayNotified,
    handleMutationTransferThread,
    threadData,
    transferTarget,
    setTransferTarget,
    loading,
  };
};

export default useTransferThread;
