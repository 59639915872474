import { useQuery } from "@apollo/client";
import EMAIL_SIGNATURE_QUERY from "../../../graphql/query/EmailSignatureQuery.graphql";

/*

DOCUMENTATION
  this hook is created to be used with EmailSignature

  args:
    client: (obj) required. Apollo Client instance.
    userId: (str) required. ID of user to check for email signature settings.
    messageVal: (str) state. The message where the email signature will be appended.
    setMessageVal: (func) updates messageVal state.

  returns:
    emailSigOn: (bool) if user email signature is on, value will be true.
    handleAddSignature: (func) Receives optional onAddSigCb used for running UI like setting cursor or redux. Appends email signature to messageVal, runs onAddSignature callback. 

*/

const useEmailSignature = ({ client, userId, messageVal, setMessageVal }) => {
  const { data } = useQuery(EMAIL_SIGNATURE_QUERY, {
    client,
    variables: {
      userId,
    },
  });

  const appendEmailSignature = (value) =>
    `${value}\n\n${data?.emailSignature?.emailSignature?.body}`;

  // Callback used to set cursor, close bubble, or any other UI
  const handleAddSignature = (onAddSigCb) => {
    setMessageVal(appendEmailSignature(messageVal));
    onAddSigCb?.();
  };

  return {
    emailSigOn: data?.emailSignature?.emailSigOn,
    autoAddSig: data?.emailSignature?.autoAddSig,
    appendEmailSignature,
    handleAddSignature,
  };
};

export default useEmailSignature;
