import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { EntityCard, InlineTextButton, Text } from '../../../elements';

const CustomerAccountHeader = ({
  activeCustomerData,
  onAvatarNameClick,
  onAddAccountNumber,
  ...props
}) => {
  const accountNumber = activeCustomerData?.accountNumber;

  const subtextComponent = accountNumber ? (
    <Text maxWidth="450px">{accountNumber}</Text>
  ) : (
    <InlineTextButton noOutline onClick={onAddAccountNumber}>
      {i18n.t('slideouts-CustomerInfo-addAccountNumber', 'Add Account Number')}
    </InlineTextButton>
  );

  return (
    <EntityCard
      {...props}
      avatarChildren={activeCustomerData?.name}
      maintext={activeCustomerData?.name}
      maintextProps={{ onClick: onAvatarNameClick }}
      subtext={subtextComponent}
      customMaintextStyle={() => `margin: 0;`}
      customContainerStyle={() => 'border-width: 0;'}
      avatarProps={{
        icon: 'company',
        onClick: onAvatarNameClick,
      }}
    />
  );
};

CustomerAccountHeader.propTypes = {
  activeCustomerData: PropTypes.object,
  onAvatarNameClick: PropTypes.func,
  onAddAccountNumber: PropTypes.func,
};

CustomerAccountHeader.defaultProps = {
  onAvatarNameClick: undefined,
  onAddAccountNumber: () => {},
  activeCustomerData: null,
};

export default CustomerAccountHeader;
