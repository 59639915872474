import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { MetaText } from '../../elements';

const SeparatorDate = ({ value }) => {
  const dateValue = new Date(value);
  const today = new Date();

  const isToday =
    dateValue.getFullYear() === today.getFullYear() &&
    dateValue.getMonth() === today.getMonth() &&
    dateValue.getDate() === today.getDate();

  const i18nTodayStr = i18n.t('threads-SeparatorDate-today');
  const i18nLongDateStr = i18n.strftime(dateValue, '%A, %B %-d, %Y');

  return (
    <MetaText contrast="low" dataTestId="separator-date-value">
      {isToday ? i18nTodayStr : i18nLongDateStr}
    </MetaText>
  );
};

SeparatorDate.propTypes = {
  value: PropTypes.string.isRequired,
};

export default SeparatorDate;
