import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import styled from 'styled-components';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { InternalUseOnly } from '../../elements';
import MessageText from '../../elements/Text/MessageText';

const Wrapper = styled.div`
  margin: 16px;
`;

const InternalUseOnlyMessage = ({ message, fullName, printMessage }) => {
  const {
    type,
    text,
    deletionDescription,
    deletedByContact,
    deletionReason,
    createdAt,
    targetContact,
    transferredFromGroup,
    ignoredByContact,
    ignoreReason,
  } = message;

  const [messageExpanded, setMessageExpanded] = useState(false);
  const ff_spam_filtering = useSelector(
    (state) => state.accountData.account.ff_spam_filtering
  );
  const spam_ttl_in_days = useSelector(
    (state) => state.accountData.account.spam_ttl_in_days
  );

  if (!ff_spam_filtering && type === 'spam_warning') {
    return null;
  }

  let originalMessageText;
  let messageContent;
  switch (type) {
    case 'spam_warning': {
      messageContent = i18n.t('threads-Message-messageMarkedAsSpam', {
        defaultValue:
          'Until the thread is marked as not spam, the time to claim clock will not start.',
      });
      originalMessageText = i18n.t(
        'threads-Message-messageMarkedAsSpamDescription',
        {
          defaultValue:
            "This thread was marked as spam with %{confidence}% confidence by Prokeep's spam detector. This thread will be closed on %{date} - %{spam_ttl_in_days} days from when it was marked as spam",
          confidence: 95,
          date: moment(createdAt)
            .add(spam_ttl_in_days, 'days')
            .format('MMMM Do, YYYY'),
          spam_ttl_in_days,
        }
      );
      break;
    }
    case 'deleted': {
      const deletionReasonsFormatted = {
        SENSITIVE_INFORMATION: i18n.t('threads-Message-sensitiveInfo'),
        ACCIDENTALLY_SENT: i18n.t('threads-Message-accidentallySent'),
        INAPPROPRIATE_CONTENT: i18n.t('threads-Message-inappropriateContent'),
      };
      const deletedByName = contactName(deletedByContact);

      messageContent = i18n.t('threads-Message-messageRemovedBy', {
        name: deletedByName,
        reason: deletionReasonsFormatted[deletionReason],
      });

      if (deletionDescription) {
        originalMessageText = deletionDescription;
      }
      break;
    }
    case 'transfer': {
      let targetContactName;
      if (targetContact !== null) {
        targetContactName = contactName(targetContact);
      } else if (transferredFromGroup !== null) {
        targetContactName = transferredFromGroup?.name;
      } else {
        targetContactName = 'you';
      }

      messageContent =
        transferredFromGroup !== null
          ? i18n.t('threads-Message-hasTransferredFrom', {
              name: fullName,
              targetName: targetContactName,
            })
          : i18n.t('threads-Message-hasTransferredTo', {
              name: fullName,
              targetName: targetContactName,
            });
      if (text !== null && text.length > 0) {
        originalMessageText = text;
      }
      break;
    }
    case 'close': {
      messageContent = i18n.t('threads-ThreadHistory-closedBy', {
        name: fullName,
      });
      break;
    }
    case 'ignore': {
      const ignoredReasonFormatted = {
        SPAM: i18n.t('threads-Ignore-spam'),
        NO_REPLY_NEEDED: i18n.t('threads-ignoreThread-noNeedResponse'),
        ACCIDENTALLY_SENT: i18n.t('threads-ignoreThread-mistake'),
        AUTOMATED: i18n.t('threads-ignoreThread-automated'),
      };

      const ignoredByName = contactName(ignoredByContact);

      messageContent = i18n.t('threads-Message-messageIgnoredBy', {
        name: ignoredByName,
        reason: ignoredReasonFormatted[ignoreReason],
      });
      break;
    }
    case 'unclaim': {
      messageContent = i18n.t('threads-Message-returnToInboxDueTo');
      break;
    }
    case 'pci_breach_warning': {
      messageContent = i18n.t('threads-Message-PCIWarning');
      originalMessageText = i18n.t('threads-Message-PCIExpandableContext', {
        defaultValue:
          'It looks like %{breacherName} sent in a photo of a credit card. This is an unsafe way to process payments. We have removed this image to better comply with PCI security standards, best practices provided here: https://knowledge.prokeep.com/knowledge/secure-payments.',
        breacherName: fullName,
      });
      break;
    }
    case 'pci_message_text_warning': {
      messageContent = i18n.t('threads-Message-PCIWarning');
      originalMessageText = i18n.t('threads-Message-PCIMessageTextExpandable', {
        defaultValue:
          'It looks like %{contactFullName} has sent in credit card information. This is an unsafe way to process payments. We have removed this information to better comply with PCI standards, best practices provided here: https://knowledge.prokeep.com/knowledge/secure-payments.',
        contactFullName: fullName,
      });
      break;
    }
    default:
      messageContent = <MessageText />;
      break;
  }

  return (
    <Wrapper>
      <InternalUseOnly
        maintext={messageContent}
        subtext={originalMessageText}
        timestamp={createdAt}
        isOpen={messageExpanded}
        setIsOpen={setMessageExpanded}
        displayCaret={[
          'deleted',
          'transfer',
          'spam_warning',
          'pci_breach_warning',
          'pci_message_text_warning',
        ].includes(type)}
        type={type}
        printMessage={printMessage}
      />
    </Wrapper>
  );
};

InternalUseOnlyMessage.propTypes = {
  message: PropTypes.object.isRequired,
  fullName: PropTypes.string.isRequired,
  printMessage: PropTypes.bool.isRequired,
};

export default InternalUseOnlyMessage;
