// something weird going on with the linter in this file, keep an eye on it
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { useApolloClient, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import {
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
  USER_QUERY,
  CUSTOMER_CONTACT_QUERY,
  CUSTOMER_ACCOUNT_QUERY,
  GROUP_QUERY,
  useHasEmailChannel,
  useHasFaxChannel,
  useHasSmsChannel,
} from 'client-lib';
import {
  accountName,
  accountNumber,
  arrayToObject,
} from 'client-lib/src/lib/utils/helpers';
import { useHistory } from 'react-router-dom';
import theme from 'styled-theming';
import { Column } from '../../Common';
import CustomerGroupEdit from '../EditCustomer/CustomerGroupEdit';
import CustomerInfoOptions from '../CustomerInfoOptions';
import { setActiveCustomerSource } from '../../../actions/general';
import useStartContactThread from '../../../hooks/customer/useStartContactThread';
import THEMES from '../../../styles/themes/app';
import TeamMemberSlideout from './TeamMemberSlideout';
import CustomerAccountCardSlideout from './CustomerAccountSlideouts/CustomerAccountCardSlideout';
import InternalGroupSlideoutContainer from './InternalGroupSlideoutContainer';
import ContactCardSlideout from './CustomerSlideouts/ContactCardSlideout';
import useGetUserAccountPolicies from '../../../hooks/customer/useGetUserAccountPolicies';

const NOTESUPDATEDAT_FONTSIZE = theme('fontSize', {
  default: '12px',
  large: '16px',
});

export const newlineStringLiteralIntoBreak = (str) =>
  str.replace(/(?:\r\n|\r|\n)/g, '<br />');

const FakeComponent = () => <div />;
/* eslint-disable */
const CustomerGroupEditTestStandin = ({ onCancel }) => (
  <div data-testid="cge-test-standin">
    <button onClick={onCancel}>Cancel</button>
  </div>
);
/* eslint-enable */

export const ColumnContainer = styled(Column)`
  display: block;
  min-height: calc(100vh - 75px);
  background-color: ${THEMES.BACKGROUND_PRIMARY};
`;

export const InnerWrap = styled.div``;

export const HeadWrap = styled.div`
  overflow: hidden;
  white-space: nowrap;
`;

export const TeamHeadWrap = styled.div`
  overflow: hidden;
  white-space: nowrap;
  background-color: ${THEMES.BACKGROUND_SECONDARY};
  padding: 0.8rem 1rem;
`;

export const sectionItemCustomStyle = ({
  smallFont = false,
  noMargin = false,
}) => `
  margin: ${noMargin ? '0' : '8px'} 0 0 0;
  text-overflow: ellipsis;
  overflow: hidden;
  overflow-wrap: break-word;
  font-size: ${smallFont ? '12px' : '17px'};
`;

export const HeaderCloseSection = styled.div`
  background-color: ${THEMES.BACKGROUND_SECONDARY};
  padding: 0.8rem 0.8rem 0 1rem;
  color: ${THEMES.FOREGROUND_HIGH};
  display: flex;
  justify-content: flex-end;
`;

export const SubHeaderSection = styled.section`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.backgroundColor};
  padding: ${(props) => (props.slimVerticalPadding ? '10px 1rem' : '1rem')};
  width: 100%;
  border-top: 1px solid ${THEMES.BORDER_COLOR};
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
`;

export const GroupDisplayWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const NotesUpdatedAt = styled.span`
  color: ${THEMES.FOREGROUND_LOW};
  text-transform: none;
  font-weight: 500;
  font-size: ${NOTESUPDATEDAT_FONTSIZE};
  display: inline-block;
  padding-left: 8px;
  font-family: 'Barlow', sans-serif;
`;

export const PriorityNotificationSection = styled.div`
  margin-right: 5px;
  display: flex;
  align-items: center;
`;

export const OptOutSection = styled.div`
  position: absolute;
  right: 7px;
  font-weight: bold;
  font-family: 'Barlow', sans-serif;
  display: flex;
  align-items: center;
`;

export const NotesWrap = styled.div`
  margin-top: 8px;
  background-color: ${THEMES.BACKGROUND_SECONDARY};
  padding: 8px;
  border-radius: 4px;
  word-wrap: break-word;
`;

export const TextWrap = styled.div`
  margin-top: 2px;
`;

export const TruncateOverflowWrapper = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 450px;
`;

export const TeamInfoWrapper = styled.div`
  padding: 1rem;
  padding-bottom: calc(1rem + 1px);
  border-bottom: 1px solid
    ${(props) => THEMES.BORDER_COLOR({ theme: props.styledTheme })};
`;

export const GroupBadgesSection = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
`;

export const BadgesWrapper = styled.div`
  display: flex;
  padding-top: 8px;
  flex-wrap: wrap;
`;

export const LoadingWrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
  width: 100%;
`;

const CustomerInfo = ({ closeCustomerInfo, isTest, channelType }) => {
  const client = useApolloClient();
  const history = useHistory();

  let customerContact;
  let customerAccount;
  let teamMemberData;
  let internalGroupData;

  const styledTheme = useContext(ThemeContext);

  const dispatch = useDispatch();
  const internalThreadsAvailable = useSelector(
    (state) => state?.accountData?.account?.ff_internal_threads
  );

  const apiMessageFeatureEnabled = useSelector(
    (state) => state?.accountData?.account?.ff_api
  );

  const currentUser = useSelector(
    (state) => state?.session?.currentUser,
    (currentUser, prev) =>
      currentUser?.userId === prev?.userId &&
      currentUser?.accountPolicies === prev?.accountPolicies
  );

  const threadsActiveGroupIds = useSelector(
    (state) => state?.session?.threadsActiveGroupIds
  );
  const allGroups = useSelector((state) => state?.accountData?.allGroups);

  const entityId = useSelector(
    (state) => state?.general?.activeSlideoutEntityId
  );
  const entityType = useSelector(
    (state) => state?.general?.activeSlideoutEntityType
  );

  useGetUserAccountPolicies({
    userId: currentUser?.userId,
    actionList: [
      AVAILABLE_PERMISSIONS.EDIT_CONTACT_EMAIL_OPT_OUT,
      AVAILABLE_PERMISSIONS.EDIT_CONTACT_FAX_OPT_OUT,
      AVAILABLE_PERMISSIONS.EDIT_CONTACT_OPT_OUT,
      AVAILABLE_PERMISSIONS.EDIT_CUSTOMER_REP,
    ],
  });

  const queryConfig = {
    CustomerContact: {
      query: CUSTOMER_CONTACT_QUERY,
      key: 'customerContact',
    },
    CustomerAccount: {
      query: CUSTOMER_ACCOUNT_QUERY,
      key: 'customerAccount',
    },
    User: {
      query: USER_QUERY,
      key: 'user',
    },
    Group: {
      query: GROUP_QUERY,
      key: 'group',
    },
  };

  const { data, loading, error } = useQuery(queryConfig?.[entityType]?.query, {
    variables: {
      id: entityId,
    },
    skip: !entityId || !entityType,
  });

  const retreiveAccountInfoString = (entity) => {
    const name = accountName(entity);
    const number = accountNumber(entity);

    if (name && number) {
      return (
        <TruncateOverflowWrapper>
          {name} &nbsp; | &nbsp; ACCT#
          {number}
        </TruncateOverflowWrapper>
      );
    }

    if (name) {
      return name;
    }

    if (number) {
      return number;
    }

    return '';
  };

  if (data?.customerContact) {
    customerContact = data?.customerContact;
  }
  if (data?.customerAccount) {
    customerAccount = data?.customerAccount;
  }
  if (data?.group) {
    internalGroupData = data?.group;
  }
  if (data?.user) {
    teamMemberData = data?.user;
  }

  const groupIds = teamMemberData?.groupIds;
  const teamMemberGroups = allGroups.filter((group) =>
    groupIds?.includes(group.id)
  );
  const teamMemberGroupsNames = teamMemberGroups?.map((group) => group.name);

  const inActiveThreadGroup =
    history &&
    history.location &&
    history.location.pathname &&
    (history.location.pathname.startsWith('/threads/open') ||
      history.location.pathname.startsWith('/threads/inbox'));

  const { startContactThread, hasThreadOpen } = useStartContactThread({
    client,
    threadsActiveGroupIds,
    currentUser,
    dispatch,
    history,
    channelType,
    inActiveThreadGroup,
    includeInternal: internalThreadsAvailable,
    entity:
      customerContact || customerAccount || teamMemberData || internalGroupData,
  });
  const { hasEmailChannel } = useHasEmailChannel({
    currentUser,
    client,
    threadsActiveGroupIds,
  });

  const { hasFaxChannel } = useHasFaxChannel({
    currentUser,
    client,
    threadsActiveGroupIds,
  });

  const { hasSmsChannel } = useHasSmsChannel({
    currentUser,
    client,
    threadsActiveGroupIds,
  });

  if (error) return null;

  const accountGroups = allGroups ? arrayToObject(allGroups, 'id') : null;

  const CustomerInfoOptionsTestPrep = isTest
    ? FakeComponent
    : CustomerInfoOptions;

  if (customerContact) {
    const CustomerGroupEditComponent = isTest
      ? CustomerGroupEditTestStandin
      : CustomerGroupEdit;

    const userCanEditAccountRep = checkIfCurrentUserHasPermission(
      AVAILABLE_PERMISSIONS.EDIT_CUSTOMER_REP,
      currentUser?.accountPolicies,
      currentUser?.groupPolicies
    );

    const optInOutEditPermission = checkIfCurrentUserHasPermission(
      AVAILABLE_PERMISSIONS.EDIT_CONTACT_OPT_OUT,
      currentUser?.accountPolicies,
      currentUser?.groupPolicies
    );

    const allowEmailsOptOut = checkIfCurrentUserHasPermission(
      AVAILABLE_PERMISSIONS.EDIT_CONTACT_EMAIL_OPT_OUT,
      currentUser?.accountPolicies,
      currentUser?.groupPolicies
    );

    const allowFaxOptOut = checkIfCurrentUserHasPermission(
      AVAILABLE_PERMISSIONS.EDIT_CONTACT_FAX_OPT_OUT,
      currentUser?.accountPolicies,
      currentUser?.groupPolicies
    );
    return (
      <ContactCardSlideout
        loading={loading}
        closeCustomerInfo={closeCustomerInfo}
        customerContact={customerContact}
        retreiveAccountInfoString={retreiveAccountInfoString}
        startContactThread={startContactThread}
        hasThreadOpen={hasThreadOpen}
        styledTheme={styledTheme}
        accountGroups={accountGroups}
        userCanEditAccountRep={userCanEditAccountRep}
        sectionItemCustomStyle={sectionItemCustomStyle}
        optInOutEditPermission={optInOutEditPermission}
        allowEmailsOptOut={allowEmailsOptOut}
        allowFaxOptOut={allowFaxOptOut}
        apiMessageFeatureEnabled={apiMessageFeatureEnabled}
        inActiveThreadGroup={inActiveThreadGroup}
        CustomerGroupEditComponent={CustomerGroupEditComponent}
        CustomerInfoOptionsTestPrep={CustomerInfoOptionsTestPrep}
        allGroups={allGroups}
        hasEmailChannel={hasEmailChannel}
        hasFaxChannel={hasFaxChannel}
        hasSmsChannel={hasSmsChannel}
        channelType={channelType}
      />
    );
  }

  if (customerAccount) {
    return (
      <CustomerAccountCardSlideout
        loading={loading}
        closeCustomerInfo={closeCustomerInfo}
        customerAccount={customerAccount}
        setActiveCustomerSource={setActiveCustomerSource}
        styledTheme={styledTheme}
        history={history}
        sectionItemCustomStyle={sectionItemCustomStyle}
        dispatch={dispatch}
        accountPolicies={currentUser?.accountPolicies}
        groupPolicies={currentUser?.groupPolicies}
      />
    );
  }
  if (teamMemberData) {
    return (
      <TeamMemberSlideout
        loading={loading}
        closeCustomerInfo={closeCustomerInfo}
        customerContact
        styledTheme={styledTheme}
        sectionItemCustomStyle={sectionItemCustomStyle}
        startContactThread={startContactThread}
        teamMemberGroupsNames={teamMemberGroupsNames}
        teamMemberData={teamMemberData}
        isCurrentUser={teamMemberData?.id === currentUser?.userId}
      />
    );
  }

  if (internalGroupData) {
    return (
      <InternalGroupSlideoutContainer
        loading={loading}
        closeCustomerInfo={closeCustomerInfo}
        styledTheme={styledTheme}
        sectionItemCustomStyle={sectionItemCustomStyle}
        internalGroupData={internalGroupData}
        startContactThread={startContactThread}
        currentUser={currentUser}
      />
    );
  }
  return null;
};

CustomerInfo.propTypes = {
  closeCustomerInfo: PropTypes.func.isRequired,
  isTest: PropTypes.bool,
  channelType: PropTypes.string.isRequired,
};

CustomerInfo.defaultProps = {
  isTest: false,
};

export default CustomerInfo;
