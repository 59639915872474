import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import styled, { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import Fade from '@mui/material/Fade';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { useReadThreadsFromApolloCache } from 'client-lib';
import { useApolloClient } from '@apollo/client';
import SettingsSidebar from './SettingsSidebar';
import { closeSnackbar, setActiveSidebar } from '../../actions/general';
import CreateSidebar from './CreateSidebar';
import { Badge, Button, Text, Avatar } from '../../elements';
import THEMES from '../../styles/themes/app';
import {
  Wrapper,
  BadgeWrap,
  IconWrap,
  createButtonStyle,
  iconButtonStyle,
  SidebarWrap,
  AvatarWrap,
  WhitelabelLogoWrap,
  WhitelabelImg,
} from './styles';
import { useSetThreadCounts } from '../Threads';
import { setThreadCounts } from '../../actions';
import useWhitelabel from './useWhitelabel';

const SnackbarContentsWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const CheckIcon = styled.i`
  padding-right: 8px;
  font-size: 22px;
`;
const LinkButton = styled.div`
  padding-left: 8px;
`;

// removes icons from messing with printer preview
const NoPrintIcon = styled.i`
  @media print {
    display: none;
  }
`;

const TruncateOverflowWrapper = styled.span`
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 24px;
  max-width: 50vw;
`;

const SidebarBadge = ({ children, active, ...rest }) => (
  <BadgeWrap>
    <Badge
      badgeBorderColor={
        active
          ? THEMES.SIDEBAR_ICON_ACTIVE_BACKGROUND
          : THEMES.COLORED_BACKGROUND_PRIMARY
      }
      {...rest}
    >
      {children}
    </Badge>
  </BadgeWrap>
);
SidebarBadge.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  active: PropTypes.bool,
};
SidebarBadge.defaultProps = { children: null, active: false };

const Sidebar = ({
  announcementsPagePermission,
  reportsPagePermission,
  dashboardPagePermission,
}) => {
  const client = useApolloClient();
  const history = useHistory();
  const location = useLocation();
  const styledTheme = useContext(ThemeContext);

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state?.session?.currentUser);
  const threadInfo = useSelector((state) => state?.threadInfo);
  const general = useSelector((state) => state?.general);
  const customerInfo = useSelector((state) => state?.customerInfo);
  const threadsActiveGroupIds = useSelector(
    (state) => state?.session?.threadsActiveGroupIds
  );
  const includeInternal = useSelector(
    (state) => state.accountData.account?.ff_internal_threads
  );

  const { whitelabelLogo } = useWhitelabel({ client, currentUser });

  const { pathname } = location;

  const { contactId, userId, groupInboxNotificationsEnabled } = currentUser;

  const showThreadNotification =
    groupInboxNotificationsEnabled === false
      ? threadInfo?.threadCounts?.unreadThreadCount
      : threadInfo?.threadCounts?.unclaimedThreadCount ||
        threadInfo?.threadCounts?.unreadThreadCount;

  let threadBadge = null;
  if (!showThreadNotification) {
    threadBadge = null;
  } else {
    threadBadge = (
      <SidebarBadge
        active={pathname.indexOf('/threads') === 0}
        data-testid="threads-badge"
      />
    );
  }

  let threadsUrl = `/threads/${threadInfo.filter}`;

  if (threadInfo.activeThreadId && threadInfo.filter === 'open') {
    threadsUrl += `/${threadInfo.activeThreadId}`;
  } else if (
    threadInfo.activeUnclaimedThreadId &&
    threadInfo.filter === 'inbox'
  ) {
    threadsUrl += `/${threadInfo.activeUnclaimedThreadId}`;
  }

  let customersUrl = `/contacts/${customerInfo.filter}`;
  if (customerInfo.activeCustomerId) {
    customersUrl += `/${customerInfo.activeCustomerId}`;
  }

  const { snackType, snackAutoHide } = general;

  let snackColor;
  let iconClassName;

  if (snackType === 'warning') {
    snackColor = THEMES.THEME_YELLOW({ theme: styledTheme });
    iconClassName = 'ri-indeterminate-circle-line';
  } else if (snackType === 'error') {
    snackColor = THEMES.THEME_RED({ theme: styledTheme });
    iconClassName = 'ri-error-warning-line';
  } else {
    snackColor = THEMES.THEME_GREEN({ theme: styledTheme });
    iconClassName = 'ri-checkbox-circle-line';
  }

  const autoHideDuration = snackAutoHide ? 4000 : null;

  const { inboxThreads, myOpenThreads } = useReadThreadsFromApolloCache({
    client,
    threadsActiveGroupIds,
    contactId,
    userId,
    includeInternal,
  });

  useSetThreadCounts({
    contactId,
    inboxThreads,
    myOpenThreads,
    setThreadCounts: (payload) => dispatch(setThreadCounts(payload)),
  });

  // this is to mitigate discrepencies between the url and the active sidebar when they do not match.
  useEffect(() => {
    if (
      general.activeSidebar === 'settings' &&
      pathname.indexOf('/settings') !== 0
    ) {
      dispatch(setActiveSidebar('default'));
    }
  }, [pathname]);

  if (general.activeSidebar === 'hidden') return null;

  return (
    <SidebarWrap data-testid="sidebar">
      {general.activeSidebar === 'settings' ? (
        <SettingsSidebar
          history={history}
          whitelabelLogo={whitelabelLogo}
          hasDashboardPermission={dashboardPagePermission}
          onExit={() => dispatch(setActiveSidebar('default'))}
        />
      ) : general.activeSidebar === 'create' ? (
        <CreateSidebar
          currentUser={currentUser}
          activeSidebar={general.activeSidebar}
          dispatchActiveSidebar={(value) => dispatch(setActiveSidebar(value))}
          showAnnouncementsNavItem={announcementsPagePermission}
        />
      ) : (
        <Wrapper>
          {whitelabelLogo ? (
            <WhitelabelLogoWrap>
              <WhitelabelImg
                src={whitelabelLogo}
                onClick={() =>
                  dashboardPagePermission
                    ? history.push('/dashboard')
                    : history.push('/inbox')
                }
              />
            </WhitelabelLogoWrap>
          ) : (
            <AvatarWrap
              data-testid="sidebar-avatar"
              onClick={() =>
                dashboardPagePermission
                  ? history.push('/dashboard')
                  : history.push('/inbox')
              }
            >
              <Avatar
                type="bot"
                customStyle={() => `&:hover{cursor: pointer;}`}
              />
            </AvatarWrap>
          )}
          <IconWrap>
            <Button
              type="noStyle"
              size="zero"
              noOutline
              title={i18n.t('sidebar-CreateSidebar-create', {
                defaultValue: 'Create',
              })}
              onClick={() => dispatch(setActiveSidebar('create'))}
              customStyle={createButtonStyle}
              dataTestId="create-button"
            >
              <NoPrintIcon className="ri-edit-box-line" />
            </Button>
          </IconWrap>
          {dashboardPagePermission && (
            <IconWrap isTop active={pathname.indexOf('/dashboard') === 0}>
              <NavLink
                to="/dashboard"
                title={i18n.t('settings-Dashboard-dashboard', {
                  defaultValue: 'Dashboard',
                })}
                data-testid="dashboard-icon"
              >
                <Button
                  type="noStyle"
                  size="zero"
                  noOutline
                  active={pathname.indexOf('/dashboard') === 0}
                  customStyle={iconButtonStyle}
                >
                  <NoPrintIcon className="ri-dashboard-line" />
                </Button>
              </NavLink>
            </IconWrap>
          )}
          <IconWrap
            isTop={!dashboardPagePermission}
            active={pathname.indexOf('/threads') === 0}
          >
            <NavLink
              to={threadsUrl}
              title={i18n.t('threads-Threads-inbox', { defaultValue: 'Inbox' })}
              data-testid="threads-icon"
            >
              <Button
                type="noStyle"
                size="zero"
                noOutline
                active={pathname.indexOf('/threads') === 0}
                customStyle={iconButtonStyle}
              >
                <NoPrintIcon className="ri-inbox-fill" />
                {threadBadge}
              </Button>
            </NavLink>
          </IconWrap>

          <IconWrap active={pathname.indexOf('/contacts') === 0}>
            <NavLink
              to={customersUrl}
              title={i18n.t('customers-CustomerList-contacts', {
                defaultValue: 'Contacts',
              })}
              data-testid="contacts-icon"
            >
              <Button
                type="noStyle"
                size="zero"
                noOutline
                active={pathname.indexOf('/contacts') === 0}
                customStyle={iconButtonStyle}
              >
                <NoPrintIcon className="ri-contacts-book-2-fill" />
              </Button>
            </NavLink>
          </IconWrap>

          {announcementsPagePermission && (
            <IconWrap active={pathname.indexOf('/broadcasts') === 0}>
              <NavLink
                to="/broadcasts"
                title={i18n.t('settings-SettingsNavigation-broadcastsHover')}
                data-testid="broadcast-icon"
              >
                <Button
                  type="noStyle"
                  size="zero"
                  noOutline
                  active={pathname.indexOf('/broadcasts') === 0}
                  customStyle={iconButtonStyle}
                >
                  <NoPrintIcon className="ri-base-station-fill" />
                </Button>
              </NavLink>
            </IconWrap>
          )}

          {reportsPagePermission && (
            <IconWrap active={pathname.indexOf('/reports') === 0}>
              <NavLink
                to="/reports"
                title={i18n.t('settings-Reports-reports', {
                  defaultValue: 'Reports',
                })}
                data-testid="reports-icon"
              >
                <Button
                  type="noStyle"
                  size="zero"
                  noOutline
                  active={pathname.indexOf('/reports') === 0}
                  customStyle={iconButtonStyle}
                >
                  <NoPrintIcon className="ri-bar-chart-box-fill" />
                </Button>
              </NavLink>
            </IconWrap>
          )}

          <IconWrap active={pathname.indexOf('/search') === 0}>
            <NavLink
              to="/search"
              title={i18n.t('search-Search-search', { defaultValue: 'Search' })}
              data-testid="search-icon"
            >
              <Button
                type="noStyle"
                size="zero"
                noOutline
                active={pathname.indexOf('/search') === 0}
                customStyle={iconButtonStyle}
              >
                <NoPrintIcon className="ri-search-2-fill" />
              </Button>
            </NavLink>
          </IconWrap>

          <IconWrap active={pathname.indexOf('/settings') === 0}>
            <NavLink
              to="/settings/my-account"
              title={i18n.t('settings-SettingsNavigation-settings', {
                defaultValue: 'settings',
              })}
              data-testid="settings-icon"
            >
              <Button
                type="noStyle"
                size="zero"
                noOutline
                active={pathname.indexOf('/settings') === 0}
                customStyle={iconButtonStyle}
              >
                <NoPrintIcon className="ri-settings-3-fill" />
              </Button>
            </NavLink>
          </IconWrap>
        </Wrapper>
      )}
      <Snackbar
        style={{
          top: '0',
          bottom: 'auto',
          left: '50%',
          maxWidth: 'none',
          transform: general.snackbarOpen
            ? 'translate3d(-50%, 0, 0)'
            : 'translate3d(-50, -5px, 0)',
        }}
        open={general.snackbarOpen}
        autoHideDuration={autoHideDuration}
        TransitionComponent={Fade}
        onClose={() => dispatch(closeSnackbar())}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <SnackbarContent
          message={
            <SnackbarContentsWrapper>
              <CheckIcon className={iconClassName} />
              <Text contrast="colorHigh">
                <TruncateOverflowWrapper>
                  {general.snackMessage}
                </TruncateOverflowWrapper>
              </Text>
              {general.snackButtonLink ? (
                <LinkButton>
                  <Button
                    noOutline
                    customStyle={(props) => `
                          padding: 0px;
                          display: block;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                          max-width: 100%;
                          color: ${THEMES.BACKGROUND_PRIMARY(props)};
                      `}
                    type="link"
                    onClick={() => {
                      history.push({
                        pathname: general.snackButtonLink,
                      });
                      dispatch(closeSnackbar());
                    }}
                  >
                    {i18n.t('threads-GlobalHandlers-viewNowLink')}
                  </Button>
                </LinkButton>
              ) : null}
            </SnackbarContentsWrapper>
          }
          style={{
            backgroundColor: snackColor,
            width: '100%',
            minWidth: 'initial',
            display: 'flex',
          }}
        />
      </Snackbar>
    </SidebarWrap>
  );
};

Sidebar.propTypes = {
  announcementsPagePermission: PropTypes.bool,
  reportsPagePermission: PropTypes.bool,
  dashboardPagePermission: PropTypes.bool,
  whitelabelLogo: PropTypes.string,
};

Sidebar.defaultProps = {
  announcementsPagePermission: false,
  reportsPagePermission: false,
  dashboardPagePermission: false,
  whitelabelLogo: null,
};

export default Sidebar;
