import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTruncateEmail } from 'client-lib';
import ShowMoreShowLess from './ShowMoreShowLess';
import MessageText from '../../elements/Text/MessageText';

const CollapsibleEmailText = ({ text }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const { original, truncated, isTruncationMatch } = useTruncateEmail(text);

  if (isTruncationMatch) {
    return (
      <>
        <MessageText contrast="med">
          {isTruncated ? truncated : original}
        </MessageText>

        <ShowMoreShowLess
          isOpen={!isTruncated}
          setIsOpen={(open) => setIsTruncated(!open)}
        />
      </>
    );
  }
  return <MessageText>{text}</MessageText>;
};

CollapsibleEmailText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CollapsibleEmailText;
