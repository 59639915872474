import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import THEMES from '../../styles/themes/library/checkbox';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';

const getStateBackgroundColor = (disabled) =>
  disabled ? THEMES.DISABLED_BACKGROUND : THEMES.BACKGROUND;

const Container = styled.div`
  display: inline-block;
`;

const LabelAndBoxContainer = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent};
  position: relative;
  align-items: ${(props) => (props.multiline ? 'flex-start' : 'center')};
  flex-direction: ${(props) => (props.labelLeft ? 'row-reverse' : 'normal')};
`;

const Box = styled.button`
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
  max-height: 16px;
  max-width: 16px;
  border: 1px solid
    ${(props) => (props.disabled ? THEMES.DISABLED_BORDER : THEMES.BORDER)};
  background-color: ${(props) => getStateBackgroundColor(props.disabled)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  outline: none;
  border-radius: 2px;
  disabled: ${(props) => props.disabled};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  :focus {
    border-color: ${THEMES.FOCUS};
  }
`;

const SquareCheck = styled.i`
  width: 12px;
  height: 12px;
  font-size: 12px;
  color: ${(props) => getStateBackgroundColor(props.disabled)};
  background-color: ${(props) =>
    !props.checked
      ? getStateBackgroundColor(props.disabled)
      : props.disabled
      ? THEMES.DISABLED_CHECK_BACKGROUND
      : THEMES.CHECK_BACKGROUND};
`;

const Label = styled.div`
  font-size: ${FONTSIZE_THEMES.TEXT};
  font-weight: 500;
  font-family: 'Barlow', sans-serif;
  color: ${(props) => (props.disabled ? THEMES.DISABLED_LABEL : THEMES.LABEL)};
  padding: ${(props) => (props.labelLeft ? '0 6px 0 0' : '0 0 0 6px')};
`;

const IndeterminateStateLine = styled.div`
  border-top: solid 1px ${THEMES.BORDER};
  width: 8px;
  position: absolute;
`;

const Checkbox = React.forwardRef(
  (
    {
      checked,
      onCheck,
      label,
      labelLeft,
      disabled,
      multiline,
      customBoxStyle,
      customCheckStyle,
      customLabelStyle,
      customContainerStyle,
      dataTestId,
      indeterminate,
      justifyContent,
      ...otherProps
    },
    ref
  ) => (
    <Container
      checked={checked}
      disabled={disabled}
      customContainerStyle={customContainerStyle}
      css={(props) => props?.customContainerStyle?.(props)}
    >
      <LabelAndBoxContainer
        labelLeft={labelLeft}
        multiline={multiline}
        justifyContent={justifyContent}
      >
        <Box
          checked={checked}
          onClick={onCheck}
          ref={ref}
          disabled={disabled}
          customBoxStyle={customBoxStyle}
          css={(props) => props?.customBoxStyle?.(props)}
          data-testid={dataTestId}
          {...otherProps}
        >
          <SquareCheck
            checked={checked}
            disabled={disabled}
            customCheckStyle={customCheckStyle}
            css={(props) => props?.customCheckStyle?.(props)}
            className="ri-check-line"
          />
        </Box>

        {indeterminate && !disabled && !checked && <IndeterminateStateLine />}

        {label && (
          <Label
            checked={checked}
            labelLeft={labelLeft}
            disabled={disabled}
            customLabelStyle={customLabelStyle}
            css={(props) => props?.customLabelStyle?.(props)}
          >
            {label}
          </Label>
        )}
      </LabelAndBoxContainer>
    </Container>
  )
);

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired,
  label: PropTypes.string,
  labelLeft: PropTypes.bool,
  disabled: PropTypes.bool,
  customBoxStyle: PropTypes.func,
  customCheckStyle: PropTypes.func,
  customLabelStyle: PropTypes.func,
  customContainerStyle: PropTypes.func,
  dataTestId: PropTypes.string,
  multiline: PropTypes.bool,
  indeterminate: PropTypes.bool,
  justifyContent: PropTypes.string,
};

Checkbox.defaultProps = {
  label: '',
  labelLeft: false,
  disabled: false,
  customBoxStyle: null,
  customCheckStyle: null,
  customLabelStyle: null,
  customContainerStyle: null,
  dataTestId: null,
  multiline: false,
  indeterminate: false,
  justifyContent: 'center',
};

Checkbox.displayName = 'Checkbox';

export default Checkbox;
