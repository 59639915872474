import { useMutation } from "@apollo/client";
import DeleteCustomerContactMutation from "../../../../graphql/mutation/DeleteCustomerContactMutation.graphql";
import DeleteCustomerAccountMutation from "../../../../graphql/mutation/DeleteCustomerAccountMutation.graphql";
import useForm from "../../../../utils/hooks/useForm";

/*
DOCUMENTATION

this hook is used to delete a customerContact or delete a customerAccount

args:
  client: (obj) required. apolloClient instance
  customer: (obj) required. customerContact OR customerAccount object instance. the one that will potentially be deleted
  onDeleteAccountError: (func) optional. callback that will fire on delete customer account error
  onDeleteAccountSuccess: (func) optional. callback that will fire on delete customer account success
  onDeleteContactError: (func) optional. callback that will fire on delete customer contact error
  onDeleteContactSuccess: (func) optional. callback that will fire on delete customer contact success

returns:
  deleteContact: (func) will send mutation to delete customerContact
  deleteAccount: (func) will send mutation to delte customerAccount
*/

function useDeleteCustomer({
  client,
  customer,
  setDeleteLoading,
  onDeleteAccountError,
  onDeleteAccountSuccess,
  onDeleteContactError,
  onDeleteContactSuccess,
  i18n,
  deleteMode,
}) {
  const [deleteCustomerContactMutation] = useMutation(
    DeleteCustomerContactMutation,
    { client }
  );
  const [deleteCustomerAccountMutation] = useMutation(
    DeleteCustomerAccountMutation,
    { client }
  );
  const requiredValidation = {
    type: "required",
    errorMessage: i18n.t("slideouts-CreateCustomerForm-required"),
  };
  const inputFieldsConfig = {
    reason: { value: "", validations: [requiredValidation] },
    notes: { value: "" },
  };

  const deleteContact = async () => {
    const deleteData = await deleteContactMutate();
    setDeleteLoading?.(false);
    if (!deleteData) onDeleteContactError();
    else if (onDeleteContactSuccess) onDeleteContactSuccess();
  };

  const { fields, onSubmit, updateField } = useForm(
    inputFieldsConfig,
    async (formValues) => {
      setDeleteLoading(true);
      const submitValues = {
        notes: formValues?.notes?.value,
        reason: formValues?.reason?.value,
      };
      if (deleteMode === "companyContacts") {
        submitValues.deleteCustomers = true;
      }
      if (deleteMode === "onlyCompany") {
        submitValues.deleteCustomers = false;
      }

      deleteAccount({ submitValues });
    }
  );

  const deleteAccount = async ({ submitValues }) => {
    const deleteData = await deleteAccountMutate(submitValues);
    setDeleteLoading?.(false);
    if (!deleteData) onDeleteAccountError();
    else onDeleteAccountSuccess();
  };

  const deleteContactMutate = async () => {
    const { id: customerContactId } = customer;
    setDeleteLoading?.(true);

    const data = await deleteCustomerContactMutation({
      variables: {
        input: {
          customerContactId,
        },
      },
    }).catch(console.warn);

    if (data && !data.data.deleteCustomerContact.errors) return true;
    return false;
  };

  const deleteAccountMutate = async (submitValues) => {
    const { id: customerAccountId } = customer;
    let inputValues = { customerAccountId };
    if (submitValues) {
      inputValues = { ...inputValues, ...submitValues };
    }
    const data = await deleteCustomerAccountMutation({
      variables: {
        input: inputValues,
      },
    }).catch(console.warn);

    if (data && !data.data.deleteCustomerAccount.errors) return true;
    return false;
  };

  return {
    deleteContact,
    deleteAccount,
    fields,
    updateField,
    onSubmit,
  };
}

export default useDeleteCustomer;
