import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { usePrintThread } from 'client-lib';
import { ThemeProvider } from '@mui/system';
import Message from './Message';
import PrintContactHeader from './PrintContactHeader';
import PrintGroupHeader from './PrintGroupHeader';
import PrintInternalHeader from './PrintInternalHeader';
import PrintOtherExternalContacts from './PrintOtherExternalContacts';

const HistoryWrap = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const SingleThreadPrint = ({ thread }) => {
  const { data } = usePrintThread({ threadId: thread?.id });
  const fontSize = useSelector(
    (state) => state?.session?.currentUser?.fontSize
  );
  const mode = 'light';

  return (
    <ThemeProvider
      theme={{ mode, fontSize: fontSize?.toLowerCase() || 'default' }}
    >
      <HistoryWrap>
        {thread?.externalContact && <PrintContactHeader thread={thread} />}
        {thread?.group && !thread?.externalContact && (
          <PrintGroupHeader thread={thread} />
        )}
        {!thread?.group && !thread?.externalContact && (
          <PrintInternalHeader thread={thread} />
        )}
        {thread?.otherExternalContacts && (
          <PrintOtherExternalContacts thread={thread} />
        )}

        {data?.threadHistory?.map?.((edge) => {
          return <Message key={edge.id} message={edge} printMessage />;
        })}
      </HistoryWrap>
    </ThemeProvider>
  );
};

SingleThreadPrint.propTypes = {
  thread: PropTypes.object.isRequired,
};

export default SingleThreadPrint;
