import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import SeparatorDate from '../Common/SeparatorDate';
import THEMES from '../../styles/themes/app';
import { MetaText } from '../../elements';
import ThreadedMultiTag from '../Tag/ThreadedMultiTag';
import Print from '../Print/Print';
import PrintThreads from './PrintThreads';
import SingleThreadPrint from './SingleThreadPrint';

const Wrapper = styled.div`
  padding: 0 20px;
`;

const DateThreadRefWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 32px;
`;

const SeparatorHr = styled.div`
  background: ${THEMES.BORDER_COLOR};
  height: 1px;
  margin: 5px 0 10px 0;
  text-align: center;

  & > span {
    background: ${THEMES.BACKGROUND_PRIMARY};
    padding: 8px;
    position: relative;
    top: -8px;
  }
`;

const VerticalHr = styled.div`
  background: ${THEMES.BORDER_COLOR};
  height: 16px;
  width: 1px;
  margin-right: 16px;
  margin-left: 16px;
  text-align: center;
`;

const DateAndGroupWrapper = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 16px;
`;

const Separator = ({
  thread,
  includeGroup,
  firstMessageOutgoing,
  flipTagBubbleCallback,
  flipWorkOrderOptions,
}) => (
  <Wrapper>
    <DateThreadRefWrap>
      <DateAndGroupWrapper>
        <SeparatorDate value={thread.insertedAt} />
        {includeGroup ? (
          <>
            <MetaText contrast="low">
              &nbsp;
              {firstMessageOutgoing
                ? i18n.t('threads-Separator-from')
                : i18n.t('threads-Separator-to')}
              &nbsp;
            </MetaText>
            <MetaText
              contrast="med"
              customStyle={() => `
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                `}
            >
              {thread?.group?.name}
            </MetaText>
          </>
        ) : null}
      </DateAndGroupWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Print printLabel={i18n.t('threads-ThreadDetailsHeader-print')}>
          <PrintThreads>
            <SingleThreadPrint thread={thread} />
          </PrintThreads>
        </Print>
        <VerticalHr />
        <ThreadedMultiTag
          thread={thread}
          flipTagBubbleCallback={flipTagBubbleCallback}
          flipWorkOrderOptions={flipWorkOrderOptions}
        />
      </div>
    </DateThreadRefWrap>
    <SeparatorHr />
  </Wrapper>
);

Separator.propTypes = {
  thread: PropTypes.instanceOf(Object).isRequired,
  includeGroup: PropTypes.bool,
  firstMessageOutgoing: PropTypes.bool,
  flipTagBubbleCallback: PropTypes.func,
  flipWorkOrderOptions: PropTypes.bool,
};

Separator.defaultProps = {
  includeGroup: false,
  firstMessageOutgoing: false,
  flipTagBubbleCallback: undefined,
  flipWorkOrderOptions: false,
};

export default Separator;
