import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import theme from 'styled-theming';

import { Heading2, Button, IconButton } from '../../../elements';
import THEMES from '../../../styles/themes/app';

import COLORS from '../../../styles/colors';
import Flyout from '../../Flyout2/Flyout';
import EditBroadcastListFlyout from './pages/EditBroadcastListFlyout';
import { MAX_LIST_LENGTH } from '../../../utils/helpers/constants';

export const wizardBodyClassName = 'broadcast-list-template-body';

// generic colors
const FOOTER = theme('mode', {
  classic: COLORS.PK_CLASSIC.PRIMARY_NAVY,
  light: COLORS.PK.BLUE_100,
  dark: COLORS.PK_DARK.BLACK,
  holiday: COLORS.PK_HOLIDAY.HOLIDAY_PRIMARY,
});

const TemplateContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${THEMES.BACKGROUND_SECONDARY};
`;

const Header = styled.div`
  min-height: 80px;
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  display: flex;
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
`;

const Title = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const Body = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  background-color: ${THEMES.BACKGROUND_SECONDARY};
  overflow-y: auto;
`;

const FooterContainer = styled.div`
  min-height: 80px;
  background-color: ${FOOTER};
  border-top: 1px solid ${THEMES.BORDER_COLOR};
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.isEdit ? 'flex-end' : 'space-between')};
  padding: 0 20px;
`;

const HeaderSpan = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  column-gap: 6px;
  width: 80%;
`;

const IconButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const getTextOverflowStyle = () => `
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CreateBroadcastListTemplate = ({
  backButtonAction,
  children,
  continueButtonAction,
  continueButtonDisabled,
  continueButtonText,
  customHeaderCenter,
  flyoutPrimaryButton,
  showIcon,
  tag,
  isEdit,
}) => {
  const [editListFlyout, setEditListFlyout] = useState(false);
  const [name, setName] = useState(tag.name);
  const [nameError, setNameError] = useState(tag.error);
  const [description, setDescription] = useState(tag.description);
  const [descriptionError, setDescriptionError] = useState(
    tag.descriptionError
  );

  useEffect(() => {
    setName(tag.name);
    setDescription(tag.description);
  }, [tag.name, tag.description]);

  // Handles naming List
  const handleInputTagChange = (e) => {
    if (e.target.value === '') {
      setNameError(i18n.t('slideouts-GroupMessageOverview-listNameRequired'));
    } else if (e.target.value.length > MAX_LIST_LENGTH) {
      setNameError(
        i18n.t('broadcasts-maximum-characterError', {
          defaultValue: "You've exceeded the 255 maximum character limit.",
        })
      );
    } else {
      setNameError('');
    }
    setName(e.target.value);
  };

  const handleInputListDescriptionChange = (e) => {
    if (e.target.value?.length > 255) {
      setDescriptionError(
        i18n.t('broadcasts-maximum-characterError', {
          defaultValue: "You've exceeded the 255 maximum character limit.",
        })
      );
    } else {
      setDescriptionError('');
    }
    setDescription(e.target.value);
  };

  const closeFlyout = ({ save = false }) => {
    setEditListFlyout(false);
    setNameError('');
    setDescriptionError('');
    if (!save) {
      setDescription(tag.description);
      setName(tag.name);
    }
  };

  const handleSave = () => {
    flyoutPrimaryButton(name, description);
    closeFlyout({ save: true });
  };

  return (
    <TemplateContainer data-testid="broadcast-list-template">
      <Flyout
        title={i18n.t('settings-AddOrEditList-editList', {
          defaultValue: 'Edit List',
        })}
        isOpen={editListFlyout}
        onRequestClose={closeFlyout}
        buttonActions={{
          primary: handleSave,
          secondary: closeFlyout,
        }}
        buttonProps={{
          primary: { disabled: nameError || descriptionError },
        }}
        buttonLabels={{
          primary: i18n.t('settings-AddOrEditList-save', {
            defaultValue: 'Save',
          }),
          secondary: i18n.t('broadcasts-ReachableAudienceModal-close', {
            defaultValue: 'Close',
          }),
        }}
      >
        <EditBroadcastListFlyout
          setName={handleInputTagChange}
          setDescription={handleInputListDescriptionChange}
          description={description}
          descriptionError={descriptionError}
          name={name}
          nameError={nameError}
        />
      </Flyout>
      <Header>
        <Title>
          {customHeaderCenter || (
            <HeaderSpan data-testid="broadcast-list-template-title">
              <Heading2 customStyle={() => getTextOverflowStyle()}>
                {tag.name}
              </Heading2>
              {showIcon && (
                <IconButtonContainer>
                  <IconButton
                    onClick={() => {
                      setEditListFlyout(true);
                    }}
                    contrast="highColor"
                    dataTestId="edit-list-icon"
                  >
                    <i className="ri-pencil-fill" />
                  </IconButton>
                </IconButtonContainer>
              )}
            </HeaderSpan>
          )}
        </Title>
      </Header>
      <Body className={wizardBodyClassName}>{children}</Body>
      <FooterContainer
        data-testid="broadcast-list-template-footer"
        isEdit={isEdit}
      >
        {!isEdit && (
          <Button
            type="tertiary"
            onClick={backButtonAction}
            dataTestId="broadcastlist-back-button"
          >
            {i18n.t('slideouts-GroupMessageOverview-back')}
          </Button>
        )}
        <Button
          disabled={continueButtonDisabled}
          onClick={continueButtonAction}
          dataTestId="broadcastlist-continue-button"
        >
          {continueButtonText}
        </Button>
      </FooterContainer>
    </TemplateContainer>
  );
};

CreateBroadcastListTemplate.propTypes = {
  backButtonAction: PropTypes.func,
  children: PropTypes.element.isRequired,
  continueButtonAction: PropTypes.func,
  continueButtonDisabled: PropTypes.bool,
  continueButtonText: PropTypes.string,
  customHeaderCenter: PropTypes.element,
  flyoutPrimaryButton: PropTypes.func,
  headerRightElement: PropTypes.element,
  showIcon: PropTypes.bool,
  tag: PropTypes.shape({
    name: PropTypes.string,
    error: PropTypes.string,
    description: PropTypes.string,
    descriptionError: PropTypes.string,
  }),
  isEdit: PropTypes.bool,
};

CreateBroadcastListTemplate.defaultProps = {
  backButtonAction: () => {},
  continueButtonAction: () => {},
  continueButtonDisabled: false,
  continueButtonText: '',
  customHeaderCenter: null,
  flyoutPrimaryButton: () => {},
  headerRightElement: null,
  showIcon: false,
  tag: {
    name: '',
    error: '',
    description: '',
    descriptionError: '',
  },
  isEdit: false,
};

export default CreateBroadcastListTemplate;
