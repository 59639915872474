import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { ThemeContext } from 'styled-components';
import { Text } from '../../../elements';
import { FAILED_MESSAGE_LINE_HEIGHT } from './styles';

/**
 * Represents a component that displays a failed message error.
 * @component
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The children to be displayed.
 * @param {string} [props.dataTestId] - The data test id.
 *
 * @returns {JSX.Element} The rendered FailedMessageError component.
 */

const FailedMessageError = ({ children, dataTestId }) => {
  const styledTheme = useContext(ThemeContext);

  return (
    <Text
      contrast="primaryRed"
      customStyle={() =>
        `line-height: ${FAILED_MESSAGE_LINE_HEIGHT({ theme: styledTheme })};`
      }
      dataTestId={dataTestId || 'threads-Message-FailedMessageError'}
    >
      <span
        style={{
          fontWeight: 'bold',
        }}
      >
        {i18n.t('threads-Message-messageFailed')}:{' '}
      </span>
      {children}
    </Text>
  );
};

FailedMessageError.propTypes = {
  children: PropTypes.node,
  dataTestId: PropTypes.string,
};

FailedMessageError.defaultProps = {
  children: null,
  dataTestId: null,
};

export default FailedMessageError;
