import i18n from 'i18n-js';
import { openSnackbar } from '../../../actions/general';
import * as TYPES from '../../../utils/helpers/types';

// Table sorting options for SortBy component
export const getSortOptions = (i18n) => [
  {
    label: i18n.t('dashboards-GroupSelect-Ascending', {
      defaultValue: 'Ascending (A-Z)',
    }),
    value: 'ASC',
  },
  {
    label: i18n.t('dashboards-GroupSelect-Descending', {
      defaultValue: 'Descending (Z-A)',
    }),
    value: 'DESC',
  },
];

// Handles displaying green snackbar on API call success
export const onSuccess = (dispatch, message) => {
  dispatch(openSnackbar(message));
};

// Handles displaying red snackbar on API call failure/error
export const onError = (dispatch, message) => {
  dispatch(openSnackbar(message, 'error'));
};

/**
 * Gets one line of text to display in the status column.
 * Possible to have multiple states for why a contact can't be selected.
 * @param {TYPES.ContactSMSSettings} contact
 * @returns {string} One line of text to display in the status column.
 */
export const getOptInStatusText = (contact) => {
  if (!contact) return '';
  if (contact.smsStatus?.includes(TYPES.SMS_STATUS.not_in_service)) {
    return i18n.t('broadcasts-list-outOfService', {
      defaultValue:
        'This number has been disconnected or is no longer in service.',
    });
  } else if (contact.smsStatus?.includes(TYPES.SMS_STATUS.landline)) {
    return i18n.t('broadcasts-list-landline', {
      defaultValue: 'Number is not text compatible',
    });
  } else if (
    contact.promotionalBroadcastOptOut === false &&
    contact.announcementsOptOut === false
  ) {
    return i18n.t('broadcasts-broadcast-repliedStart', {
      defaultValue: 'Replied "START"',
    });
  } else if (
    contact.promotionalBroadcastOptOut &&
    contact.announcementsOptOut === false
  ) {
    return i18n.t('broadcasts-list-noPromo', {
      defaultValue: 'Replied "NO PROMO"',
    });
  } else if (
    contact.promotionalBroadcastOptOut &&
    contact.announcementsOptOut
  ) {
    return i18n.t('broadcasts-broadcast-repliedStop', {
      defaultValue: 'Replied "STOP"',
    });
  }

  return '';
};
