import { useQuery } from "@apollo/client";
import THREAD_QUERY from "../../graphql/query/ThreadQuery.graphql";

/**
 * @typedef {Object} UseQueryWrapper
 * @property {object} client - Apollo Client instance
 * @property {any} options - Other Apollo options
 */

/**
 * this hook is meant to be used for a generic thread query for the client.
 * @param {UseQueryWrapper} param0
 * @returns {object} make of loading, error and data
 */
const useThreadQuery = ({ client, ...options }) =>
  useQuery(THREAD_QUERY, { client, ...options });

export default useThreadQuery;
