import React from 'react';
import PropTypes from 'prop-types';
import { formatDataTestName } from 'client-lib/src/lib/utils/helpers';
import IconButton from '../../../elements/Button/IconButton';
import { Heading2, Text } from '../../../elements/Text/Text';
import { Avatar } from '../../../elements';
import {
  AvatarContainer,
  Container,
  InnerContainer,
  TitleWrapper,
  getSubtitleTextStyles,
  TilteButtonContainer,
  FlexBox,
} from './styles';

const Header = ({
  headerIcon,
  onRequestClose,
  subtitle,
  title,
  titleButton,
  titleButtonProps,
  titleButtonAction,
}) => {
  return (
    <Container>
      <InnerContainer>
        <TitleWrapper>
          <FlexBox>
            {headerIcon && (
              <AvatarContainer>
                <Avatar
                  role="img"
                  icon={headerIcon}
                  size={subtitle ? 'sm' : 'lg'}
                />
              </AvatarContainer>
            )}
            <Heading2 customStyle={() => `overflow-wrap: anywhere;`}>
              {title}
            </Heading2>
            <TilteButtonContainer>
              {titleButton && (
                <IconButton
                  size="lg"
                  onClick={() => titleButtonAction()}
                  contrast={titleButtonProps?.contrast}
                  className={titleButton}
                />
              )}
            </TilteButtonContainer>
          </FlexBox>
          <IconButton
            size="lg"
            onClick={onRequestClose}
            dataTestId={`close-${formatDataTestName(title)}-icon-button`}
          >
            <i className="ri-close-line" />
          </IconButton>
        </TitleWrapper>
        <Text customStyle={getSubtitleTextStyles}>{subtitle}</Text>
      </InnerContainer>
    </Container>
  );
};

Header.propTypes = {
  headerIcon: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleButton: PropTypes.string,
  titleButtonProps: PropTypes.object,
  titleButtonAction: PropTypes.func,
};

Header.defaultProps = {
  headerIcon: '',
  subtitle: '',
  titleButton: '',
  titleButtonAction: () => {},
  titleButtonProps: {},
};

export default Header;
