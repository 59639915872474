import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import THEMES from '../../../../styles/themes/app';
import {
  Heading3,
  Text,
  IconButton,
  IconBorderButton,
  InlineTextButton,
} from '../../../../elements';
import CustomerInfoOptions from '../../CustomerInfoOptions';
import useMeasure from '../../../Animations/useMeasure';

const HeaderContainer = styled.div`
  display: flex;
  max-height: 178px;
  min-height: 120px;
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 12px;
`;

const MainHeaderContainer = styled.div`
  max-width: 250px;
  display: flex;
  flex-direction: row;
  column-gap: 2px;
  padding-top: 8px;
  padding-bottom: 4px;
`;

const SubHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  max-width: 500px;
  text-align: center;
  padding-bottom: 12px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 4px;
  column-gap: 8px;
`;

const TopButtonWidthContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  padding-top: 16px;
`;
const TopButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  padding-right: 12px;
`;

const HeaderTextContainer = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
`;

const CustomerAccountHeaderContainer = styled.div``;

const CustomerAccountHeader = ({
  closeCustomerInfo,
  customerAccount,
  handleClickOnMode,
  userCanEditCompanies,
}) => {
  const [bind, measure] = useMeasure();
  const { accountNumber, id, name, __typename } = customerAccount;
  const history = useHistory();
  const bubbleDisplace = measure.width - 122;
  const contentBubbleDisplace = measure.width - 246;
  return (
    <CustomerAccountHeaderContainer>
      <TopButtonWidthContainer {...bind}>
        <TopButtonContainer>
          <CustomerInfoOptions
            key={customerAccount?.id}
            customer={customerAccount}
            onDelete={() => history.push('/contacts/all')}
            disabled={!userCanEditCompanies}
            adjustCarrotOverride={{ displayOptions: 54, contentMode: 178 }}
            optionsBubbleOverride={{
              displayOptions: bubbleDisplace,
              contentMode: contentBubbleDisplace,
            }}
          />
          <IconButton onClick={closeCustomerInfo} size="lg">
            <i className="ri-close-fill" />
          </IconButton>
        </TopButtonContainer>
      </TopButtonWidthContainer>
      <HeaderContainer>
        <MainHeaderContainer>
          <HeaderTextContainer>
            <Heading3 maxWidth="275px">{name}</Heading3>
          </HeaderTextContainer>

          {userCanEditCompanies ? (
            <IconButton
              onClick={() => {
                handleClickOnMode('edit');
              }}
              size="sm"
              contrast="highColor"
            >
              <i className="ri-pencil-fill" />
            </IconButton>
          ) : null}
        </MainHeaderContainer>
        <SubHeaderContainer>
          {accountNumber ? (
            <Text contrast="low" maxWidth="200px">
              {accountNumber}
            </Text>
          ) : (
            <InlineTextButton
              type="EmphasisText"
              noOutline
              onClick={() => {
                handleClickOnMode('edit', true);
              }}
            >
              {i18n.t('slideouts-CustomerInfo-addAccountNumber', {
                defaultValue: 'Add Account Number',
              })}
            </InlineTextButton>
          )}
        </SubHeaderContainer>
        <ButtonsContainer>
          <NavLink to={`/contacts/all/${id}/${__typename?.toLowerCase()}`}>
            <IconBorderButton>
              <i className="ri-contacts-book-2-fill" />
            </IconBorderButton>
          </NavLink>
        </ButtonsContainer>
      </HeaderContainer>
    </CustomerAccountHeaderContainer>
  );
};

CustomerAccountHeader.propTypes = {
  companyName: PropTypes.string,
  closeCustomerInfo: PropTypes.func.isRequired,
  customerAccount: PropTypes.object,
  handleClickOnMode: PropTypes.func.isRequired,
  userCanEditCompanies: PropTypes.bool.isRequired,
};

CustomerAccountHeader.defaultProps = {
  companyName: '',
  customerAccount: {},
};

export default CustomerAccountHeader;
