import { useRef } from "react";
import useAccountQuery from "../../../api/query/useAccountQuery";
import useThreadQuery from "../../../api/query/useThreadQuery";
import useCustomerQuery from "../../../api/query/useCustomerQuery";
import { getEntityData } from "../../../utils/helpers";
import useReadThreadsFromApolloCache from "./useReadThreadsFromApolloCache";
import useUserGroups from "./useUserGroups";

/*

DOCUMENTATION:
  useThreads is meant to be used with the top level Threads component. handles reading the cache for active/open threads, groups, as well as getting thread
  data and contact data. It also does a small amount of business logic like sorting the threads by date.

  args:
    client: (obj) required. Apollo Client instance.
    currentUser: (obj) required. current user object found in client side redux store -> session.currentUser
    threadsActiveGroupIds: (arr) required. active group ids of desired threads
    activeThreadId: (str) optional. id of active thread if applicable
    allGroups: (arr) required. Groups array that comes from the state manager.

  returns:
    inboxThreads: (arr) time sorted array of inbox threads
    myOpenThreads: (arr) time sorted array of open threads that the user "owns"
    allOpenThreads: (arr) time sorted array of all open threads in every group the user is subscribed to.
    groups: (arr) alphabetically sorted array of groups
    activeThread: (obj) thread instance of active thread (if activeThreadId provided)
    externalContactDetails: (obj) contact instance of active thread (if activethreadId provided)

*/

const useThreads = ({
  client,
  currentUser,
  threadsActiveGroupIds,
  activeThreadId,
  includeInternal,
  allGroups,
}) => {
  const { userId, contactId } = currentUser;

  const {
    inboxThreads,
    inboxSpamThreads,
    myOpenThreads,
    allOpenThreads,
    myClosedThreads,
    allClosedThreads,
  } = useReadThreadsFromApolloCache({
    client,
    threadsActiveGroupIds,
    contactId,
    userId,
    includeInternal,
  });

  const { data: accountData } = useAccountQuery({
    client,
    variables: {
      id: currentUser?.accountId,
    },
  });

  // Need to grab the message directly when activeThreadId is provided
  // Replaces the use of all data in the various Threads arrays
  //  Allowing those arrays to contian only the needed items for the list
  // PRKP-6113
  const { data: activeThreadData } = useThreadQuery({
    client,
    variables: {
      id: activeThreadId,
    },
    skip: !activeThreadId,
  });

  // PRKP-7925 - Force cache update for primary contact
  // Allows being unable to expand contact details if the contact
  // is not in a message on the screen
  useCustomerQuery({
    client,
    customerContactId: activeThreadData?.thread?.externalContact?.id,
  });

  const presenceTrackingAvailable = accountData?.account?.ff_presence_tracking;

  const { groups } = useUserGroups(currentUser, allGroups);

  // we are using a ref here to have more control over the activeThread, we don't want it to revert back
  // to null if the cache is temporarily null.
  const activeThreadRef = useRef(null);
  let externalContactDetails = null;
  let internalContactDetails = null;
  let groupContactDetails = null;

  if (activeThreadId) {
    const threadData = activeThreadData?.thread;

    if (threadData) {
      activeThreadRef.current = threadData;
    }
  } else {
    activeThreadRef.current = null;
  }

  const activeThread = activeThreadRef?.current;

  if (activeThread?.externalContact?.id) {
    externalContactDetails = getEntityData({
      client,
      customerId: activeThread.externalContact.id,
    });
  }
  if (!activeThread?.externalContact && activeThread?.groupShares?.length) {
    groupContactDetails = activeThread?.groupShares?.[0]?.group;
  }
  if (activeThread?.type === "internal" && activeThread?.userShares?.length) {
    internalContactDetails = activeThread.userShares.filter(
      (userShare) => userShare.userId !== userId
    )?.[0];
  }

  return {
    inboxThreads,
    inboxSpamThreads,
    myOpenThreads,
    allOpenThreads,
    myClosedThreads,
    allClosedThreads,
    groups,
    activeThread,
    externalContactDetails,
    internalContactDetails,
    groupContactDetails,
    presenceTrackingAvailable,
  };
};

export default useThreads;
