import React from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import {
  useClaimAndCloseThreadMutation,
  useCloseThreadMutation,
} from 'client-lib';
import i18n from 'i18n-js';
import { Button } from '../../elements';
import store from '../../store';

const CloseThreadButton = ({ threadType }) => {
  const { activeThreadId } = useParams();
  const history = useHistory();
  const client = useApolloClient();
  const [handleCloseThread] = useCloseThreadMutation({ client, store });
  const [handleClaimAndCloseThread] = useClaimAndCloseThreadMutation({
    client,
    store,
    history,
  });

  const handleOnClick = () => {
    if (threadType === 'fax') {
      handleClaimAndCloseThread({
        threadId: activeThreadId,
        onSuccess: () => history.push('/threads/inbox/'),
      });
    } else {
      handleCloseThread({ threadId: activeThreadId });
    }
  };

  let closeText;

  switch (threadType) {
    case 'fax':
      closeText = i18n.t('threads-CloseThreadButton-endFaxConversation', {
        defaultValue: 'Acknowledge and close thread',
      });
      break;
    case 'internal':
      closeText = i18n.t('threads-CloseThreadButton-endConversation');
      break;
    default:
      closeText = i18n.t('threads-ThreadDetailsHeader-close');
      break;
  }
  return (
    <Button
      type="secondaryLight"
      dataTestId="close-thread-button"
      title="Close Thread"
      iconAndText
      onClick={handleOnClick}
    >
      <i className="ri-check-double-line" />
      {closeText}
    </Button>
  );
};

CloseThreadButton.propTypes = {
  threadType: PropTypes.string,
};

CloseThreadButton.defaultProps = {
  threadType: '',
};

export default CloseThreadButton;
