import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import ClickableCard from '../../elements/EntityCard/ClickableCard';

const OtherExternalContactsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 12px;
`;

const CardContainer = styled.div``;

const PrintOtherExternalContacts = ({ thread }) => {
  return (
    <OtherExternalContactsContainer>
      {thread?.otherExternalContacts.map((contact) => {
        const maintext = contactName(contact);
        return (
          <CardContainer>
            <ClickableCard maintext={maintext} />
          </CardContainer>
        );
      })}
    </OtherExternalContactsContainer>
  );
};

PrintOtherExternalContacts.propTypes = {
  thread: PropTypes.object.isRequired,
};

export default PrintOtherExternalContacts;
