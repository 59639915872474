import { useState } from "react";
import { useMutation } from "@apollo/client";
import addCustomerContactToGroupMutation from "../../../../graphql/mutation/AddCustomerContactToGroupMutation.graphql";
import removeCustomerContactFromGroupMutation from "../../../../graphql/mutation/RemoveCustomerContactFromGroupMutation.graphql";
import { arrayToObject } from "../../../../utils/helpers";

/*
DOCUMENTATION:

useCustomerGroupEdit is designed to be used with the component CustomerGroupEdit.

args:
  customer: (obj) required. this is a CustomerAccount object instance.
  onSave: (func) optional. a callback function the dev would like to run after the success of saving the customers groups
  client: (obj) required. this is an instance of the apolloClient needed for using useMutation outside of web/mobile context

returns:
  groupDropdownVal: (str) the value that goes into the input field when querying for groupss
  onGroupInputChange: (func) updates groupDropdownVal
  invalidMessage: (str) error message if groups invalid
  groupIds: (obj) the group Id's that have been selected to be a part of the displayed groups before save
  removeFromGroupIds: (func) use when user is deleting groups from temporary groups list (the one shown before save)
  onSuggestionSelect: (fumc) use when user selects option from dropdown, adds locatio to temp list
  handleSubmit: (func) submit's new/removed group ids, will validate that there's at least one group assigned.
*/

function useCustomerGroupEdit({ customer, onSave, client }) {
  const [groupDropdownVal, setGroupDropdownVal] = useState("");
  const [groupIds, setGroupIds] = useState(customer?.groupIds || []);
  const [invalidMessage, setInvalidMessage] = useState("");

  const [addCustomerContactToGroup] = useMutation(
    addCustomerContactToGroupMutation,
    { client }
  );
  const [removeCustomerContactFromGroup] = useMutation(
    removeCustomerContactFromGroupMutation,
    { client }
  );

  const onGroupInputChange = (e, { newValue }) => {
    if (e.target && e.target.value) setGroupDropdownVal(e.target.value);
    else setGroupDropdownVal(newValue);
  };

  const onSuggestionSelect = (e, { suggestion }) => {
    const alreadyHasId = groupIds.reduce((bool, val) => {
      if (bool) return true;
      return val === suggestion.id;
    }, false);

    if (alreadyHasId) {
      setGroupDropdownVal("");
      return;
    }

    const newGroupIds = [...groupIds];
    newGroupIds.push(suggestion.id);

    setGroupDropdownVal("");
    setGroupIds(newGroupIds);
    setInvalidMessage("");
  };

  const removeFromGroupIds = (id) => {
    const newGroupIds = groupIds.filter((gId) => gId !== id);

    setGroupIds(newGroupIds);
  };

  const handleSubmit = () => {
    const { groupIds: origIds } = customer;

    const [addedIds, removedIds] = getAddedAndRemovedIds();

    // validation
    if (origIds.length + addedIds.length - removedIds.length < 1) {
      setInvalidMessage(
        "There must be at least one group assigned to this customer."
      );
      return;
    }

    alteredGroupsRoute();
  };

  const getAddedAndRemovedIds = () => {
    const { groupIds: origIds } = customer;
    const newIds = [...groupIds];

    const removedIds = [];
    const addedIds = [];

    const origIdsObj = arrayToObject(origIds);
    const newIdsObj = arrayToObject(newIds);

    origIds.forEach((el) => {
      if (!Object.prototype.hasOwnProperty.call(newIdsObj, el))
        removedIds.push(el);
    });

    newIds.forEach((el) => {
      if (!Object.prototype.hasOwnProperty.call(origIdsObj, el))
        addedIds.push(el);
    });

    return [addedIds, removedIds];
  };

  const alteredGroupsRoute = async () => {
    const [addedIds, removedIds] = getAddedAndRemovedIds();

    if (addedIds.length) {
      await addIds(addedIds);
    }
    if (removedIds.length) {
      await deleteIds(removedIds);
    }

    onSave();
  };

  const deleteIds = async (removedIds) => {
    const promises = removedIds.map((id) =>
      removeCustomerContactFromGroup({
        variables: {
          input: {
            customerContactId: customer.id,
            groupId: id,
          },
        },
      })
    );

    const vals = await Promise.all(promises).catch(console.warn);

    vals.forEach(({ data }) => {
      const { errors } = data.removeCustomerContactFromGroup;
      if (errors) console.warn(errors);
    });
  };

  const addIds = async (addedIds) => {
    const promises = addedIds.map((id) =>
      addCustomerContactToGroup({
        variables: {
          input: {
            customerContactId: customer.id,
            groupId: id,
          },
        },
      })
    );

    const datas = await Promise.all(promises).catch(console.warn);

    datas.forEach(({ data }) => {
      const { errors } = data.addCustomerContactToGroup;
      if (errors) console.warn(errors);
    });
  };

  const handleOnBlur = () => {
    setGroupDropdownVal("");
  };

  return {
    groupDropdownVal,
    invalidMessage,
    groupIds,
    removeFromGroupIds,
    onGroupInputChange,
    onSuggestionSelect,
    handleSubmit,
    handleOnBlur,
  };
}

export default useCustomerGroupEdit;
