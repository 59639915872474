import styled from 'styled-components';
import THEMES from '../../../styles/themes/app';

export const Container = styled.div`
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  box-shadow: 0 4px 4px -2px ${THEMES.BORDER_COLOR};
  display: flex;
  min-height: 48px;
  padding: 16px;
  width: calc(100% - 32px);
  flex-grow: 0;
  flex-shrink: 0;
  z-index: 1;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  overflow-wrap: break-word;
`;

export const TilteButtonContainer = styled.div`
  height: 100%;
  padding-left: 8px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AvatarContainer = styled.div`
  margin-right: 8px;
`;

export const getSubtitleTextStyles = () => `
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.02em;
`;

export const FlexBox = styled.div`
  display: flex;
`;
