import React from 'react';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'styled-theming';
import {
  formatPhoneNumber,
  contactName,
  accountName,
  accountNumber,
  sortLabels,
} from 'client-lib/src/lib/utils/helpers';
import { useSelector } from 'react-redux';
import THEMES from '../../../../styles/themes/app';
import {
  Heading5,
  Text,
  Chip,
  MetaText,
  IconButton,
} from '../../../../elements';
import { CustomerContactOptIn } from '../CustomerInfoFields/CustomerInfoFields';
import CustomerContactCardHeader from './CustomerContactCardHeader';
import ChipLabels from '../../../ChipLabels/ChipLabels';

const SectionContainer = styled.div`
  padding: 1rem;
  padding-bottom: calc(1rem + 1px);
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const ChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
`;
const NOTES_FONTSIZE = theme('fontSize', {
  default: '12px',
  large: '16px',
});

const NotesUpdatedAt = styled.span`
  color: ${THEMES.FOREGROUND_LOW};
  text-transform: none;
  font-weight: 500;
  padding-top: 8px;
  font-size: ${NOTES_FONTSIZE};
  font-family: 'Barlow', sans-serif;
  padding-left: 4px;
`;

const ChipsLabelsContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  row-gap: 8px;
  width: 100%;
  flex-wrap: wrap;
`;

const NotesWrapper = styled.div`
  word-wrap: break-word;
`;

const ContactCardStatic = ({
  customerContact,
  optInOutEditPermission,
  allowEmailsOptOut,
  allowFaxOptOut,
  apiMessageFeatureEnabled,
  allGroups,
  handleClickOnEdit,
  closeCustomerInfo,
  inActiveThreadGroup,
  startContactThread,
  hasEmailChannel,
  hasFaxChannel,
  hasSmsChannel,
  CustomerInfoOptionsTestPrep,
  handleFocusLabel,
  promoAccess,
}) => {
  const displayChips = allGroups?.filter((group) => {
    return customerContact?.groupIds?.includes(group?.id);
  });

  const name = contactName(customerContact);
  const companyName =
    customerContact?.account?.name && customerContact?.account?.accountNumber
      ? `${accountName(customerContact)} - #${accountNumber(customerContact)}`
      : accountName(customerContact);

  const ff_contact_labels = useSelector(
    (state) => state.accountData.account?.ff_contact_labels
  );

  let renderLabels = [];
  if (ff_contact_labels)
    renderLabels = customerContact?.labels?.map((label) => {
      return { color: label.color, text: label.name, value: label.id };
    });

  return (
    <div>
      <CustomerContactCardHeader
        header={name}
        companyName={companyName}
        handleClickOnEdit={handleClickOnEdit}
        closeCustomerInfo={closeCustomerInfo}
        inActiveThreadGroup={inActiveThreadGroup}
        customerContact={customerContact}
        startContactThread={startContactThread}
        hasEmailChannel={hasEmailChannel}
        hasFaxChannel={hasFaxChannel}
        hasSmsChannel={hasSmsChannel}
        CustomerInfoOptionsTestPrep={CustomerInfoOptionsTestPrep}
      />
      <SectionContainer backgroundColor={THEMES.BACKGROUND_PRIMARY}>
        <Heading5 contrast="high">
          {i18n.t('slideouts-CustomerInfo-phone', {
            defaultValue: 'phone',
          })}
        </Heading5>
        <Text contrast="mid">
          {formatPhoneNumber(customerContact?.phoneNumber)}
        </Text>
      </SectionContainer>
      <SectionContainer backgroundColor={THEMES.BACKGROUND_PRIMARY}>
        <Heading5 contrast="high">
          {i18n.t('slideouts-CustomerInfo-fax', { defaultValue: 'fax' })}
        </Heading5>
        <Text contrast="mid">
          {formatPhoneNumber(customerContact?.faxNumber)}
        </Text>
      </SectionContainer>
      <SectionContainer backgroundColor={THEMES.BACKGROUND_PRIMARY}>
        <Heading5 contrast="high">
          {i18n.t('slideouts-CreateCustomerForm-email', {
            defaultValue: 'email',
          })}
        </Heading5>
        <Text maxWidth="350px" contrast="mid">
          {customerContact?.emailAddress}
        </Text>
      </SectionContainer>
      <SectionContainer backgroundColor={THEMES.BACKGROUND_PRIMARY}>
        <Heading5 contrast="high">
          {i18n.t('slideouts-CustomerInfo-groups', { defaultValue: 'groups' })}
        </Heading5>
        <ChipsLabelsContainer>
          {displayChips?.length > 0
            ? displayChips?.map((group) => {
                return (
                  <Chip key={group?.id} size="sm">
                    {group.name}
                  </Chip>
                );
              })
            : null}
        </ChipsLabelsContainer>
      </SectionContainer>
      <SectionContainer backgroundColor={THEMES.BACKGROUND_PRIMARY}>
        <Heading5 contrast="high">
          {i18n.t('slideouts-CustomerInfo-accountRep', {
            defaultValue: 'account rep',
          })}
        </Heading5>
        <Text contrast="mid">
          {customerContact?.repUser?.firstName}{' '}
          {customerContact?.repUser?.lastName}
        </Text>
      </SectionContainer>
      <SectionContainer backgroundColor={THEMES.BACKGROUND_PRIMARY}>
        <Heading5 contrast="high">
          {i18n.t('slideouts-CustomerInfo-notes', { defaultValue: 'notes' })}
          {customerContact?.notesUpdatedAt && (
            <NotesUpdatedAt>
              {i18n.t('slideouts-CustomerInfo-lastModified', {
                date: i18n.strftime(
                  new Date(customerContact?.notesUpdatedAt),
                  '%-m/%d/%Y'
                ),
              })}
            </NotesUpdatedAt>
          )}
        </Heading5>
        {customerContact?.notes ? (
          <NotesWrapper>
            <Text contrast="mid">{customerContact?.notes}</Text>
          </NotesWrapper>
        ) : (
          <MetaText contrast="low">
            {i18n.t('slideouts-CustomerInfo-nothingToSee')}
          </MetaText>
        )}
      </SectionContainer>
      {ff_contact_labels && (
        <SectionContainer backgroundColor={THEMES.BACKGROUND_PRIMARY}>
          <Heading5 dataTestId="contact-label-header">
            {i18n.t('settings-Label-labels', { defaultValue: 'Labels' })}
          </Heading5>
          <ChipsContainer>
            <ChipLabels
              size="sm"
              options={{ value: sortLabels(renderLabels) }}
            />
            <IconButton
              onClick={() => {
                handleFocusLabel();
                handleClickOnEdit();
              }}
              contrast="highColor"
              noOutline
              dataTestId="add-label-btn"
            >
              <i className="ri-add-fill" />
            </IconButton>
          </ChipsContainer>
        </SectionContainer>
      )}
      <SectionContainer backgroundColor={THEMES.BACKGROUND_PRIMARY}>
        <CustomerContactOptIn
          customerContact={customerContact}
          apiMessageFeatureEnabled={apiMessageFeatureEnabled}
          optInOutEditPermission={optInOutEditPermission}
          allowEmailsOptOut={allowEmailsOptOut}
          allowFaxOptOut={allowFaxOptOut}
          promoAccess={promoAccess}
        />
      </SectionContainer>
    </div>
  );
};

ContactCardStatic.propTypes = {
  customerContact: PropTypes.object,
  optInOutEditPermission: PropTypes.bool.isRequired,
  allowEmailsOptOut: PropTypes.bool.isRequired,
  allowFaxOptOut: PropTypes.bool.isRequired,
  apiMessageFeatureEnabled: PropTypes.bool.isRequired,
  allGroups: PropTypes.array.isRequired,
  handleClickOnEdit: PropTypes.func.isRequired,
  closeCustomerInfo: PropTypes.func.isRequired,
  inActiveThreadGroup: PropTypes.bool.isRequired,
  startContactThread: PropTypes.func,
  hasEmailChannel: PropTypes.bool.isRequired,
  hasFaxChannel: PropTypes.bool.isRequired,
  hasSmsChannel: PropTypes.bool.isRequired,
  CustomerInfoOptionsTestPrep: PropTypes.elementType,
  handleFocusLabel: PropTypes.func.isRequired,
  promoAccess: PropTypes.bool,
};

ContactCardStatic.defaultProps = {
  customerContact: {},
  CustomerInfoOptionsTestPrep: null,
  promoAccess: false,
  startContactThread: () => {},
};

export default ContactCardStatic;
