import React, { useState } from 'react';
import styled from 'styled-components';
import i18n from 'i18n-js';
import { useIgnoreThreadMutation } from 'client-lib';
import { useApolloClient } from '@apollo/client';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Modal, Select, Text } from '../../elements';
import { openSnackbar } from '../../actions/general';

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
const Subtitle = styled.div`
  padding-bottom: 24px;
  margin-top: -20px;
`;

const IgnoreThread = ({ open, setIsOpen, messageId, contactName }) => {
  const client = useApolloClient();
  const [selectedValue, setSelectedValue] = useState();
  const { handleIgnoreThread, options } = useIgnoreThreadMutation({
    client,
    i18n,
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const onSuccess = () => {
    dispatch(
      openSnackbar(
        i18n.t('threads-IgnoreThread-ignoredAndClosed', { contactName })
      )
    );
    history.push('/threads/inbox');
  };

  const onError = () => {
    dispatch(
      openSnackbar(i18n.t('slideouts-GroupMessageName-genericError'), 'error')
    );
  };

  const handleOnSubmit = debounce(
    (value) => {
      setIsOpen(false);
      handleIgnoreThread({
        messageId,
        ignoreReason: value,
        onSuccess,
        onError,
      });
    },
    1000,
    { leading: true }
  );

  return (
    <Modal
      size="sm"
      onRequestClose={() => setIsOpen(false)}
      modalTitle={i18n.t('threads-Ignore-thread', {
        defaultValue: 'Ignore Thread',
      })}
      primaryButtonText="Submit"
      secondaryButtonText={i18n.t('modals-BlockNumber-cancel')}
      primaryButtonOnClick={() => handleOnSubmit(selectedValue)}
      secondaryButtonOnClick={() => setIsOpen(false)}
      primaryButtonProps={{ disabled: !selectedValue }}
      isOpen={open}
    >
      <ModalContentWrapper>
        <Subtitle>
          <Text>
            {i18n.t('threads-Ignore-ignoreAndClosed', {
              defaultValue: 'This Thread will be ignored and closed.',
            })}
          </Text>
        </Subtitle>
        <Select
          label={i18n.t('threads-Ignore-chooseReason', {
            defaultValue: 'CHOOSE A REASON',
          })}
          placeholder={i18n.t('threads-Ignore-selectItem', {
            defaultValue: 'Select an item',
          })}
          value={selectedValue}
          onChange={(selected) => setSelectedValue(selected.value)}
          options={options}
        />
      </ModalContentWrapper>
    </Modal>
  );
};

IgnoreThread.propTypes = {
  open: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  messageId: PropTypes.string.isRequired,
  contactName: PropTypes.string,
};

IgnoreThread.defaultProps = {
  contactName: '',
};

export default IgnoreThread;
