import * as TYPES from './types';

/**
 * Checks if an SMS status is not compatible based on specific blocking statuses.
 *
 * This function evaluates whether the `smsStatus` matches any status
 * defined in the blocking statuses array. It first checks if `smsStatus` is either not present or an empty string,
 * in which case it returns `false`, indicating the SMS status is not considered incompatible. If `smsStatus`
 * is present, it checks against the blocking statuses to determine compatibility.
 *
 * @param {string[]} smsStatus - The status of the SMS item.
 * @returns {boolean} - Returns `true` if the SMS status is found in the blocking statuses, indicating
 * it is not compatible. Otherwise, returns `false`.
 */
const isSMSNotCompatible = (smsStatus) => {
  if (!smsStatus || smsStatus.length === 0) {
    return false;
  }

  const blockingStatus = [
    TYPES.SMS_STATUS.landline,
    TYPES.SMS_STATUS.not_in_service,
  ];

  return blockingStatus.some((status) => smsStatus.includes(status));
};

export default isSMSNotCompatible;
