import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTruncateEmail } from 'client-lib';
import ShowMoreShowLess from './ShowMoreShowLess';
import MessageText from '../../elements/Text/MessageText';

const NonCollapsibleEmailText = ({ text }) => {
  const [isTruncated, setIsTruncated] = useState(true);

  const { original, isTruncationMatch } = useTruncateEmail(text);

  if (isTruncationMatch) {
    return (
      <>
        <MessageText contrast="med">{original}</MessageText>

        <ShowMoreShowLess
          isOpen={!isTruncated}
          setIsOpen={(open) => setIsTruncated(!open)}
        />
      </>
    );
  }
  return <MessageText>{text}</MessageText>;
};

NonCollapsibleEmailText.propTypes = {
  text: PropTypes.string.isRequired,
};

export default NonCollapsibleEmailText;
