import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';
import { Text, Heading5 } from '../../../../../elements';
import AttachmentView from '../../../../FileUpload/AttachmentView';
import LinkWrapper from '../../../../Common/LinkWrapper';

const MessageTextWrapper = styled.div`
  line-height: 1.6;
  word-break: break-word;
`;

const TextAndEditContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const MediaTitleWrap = styled.div`
  padding: 8px 0;
`;

const OverviewMessageView = ({ messageText, attachment, link }) => (
  <>
    <TextAndEditContainer>
      <MessageTextWrapper>
        <Text dataTestId="overview-message-view-text">{messageText}</Text>
        {link && link !== '' && (
          <LinkWrapper dataTestId="overview-message-view-link">
            Prokeep.com/shortened
          </LinkWrapper>
        )}
      </MessageTextWrapper>
    </TextAndEditContainer>
    <div>
      {attachment.id && (
        <>
          <MediaTitleWrap>
            <Heading5>
              {i18n.t('announcements-OverviewMessageView-media')}
            </Heading5>
          </MediaTitleWrap>
          <div>
            <AttachmentView attachment={attachment} />
          </div>
        </>
      )}
    </div>
  </>
);

OverviewMessageView.propTypes = {
  messageText: PropTypes.string,
  attachment: PropTypes.object,
  onEditClick: PropTypes.func,
  sendStartedAt: PropTypes.bool,
  link: PropTypes.string,
};

OverviewMessageView.defaultProps = {
  attachment: {},
  onEditClick: () => {},
  sendStartedAt: false,
  messageText: '',
  link: '',
};

export default OverviewMessageView;
