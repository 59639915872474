import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import styled from 'styled-components';
import { formatPhoneNumber } from 'client-lib/src/lib/utils/helpers';
import {
  Heading2,
  Text,
  Heading5,
  Button,
  IconButton,
  Loading,
} from '../../../elements';
import THEMES from '../../../styles/themes/app';
import {
  ColumnContainer,
  InnerWrap,
  HeaderCloseSection,
  TeamHeadWrap,
  SubHeaderSection,
  TeamInfoWrapper,
  LoadingWrapper,
} from './CustomerInfo';
import ScrollableGroupMembers from './ScrollableGroupMembers';

const UsersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InternalGroupSlideout = ({
  closeCustomerInfo,
  internalGroupData,
  styledTheme,
  sectionItemCustomStyle,
  startContactThread,
  handleFetchMoreTeamMembers,
  pageInfo,
  users,
  usersQueryLoading,
  loading,
}) => (
  <ColumnContainer>
    {loading ? (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    ) : (
      <InnerWrap>
        <HeaderCloseSection>
          <IconButton
            noOutline
            contrast="high"
            size="lg"
            onClick={closeCustomerInfo}
          >
            <i className="ri-close-line" />
          </IconButton>
        </HeaderCloseSection>
        <TeamHeadWrap>
          <Heading2 contrast="high">{internalGroupData?.name}</Heading2>
        </TeamHeadWrap>
        <SubHeaderSection
          backgroundColor={THEMES.BACKGROUND_TERTIARY({
            theme: styledTheme,
          })}
          slimVerticalPadding
        >
          <div>
            <Button
              onClick={() => startContactThread(internalGroupData)}
              dataTestId="contact-info-message-button"
            >
              {i18n.t('slideouts-CustomerInfo-message')}
            </Button>
          </div>
        </SubHeaderSection>
        <TeamInfoWrapper styledTheme={styledTheme}>
          <Heading5 contrast="high">
            {i18n.t('slideouts-CustomerInfo-phone')}
          </Heading5>
          <Text contrast="med" customStyle={sectionItemCustomStyle}>
            {formatPhoneNumber(internalGroupData?.conversations_phone_number)}
          </Text>
        </TeamInfoWrapper>
        <TeamInfoWrapper styledTheme={styledTheme}>
          <Heading5 contrast="high">Members</Heading5>
        </TeamInfoWrapper>
        <UsersWrapper>
          {users?.length ? (
            <ScrollableGroupMembers
              users={users}
              handleFetchMoreTeamMembers={handleFetchMoreTeamMembers}
              pageInfo={pageInfo}
              usersQueryLoading={usersQueryLoading}
            />
          ) : (
            ''
          )}
        </UsersWrapper>
      </InnerWrap>
    )}
  </ColumnContainer>
);

InternalGroupSlideout.propTypes = {
  closeCustomerInfo: PropTypes.func.isRequired,
  internalGroupData: PropTypes.object.isRequired,
  styledTheme: PropTypes.object.isRequired,
  sectionItemCustomStyle: PropTypes.func.isRequired,
  startContactThread: PropTypes.func.isRequired,
  handleFetchMoreTeamMembers: PropTypes.func.isRequired,
  pageInfo: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  usersQueryLoading: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default InternalGroupSlideout;
