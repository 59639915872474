import React from 'react';
import PropTypes from 'prop-types';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import { useSelector } from 'react-redux';
import { EntityCard } from '../../../elements';

const UserHeader = ({ activeCustomerData, onAvatarNameClick, ...props }) => {
  const presenceTrackingAvailable = useSelector(
    (state) => state.accountData.account?.ff_presence_tracking
  );

  return (
    <EntityCard
      {...props}
      avatarChildren={contactName(activeCustomerData)}
      maintext={contactName(activeCustomerData)}
      maintextProps={{ onClick: onAvatarNameClick }}
      subtext={activeCustomerData?.title}
      customMaintextStyle={() => `margin: 0;`}
      customContainerStyle={() => 'border-width: 0;'}
      avatarProps={{
        avatarUrl: activeCustomerData?.avatarUrl,
        icon: 'user',
        type: 'internal',
        displayPresence: presenceTrackingAvailable,
        active: activeCustomerData?.online,
        onClick: onAvatarNameClick,
      }}
    />
  );
};

UserHeader.propTypes = {
  activeCustomerData: PropTypes.object,
  onAvatarNameClick: PropTypes.func,
};

UserHeader.defaultProps = {
  onAvatarNameClick: undefined,
  activeCustomerData: null,
};

export default UserHeader;
