import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { useAddOrRemoveTag } from 'client-lib';
import i18n from 'i18n-js';
import { openSnackbar } from '../../actions/general';
import MultiTagDisplay from './MultiTagDisplay';

const ThreadedMultiTag = ({
  thread,
  flipTagBubbleCallback,
  highlightTarget,
  flipWorkOrderOptions,
}) => {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const [editingRefNum, setEditingRefNum] = useState(false);

  const onMutationSuccess = (data) => {
    const message = data?.removeThreadFromWorkOrder
      ? i18n.t('threads-Tag-addOrEditTag-tagRemoved', {
          defaultValue: 'Thread tag removed',
        })
      : i18n.t('threads-Tag-addOrEditTag-tagAdded', {
          defaultValue: 'Thread tag added',
        });
    dispatch(openSnackbar(message));
  };

  const onMutationError = (error) => {
    dispatch(
      openSnackbar(
        error || i18n.t('slideouts-GroupMessageName-genericError'),
        'error'
      )
    );
  };

  const {
    tagInputValue,
    setTagInputValue,
    tags,
    handleAddOrRemoveThreadFromWorkOrder,
  } = useAddOrRemoveTag({
    onSubmit: () => setEditingRefNum(false),
    thread,
    client,
    i18n,
    onMutationSuccess,
    onMutationError,
  });

  return (
    <MultiTagDisplay
      id={thread?.id}
      tags={tags}
      handleAddOrRemoveThreadFromWorkOrder={
        handleAddOrRemoveThreadFromWorkOrder
      }
      tagInputValue={tagInputValue}
      setTagInputValue={setTagInputValue}
      editingRefNum={editingRefNum}
      setEditingRefNum={setEditingRefNum}
      flipTagBubbleCallback={flipTagBubbleCallback}
      highlightTarget={highlightTarget}
      flipWorkOrderOptions={flipWorkOrderOptions}
    />
  );
};

ThreadedMultiTag.propTypes = {
  thread: PropTypes.object.isRequired,
  flipTagBubbleCallback: PropTypes.func,
  highlightTarget: PropTypes.string,
  flipWorkOrderOptions: PropTypes.bool,
};

ThreadedMultiTag.defaultProps = {
  flipTagBubbleCallback: () => false,
  highlightTarget: null,
  flipWorkOrderOptions: false,
};

export default ThreadedMultiTag;
