/**
 * Types of status messages.
 *
 * @readonly
 * @enum {string}
 */
export const STATUS_MESSAGE = {
  NOT_IN_SERVICE: 'NOT_IN_SERVICE',
  SUSPECTED_LANDLINE: 'SUSPECTED_LANDLINE',
  FAILED: 'FAILED',
};
