import React from 'react';
import PropTypes from 'prop-types';
import { InlineTextButton } from '../../elements';

const LinkWrapper = ({ children, href, target, dataTestId }) => {
  const clickHandler = () => {
    if (!href) return;
    window.open(href, target);
  };

  return (
    <InlineTextButton onClick={clickHandler} dataTestId={dataTestId} noOutline>
      {children}
    </InlineTextButton>
  );
};

export default LinkWrapper;

LinkWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  target: PropTypes.string,
  dataTestId: PropTypes.string,
};

LinkWrapper.defaultProps = {
  href: '',
  target: '',
  dataTestId: '',
};
