import React from 'react';
import { useSpring, animated } from 'react-spring';
import PropTypes from 'prop-types';
import useMeasure from './useMeasure';

const Accordion = ({
  children,
  open,
  noMeasure,
  customConfig,
  customStyles,
  customClassName,
}) => {
  const [bind, measure] = useMeasure();

  const openHeight = noMeasure ? '100%' : measure.height;
  const closeHeight = noMeasure ? '0%' : 0;

  const currentHeight = open ? openHeight : closeHeight;

  const animation = useSpring({
    width: '100%',
    overflow: 'hidden',
    ...customStyles,
    height: currentHeight,
    config: { ...customConfig },
  });

  return (
    <animated.div className={customClassName} style={animation}>
      <div {...bind} style={{ height: '100%' }}>
        {children({
          openHeight,
          closeHeight,
          currentHeight,
          open,
          measure,
        })}
      </div>
    </animated.div>
  );
};

Accordion.propTypes = {
  children: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  noMeasure: PropTypes.bool,
  customConfig: PropTypes.object,
  customStyles: PropTypes.object,
  customClassName: PropTypes.string,
};

Accordion.defaultProps = {
  noMeasure: false,
  customConfig: {},
  customStyles: {},
  customClassName: '',
};

export default Accordion;
