import { useQuery } from "@apollo/client";
import { THREAD_HISTORY_QUERY } from "../../..";

/*

DOCUMENTATION
  this hook is created to be used with ThreadHistory

  args:
    threadId: id of the thread you want
    client: (obj) required. Apollo Client instance.

  returns:
    threads: (arr) array of thread instances, stored in local state

*/

const usePrintThread = ({ client, threadId }) => {
  const { data } = useQuery(THREAD_HISTORY_QUERY, {
    client,
    variables: {
      threadId,
    },
  });

  return {
    data,
  };
};

export default usePrintThread;
