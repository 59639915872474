import React, { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";

/*
const genid = (digits) => {
  const str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVXZ';
  const uuid = [];
  for (let i = 0; i < digits; i++) { // eslint-disable-line no-plusplus
    uuid.push(str[Math.floor(Math.random() * str.length)]);
  }
  return uuid.join('');
};
*/

const ApolloSyncId = createContext("");
const ApolloSyncIdUpdate = createContext();

export const useApolloSyncId = () => useContext(ApolloSyncId);
export const useApolloSyncUpdate = () => useContext(ApolloSyncIdUpdate);

export function ApolloSyncProvider({ children }) {
  // const [id, setId] = useState(genid(10));
  // const shuffleId = () => setId(genid(10));
  // disabling apollo sync b/c it's being wonky with mobile.
  const [id, setId] = useState("abcd");

  const shuffleId = (randomStr = "abcd") => setId(randomStr); // eslint-disable-line react/jsx-no-constructed-context-values

  return (
    <ApolloSyncId.Provider value={id}>
      <ApolloSyncIdUpdate.Provider value={shuffleId}>
        {children}
      </ApolloSyncIdUpdate.Provider>
    </ApolloSyncId.Provider>
  );
}

ApolloSyncProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
