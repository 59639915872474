import React from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { usePaginatedTeamMember } from 'client-lib';
import InternalGroupSlideout from './InternalGroupSlideout';

const InternalGroupSlideoutContainer = ({
  closeCustomerInfo,
  styledTheme,
  sectionItemCustomStyle,
  internalGroupData,
  startContactThread,
  currentUser,
  loading,
}) => {
  const client = useApolloClient();
  const internalGroupId = internalGroupData?.id;
  const teamQueryVariables = {
    groupIds: [internalGroupId],
    relayStylePagination: true,
  };

  const usersData = usePaginatedTeamMember({
    queryVariables: teamQueryVariables,
    client,
    currentUser,
  });

  const { handleFetchMoreTeamMembers, pageInfo, users, usersQueryLoading } =
    usersData;

  return (
    <InternalGroupSlideout
      loading={loading}
      handleFetchMoreTeamMembers={handleFetchMoreTeamMembers}
      closeCustomerInfo={closeCustomerInfo}
      styledTheme={styledTheme}
      sectionItemCustomStyle={sectionItemCustomStyle}
      internalGroupData={internalGroupData}
      startContactThread={startContactThread}
      users={users}
      pageInfo={pageInfo}
      usersQueryLoading={usersQueryLoading}
    />
  );
};

InternalGroupSlideoutContainer.propTypes = {
  closeCustomerInfo: PropTypes.func.isRequired,
  internalGroupData: PropTypes.object.isRequired,
  styledTheme: PropTypes.object.isRequired,
  sectionItemCustomStyle: PropTypes.func.isRequired,
  startContactThread: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default InternalGroupSlideoutContainer;
