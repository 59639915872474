import React, { useContext } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import THEMES from '../../styles/themes/app';
import Header from './Header/Header';
import {
  SubheaderWrapper,
  ChildrenWrapper,
  getOverlayStyles,
  FlexWrapper,
} from './styles';
import Footer from './Footer/Footer';
import DetectClickOutside from '../Common/DetectClickOutside';

const Flyout = ({
  buttonActions,
  buttonLabels,
  buttonProps,
  children,
  customStyles,
  direction,
  disableFooter,
  disableHeader,
  disableOverlay,
  error,
  isOpen,
  onClickOutside,
  onRequestClose,
  shouldCloseOnOverlayClick,
  subheader,
  subtitle,
  title,
  titleIcon,
  ...props
}) => {
  const styledTheme = useContext(ThemeContext);

  const animation = useSpring({
    position: 'absolute',
    top: 0,
    [direction]: isOpen ? 0 : -300,
    backgroundColor: `${THEMES.BACKGROUND_PRIMARY(styledTheme)}`,
    width: '438px',
    height: '100vh',
    boxShadow: '-4px 0px 4px rgba(0, 0, 0, 0.25)',
  });

  return (
    <ReactModal
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={{ overlay: getOverlayStyles(disableOverlay) }}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      css={(props) => props?.customStyles?.modal?.(props)}
      {...props}
    >
      <DetectClickOutside onClickOutside={onClickOutside}>
        <animated.div style={animation}>
          <FlexWrapper>
            {!disableHeader && (
              <Header
                title={title}
                subtitle={subtitle}
                onRequestClose={onRequestClose}
                headerIcon={titleIcon}
                titleButton={buttonLabels?.title}
                titleButtonAction={buttonActions?.title}
                titleButtonProps={buttonProps?.title}
              />
            )}
            {subheader && (
              <SubheaderWrapper css={customStyles?.subheader}>
                {subheader}
              </SubheaderWrapper>
            )}
            <ChildrenWrapper css={customStyles?.content}>
              {children}
            </ChildrenWrapper>
            {!disableFooter && buttonLabels && (
              <Footer
                buttonLabels={buttonLabels}
                buttonActions={buttonActions}
                buttonProps={buttonProps}
                error={error}
                title={title}
              />
            )}
          </FlexWrapper>
        </animated.div>
      </DetectClickOutside>
    </ReactModal>
  );
};
Flyout.propTypes = {
  buttonActions: PropTypes.shape({
    primary: PropTypes.func,
    secondary: PropTypes.func,
    destructive: PropTypes.func,
    title: PropTypes.func,
  }),
  buttonLabels: PropTypes.shape({
    primary: PropTypes.string,
    secondary: PropTypes.string,
    destructive: PropTypes.string,
    title: PropTypes.string,
  }),
  buttonProps: PropTypes.shape({
    primary: PropTypes.object,
    secondary: PropTypes.string,
    destructive: PropTypes.string,
    title: PropTypes.object,
  }),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
  customStyles: PropTypes.shape({
    subheader: PropTypes.func,
    content: PropTypes.func,
    modal: PropTypes.func,
  }),
  direction: PropTypes.string,
  disableFooter: PropTypes.bool,
  disableHeader: PropTypes.bool,
  disableOverlay: PropTypes.bool,
  error: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClickOutside: PropTypes.func,
  onRequestClose: PropTypes.func,
  shouldCloseOnOverlayClick: PropTypes.bool,
  subheader: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  subtitle: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  titleIcon: PropTypes.string,
};

Flyout.defaultProps = {
  buttonActions: null,
  buttonLabels: null,
  buttonProps: null,
  customStyles: null,
  direction: 'right',
  disableFooter: false,
  disableHeader: false,
  disableOverlay: false,
  error: '',
  onClickOutside: () => {},
  onRequestClose: () => {},
  shouldCloseOnOverlayClick: true,
  subheader: null,
  subtitle: '',
  title: '',
  titleIcon: '',
};

export default Flyout;
