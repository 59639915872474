import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { canPreviewBasedOnFileExtension } from 'client-lib/src/lib/utils/helpers';
import { useSelector } from 'react-redux';
import { EmphasisText, Heading2, Text, Loading } from '../../../../../elements';
import THEMES from '../../../../../styles/themes/app';
import AttachmentView from '../../../../FileUpload/AttachmentView';
import LinkWrapper from '../../../../Common/LinkWrapper';
import { ANNOUNCEMENT_TYPES } from '../../GroupMessageWizard';

const MessageContainer = styled.div`
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  width: 100%;
  min-width: 250px;
  border-radius: 23px;
  min-height: 60px;
  padding: 24px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: ${(props) => (props.loading ? 'flex' : 'inline-block')};
  justify-content: center;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  white-space: pre-line;

  & :before {
    content: '';
    position: absolute;
    z-index: 0;
    bottom: 0;
    right: -8px;
    height: 20px;
    width: 20px;
    background-color: ${THEMES.BACKGROUND_PRIMARY};
    background-attachment: fixed;
    border-bottom-left-radius: 15px;
  }
  & :after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: -10px;
    width: 10px;
    height: 20px;
    background: ${THEMES.BACKGROUND_TERTIARY};
    border-bottom-left-radius: 10px;
  }
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  margin: auto;
  padding: 40px;
  box-sizing: border-box;
`;

const HeaderWrapper = styled.div`
  margin-bottom: 8px;
  min-width: 250px;
`;

const SubheaderWrapper = styled.div`
  margin-bottom: 24px;
  min-width: 250px;
`;

const LoadingWrapper = styled.div`
  height: 80%;
  display: flex;
  align-items: center;
  width: fit-content;
`;

const AttachmentContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

const TextWrapper = styled.div`
  padding-bottom: 4px;
  word-break: break-word;
`;

const LinkMessage = () => (
  <div>
    <br />
    <Text>{'https://<link-will-go-here>'}</Text>
  </div>
);

const GroupMessagePreview = ({
  messageText,
  loading,
  attachment,
  accountName,
  sent,
  sendAttachmentAsLink,
  attachmentBiggerThanPreview,
  link,
  announcementType,
}) => {
  const canPreviewFile =
    attachment && canPreviewBasedOnFileExtension(attachment?.originalFilename);
  const promoAccess = useSelector(
    (state) => state.accountData.account?.ff_broadcast_list_improvements
  );

  const optOutText =
    announcementType === ANNOUNCEMENT_TYPES.PROMOTIONAL && promoAccess
      ? 'Reply NO PROMO for no promotional messages or STOP to receive no messages at all from this number. Replying UNSTOP will add you back to the recipient list.'
      : 'Text STOP to opt out';
  return (
    <Container>
      <HeaderWrapper>
        <Heading2>{i18n.t('slideouts-GroupMessageContent-preview')}</Heading2>
      </HeaderWrapper>
      <SubheaderWrapper>
        {sent ? (
          <Text>{i18n.t('slideouts-GroupMessageContent-saw')}</Text>
        ) : (
          <Text>{i18n.t('slideouts-GroupMessageContent-willSee')}</Text>
        )}
      </SubheaderWrapper>
      <MessageContainer loading={loading ? 1 : 0}>
        {loading ? (
          <LoadingWrapper>
            <Loading size="xs" />
          </LoadingWrapper>
        ) : (
          <>
            <TextWrapper>
              <Text>{`${accountName}:`}</Text>
            </TextWrapper>
            <TextWrapper>
              <EmphasisText>{messageText}</EmphasisText>
              {link !== '' && (
                <LinkWrapper dataTestId="group-message-preview-link">
                  Prokeep.com/shortened
                </LinkWrapper>
              )}
            </TextWrapper>
            <TextWrapper>
              <br />
              <Text>{optOutText}</Text>
            </TextWrapper>
            {attachment ? (
              sendAttachmentAsLink ||
              !canPreviewFile ||
              attachmentBiggerThanPreview ? (
                <LinkMessage />
              ) : (
                <AttachmentContainer>
                  <AttachmentView attachment={attachment} />
                </AttachmentContainer>
              )
            ) : null}
          </>
        )}
      </MessageContainer>
    </Container>
  );
};

GroupMessagePreview.propTypes = {
  messageText: PropTypes.string,
  attachment: PropTypes.object,
  loading: PropTypes.bool,
  accountName: PropTypes.string,
  sent: PropTypes.bool,
  sendAttachmentAsLink: PropTypes.bool,
  attachmentBiggerThanPreview: PropTypes.bool,
  link: PropTypes.string,
  announcementType: PropTypes.string,
};

GroupMessagePreview.defaultProps = {
  messageText: '',
  attachment: {},
  loading: false,
  accountName: '',
  sent: false,
  sendAttachmentAsLink: false,
  attachmentBiggerThanPreview: false,
  link: '',
  announcementType: '',
};

export default GroupMessagePreview;
