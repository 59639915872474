import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/lazy';
import styled from 'styled-components';
import { Modal } from '../../elements';

const VideoWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  height: 100%;
  width: 100%;

  & .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

// leaving handlers in here but commented out in case they decide later thay want to track any
// of these items. the ref was a way to track timestamps of play/pause/etc
const VideoPlayer = ({
  videoUrl,
  modalTitle,
  open,
  onClose,
  // onStart,
  // onEnded,
  // videoReference,
}) => (
  <Modal
    modalTitle={modalTitle}
    onRequestClose={onClose}
    ariaHideApp={false}
    size="lg"
    isOpen={open}
  >
    <VideoWrapper>
      <ReactPlayer
        // onStart={onStart}
        // onEnded={onEnded}
        className="react-player"
        controls
        height="100%"
        width="100%"
        url={videoUrl}
        // ref={videoReference}
        playing
      />
    </VideoWrapper>
  </Modal>
);
VideoPlayer.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  modalTitle: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // onStart: PropTypes.func.isRequired,
  // onEnded: PropTypes.func.isRequired,
  // videoReference: PropTypes.func.isRequired,
};

VideoPlayer.defaultProps = {
  modalTitle: '',
};

export default VideoPlayer;
