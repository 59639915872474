import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { Text } from '../../elements';
import ShowMoreShowLess from '../Threads/ShowMoreShowLess';
import { CodeWrapper } from '../Settings/Webchat/WidgetSnippetDashboard';
import MessageText from '../../elements/Text/MessageText';

class HtmlErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      htmlOpen: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error) {
    // You can also log the error to an error reporting service like AppSignal
    // logErrorToMyService(error, errorInfo);
    console.error(error.message);
  }

  render() {
    const { hasError, htmlOpen } = this.state;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <MessageText contrast="red">
            {i18n.t('threads-HtmlErrorBoundary-couldNotParse', {
              defaultValue:
                'Could not parse as Rich Text. Click Show More to read raw HTML',
            })}
          </MessageText>

          {htmlOpen ? (
            <CodeWrapper>
              <Text>{this.props.html}</Text>
            </CodeWrapper>
          ) : (
            <div />
          )}

          <ShowMoreShowLess
            isOpen={htmlOpen}
            setIsOpen={(open) => this.setState({ htmlOpen: open })}
          />
        </div>
      );
    }

    return this.props.children;
  }
}

HtmlErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
  html: PropTypes.string.isRequired,
};

export default HtmlErrorBoundary;
