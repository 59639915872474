import React from 'react';
import Button from './Button';

const customStyle = () => `
  padding: 0 14px;
`;

const IconBorderButton = ({ ...rest }) => {
  return <Button customStyle={customStyle} {...rest} />;
};

export default IconBorderButton;
