import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'styled-theming';
import THEMES from '../../styles/themes/library/textInput';
import FONTSIZE_THEMES from '../../styles/themes/fontSize/fontSize';

const showError = (error) => error && typeof error === 'string';

const INPUT_ERROR_HEIGHT = theme('fontSize', {
  default: '24px',
  large: '39px',
});

const InputErrorText = styled.div`
  font-size: ${FONTSIZE_THEMES.INPUTERROR_TEXT};
  font-weight: 500;
  font-family: 'Barlow', sans-serif;
  margin-left: ${(props) => (showError(props.error) ? '4px' : '0')};
  color: ${(props) =>
    showError(props.error) ? THEMES.ERROR : THEMES.HELPER_TEXT};
`;

const ErrorIcon = styled.i`
  font-size: ${FONTSIZE_THEMES.INPUTERROR_TEXT};
  color: ${THEMES.ERROR};
`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
`;

const WhiteSpaceContainerBelow = styled.div`
  height: ${INPUT_ERROR_HEIGHT};
  padding-top: 8px;
`;

const InputError = ({ error, helperText, dataTestId }) => (
  <WhiteSpaceContainerBelow>
    {helperText && !showError(error) && (
      <InputErrorText error={error} data-testid={dataTestId}>
        {helperText}
      </InputErrorText>
    )}
    {showError(error) ? (
      <ErrorContainer>
        <ErrorIcon className="ri-error-warning-fill" />
        <InputErrorText error={error} data-testid={dataTestId}>
          {error}
        </InputErrorText>
      </ErrorContainer>
    ) : null}
  </WhiteSpaceContainerBelow>
);

InputError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  helperText: PropTypes.string,
  dataTestId: PropTypes.string,
};

InputError.defaultProps = {
  error: false,
  helperText: '',
  dataTestId: '',
};

export default InputError;
