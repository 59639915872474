import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { LABELS_MINIMAL_QUERY } from 'client-lib';
import useLazyPaginated from 'client-lib/src/lib/api/query/useLazyPaginated';
import i18n from 'i18n-js';
import { AsyncSelectPaginate } from '../../elements';
import { getLabelThemeColor } from '../../styles/themes/library/utils/getLabelThemeColor';

const LabelAsyncSelect = ({ value, setValue, ...props }) => {
  const client = useApolloClient();
  const [queryError, setQueryError] = useState('');

  const generateOptions = (labels) => {
    return labels.edges.map((label) => ({
      label: label.node?.name,
      value: label.node?.id,
      color: label.node?.color,
      applyLabelColor: getLabelThemeColor(label.node?.color),
    }));
  };

  const variables = {};

  const { triggerQuery, handleFetchMore } = useLazyPaginated({
    client,
    query: LABELS_MINIMAL_QUERY,
    key: 'labels',
    resultsNumber: 25,
    queryVariables: {
      filter: value,
      ...variables,
    },
  });

  const initialQuery = (inputVal) => {
    return triggerQuery({
      variables: {
        filter: inputVal,
        first: 25,
        ...variables,
      },
    });
  };

  const fetchMore = () => {
    return handleFetchMore();
  };

  const loadOptions = async (inputVal, prevOptions, additional) => {
    setQueryError('');
    let response;

    if (prevOptions?.length > 0 && additional.prevInputVal === inputVal) {
      response = await fetchMore(inputVal, prevOptions);
    } else {
      response = await initialQuery(inputVal);
    }

    const { data, error } = response;

    const hasMore = data?.labels?.pageInfo?.hasNextPage;

    if (error) {
      setQueryError(i18n.t('slideouts-GroupMessageName-genericError'));
      return { options: [], hasMore };
    }

    const labelOptions = generateOptions(data?.labels);

    if (inputVal) {
      const filteredLabelOptions = labelOptions?.filter((opt) =>
        opt.label.toLowerCase().includes(inputVal.toLowerCase())
      );

      return {
        options: filteredLabelOptions,
        hasMore,
        additional: { prevInputVal: inputVal },
      };
    }

    return {
      options: labelOptions,
      hasMore,
      additional: { prevInputVal: inputVal },
    };
  };

  const hasValidLabels = useMemo(() => {
    if (Array.isArray(value)) {
      return value.every((val) => val && val.label);
    }

    return value && value.label;
  }, [value]);

  return (
    <AsyncSelectPaginate
      value={hasValidLabels ? value : []}
      isMulti
      hasMore={false}
      loadOptions={loadOptions}
      onChange={(selectedOption) => setValue(selectedOption)}
      dataTestId="label-async-select"
      error={queryError}
      {...props}
    />
  );
};

LabelAsyncSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  setValue: PropTypes.func.isRequired,
};

export default LabelAsyncSelect;
