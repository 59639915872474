import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from './Button';
import THEMES from '../../styles/themes/library/textInput';
import APP_THEMES from '../../styles/themes/app';
import DetectClickOutside from '../../components/Common/DetectClickOutside';

const Container = styled.div`
  position: relative;
`;

const ButtonContainer = styled.div`
  display: flex;
  border-radius: 4px;
  &:focus-within {
    box-shadow: 0 0 0 3px lightskyblue;
  }
`;

const OptionsContainer = styled.div`
  position: absolute;
  display: grid;
  z-index: 99;
  right: 0;
  top: 40px;
  margin-top: 3px;
  border: 1px solid ${THEMES.BORDER};
  border-radius: 4px;
  background: ${THEMES.BACKGROUND};
  box-shadow: ${APP_THEMES.BOX_SHADOW};
  & button:nth-child(1) {
    border-radius: 4px 4px 0 0;
  }
  & button:last-child {
    border-radius: 0 0 4px 4px;
  }
  & button:nth-child(1):last-child {
    border-radius: 4px;
  }
  & button:not(:last-child, :nth-child(1)) {
    border-radius: 0px;
  }
`;

const customLeftButtonStyles = (props) => `
  border-radius: 4px 0 0 4px;
  &:focus{box-shadow: none;}
  ${props?.customLeftButtonStyle?.(props)}
`;

const customRightButtonStyles = (props) => `
  border-radius: 0 4px 4px 0;
  border-left: none;
  width: 0;
  && i {
    font-size: 24px !important;
  }
  &:focus{box-shadow: none;}
  ${props?.customRightButtonStyle?.(props)}
`;

const customOptionButtonStyles = (props) => `
  background: ${THEMES.OPTION_BACKGROUND(props)};
  color: ${THEMES.OPTION_TEXT(props)};
  width: 100%;
  display: flex;
  justify-content: flex-start;

  :focus {
    box-shadow: inset 0 0 0 2px ${THEMES.FOCUS(props)};
  }
  &:hover, &:focus{
    background: ${THEMES.OPTION_BACKGROUND_HOVER(props)};
    color: ${THEMES.OPTION_TEXT_HOVER(props)};
  }
  &:active{
    background: ${THEMES.OPTION_BACKGROUND_ACTIVE(props)};
    color: ${THEMES.OPTION_TEXT_ACTIVE(props)};
  }
  ${props?.customDropdownButtonStyle?.(props)}
`;

const OptionButton = ({
  onClick,
  options,
  leftButtonProps,
  rightButtonProps,
  customRightButtonStyle,
  customLeftButtonStyle,
  customDropdownButtonStyle,
  children,
  ...props
}) => {
  const [listOpen, setListOpen] = useState(false);

  const handleListOpen = () => {
    setListOpen(true);
  };

  return (
    <Container>
      <ButtonContainer>
        <Button
          onClick={onClick}
          customLeftButtonStyle={customLeftButtonStyle}
          customStyle={(props) => customLeftButtonStyles(props)}
          {...props}
          {...leftButtonProps}
        >
          {children}
        </Button>
        <Button
          listOpen={listOpen}
          setListOpen={setListOpen}
          onClick={handleListOpen}
          customRightButtonStyle={customRightButtonStyle}
          customStyle={(props) => customRightButtonStyles(props)}
          {...props}
          {...rightButtonProps}
        >
          {listOpen ? (
            <i className="ri-arrow-drop-up-line" />
          ) : (
            <i className="ri-arrow-drop-down-line" />
          )}
        </Button>
      </ButtonContainer>
      {listOpen && options?.length ? (
        <OptionsContainer>
          <DetectClickOutside onClickOutside={() => setListOpen(false)}>
            {options.map((option) => (
              <Button
                key={option.value}
                type="noStyle"
                customDropdownButtonStyle={customDropdownButtonStyle}
                customStyle={(props) => customOptionButtonStyles(props)}
                onClick={() => {
                  option.onClick();
                  setListOpen(false);
                }}
              >
                {option.value}
              </Button>
            ))}
          </DetectClickOutside>
        </OptionsContainer>
      ) : null}
    </Container>
  );
};

OptionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  leftButtonProps: PropTypes.object,
  rightButtonProps: PropTypes.object,
  options: PropTypes.array.isRequired,
  customRightButtonStyle: PropTypes.func,
  customLeftButtonStyle: PropTypes.func,
  customDropdownButtonStyle: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
};

OptionButton.defaultProps = {
  leftButtonProps: {},
  rightButtonProps: {},
  customRightButtonStyle: () => {},
  customLeftButtonStyle: () => {},
  customDropdownButtonStyle: () => {},
};

export default OptionButton;
