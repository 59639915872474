import { useMutation } from "@apollo/client";
import useForm from "../../../utils/hooks/useForm";
import updateCustomerAccountMutation from "../../../graphql/mutation/UpdateCustomerAccountMutation.graphql";
import { COMMON_NORMALIZERS } from "../../../utils/helpers";
import {
  AVAILABLE_PERMISSIONS,
  checkIfCurrentUserHasPermission,
} from "../../../utils/roles";

const defaultFormValues = {
  id: "",
  name: "",
  phoneNumber: "",
  email: "",
  accountNumber: "",
  notes: "",
  notesUpdatedAt: null,
};

const useEditAccountDetails = ({
  currentUser,
  client,
  handleOnClose,
  onHandleError,
  i18n,
  initialFormValues = null,
}) => {
  const { accountPolicies, groupPolicies } = currentUser;

  const userCanEditCompanies = checkIfCurrentUserHasPermission(
    AVAILABLE_PERMISSIONS.EDIT_COMPANIES,
    accountPolicies,
    groupPolicies
  );

  const [updateCustomerAccount, { loading: updateAccountLoading }] =
    useMutation(updateCustomerAccountMutation, {
      client,
    });

  const validationOverrideAction = (field, fields, currentValidity) => {
    if (!field.otherProps.required) {
      field.setError("");
      return true;
    }

    return currentValidity;
  };

  const requiredValidation = {
    type: "required",
    errorMessage: i18n.t("slideouts-CreateCustomerForm-required"),
  };
  const phoneNumberValidation = {
    type: "phoneFormat",
    errorMessage: i18n.t("slideouts-CreateCustomerForm-invalidPhone"),
  };
  const emailAddressValidation = {
    type: "emailFormat",
    errorMessage: i18n.t("slideouts-CreateCustomerForm-invalidEmail"),
  };
  const firstNameLengthValidation = {
    type: "minLength",
    minLength: 2,
    errorMessage: i18n.t("slideouts-CreateCustomerForm-firstNameError"),
    optional: true,
  };

  const accountNumberValidation = {
    type: "maxLength",
    maxLength: 255,
    errorMessage: i18n.t("slideouts-CreateCustomerForm-acctLengthError"),
    optional: true,
  };

  const initialValues = {
    id: {
      value: initialFormValues?.id
        ? initialFormValues.id
        : defaultFormValues.id,
    },
    name: {
      value: initialFormValues?.name
        ? initialFormValues.name
        : defaultFormValues.name,
      validations: [requiredValidation, firstNameLengthValidation],
    },
    phoneNumber: {
      value: initialFormValues?.phoneNumber
        ? initialFormValues.phoneNumber
        : defaultFormValues.phoneNumber,
      otherProps: {
        text: initialFormValues?.phoneNumber
          ? COMMON_NORMALIZERS.normalizePhone(initialFormValues.phoneNumber)
          : defaultFormValues.phoneNumber,
        validationOverrideAction,
      },
      validations: [phoneNumberValidation],
      optional: true,
    },
    email: {
      value: initialFormValues?.email
        ? initialFormValues.email
        : defaultFormValues.email,
      validations: [emailAddressValidation],
      optional: true,
      otherProps: {
        validationOverrideAction,
      },
    },
    accountNumber: {
      value: initialFormValues?.accountNumber
        ? initialFormValues.accountNumber
        : defaultFormValues.accountNumber,
      validations: [accountNumberValidation],
      optional: true,
    },
    notes: {
      value: initialFormValues?.notes
        ? initialFormValues?.notes
        : defaultFormValues.notes,
    },
    notesUpdatedAt: {
      value: initialFormValues?.notesUpdatedAt
        ? initialFormValues?.notesUpdatedAt
        : defaultFormValues.notesUpdatedAt,
    },
  };

  const { fields, updateField, onSubmit, isVisitedForm } = useForm(
    initialValues,
    async (currentFields) => {
      const { id, name, email, accountNumber, phoneNumber, notes } =
        currentFields;

      const customerAccountValues = {
        id: id.value,
        name: name.value,
        phoneNumber: phoneNumber.value,
        email: email.value,
        accountNumber: accountNumber.value,
        notes: notes.value,
      };

      const customerAccountResponse = await updateAccount(
        customerAccountValues
      );

      handleMutationResponse(
        customerAccountResponse.updateCustomerAccount.customerAccount,
        customerAccountResponse.updateCustomerAccount.errors
      );
    }
  );

  const handleMutationResponse = (mutationData, mutationErrors) => {
    if (mutationErrors !== null && mutationErrors.length > 0) {
      onHandleError(
        i18n.t("slideouts-CreateCustomerForm-genericError"),
        mutationErrors
      );
    } else {
      handleOnClose(mutationData);
    }
  };

  const handlePhoneNumberChange = (ev) => {
    const desnormalizedPhone = ev.target.value.replace(/\D+/g, "");
    const otherPropsObject = {
      text: COMMON_NORMALIZERS.normalizePhone(ev.target.value),
    };

    fields.phoneNumber.setValue(desnormalizedPhone);
    fields.phoneNumber.setOtherProps(otherPropsObject);
    fields.phoneNumber.setVisited(true);
  };

  const updateAccount = async (values) => {
    const variables = {
      input: {
        id: values.id,
        name: values.name,
        phoneNumber: values.phoneNumber,
        emailAddress: values.email,
        accountNumber: values.accountNumber,
        notes: values.notes,
      },
    };
    try {
      const response = await updateCustomerAccount({ variables });
      return response.data;
    } catch (e) {
      console.warn(e);
    }

    return null;
  };

  return {
    onSubmit,
    isVisitedForm,
    handlePhoneNumberChange,
    fields,
    updateField,
    mutationLoading: updateAccountLoading,
    userCanEditCompanies,
  };
};

export default useEditAccountDetails;
