import { useEffect, useState } from "react";
import debounce from "lodash/debounce";
import useStartEmailThreadMutation from "../../../api/mutations/useStartEmailThreadMutation";
import useUploadAttachment from "../attachment/useUploadAttachment";

/*

DOCUMENTATION
  this hook is created to be used with CreateEmail

  args:
    client: (obj) required. Apollo Client instance.
    threadsActiveGroupIds: (arr) required. the id of the users active group (from where the text/thread will be sent).
    onMutationStart: (func) optional. will be called before mutation fires.
    onMutationSuccess: (func) optional. will be called on mutation success.
    onMutationError: (func) optional. will be called on mutation error.
    groups: (arr) required. Groups array that comes from the state manager.

  returns:
    handleMutationStartThread: (func) mutation to start new thread.
    contact: (obj) state. holds currently selected contact (who the new thread will be sent to)
    setContact: (func) updates contact state.
    contactEmail: (str) state. used in the case of emailing an unknown contact.
    setContactEmail: (func) updates contactEmail.
    senderGroup: (obj) state. this controlls who the "from" group will be, if the user has more than one group as active.
    setSenderGroup: (func) updates senderGroup.
    groupOptions: (obj) these are the groups that the user has selected as active, they have been restructured to { value, label }. Meant to be used with "select" elements.
    messageInputValue: (str) state. The message that will start the thread.
    setMessageInputValue: (func) updates messageInputValue state.
    attachment: (obj) state. holds any attachment that will be sent with the new thread.
    setAttachment: (func) updates attachment state.

*/

const useCreateEmail = ({
  client,
  threadsActiveGroupIds,
  onMutationStart = () => {},
  onMutationSuccess = () => {},
  onMutationError = () => {},
  initialThread = null,
  onError = () => {}, // more generic, meant to communicate with user.
  activeEmailChannels,
}) => {
  const [handleStartEmailThread] = useStartEmailThreadMutation({ client });

  const groupOptions = activeEmailChannels
    ? activeEmailChannels
        .filter((activeEmailChannel) =>
          threadsActiveGroupIds.some(
            (activeId) => activeId === activeEmailChannel.group.id
          )
        )
        ?.map((activeEmailChannel) => ({
          value: activeEmailChannel.group.id,
          label: `${activeEmailChannel.group.name}\u00A0 \u00A0(${activeEmailChannel.group.emailAddress})`,
          allowInbound: activeEmailChannel.emailConfig.allowInbound,
        }))
    : [];

  // Id value of contact that new thread is being sent to
  const [contact, setContact] = useState(
    initialThread ? initialThread.contact : null
  );

  const [contactEmail, setContactEmail] = useState(
    initialThread ? initialThread.contactEmail : ""
  );

  const [carbonCopyContacts, setCarbonCopyContacts] = useState(
    initialThread ? initialThread.carbonCopyContacts : []
  );

  const defaultSenderGroup =
    groupOptions?.length === 1
      ? groupOptions[0]
      : groupOptions?.find(
          (option) => option.value === threadsActiveGroupIds[0]
        );

  const [senderGroup, setSenderGroup] = useState(
    initialThread?.senderGroup ? initialThread.senderGroup : defaultSenderGroup
  );

  const [tags, setTags] = useState([]);

  // The first message sent with new thread
  const [messageInputValue, setMessageInputValue] = useState(
    initialThread ? initialThread.messageInputValue : ""
  );

  const [messageSubjectValue, setMessageSubjectValue] = useState(
    initialThread ? initialThread.messageSubjectValue : ""
  );

  const [attachment, setAttachment] = useState(
    initialThread ? initialThread.attachment : null
  );
  const [attachments, setAttachments] = useState(
    initialThread ? initialThread.attachments : []
  );

  const deleteAttachment = (id) => {
    const newAttachments = [...attachments].filter(
      (attachment) => attachment?.id !== id
    );
    setAttachments(newAttachments);
  };

  const uploadAttachment = useUploadAttachment({ client });

  useEffect(() => {
    if (!initialThread?.senderGroup) {
      if (groupOptions?.length === 1) {
        setSenderGroup(groupOptions[0]);
      } else {
        setSenderGroup(
          groupOptions?.find(
            (option) => option.value === threadsActiveGroupIds[0]
          )
        );
      }
    }
  }, [groupOptions.length]);

  useEffect(() => {
    if (senderGroup?.value === "") {
      setSenderGroup(defaultSenderGroup);
    }
  }, []);

  const handleAttachmentsForMobile = async () => {
    let newAttachments = [];
    if (attachment) {
      const uploadedAttachment = await uploadAttachment(attachment).catch(
        (err) => {
          console.error(err);
          onError(err);
        }
      );
      newAttachments = [{ url: uploadedAttachment?.url, method: "EMBED" }];
    }
    return newAttachments;
  };

  const handleAttachmentsForWeb = async () => {
    let messageAttachments = [];

    if (attachments.length) {
      const settledResponse = await Promise.allSettled(
        attachments.map((attachment) => uploadAttachment(attachment))
      );

      settledResponse.forEach((resp) => {
        if (resp.status === "rejected") onError(resp.reason);
      });

      messageAttachments = settledResponse
        .filter((resp) => resp.status === "fulfilled")
        .map((resp) => ({
          url: resp.value.url,
          method: "EMBED",
        }));
    }

    return messageAttachments;
  };

  const handleMutationStartThread = debounce(
    async () => {
      onMutationStart();

      let messageAttachments = [];

      if (process.env.PLATFORM === "mobile") {
        messageAttachments = await handleAttachmentsForMobile();
      } else {
        messageAttachments = await handleAttachmentsForWeb();
      }

      const carbonCopyIds = [];
      const anonymousCcList = [];
      if (carbonCopyContacts.length >= 1) {
        carbonCopyContacts.forEach((contact) => {
          if (contact?.id) {
            carbonCopyIds.push(contact?.id);
          }
          if (!contact?.id && contact?.emailAddress) {
            anonymousCcList.push(contact?.emailAddress);
          }
        });
      }

      const startThreadArgs = {
        message: {
          text: messageInputValue,
          attachments: messageAttachments,
        },
        tags: tags || null,
        emailMetadata: {
          subject: messageSubjectValue,
          cc: carbonCopyIds,
          anonymousCc: anonymousCcList,
        },
        onSuccess: onMutationSuccess,
        onError: onMutationError,
      };

      if (tags.length !== 0) {
        startThreadArgs.tags = tags.map((tag) => tag.referenceNumber);
      }

      if (contact?.__typename === "CustomerContact") {
        startThreadArgs.senderGroupId =
          threadsActiveGroupIds.length > 1
            ? senderGroup?.value
            : threadsActiveGroupIds[0];
        startThreadArgs.externalContactId =
          contact && contact.id ? contact.id : null;
        startThreadArgs.externalEmailAddress = contactEmail;
      } else if (!contact && contactEmail) {
        startThreadArgs.senderGroupId =
          threadsActiveGroupIds.length > 1
            ? senderGroup?.value
            : threadsActiveGroupIds[0];
        startThreadArgs.externalEmailAddress = contactEmail;
      }

      handleStartEmailThread(startThreadArgs);
    },
    1000,
    { leading: true }
  );

  return {
    handleMutationStartThread,
    contact,
    setContact,
    contactEmail,
    setContactEmail,
    carbonCopyContacts,
    setCarbonCopyContacts,
    senderGroup,
    setSenderGroup,
    groupOptions,
    tags,
    setTags,
    messageInputValue,
    setMessageInputValue,
    messageSubjectValue,
    setMessageSubjectValue,
    attachment,
    setAttachment,
    attachments,
    setAttachments,
    deleteAttachment,
  };
};

export default useCreateEmail;
