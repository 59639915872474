// NOTE: try to avoid naming colors after elements. example "buttonBlue".

const COLORS = {
  PK: {
    GOLD: '#FFE7BE',
    GOLD_20: '#FFFBF5',
    GOLD_40: '#FFEDD1',
    GOLD_60: '#FFE0AC',
    GOLD_80: '#FFD387',
    GOLD_100: '#F4B74E',

    BLUE: '#2A7991',
    BLUE_10: '#E9F5F3',
    BLUE_20: '#D3EBE8',
    BLUE_40: '#9CD4D6',
    BLUE_60: '#57A7B9',
    BLUE_80: '#225F76',
    BLUE_100: '#132C41',
    BLUR: '#2A7991',

    GREEN: '#64B587',
    GREEN_20: '#C8EDD7',
    GREEN_40: '#AAE3C2',
    GREEN_60: '#84CFA4',
    GREEN_80: '#2E8052',
    GREEN_100: '#1D633C',

    RED: '#DA5039',
    RED_20: '#FFCAC2',
    RED_40: '#FFA394',
    RED_60: '#FF7E69',
    RED_80: '#A3250F',
    RED_100: '#851704',

    TEAL: '#56B7A3',
    TEAL_20: '#D3EDE7',
    TEAL_40: '#B1E3DA',
    TEAL_60: '#86D1C3',
    TEAL_80: '#2C8270',
    TEAL_100: '#1C6656',

    WHITE: '#FFFFFF',
    GREY_10: '#F6F2EB',
    GREY_20: '#E6E2DC',
    GREY_30: '#CCC9C4',
    GREY_40: '#B3B0AB',
    GREY_45: '#B2B0AB',
    GREY_50: '#999793',
    GREY_60: '#807D7A',
    GREY_70: '#666462',
    GREY_80: '#4D4B49',
    GREY_90: '#333231',
    BLACK: '#131C2F',
    NEUTRAL_GREY: '#F8F8F8',

    TRANSPARENT: 'transparent',
    TRANSPARENT_DARK: 'rgba(0, 0, 0, 0.1)',
    TRANSPARENT_LIGHT: 'rgba(255, 255, 255, 0.1)',

    LOGO_PRIMARY: '#67ACBD',
    LOGO_SECONDARY: '#A2D2D5',
  },
  PK_DARK: {
    GREY_1: '#b2b2b2',
    GREY_2: '#8b8b8e',
    GREY_3: '#666668',
    GREY_4: '#363636',
    GREY_5: '#242424',
    BLACK: '#121212',

    RED: '#DE3112',

    NEUTRAL_GREY: '#595959',

    TRANSPARENT_WHITE_HIGH: 'rgba(255, 255, 255, .87)',
    TRANSPARENT_WHITE_MED: 'rgba(255, 255, 255, .60)',
    TRANSPARENT_WHITE_LOW: 'rgba(255, 255, 255, .38)',
  },
  PK_HOLIDAY: {
    HOLIDAY_PRIMARY: '#055902',
    HOLIDAY_SECONDARY: '#067302',
    HOLIDAY_TERTIARY: '#078C03',
    HOLIDAY_ACCENT: '#B01212',
    HOLIDAY_ACCENT_2: '#BFB169',
    HOLIDAY_ACCENT_3: '#8C824D',
  },
  PK_CLASSIC: {
    // nuetral hues
    PRIMARY_WHITE: '#FFFFFF', // white
    PRIMARY_BLACK: '#000000', // black
    SECONDARY_BLACK: '#393A3A', // text
    TERTIARY_BLACK: 'rgb(85,84,89)',
    PRIMARY_GRAY: '#AFAFAF', // secondaryGray
    SECONDARY_GRAY: '#E0E0E0', // borderGray,
    LIGHT_GRAY: '#EEE', // lightGray
    DARK_GRAY: 'darkgrey', // darkgrey
    DARK_GRAY_HOVER: '#BBB', // darkgrey_hover
    SUPER_LIGHT_GRAY: '#F7F7F7', // super(secret)LightGray

    // red
    PRIMARY_RED: '#C31A17', // alertRed
    PRIMARY_RED_HOVER: '#D32A27', // hoverRed
    PURE_RED: 'red', // red
    GRAPH_RED: '#FF5879',
    RED_LABEL: '#BA2E12',
    RED_LABEL_HOVER: '#F0674C',
    RED_LABEL_TEXT: '#FFB7A9',
    PIE_CHART_RED: '#FF5879',

    // orange
    ORANGE: '#EE6C00',
    ORANGE_LABEL: '#E07415',
    ORANGE_LABEL_HOVER: '#EBA263',
    ORANGE_LABEL_TEXT: '#FFCFA5',
    PIE_CHART_ORANGE: '#E59E37',
    WIDGET_ORANGE: '#E2A035',

    // yellow
    WARNING_YELLOW: '#FFCC00', // warningYellow
    PIE_CHART_YELLOW: '#F1E454',
    WIDGET_YELLOW: '#F0E350',

    // green
    PRIMARY_GREEN: '#55970C', // brightGreen,
    SECONDARY_GREEN: '#ABDE75', // I think is only used for selected icons in CreateSidebar
    TERTIARY_GREEN: '#82BE43', // thatOneGreen
    SWITCH_GREEN: '#52d869',
    LOGO_GREEN: '#82BD44',
    GREEN_LABEL: '#607B12',
    GREEN_LABEL_HOVER: '#99B152',
    GREEN_LABEL_TEXT: '#D1E793',
    PIE_CHART_GREEN: '#199E74',

    // blue
    PRIMARY_BLUE: '#004E7A', // primaryBlue, buttonBlue
    LIGHT_BLUE: '#006AA5', // hoverBlue, (logo blue!)
    LIGHT_BLUE_HOVER: '#1580BA', //
    PRIMARY_NAVY: '#04354E', // secondaryNavy
    LINK_BLUE: '#196DAC', // linkBlue
    SKY_BLUE: 'LightSkyBlue', // LightSkyBlue
    BRIGHT_BLUE: '#2A9FDF', // brightBlue
    BLUE_LABEL: '#0B7DA9',
    BLUE_LABEL_HOVER: '#72C5E4',
    BLUE_LABEL_TEXT: '#B1E9FF',
    PIE_CHART_BLUE: '#61B5E2',

    // purple
    PURPLE_LABEL: '#7449AB',
    PURPLE_LABEL_HOVER: '#AB88D8',
    PURPLE_LABEL_TEXT: '#D9C3F8',

    // transparent
    TRANSPARENT: 'transparent',

    // brown
    WIDGET_BROWN: '#A4804A',

    // OLD app colors. Note that not all colors that are in the app are here.

    // secondaryGray: '#AFAFAF',
    // lightGray: '#EEE',
    // borderGray: '#E0E0E0',
    // secondaryNavy: '#04354E', // '#0A283D',
    // primaryBlue: '#004E7A', // '#006AA5',
    // activeIconGreen: '#539709',
    // linkBlue: '#196DAC',
    // brightBlue: '#2A9FDF',
    // attentionBlue: '#49BCE5',
    // buttonBlue: 'rgb(0, 106, 165)',
    // alertRed: '#C31A17',
    // text: '#393A3A',
    // settings: {
    //   navBackgroundColor: '#07354E',
    //   mainBackgroundColor: '#004F7A',
    // },
    // lightYellow: '#FEFFF0',
    // warningYellow: '#FFCC00',
    // thatOneGreen: '#82BE43',
    // brightGreen: '#55970C',
    // yetAnotherGreen: '#00B67E', // #2BC9A3

    LOGO_PRIMARY: '#A2D2D5',
    LOGO_SECONDARY: '#67ACBD',
  },
};

export default COLORS;
