import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import styled from 'styled-components';
import THEMES from '../../../../styles/themes/app';
import { Heading3, IconButton } from '../../../../elements';

const HeaderContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TopButtonWidthContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  height: 36px;
  padding-top: 14px;
`;

const TopButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 12px;
`;
const TopLeftButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  padding-left: 12px;
`;

const HeadingContainer = styled.div`
  display: flex;
  height: 64px;
  align-items: center;
`;

const CustomerContactListHeader = ({
  closeCustomerInfo,
  handleClickOnMode,
}) => {
  return (
    <HeaderContainer>
      <TopButtonWidthContainer>
        <TopLeftButtonContainer>
          <IconButton onClick={() => handleClickOnMode('static')} size="lg">
            <i className="ri-arrow-left-s-line" />
          </IconButton>
        </TopLeftButtonContainer>
        <TopButtonContainer>
          <IconButton onClick={closeCustomerInfo} size="lg">
            <i className="ri-close-fill" />
          </IconButton>
        </TopButtonContainer>
      </TopButtonWidthContainer>
      <HeadingContainer>
        <Heading3>{i18n.t('customers-CustomerList-contacts')}</Heading3>
      </HeadingContainer>
    </HeaderContainer>
  );
};

CustomerContactListHeader.propTypes = {
  closeCustomerInfo: PropTypes.func.isRequired,
  handleClickOnMode: PropTypes.func.isRequired,
};

CustomerContactListHeader.defaultProps = {};

export default CustomerContactListHeader;
