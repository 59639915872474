import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';
import theme from 'styled-theming';
import OpenThreadsWarning from '../Modals/OpenThreadsWarning';
import DetectClickOutside from './DetectClickOutside';
import InfiniteScroll from '../InfiniteScroll/InfiniteScroll';
import {
  Text,
  Heading3,
  Heading2,
  Button,
  Checkbox,
  Loading,
} from '../../elements';
import THEMES from '../../styles/themes/app';

const CHECKBOX_FONTSIZE = theme('fontSize', {
  default: '16px',
  large: '20px',
});

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 76px;
  border-right: ${(props) =>
    props.standalone ? `solid 1px ${THEMES.BORDER_COLOR(props)}` : 'none'};
`;

const HeaderWrap = styled.div`
  display: flex;
  flex-direction: row;
  height: 75px;
  min-height: 75px;
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  border-bottom: ${(props) =>
    props.standalone ? `solid 1px ${THEMES.BORDER_COLOR(props)}` : 'none'};
`;

const SelectButton = styled.button`
  display: flex;
  flex: 1 1 0%;
  padding: 0;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  outline: none;
  max-width: 100%;
  width: 100%;
`;

const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 50px);
  padding: 0 16px;
  align-items: baseline;
  flex: 1;
  max-width: calc(100% - 50px);
  box-sizing: border-box;
`;

const DropdownArrow = styled.div`
  display: flex;
  padding: 0 13px;
  align-items: center;
`;

const GroupWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Option = styled.div`
  border-bottom: solid 1px ${THEMES.BORDER_COLOR};
  background-color: ${THEMES.BACKGROUND_SECONDARY};
`;

const SelectAllCheckboxContainer = styled.div`
  padding: 10px 16px;
  background-color: ${THEMES.BACKGROUND_TERTIARY};
`;

const CheckboxContainer = styled.div`
  padding: 30px 16px;
  display: flex;
  justify-content: flex-start;
`;

const GroupTextWrapper = styled.div`
  display: flex;
`;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ThreadsListColumnHeader = ({
  groups,
  groupSelectOpen,
  onSelectClick,
  onThreadsButtonClick,
  onCloseWarningClick,
  isWarningOpen,
  standalone,
  activeGroupIds,
  multiGroup,
  heightDeduction,
  onSelectionFinal,
  loading,
}) => {
  const selectedGroup = groups.find((l) => l.id === activeGroupIds[0]);
  const [selectedGroupName, setSelectedGroupName] = useState('');

  useEffect(() => {
    if (!multiGroup) {
      setSelectedGroupName(selectedGroup?.name);
    }
  }, [selectedGroup]);

  const selectedGroups =
    activeGroupIds?.length &&
    activeGroupIds
      .map((activeGroupId) =>
        groups.find((group) => group.id === activeGroupId)
      )
      .filter((group) => group !== undefined);

  const makeGroupsReadableAgain = (selectedGroups) => {
    if (!selectedGroups || !selectedGroups?.length) return '';

    if (selectedGroups.length === 1) return selectedGroups[0].name;

    return selectedGroups.map((group) => group.name).join(', ');
  };

  const selectedGroupsNames = makeGroupsReadableAgain(selectedGroups);

  // new multi-group logic
  const [selectedGroupIdsState, setSelectedGroupIdsState] =
    useState(activeGroupIds);

  const toggleSelectedGroupId = (selectedGroupId) => {
    const newSelectedGroupIdsState = [...selectedGroupIdsState];

    const threadActiveIndex = newSelectedGroupIdsState.findIndex(
      (groupId) => groupId === selectedGroupId
    );

    if (threadActiveIndex >= 0)
      newSelectedGroupIdsState.splice(threadActiveIndex, 1);
    else newSelectedGroupIdsState.push(selectedGroupId);

    setSelectedGroupIdsState(newSelectedGroupIdsState);
  };

  const onGroupClick = (selectedGroupId) => {
    onSelectClick();
    setSelectedGroupName(selectedGroupId.name);
    onSelectionFinal([selectedGroupId.id]);
  };

  const allAreActive = groups.reduce((bool, group) => {
    if (!bool) return bool;
    return selectedGroupIdsState.includes(group.id);
  }, true);

  const groupIds = groups.map((group) => group.id);

  const handleClickGroupSelect = () => {
    onSelectClick();
    if (groupSelectOpen) {
      onSelectionFinal(selectedGroupIdsState);
    }
  };

  const handleClickOutside = () => {
    if (groupSelectOpen) {
      handleClickGroupSelect();
    }
  };

  useEffect(() => {
    setSelectedGroupIdsState(activeGroupIds);
  }, [groupSelectOpen]);

  const getLabelHeaderText = (content) => {
    return content === 'All Groups'
      ? i18n.t('threads-ThreadsListColumnHeader-allGroups', {
          defaultValue: 'All Groups',
        })
      : content;
  };

  return (
    <ComponentWrapper standalone={standalone}>
      {loading ? (
        <LoadingContainer>
          <Loading size="xxs" />
        </LoadingContainer>
      ) : (
        <DetectClickOutside onClickOutside={handleClickOutside}>
          <HeaderWrap standalone={standalone}>
            <SelectButton
              onClick={() =>
                groups.length > 1 ? handleClickGroupSelect() : false
              }
              onKeyPress={(e) =>
                e.keyCode === 32 && groups.length > 1
                  ? handleClickGroupSelect()
                  : false
              }
              data-testid="st_groups_button"
            >
              <GroupWrapper>
                <TitleWrap>
                  {groups.length > 1 ? (
                    <GroupTextWrapper>
                      <Text contrast="highColor">
                        {i18n.t('threads-ThreadsListColumnHeader-groups')}
                      </Text>
                    </GroupTextWrapper>
                  ) : (
                    <Text contrast="low">
                      {i18n.t('threads-ThreadsListColumnHeader-group')}
                    </Text>
                  )}
                  <Heading3
                    customStyle={() => `
                      display: block;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 100%;
                    `}
                  >
                    {multiGroup
                      ? selectedGroupsNames
                      : getLabelHeaderText(selectedGroupName)}
                  </Heading3>
                </TitleWrap>
                {groups.length > 1 ? (
                  <DropdownArrow>
                    <Heading2 contrast="med">
                      <i className="ri-arrow-drop-down-line" />
                    </Heading2>
                  </DropdownArrow>
                ) : null}
              </GroupWrapper>
            </SelectButton>
          </HeaderWrap>
          {groupSelectOpen ? (
            <>
              {multiGroup && (
                <SelectAllCheckboxContainer>
                  <Checkbox
                    checked={allAreActive}
                    onCheck={() =>
                      setSelectedGroupIdsState(allAreActive ? [] : groupIds)
                    }
                    dataTestId="select-all-btn"
                    label={i18n.t('threads-ThreadsListColumnHeader-selectAll')}
                    labelRight
                  />
                </SelectAllCheckboxContainer>
              )}
              <InfiniteScroll
                bidirectionalScroll={false}
                scrollableList={groups}
                height={`calc(100vh - ${
                  heightDeduction + (multiGroup ? 116 : 76)
                }px)`}
                noRowsElement={<div />}
                loading={false}
                renderRow={({ list, index }) => {
                  const group = list[index];
                  const isActive = selectedGroupIdsState.includes(group.id);
                  return multiGroup ? (
                    <Option key={group.id}>
                      <CheckboxContainer
                        onClick={() => toggleSelectedGroupId(group.id)}
                      >
                        <Checkbox
                          dataTestId={`group-select-btn-${index}`}
                          checked={isActive}
                          onCheck={() => {}}
                          label={group.name}
                          labelRight
                          customLabelStyle={(props) => `
                              font-size: ${CHECKBOX_FONTSIZE(props)};
                              white-space: nowrap;
                              text-overflow: ellipsis;
                              display: block;
                              overflow: hidden;
                              max-width: 100%;
                            `}
                        />
                      </CheckboxContainer>
                    </Option>
                  ) : (
                    <Option key={group.id}>
                      <Button
                        type="noStyle"
                        noOutline
                        customStyle={() =>
                          'height: 76px; width: 100%; justify-content: flex-start;'
                        }
                        onClick={() => {
                          onGroupClick(group);
                        }}
                        dataTestId={group?.name?.replace(/\s/g, '-') ?? ''}
                      >
                        {getLabelHeaderText(group.name)}
                      </Button>
                    </Option>
                  );
                }}
                hasNextPage={false}
                loadingBorderBottom={false}
                loadingHeight={77}
                listItemHeight={77}
              />
            </>
          ) : null}
        </DetectClickOutside>
      )}
      <OpenThreadsWarning
        onThreadButtonClick={onThreadsButtonClick}
        onCloseClick={onCloseWarningClick}
        isOpen={isWarningOpen}
      />
    </ComponentWrapper>
  );
};

ThreadsListColumnHeader.propTypes = {
  groups: PropTypes.array,
  activeGroupId: PropTypes.string,
  groupSelectOpen: PropTypes.bool.isRequired,
  onSelectClick: PropTypes.func.isRequired,
  onGroupClick: PropTypes.func,
  onThreadsButtonClick: PropTypes.func.isRequired,
  onCloseWarningClick: PropTypes.func.isRequired,
  isWarningOpen: PropTypes.bool,
  standalone: PropTypes.bool,
  multiGroup: PropTypes.bool,
  activeGroupIds: PropTypes.array,
  heightDeduction: PropTypes.number,
  onSelectionFinal: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

ThreadsListColumnHeader.defaultProps = {
  groups: [],
  activeGroupId: null,
  isWarningOpen: false,
  standalone: false,
  multiGroup: false,
  onGroupClick: null,
  activeGroupIds: [],
  heightDeduction: 0,
  loading: false,
};

export default ThreadsListColumnHeader;
