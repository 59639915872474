import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import {
  GROUPS_V2_QUERY,
  LABELS_QUERY,
  SEARCH_CUSTOMERS_QUERY,
  USERS_QUERY,
} from 'client-lib';
import styled from 'styled-components';
import THEMES from '../../../../styles/themes/app';
import {
  TextInput,
  InfiniteSuggest,
  Button,
  TextArea,
  Heading5,
  Text,
  Switch,
  MetaText,
} from '../../../../elements';
import { setContactSlideoutAutofocusField } from '../../../../actions/general';
import ChipLabels from '../../../ChipLabels/ChipLabels';

const SectionContainer = styled.div`
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
`;

const BottomFooter = styled.div`
  background-color: ${THEMES.BACKGROUND_SECONDARY};
  color: ${THEMES.FOREGROUND_HIGH};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem;
  height: 60px;
  position: sticky;
  bottom: 0;
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 12px;
`;

const SwitchContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const IconAndTextWrapper = styled.div`
  display: flex;
  font-size: 16px;
  align-items: center;
`;

const IconWrapper = styled.div`
  font-size: 16px;
  color: ${(props) =>
    props.optedIn ? THEMES.THEME_GREEN(props) : THEMES.FOREGROUND_LOW(props)};
  margin-right: 8px;
`;

const FooterButtonContainer = styled.div`
  margin-left: 12px;
`;

const PreferencesSubHeader = styled.div`
  display: flex;
  row-gap: 4px;
  flex-direction: column;
`;

const PreferencesContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding-top: 8px;
  padding-bottom: 16px;
  margin-bottom: ${(props) => (props.disableFooter ? 0 : '76px')};
`;

const ChipsContainer = styled.div`
  padding-top: 4px;
  padding-bottom: 16px;
`;

const FIRST = 10;

const ContactCardEditDetails = ({
  customerDetailsProps,
  mutationLoading,
  submitDisabled,
  onSubmit,
  handleOnClose,
  handleFocusLabel,
  promoAccess,
  disableFooter,
}) => {
  const [firstNameBlur, setFirstNameBlur] = useState(false);
  const [lastNameBlur, setLastNameBlur] = useState(false);
  const apiMessageFeatureEnabled = useSelector(
    (state) => state?.accountData?.account?.ff_api
  );
  const contactSlideoutAutofocusField = useSelector(
    (state) => state.general.contactSlideoutAutofocusField
  );
  const ff_contact_labels = useSelector(
    (state) => state.accountData.account?.ff_contact_labels
  );
  const dispatch = useDispatch();

  const {
    handleCompanyChange,
    handleOnCompanySelect,
    handleAccountRepSelect,
    handleAccountRepBlur,
    handleBroadcastPreferenceChange,
    handlePhoneNumberChange,
    handleFaxNumberChange,
    handleEmailChange,
    handleOnSelectGroup,
    handleOnBlurGroup,
    handleOnSelectLabel,
    handleOnBlurLabels,
    handleOnDeleteLabel,
    handleOnDeleteGroup,
    fields,
    updateField,
    existingPhoneNumberId,
    existingFaxNumberId,
    existingEmailId,
    accountRepEditPermission,
    priorityNotificationsTogglePermission,
    announcementsAccess,
    allowFaxOptOut,
    allowEmailsOptOut,
    userCanEditCompanies,
  } = customerDetailsProps;
  const {
    id,
    firstName,
    lastName,
    email,
    faxNumber,
    accountRep,
    prioritizeRep,
    phoneNumber,
    notes,
    apiOptOut,
    faxOptOut,
    emailsOptOut,
    announcementsOptOut,
    promotionalBroadcastOptOut,
    conversationsOptOut,
    company,
    group,
    jobTitle,
    labels,
  } = fields;

  const firstNameError =
    firstName.visited && firstNameBlur ? firstName.error : '';

  const lastNameError = lastName.visited && lastNameBlur ? lastName.error : '';
  const emailError =
    email.visited && existingEmailId
      ? i18n.t('settings-ProfileFormContainer-emailDuplicateError')
      : email.error;

  const phoneNumberError =
    phoneNumber.visited && existingPhoneNumberId
      ? i18n.t('slideouts-CreateCustomerForm-numAlreadyExists')
      : phoneNumber.error;

  const faxError =
    faxNumber.visited && existingFaxNumberId
      ? i18n.t('slideouts-CreateCustomerForm-numAlreadyExists')
      : faxNumber.error;

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    updateField({ name: 'firstName', value });
  };

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    updateField({ name: 'lastName', value });
  };

  const handleJobTitleChange = (e) => {
    const value = e.target.value;
    updateField({ name: 'jobTitle', value });
  };

  const handleNotesChange = (e) => {
    const value = e.target.value;
    updateField({ name: 'notes', value });
  };

  const createDisplayCompanySuggestionCallback =
    (companyName) => (companySuggestions) => {
      // don't let user create customer account with just spaces
      if (companyName?.trim() === '') return false;

      const displaySticky = companySuggestions.reduce((acc, company) => {
        if (!acc) return acc;
        return !(company?.name?.toLowerCase() === companyName?.toLowerCase());
      }, true);
      return displaySticky;
    };

  const handleGroupDownChange = (e) => {
    const value = e.target.value;
    updateField({
      name: 'group',
      value: group.value,
      otherProps: { text: value },
    });
  };

  const handleLabelDropdown = (e) => {
    const value = e.target.value;
    updateField({
      name: 'labels',
      value: labels.value,
      otherProps: { text: value },
    });
  };

  // focus on field redux gives us to autofocus on.
  useEffect(() => {
    if (contactSlideoutAutofocusField) {
      setTimeout(() => {
        fields?.[contactSlideoutAutofocusField]?.ref?.current?.focus();
      }, 400);
      // seemingly arbitrary 400 ms actually has to do with waiting longer than the actual slideout animation
      // the animation lasts 350 ms and if focus triggers before animation is over it causes jumping.
    }
  }, [contactSlideoutAutofocusField]);

  // remove autofocus redux state when unmount
  useEffect(() => {
    return () => {
      dispatch(setContactSlideoutAutofocusField(''));
    };
  }, []);

  return (
    <div>
      <SectionContainer>
        <TextInput
          label={i18n.t('slideouts-CreateCustomerForm-firstName')}
          error={firstNameError}
          dataTestId="first-name-input"
          value={firstName.value}
          onChange={handleFirstNameChange}
          ref={firstName.ref}
          onBlur={() => setFirstNameBlur(true)}
        />
      </SectionContainer>
      <SectionContainer>
        <TextInput
          label={i18n.t('slideouts-CreateCustomerForm-lastName')}
          error={lastNameError}
          dataTestId="last-name-input"
          value={lastName.value}
          onChange={handleLastNameChange}
          ref={lastName.ref}
          onBlur={() => setLastNameBlur(true)}
        />
      </SectionContainer>
      <SectionContainer>
        <TextInput
          label={i18n.t('customers-Contacts-title', {
            defaultValue: 'Job Title',
          })}
          dataTestId="job-title-input"
          error={jobTitle.error}
          value={jobTitle.value}
          onChange={(e) => handleJobTitleChange(e)}
          ref={jobTitle.ref}
        />
      </SectionContainer>
      <SectionContainer>
        <InfiniteSuggest
          inputProps={{
            label: i18n.t('slideouts-CreateCustomerForm-company'),
            onBlur: () => handleCompanyChange({ target: { value: '' } }),
            value: company.otherProps.name,
            onChange: handleCompanyChange,
            placeholder: i18n.t('slideouts-CreateCustomerForm-searchCompanies'),
            ref: company.ref,
            dataTestId: 'company-name-input',
          }}
          query={SEARCH_CUSTOMERS_QUERY}
          queryKey="searchCustomers"
          queryVariables={{
            typeFilter: 'CUSTOMER_ACCOUNT',
            query: company.otherProps.name,
            first: FIRST,
          }}
          onSelect={(e, { suggestion }) => handleOnCompanySelect(suggestion)}
          displayStickyButton={() =>
            userCanEditCompanies &&
            createDisplayCompanySuggestionCallback(company.otherProps.name)
          }
          stickyButtonText={i18n.t('slideouts-CompanyDropDown-createNew', {
            companyName: company.otherProps.name,
          })}
          onStickyButtonClick={() =>
            handleOnCompanySelect({
              addCompanySuggestion: true,
              name: company.otherProps.name,
            })
          }
        />
      </SectionContainer>
      <SectionContainer>
        <TextInput
          label={i18n.t('slideouts-CreateCustomerForm-phone')}
          error={phoneNumberError}
          value={phoneNumber?.otherProps?.text}
          onChange={(e) => handlePhoneNumberChange(e, 'contact', id.value)}
          ref={phoneNumber.ref}
        />
      </SectionContainer>
      <SectionContainer>
        <TextInput
          label={i18n.t('slideouts-CreateCustomerForm-fax', {
            defaultValue: 'Fax',
          })}
          error={faxError}
          dataTestId="fax-number-input"
          value={faxNumber?.otherProps?.text}
          onChange={(e) => handleFaxNumberChange(e, id.value)}
          ref={faxNumber.ref}
        />
      </SectionContainer>
      <SectionContainer>
        <TextInput
          label={i18n.t('slideouts-CreateCustomerForm-email')}
          error={emailError}
          dataTestId="email-input"
          value={email.value}
          onChange={(e) => handleEmailChange(e, id.value)}
          ref={email.ref}
        />
      </SectionContainer>
      <SectionContainer>
        <InfiniteSuggest
          inputProps={{
            label: i18n.t('slideouts-CreateCustomerForm-group'),
            onBlur: (e) => handleOnBlurGroup(e),
            value: group?.otherProps?.text,
            onChange: (e) => handleGroupDownChange(e),
            placeholder: i18n.t(
              'settings-GroupDropDown-groupDropDownPlaceholder'
            ),
            error: group.error,
            dataTestId: 'group-search-input',
          }}
          query={GROUPS_V2_QUERY}
          queryKey="groupsV2"
          queryVariables={{
            limit: FIRST,
            filter: group?.otherProps?.text,
          }}
          multiSelectedChipProps={{
            size: 'sm',
            dataTestId: 'group-badge',
          }}
          multiSelectedSuggestions={group?.value}
          onMultiSelectionDelete={(e, { suggestion }) =>
            handleOnDeleteGroup(suggestion.value)
          }
          onSelect={(e, { suggestion }) =>
            handleOnSelectGroup({
              text: suggestion.name,
              value: suggestion.id,
            })
          }
        />
      </SectionContainer>
      <SectionContainer>
        <TextArea
          label={i18n.t('slideouts-CustomerInfo-notes')}
          value={notes?.value}
          onChange={handleNotesChange}
          rows={4}
          dataTestId="notes-textarea"
        />
      </SectionContainer>
      {ff_contact_labels && (
        <SectionContainer>
          <InfiniteSuggest
            inputProps={{
              label: i18n.t('settings-Label-labels', {
                defaultValue: 'Labels',
              }),
              dataTestId: 'label-input',
              onBlur: (e) => {
                handleOnBlurLabels(e);
                handleFocusLabel();
              },
              value: labels?.otherProps?.text,
              onChange: (e) => {
                handleLabelDropdown(e);
              },
              placeholder: i18n.t('settings-Label-searchLabels', {
                defaultValue: 'Search Labels',
              }),
              hideBottomSpace: true,
              ref: labels.ref,
            }}
            query={LABELS_QUERY}
            queryKey="labels"
            openOnFocus
            queryVariables={{
              filter: labels?.otherProps?.text,
              limit: 20,
            }}
            onSelect={(e, { suggestion }) =>
              handleOnSelectLabel({
                text: suggestion.name,
                value: suggestion.id,
                color: suggestion.color,
              })
            }
          />
          <ChipsContainer>
            <ChipLabels
              size="sm"
              options={labels}
              handleOnDeleteLabel={handleOnDeleteLabel}
            />
          </ChipsContainer>
        </SectionContainer>
      )}
      <SectionContainer>
        <InfiniteSuggest
          inputProps={{
            label: i18n.t('slideouts-CreateCustomerForm-accountRep'),
            onBlur: handleAccountRepBlur,
            value: accountRep.value,
            onChange: (e) => accountRep.setValue(e.target.value),
            placeholder: i18n.t('slideouts-CreateThread-enterNameOrNumber'),
            disabled: !accountRepEditPermission,
            dataTestId: 'account-rep-input',
          }}
          query={USERS_QUERY}
          queryKey="users"
          queryVariables={{
            after: null,
            first: FIRST,
            around: null,
            before: null,
            filter: accountRep.value,
            last: null,
            groupIds: null,
          }}
          onSelect={(e, { suggestion }) => handleAccountRepSelect(suggestion)}
        />
        <Heading5>{i18n.t('slideouts-CustomerInfo-priority')}</Heading5>
        <ToggleWrapper>
          <Text contrast="low">
            {i18n.t('slideouts-CreateCustomerForm-showNewMessages')}
          </Text>
          <Switch
            checked={prioritizeRep.value}
            onCheck={() => {
              prioritizeRep.setValue(!prioritizeRep.value);
              prioritizeRep.setVisited(true);
            }}
            disabled={
              !accountRep.otherProps.id ||
              !priorityNotificationsTogglePermission
            }
            dataTestId="prioritize-rep-toggle"
          />
        </ToggleWrapper>
      </SectionContainer>
      <SectionContainer>
        <PreferencesSubHeader>
          <Heading5>{i18n.t('slideouts-CustomerInfo-preferences')}</Heading5>
          <Text contrast="low">
            {i18n.t('slideouts-Customers-confirmingOptInStatus')}
          </Text>
        </PreferencesSubHeader>
        <PreferencesContainer disableFooter={disableFooter}>
          <SwitchContainer firstRow>
            <IconAndTextWrapper>
              <IconWrapper>
                <i className="ri-question-answer-fill" />
              </IconWrapper>
              <MetaText>
                {i18n.t('slideouts-Customers-conversationalText')}
              </MetaText>
            </IconAndTextWrapper>
            <Switch
              dataTestId="conversation-opt-out-toggle"
              checked={!conversationsOptOut.value}
              onCheck={() => {
                conversationsOptOut.setValue(!conversationsOptOut.value);
                conversationsOptOut.setVisited(true);
              }}
            />
          </SwitchContainer>
          {apiMessageFeatureEnabled && (
            <SwitchContainer>
              <IconAndTextWrapper>
                <IconWrapper>
                  <i className="ri-terminal-box-fill" />
                </IconWrapper>
                <MetaText>
                  {i18n.t('slideouts-CustomerInfo-autoMessages')}
                </MetaText>
              </IconAndTextWrapper>
              <Switch
                dataTestId="api-opt-out-toggle"
                checked={!apiOptOut.value}
                onCheck={() => {
                  apiOptOut.setValue(!apiOptOut.value);
                  apiOptOut.setVisited(true);
                }}
              />
            </SwitchContainer>
          )}
          {announcementsAccess ? (
            <>
              <SwitchContainer>
                <IconAndTextWrapper>
                  <IconWrapper>
                    <i className="ri-base-station-fill" />
                  </IconWrapper>
                  <MetaText dataTestId="broadcast-wrapper">
                    {i18n.t('settings-SettingsNavigation-broadcasts')}
                  </MetaText>
                </IconAndTextWrapper>
                <Switch
                  dataTestId="announcements-opt-out-toggle"
                  checked={announcementsOptOut.value === false}
                  onCheck={() =>
                    handleBroadcastPreferenceChange(announcementsOptOut)
                  }
                />
              </SwitchContainer>
              {promoAccess && (
                <SwitchContainer>
                  <IconAndTextWrapper>
                    <IconWrapper>
                      <i className="ri-advertisement-fill" />
                    </IconWrapper>
                    <MetaText dataTestId="broadcast-wrapper">
                      {i18n.t('settings-broadcasts-promotionalBroadcast')}
                    </MetaText>
                  </IconAndTextWrapper>
                  <Switch
                    dataTestId="announcements-opt-out-toggle"
                    checked={promotionalBroadcastOptOut.value === false}
                    onCheck={() =>
                      handleBroadcastPreferenceChange(
                        promotionalBroadcastOptOut
                      )
                    }
                  />
                </SwitchContainer>
              )}
            </>
          ) : null}
          {allowEmailsOptOut ? (
            <SwitchContainer>
              <IconAndTextWrapper>
                <IconWrapper>
                  <i className="ri-mail-fill" />
                </IconWrapper>
                <MetaText>
                  {i18n.t('slideouts-CreateCustomerForm-email')}
                </MetaText>
              </IconAndTextWrapper>
              <Switch
                dataTestId="email-opt-out-toggle"
                checked={!emailsOptOut.value}
                onCheck={() => {
                  emailsOptOut.setValue(!emailsOptOut.value);
                  emailsOptOut.setVisited(true);
                }}
              />
            </SwitchContainer>
          ) : null}
          {allowFaxOptOut ? (
            <SwitchContainer>
              <IconAndTextWrapper>
                <IconWrapper>
                  <i className="ri-printer-fill" />
                </IconWrapper>
                <MetaText>
                  {i18n.t('slideouts-CreateCustomerForm-fax', {
                    defaultValue: 'Fax',
                  })}
                </MetaText>
              </IconAndTextWrapper>
              <Switch
                dataTestId="fax-opt-out-toggle"
                checked={!faxOptOut.value}
                onCheck={() => {
                  faxOptOut.setValue(!faxOptOut.value);
                  faxOptOut.setVisited(true);
                }}
              />
            </SwitchContainer>
          ) : null}
        </PreferencesContainer>
      </SectionContainer>
      {!disableFooter && (
        <BottomFooter>
          <FooterButtonContainer>
            <Button
              dataTestId="cancel-contact-button "
              onClick={() => handleOnClose()}
              type="secondary"
            >
              {i18n.t('slideouts-EditSaveCancel-cancel', {
                defaultValue: 'Cancel',
              })}
            </Button>
          </FooterButtonContainer>
          <FooterButtonContainer>
            <Button
              dataTestId="save-contact-button"
              loadingSpinner={mutationLoading}
              disabled={!!submitDisabled}
              onClick={onSubmit}
            >
              {i18n.t('slideouts-EditSaveCancel-save', {
                defaultValue: 'Save',
              })}
            </Button>
          </FooterButtonContainer>
        </BottomFooter>
      )}
    </div>
  );
};

ContactCardEditDetails.propTypes = {
  customerDetailsProps: PropTypes.object.isRequired,
  mutationLoading: PropTypes.bool.isRequired,
  submitDisabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleOnClose: PropTypes.func.isRequired,
  handleFocusLabel: PropTypes.func.isRequired,
  promoAccess: PropTypes.bool,
  disableFooter: PropTypes.bool,
};

ContactCardEditDetails.defaultProps = {
  promoAccess: false,
  disableFooter: false,
};

export default ContactCardEditDetails;
