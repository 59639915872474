import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';
import { useSpring, animated } from 'react-spring';
import FormattedDateTime from '../../components/Common/FormattedDateTime';
import useMeasure from '../../components/Animations/useMeasure';
import { Heading5, Text } from '../Text/Text';
import IconButton from '../Button/IconButton';
import THEMES from '../../styles/themes/app';
import { TimeStamp } from '../../components/Threads/Message/styles';
import MessageText from '../Text/MessageText';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

const MessageWrapper = styled.div`
  background-color: ${THEMES.BACKGROUND_QUATERNARY};
  border-radius: 16px;
  display: flex;
  flex-wrap: nowrap;
  padding: 0 8px;
  min-height: 24px;
`;

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: ${(props) => (props.secondRow ? '35px' : '0px')};
  padding-bottom: ${(props) => (props.secondRow ? '8px' : '0px')};
  margin: ${(props) => (props.firstRow ? '8px 0' : '0px')};
  box-sizing: border-box;
`;

const LeftSideWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 4px;
  gap: 2px 8px;
  word-break: break-word;
`;

const RightSideWrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 16px;
`;

const Eyecon = styled.i`
  font-size: 16px;
  color: ${THEMES.FOREGROUND_LOW};
`;

const ArrowIcon = styled.i`
  font-size: 26px;
`;

const MessageContent = styled.div`
  padding: 0 0 0 0;
`;

const TransferMessageText = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  margin: 0;
  border-left: solid 3px ${THEMES.BORDER_COLOR};
  font-style: italic;
  white-space: pre-wrap;
  word-break: break-word;
`;

const InternalUseOnly = ({
  maintext,
  subtext,
  isOpen,
  setIsOpen,
  timestamp,
  displayCaret,
  hideIcon,
  title,
  type,
  printMessage,
}) => {
  const [bind, measure] = useMeasure();

  const animationSupportedHeight =
    isOpen && subtext ? (measure?.height ?? 0) + 8 : 0;
  const noAnimationSupportedHeight = 'auto';

  const animation = useSpring({
    overflow: 'hidden',
    height: setIsOpen ? animationSupportedHeight : noAnimationSupportedHeight,
    config: { duration: 150 },
  });

  const dynamicTitle = typeof title === 'function' ? title() : title;

  const redTextColorMap = new Map([
    ['deleted', 'red'],
    ['pci_breach_warning', 'red80'],
    ['pci_message_text_warning', 'red80'],
  ]);

  return (
    <MessageWrapper>
      <Column>
        <RowWrapper firstRow>
          <LeftSideWrapper>
            {!hideIcon && <Eyecon className="ri-eye-off-line" />}
            <Heading5>{dynamicTitle}</Heading5>
            <MessageContent>
              <Text contrast={redTextColorMap.get(type) || 'high'}>
                {maintext}
              </Text>
            </MessageContent>
            {timestamp ? (
              <TimeStamp noPaddingLeft>
                <FormattedDateTime value={timestamp} />
              </TimeStamp>
            ) : null}
          </LeftSideWrapper>
          {displayCaret && subtext ? (
            <RightSideWrapper>
              <IconButton
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
                size="sm"
                contrast="low"
              >
                <ArrowIcon
                  className={`ri-arrow-drop-${isOpen ? 'up' : 'down'}-line`}
                />
              </IconButton>
            </RightSideWrapper>
          ) : null}
        </RowWrapper>
        <animated.div style={!printMessage ? animation : {}}>
          <RowWrapper secondRow {...bind}>
            <TransferMessageText>
              <MessageText contrast="low">{subtext}</MessageText>
            </TransferMessageText>
          </RowWrapper>
        </animated.div>
      </Column>
    </MessageWrapper>
  );
};

InternalUseOnly.propTypes = {
  maintext: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  timestamp: PropTypes.string,
  displayCaret: PropTypes.bool,
  type: PropTypes.string,
  hideIcon: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  printMessage: PropTypes.bool,
};

InternalUseOnly.defaultProps = {
  subtext: '',
  isOpen: false,
  setIsOpen: undefined,
  timestamp: '',
  displayCaret: false,
  type: '',
  hideIcon: false,
  title: () =>
    i18n.t('threads-InternalMessage-internalOnly', {
      defaultValue: 'Internal Use Only',
    }),
  printMessage: false,
};

export default InternalUseOnly;
