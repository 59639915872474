import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import i18n from 'i18n-js';
import {
  USERS_QUERY,
  USERS_GROUPS_PAGINATED_QUERY,
  SEARCH_CUSTOMERS_QUERY,
} from 'client-lib';
import {
  Heading5,
  TextArea,
  InfiniteSuggest,
  SelectedEntity,
} from '../../elements';
import THEMES from '../../styles/themes/app';

const underlineColors = {
  blue: THEMES.FOREGROUND_MED_COLOR,
  border: THEMES.BORDER_COLOR,
  red: THEMES.THEME_RED,
};

const WrapCustomerEditInput = styled.div`
  padding: 1rem 1rem 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  border-bottom-width: ${(props) => (props.focused ? '2px' : '1px')};
  border-bottom-style: solid;
  border-bottom-color: ${(props) => underlineColors[props.underlineColor]};
  background-color: ${THEMES.BACKGROUND_PRIMARY};
  & input {
    background-color: ${THEMES.BACKGROUND_PRIMARY};
  }
`;
// Note: in case Brett wants to bring the "yellow" background back vv
// background-color: ${props => props.focused ? colors.lightYellow : THEMES.BACKGROUND_PRIMARY(props)};
// & input {
//   background-color: ${props => props.focused ? colors.lightYellow : THEMES.BACKGROUND_PRIMARY(props)};
// }

const InvalidMessage = styled.span`
  color: ${THEMES.THEME_RED};
  margin-left: 5px;
  font-weight: bold;
`;

const SharedHeaderSection = styled.div`
  display: flex;
  width: 100%;
`;

const HeadWrap = styled.div`
  display: flex;
  align-items: baseline;
`;

const BreathingSpace = styled.div`
  margin-top: 8px;
`;

const Input = styled.input`
  margin-top: 8px;
  border: none;
  font-size: 17px;
  font-family: 'Barlow', sans-serif;
  font-weight: 500;
  outline: none;
  padding: 0;
  color: ${THEMES.FOREGROUND_HIGH};
`;

// const styles = {
//   editInput: {
//     marginTop: 8,
//     border: 'none',
//     fontSize: '17px',
//     fontFamily: 'Barlow, sans-serif',
//     fontWeight: '500',
//     outline: 'none',
//     padding: 0,
//   },
// };

const FIRST = 10;

class CustomerEditInput extends Component {
  state = { focused: false };

  onFocus = (e) => {
    const { onFocus } = this.props;

    const val = e.target.value;
    e.target.value = '';
    e.target.value = val;
    this.setState({ focused: true });

    if (onFocus) onFocus();
  };

  onBlur = () => {
    const { onBlur } = this.props;
    this.setState({ focused: false }, onBlur);
  };

  render() {
    const { focused } = this.state;
    const {
      value,
      onChange,
      title,
      userSelection,
      invalidMessage,
      autoFocus,
      specialtyType,
      onSelect,
      onRemoveSelection,
      children,
      innerRef,
      headerElement,
      displayStickyButton,
      stickyButtonText,
      onStickyButtonClick,
    } = this.props;
    let inputEl;
    if (specialtyType === 'noInput') {
      inputEl = null;
    } else if (specialtyType === 'companySuggest') {
      inputEl = (
        <BreathingSpace>
          <InfiniteSuggest
            inputProps={{
              value,
              onFocus: this.onFocus,
              onBlur: this.onBlur,
              onChange: (e) => onChange(e, { value }),
              autoFocus,
              hideBottomSpace: true,
              placeholder: i18n.t('slideouts-CompanyDropDown-enterName'),
            }}
            query={SEARCH_CUSTOMERS_QUERY}
            queryKey="searchCustomers"
            queryVariables={{
              typeFilter: 'CUSTOMER_ACCOUNT',
              query: value,
              first: FIRST,
            }}
            onSelect={(e, { suggestion }) => onSelect(suggestion)}
            displayStickyButton={displayStickyButton}
            stickyButtonText={stickyButtonText}
            onStickyButtonClick={onStickyButtonClick}
          />
        </BreathingSpace>
      );
    } else if (specialtyType === 'groupSuggest') {
      inputEl = (
        <BreathingSpace>
          <InfiniteSuggest
            inputProps={{
              value,
              onFocus: this.onFocus,
              onBlur: this.onBlur,
              onChange: (e) => onChange(e, { value }),
              autoFocus,
              hideBottomSpace: true,
              placeholder: i18n.t(
                'settings-GroupDropDown-groupDropDownPlaceholder'
              ),
            }}
            query={USERS_GROUPS_PAGINATED_QUERY}
            queryKey="usersGroupsPaginated"
            queryVariables={{
              first: FIRST,
              filter: value,
              groupIds: null,
            }}
            onSelect={onSelect}
          />
        </BreathingSpace>
      );
    } else if (specialtyType === 'userSuggest') {
      inputEl = (
        <BreathingSpace>
          {!userSelection?.selectedUser?.id ? (
            <InfiniteSuggest
              inputProps={{
                value,
                onFocus: this.onFocus,
                onChange,
                onBlur: this.onBlur,
                autoFocus,
                hideBottomSpace: true,
                placeholder: i18n.t('slideouts-CreateThread-enterNameOrNumber'),
              }}
              query={USERS_QUERY}
              queryKey="users"
              queryVariables={{
                after: null,
                first: FIRST,
                around: null,
                before: null,
                filter: value,
                last: null,
                groupIds: null,
              }}
              onSelect={onSelect}
            />
          ) : (
            <SelectedEntity
              maintext={userSelection?.name}
              subtext={userSelection?.selectedUser.role}
              avatarProps={{
                avatarUrl: userSelection?.selectedUser.avatarUrl,
                type: 'internal',
                active: userSelection?.selectedUser.active,
              }}
              onDelete={onRemoveSelection}
              hideBottomSpace
            />
          )}
        </BreathingSpace>
      );
    } else if (specialtyType === 'textarea') {
      inputEl = (
        <TextArea
          hideBottomSpace
          value={value}
          onChange={onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          ref={innerRef}
          autoFocus={autoFocus}
          rows={4}
          placeholder={i18n.t('slideouts-CustomerEditInput-enterNotes')}
          dataTestId="cei-textarea"
        />
      );
    } else {
      inputEl = (
        <Input
          type="text"
          value={value}
          onChange={onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          autoComplete="new-password"
          ref={innerRef}
          // style={styles.editInput}
          autoFocus={autoFocus}
          data-testid="cei-input"
        />
      );
    }

    const underlineColor = focused ? 'blue' : 'border';

    return (
      <WrapCustomerEditInput
        underlineColor={invalidMessage ? 'red' : underlineColor}
        focused={focused}
        data-testid="customer-edit-input"
      >
        <HeadWrap>
          <SharedHeaderSection>
            <Heading5 contrast="high" dataTestId="cei-title">
              {title}
            </Heading5>
            {invalidMessage && (
              <InvalidMessage data-testid="cei-invalid">
                {invalidMessage}
              </InvalidMessage>
            )}
            {headerElement?.()}
          </SharedHeaderSection>
        </HeadWrap>
        {inputEl}
        {children}
      </WrapCustomerEditInput>
    );
  }
}

CustomerEditInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  userSelection: PropTypes.object,
  invalidMessage: PropTypes.string,
  autoFocus: PropTypes.bool,
  specialtyType: PropTypes.string,
  onSelect: PropTypes.func,
  onRemoveSelection: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  children: PropTypes.node,
  innerRef: PropTypes.object,
  headerElement: PropTypes.func,
  mixedQueryType: PropTypes.string,
  displayStickyButton: PropTypes.func,
  stickyButtonText: PropTypes.string,
  onStickyButtonClick: PropTypes.func,
};

CustomerEditInput.defaultProps = {
  userSelection: {},
  invalidMessage: '',
  autoFocus: false,
  onSelect: () => {},
  onRemoveSelection: () => {},
  onBlur: () => {},
  onFocus: () => {},
  specialtyType: '',
  children: null,
  innerRef: null,
  headerElement: null,
  mixedQueryType: '',
  displayStickyButton: () => false,
  stickyButtonText: '',
  onStickyButtonClick: () => {},
};

export default CustomerEditInput;
