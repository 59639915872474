import React from 'react';
import PropTypes from 'prop-types';
import HtmlMapper from 'react-html-map';
import styleParse from 'style-to-object';
import { camelCase } from 'lodash';
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Text,
} from '../../elements';
import HtmlErrorBoundary from './HtmlErrorBoundary';

const convertStyles = (css) => {
  let reactStyles = {};

  if (css) {
    const styleObj = styleParse(css);
    reactStyles = Object.keys(styleObj).reduce((acc, key) => {
      acc[camelCase(key)] = styleObj[key];
      return acc;
    }, {});
  }

  return reactStyles;
};

/* eslint-disable react/prop-types */
const genericElementMap = (tag) =>
  function ({ classname, children, style, ...rest }) {
    const Tag = tag;

    const reactStyles = convertStyles(style);

    return (
      <Tag className={classname} style={reactStyles} {...rest}>
        {children}
      </Tag>
    );
  };

/* eslint-enable react/prop-types */

/* eslint-disable react/no-unstable-nested-components */

const HtmlMap = ({ parsedHtml, originalHtml, attachments }) => {
  return (
    <HtmlErrorBoundary html={originalHtml}>
      <HtmlMapper html={parsedHtml}>
        {{
          p: ({ children, style }) => (
            <Text customStyle={() => style}>{children}</Text>
          ),
          h1: ({ children, style }) => (
            <Heading1 customStyle={() => style}>{children}</Heading1>
          ),
          h2: ({ children, style }) => (
            <Heading2 customStyle={() => style}>{children}</Heading2>
          ),
          h3: ({ children, style }) => (
            <Heading3 customStyle={() => style}>{children}</Heading3>
          ),
          h4: ({ children, style }) => (
            <Heading4 customStyle={() => style}>{children}</Heading4>
          ),
          h5: ({ children, style }) => (
            <Heading5 customStyle={() => style}>{children}</Heading5>
          ),
          li: ({ children, style }) => (
            <Text>
              <li style={convertStyles(style)}>{children}</li>
            </Text>
          ),
          a: ({ href, style, children }) => (
            <a
              style={convertStyles(style)}
              href={href}
              target="_blank"
              rel="noreferrer"
            >
              {children}
            </a>
          ),
          img: ({ src, alt, style }) => {
            const externalId = src?.replace('cid:', '');
            const matchingAttachment = attachments.find(
              (attachment) => attachment?.externalId === externalId
            );

            if (matchingAttachment) {
              const photoSrc = `/attachments/${matchingAttachment.id}/original/${matchingAttachment.originalFilename}`;

              return (
                <img
                  src={photoSrc}
                  alt={alt}
                  style={{ ...convertStyles(style), maxWidth: '100%' }}
                />
              );
            }
            return (
              <img
                src={src}
                alt={alt}
                style={{ ...convertStyles(style), maxWidth: '100%' }}
              />
            );
          },
          div: genericElementMap('div'),
          span: genericElementMap('span'),
          em: genericElementMap('em'),
          strong: genericElementMap('strong'),
          b: genericElementMap('b'),
          i: genericElementMap('i'),
          s: genericElementMap('s'),
          strike: genericElementMap('strike'),
          font: genericElementMap('font'),
          u: genericElementMap('u'),
          ol: genericElementMap('ol'),
          ul: genericElementMap('ul'),
          menu: genericElementMap('menu'),
          header: genericElementMap('header'),
          nav: genericElementMap('nav'),
          main: genericElementMap('main'),
          aside: genericElementMap('aside'),
          table: genericElementMap('table'),
          th: genericElementMap('th'),
          tr: genericElementMap('tr'),
          td: genericElementMap('td'),
          caption: genericElementMap('caption'),
          colgroup: genericElementMap('colgroup'),
          thead: genericElementMap('thead'),
          tbody: genericElementMap('tbody'),
          tfoot: genericElementMap('tfoot'),
          center: genericElementMap('center'),
          html: genericElementMap('div'),
          body: genericElementMap('div'),
          blockquote: genericElementMap('blockquote'),
          pre: genericElementMap('pre'),

          hr: ({ children }) => (
            <>
              <hr />
              {children}
            </>
          ),
          br: ({ children }) => (
            <>
              <br />
              {children}
            </>
          ),
          form: ({ children }) => <div>{children}</div>,
          input: ({ children }) => <div>{children}</div>,
          area: ({ children }) => <div>{children}</div>,
          link: ({ children }) => <div>{children}</div>,
          meta: ({ children }) => <div>{children}</div>,
          source: ({ children }) => <div>{children}</div>,
          base: ({ children }) => <div>{children}</div>,
          col: ({ children }) => <div>{children}</div>,
          embed: ({ children }) => <div>{children}</div>,
          param: ({ children }) => <div>{children}</div>,
          track: ({ children }) => <div>{children}</div>,
          wbr: ({ children }) => <div>{children}</div>,
        }}
      </HtmlMapper>
    </HtmlErrorBoundary>
  );
};

/* eslint-enable react/no-unstable-nested-components */

HtmlMap.propTypes = {
  parsedHtml: PropTypes.string.isRequired,
  originalHtml: PropTypes.string.isRequired,
  attachments: PropTypes.array,
};

HtmlMap.defaultProps = {
  attachments: null,
};

const areEqual = (oldProps, props) => oldProps.html === props.html;

export default React.memo(HtmlMap, areEqual);
