import { useMutation } from "@apollo/client";
import { openThreadSubscriptionHandler } from "../../../configs/apollo/setupSubscriptions";
import ClaimThreadMutation from "../../../graphql/mutation/ClaimThreadMutation.graphql";
import MarkThreadAsNotSpamMutation from "../../../graphql/mutation/MarkThreadAsNotSpamMutation.graphql";

/**
 * Custom hook for claiming, claiming and marking or only marking a thread as not spam.
 *
 * @param {Object} options - The options for the hook.
 * @param {Object} options.client - The Apollo client.
 * @param {Function} options.onHandleClaimThreadSuccess - The callback function to handle successful claim thread.
 * @param {Function} options.onHandleClaimThreadError - The callback function to handle claim thread error.
 * @param {Function} options.onHandleMarkNotSpamThread - The callback function to handle marking thread as not spam.
 * @param {Function} options.onHandleClamAndMarkNotSpam - The callback function to handle claiming and marking thread as not spam.
 * @param {Object} options.store - The store object.
 * @returns {Object} - The object containing the functions to handle claim thread, claim and mark thread as not spam, and mark thread as not spam.
 */

const useClaimOrMarkNotSpamThread = ({
  client,
  onHandleClaimThreadSuccess,
  onHandleClaimThreadError,
  onHandleMarkNotSpamThread,
  onHandleClamAndMarkNotSpam,
  store,
}) => {
  const [claimThreadMutation] = useMutation(ClaimThreadMutation, { client });
  const [markThreadAsNotSpamMutation] = useMutation(
    MarkThreadAsNotSpamMutation,
    { client }
  );

  /**
   * Handles the mutation to mark a thread as not spam.
   *
   * @param {string} threadId - The ID of the thread to mark as not spam.
   * @returns {Promise<void>} - A promise that resolves when the mutation is complete.
   */
  const handleMarkNotSpamMutation = async (threadId) => {
    await markThreadAsNotSpamMutation({
      variables: {
        input: {
          threadId,
        },
      },
    });
  };

  /**
   * Handles marking a thread as not spam.
   *
   * @param {string} threadId - The ID of the thread to mark as not spam.
   * @returns {Promise<void>} - A promise that resolves when the thread is marked as not spam.
   */
  const handleMarkNotSpam = async (threadId) => {
    try {
      await handleMarkNotSpamMutation(threadId);
      onHandleMarkNotSpamThread();
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Handles claiming a thread and marking it as not spam.
   *
   * @param {string} threadId - The ID of the thread to claim and mark as not spam.
   * @returns {Promise<void>} - A promise that resolves when the thread is claimed and marked as not spam.
   */
  const handleClaimThreadAndMarkNotSpam = async (threadId) => {
    const { data } = await claimThreadMutation({
      variables: {
        input: {
          threadId,
        },
      },
    }).catch((e) => onHandleClaimThreadError?.(e));

    await handleMarkNotSpamMutation(threadId);

    if (onHandleClamAndMarkNotSpam) {
      const thread = data?.claimThread?.thread;

      if (store) openThreadSubscriptionHandler(store, client, thread);

      onHandleClamAndMarkNotSpam();
    }
  };

  /**
   * Handles the claiming of a thread.
   *
   * @param {string} threadId - The ID of the thread to claim.
   * @returns {Promise<void>} - A promise that resolves when the thread is claimed.
   */
  const handleClaimThread = async (threadId) => {
    // Navigate to threads page with claimed thread active

    const { data } = await claimThreadMutation({
      variables: {
        input: {
          threadId,
        },
      },
    }).catch((e) => onHandleClaimThreadError?.(e));

    if (onHandleClaimThreadSuccess) {
      const thread = data?.claimThread?.thread;

      if (store) openThreadSubscriptionHandler(store, client, thread);

      onHandleClaimThreadSuccess(threadId);
    }
  };

  return {
    handleClaimThread,
    handleClaimThreadAndMarkNotSpam,
    handleMarkNotSpam,
  };
};

export default useClaimOrMarkNotSpamThread;
