import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import ContactCardStatic from './ContactCardStatic';
import ContactCardEdit from './ContactCardEdit';
import { LoadingWrapper } from '../CustomerInfo';
import { Loading } from '../../../../elements';
import { setContactSlideoutAutofocusField } from '../../../../actions/general';

const ContactCardSlideout = ({
  customerContact,
  optInOutEditPermission,
  allowEmailsOptOut,
  allowFaxOptOut,
  apiMessageFeatureEnabled,
  allGroups,
  closeCustomerInfo,
  inActiveThreadGroup,
  startContactThread,
  hasEmailChannel,
  hasFaxChannel,
  hasSmsChannel,
  loading,
  CustomerInfoOptionsTestPrep,
  channelType,
}) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);

  const handleClickOnEdit = () => {
    const newEditMode = !editMode;
    setEditMode(newEditMode);
  };

  const contactSlideoutAutofocusField = useSelector(
    (state) => state.general.contactSlideoutAutofocusField
  );

  const promoAccess = useSelector(
    (state) => state.accountData.account?.ff_broadcast_list_improvements
  );

  const handleFocusLabel = () => {
    dispatch(setContactSlideoutAutofocusField('labels'));
  };

  // if given a field to autofocus on, we need to put the form into edit mode.
  useEffect(() => {
    if (contactSlideoutAutofocusField) {
      setEditMode(true);
    }
  }, [contactSlideoutAutofocusField]);

  useEffect(() => {
    return () => {
      dispatch(setContactSlideoutAutofocusField(''));
    };
  }, []);

  return loading ? (
    <LoadingWrapper>
      <Loading />
    </LoadingWrapper>
  ) : editMode ? (
    <ContactCardEdit
      closeCustomerInfo={closeCustomerInfo}
      customerContact={customerContact}
      toggleEdit={handleClickOnEdit}
      handleFocusLabel={handleFocusLabel}
      promoAccess={promoAccess}
    />
  ) : (
    <ContactCardStatic
      customerContact={customerContact}
      optInOutEditPermission={optInOutEditPermission}
      allowEmailsOptOut={allowEmailsOptOut}
      allowFaxOptOut={allowFaxOptOut}
      apiMessageFeatureEnabled={apiMessageFeatureEnabled}
      allGroups={allGroups}
      handleClickOnEdit={handleClickOnEdit}
      closeCustomerInfo={closeCustomerInfo}
      inActiveThreadGroup={inActiveThreadGroup}
      startContactThread={startContactThread}
      hasEmailChannel={hasEmailChannel}
      hasFaxChannel={hasFaxChannel}
      hasSmsChannel={hasSmsChannel}
      CustomerInfoOptionsTestPrep={CustomerInfoOptionsTestPrep}
      channelType={channelType}
      handleFocusLabel={handleFocusLabel}
      promoAccess={promoAccess}
    />
  );
};

ContactCardSlideout.propTypes = {
  customerContact: PropTypes.object.isRequired,
  optInOutEditPermission: PropTypes.bool.isRequired,
  allowEmailsOptOut: PropTypes.bool.isRequired,
  allowFaxOptOut: PropTypes.bool.isRequired,
  apiMessageFeatureEnabled: PropTypes.bool.isRequired,
  allGroups: PropTypes.array.isRequired,
  closeCustomerInfo: PropTypes.func.isRequired,
  inActiveThreadGroup: PropTypes.bool.isRequired,
  startContactThread: PropTypes.func.isRequired,
  hasEmailChannel: PropTypes.bool.isRequired,
  hasFaxChannel: PropTypes.bool.isRequired,
  hasSmsChannel: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  CustomerInfoOptionsTestPrep: PropTypes.elementType.isRequired,
  channelType: PropTypes.string.isRequired,
};

export default ContactCardSlideout;
