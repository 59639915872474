import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import styled from 'styled-components';
import i18n from 'i18n-js';
import {
  PK_TYPENAMES,
  SEARCH_CUSTOMERS_QUERY,
  useForwardMessage,
} from 'client-lib';
import { Grid } from '@mui/material';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import THEMES from '../../styles/themes/app';
import BUTTON_THEMES from '../../styles/themes/library/button';
import {
  Heading1,
  Button,
  TextArea,
  IconButton,
  InfiniteSuggest,
  SelectedEntity,
  TextInput,
  Checkbox,
  Select,
  Text,
  Chip,
} from '../../elements';
import { closeAllSlideouts, openSnackbar } from '../../actions/general';
import { emailRegex } from '../Inputs/validation';

const FullFormRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const AutocompleteWrapper = styled.div`
  min-height: 60;
  padding: 16px 1rem 0px;
`;

const Header = styled.div`
  min-height: 62px;
  margin: 0;
  display: flex;
  align-items: center;
`;

const FormWrapper = styled.div`
  display: block;
  min-height: calc(100vh - 75px);
`;

const HeaderCloseSection = styled.div`
  background-color: ${THEMES.BACKGROUND_SECONDARY};
  padding: 1rem 1rem 0.5rem 1rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CloseIcon = styled.i`
  display: block;
  margin-right: -8px;
`;

const SendAttachmentInput = styled(Grid)`
  height: ${(props) => (props.hasheight ? '30px' : '0')};
  margin-bottom: 8px;
  flex: 1;
`;

const CarbonCopyChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
`;

const ChipContainer = styled.div`
  margin: 8px 8px 0 0;
`;

const ButtonContainer = styled.div`
  height: 100px;
`;

const addCcCustomStyle = (props) => `
 color: ${BUTTON_THEMES.LINK_TEXT(props)};
 :hover {
  color: ${BUTTON_THEMES.LINK_TEXT_HOVER(props)};
 }
`;

const ForwardMessageSlideout = () => {
  const client = useApolloClient();

  const [contactText, setContactText] = useState('');
  const [carbonCopyContactText, setCarbonCopyContactText] = useState('');
  const [addCCField, setAddCCField] = useState(false);
  const [toFieldError, setToFieldError] = useState('');
  const [mutationLoading, setMutationLoading] = useState(false);

  const searchInputRef = useRef();

  useEffect(() => {
    const postAnimationFocus = setTimeout(() => {
      if (activeEmailChannels?.length === 1) {
        searchInputRef?.current?.focus();
      }
    }, 350);
    return () => {
      clearTimeout(postAnimationFocus);
    };
  }, []);

  const dispatch = useDispatch();
  const activeEmailChannels = useSelector(
    (state) => state?.accountData?.activeEmailChannels
  );
  const activeForwardMessageId = useSelector(
    (state) => state?.general?.activeForwardMessageId
  );
  const currentUser = useSelector((state) => state?.session?.currentUser);

  const onMutationSuccess = () => {
    setMutationLoading(false);
    dispatch(
      openSnackbar(
        i18n.t('slideouts-ForwardMessageSlideout-success', {
          defaultValue: 'Email forwarded successfully',
        })
      )
    );
    dispatch(closeAllSlideouts());
  };

  const onMutationError = () => {
    setMutationLoading(false);
    dispatch(
      openSnackbar(
        i18n.t('slideouts-ForwardMessageSlideout-error', {
          defaultValue: 'Message failed to forward',
        }),
        'error'
      )
    );
  };

  const {
    selectedChannelId,
    setSelectedChannelId,
    groupsDropdownList,
    contact,
    setContact,
    carbonCopyContacts,
    setCarbonCopyContacts,
    subjectInputValue,
    setSubjectInputValue,
    messageInputValue,
    setMessageInputValue,
    includeAttachments,
    setIncludeAttachments,
    contactEmail,
    setContactEmail,
    startForwardMessageMutation,
    hasAttachments,
  } = useForwardMessage({
    client,
    activeEmailChannels,
    activeForwardMessageId,
    onMutationSuccess,
    onMutationError,
    currentUser,
    setMutationLoading,
  });

  const handleSubjectInputChange = (e) => setSubjectInputValue(e.target.value);
  const handleMessageInputChange = (e) => setMessageInputValue(e.target.value);
  const handleIncludeAttachmentsChange = () =>
    setIncludeAttachments((prev) => !prev);
  const onContactTextChange = (e) => setContactText(e.target.value);
  const handleChannelChange = (channel) => setSelectedChannelId(channel.value);
  const onCCFieldChange = (e) => setCarbonCopyContactText(e.target.value);

  const checkIsSuggestionAlreadyIncluded = (suggestion) =>
    carbonCopyContacts?.some(
      (carbonCopy) => carbonCopy?.emailAddress === suggestion
    );
  const checkIsSuggestionAlreadyMainContact = (suggestion) =>
    suggestion === contactEmail;

  const certifyToInputValueOnBlur = (e) => {
    const trimmedValue = e.target.value.trim();
    const isSuggestionAlreadyIncluded =
      checkIsSuggestionAlreadyIncluded(trimmedValue);
    if (emailRegex.test(trimmedValue) === true) {
      if (isSuggestionAlreadyIncluded) {
        setContactText('');
        setToFieldError(i18n.t('slideouts-CreateEmail-contactIncluded'));
      } else {
        setContactEmail(trimmedValue);
        setContactText(trimmedValue);
      }
    } else if (!contact || contactEmail === '') {
      setContactText('');
    }
  };

  const certifyCCInputValueOnBlur = (e) => {
    const trimmedValue = e.target.value.trim();
    const isAlreadyMainContact =
      checkIsSuggestionAlreadyMainContact(trimmedValue);
    const isAlreadyInCarbonCopyContacts =
      checkIsSuggestionAlreadyIncluded(trimmedValue);
    setCarbonCopyContactText('');
    if (
      emailRegex.test(trimmedValue) === true &&
      !isAlreadyMainContact &&
      !isAlreadyInCarbonCopyContacts &&
      carbonCopyContacts.length <= 9
    ) {
      setCarbonCopyContacts([
        ...carbonCopyContacts,
        { emailAddress: trimmedValue },
      ]);
    }
  };

  const onSuggestionSelected = (e, { suggestion }) => {
    const isSuggestionAlreadyIncluded = checkIsSuggestionAlreadyIncluded(
      suggestion.emailAddress
    );
    if (isSuggestionAlreadyIncluded) {
      setContactText('');
      setToFieldError(i18n.t('slideouts-CreateEmail-contactIncluded'));
    } else {
      setContactEmail(suggestion.emailAddress);
      setContact(suggestion);
      setToFieldError('');
    }
  };

  const onCCSuggestionSelected = (e, { suggestion }) => {
    const isSuggestionAlreadyIncluded = checkIsSuggestionAlreadyIncluded(
      suggestion.emailAddress
    );
    const isMainContact = checkIsSuggestionAlreadyMainContact(
      suggestion.emailAddress
    );
    setCarbonCopyContactText('');

    if (
      !isSuggestionAlreadyIncluded &&
      !isMainContact &&
      carbonCopyContacts?.length <= 9
    ) {
      setCarbonCopyContacts([...carbonCopyContacts, suggestion]);
    }
  };

  const onCarbonCopyContactDeleted = (deletedContact) => {
    const newCarbonCopyContacts = carbonCopyContacts.filter(
      (contact) => contact.emailAddress !== deletedContact.emailAddress
    );
    setCarbonCopyContacts(newCarbonCopyContacts);
  };

  const inferAvatarChildren = (contact) =>
    contact?.firstName && contact?.lastName ? contactName(contact) : undefined;

  const inferEntityCardMainText = (contact) => {
    if (contact?.__typename === PK_TYPENAMES.CUSTOMER_CONTACT) {
      return contactName(contact);
    }
    if (!contact && contactEmail) {
      return contactEmail;
    }
    return '';
  };

  const inferEntityCardSubText = (contact) => {
    if (
      (contact?.firstName || contact?.lastName || contact?.phoneNumber) &&
      contact?.__typename === PK_TYPENAMES.CUSTOMER_CONTACT
    ) {
      return contact?.emailAddress;
    }
    return '';
  };

  const clearToFieldBubble = () => {
    setContactText('');
    setContactEmail('');
    setContact(null);
  };

  return (
    <FormWrapper data-testid="forward-message">
      <HeaderCloseSection>
        <ButtonWrapper>
          <IconButton
            noOutline
            title="close"
            size="lg"
            contrast="high"
            onClick={() => dispatch(closeAllSlideouts())}
            dataTestId="customer-info-close-button"
          >
            <CloseIcon className="ri-close-line" />
          </IconButton>
        </ButtonWrapper>
        <Header>
          <Heading1 contrast="high">
            {i18n.t('slideouts-ForwardMessageSlideout-forwardAsEmail')}
          </Heading1>
        </Header>
      </HeaderCloseSection>
      <AutocompleteWrapper>
        {groupsDropdownList ? (
          <Select
            options={groupsDropdownList}
            label={i18n.t('slideouts-ForwardMessageSlideout-fromLabel', {
              defaultValue: 'From',
            })}
            disabled={groupsDropdownList?.length === 1}
            value={selectedChannelId}
            onChange={handleChannelChange}
            placeholder={i18n.t(
              'slideouts-ForwardMessageSlideout-fromPlaceholder',
              { defaultValue: 'Select email address' }
            )}
          />
        ) : null}
        {contactEmail === '' && !contact?.id ? (
          <InfiniteSuggest
            inputProps={{
              label: i18n.t('slideouts-ForwardMessageSlideout-toLabel'),
              error: toFieldError,
              onBlur: certifyToInputValueOnBlur,
              value: contactText,
              ref: searchInputRef,
              onChange: onContactTextChange,
              rightSideLabelContent: !addCCField && (
                <Button
                  size="zero"
                  type="noStyle"
                  onClick={() => setAddCCField(true)}
                  customStyle={addCcCustomStyle}
                >
                  {i18n.t('slideouts-CreateEmail-addCc')}
                </Button>
              ),
              placeholder: i18n.t(
                'slideouts-ForwardMessageSlideout-toPlaceholder',
                { defaultValue: 'Enter Contact Name or Email' }
              ),
            }}
            query={SEARCH_CUSTOMERS_QUERY}
            queryKey="searchCustomers"
            queryVariables={{
              query: contactText,
              after: null,
              typeFilter: 'CUSTOMER_CONTACT',
            }}
            onSelect={onSuggestionSelected}
            includeGroupIndicator
            isDisabled={(node) => !node.emailAddress}
          />
        ) : (
          <div style={{ position: 'relative' }}>
            {!addCCField && (
              <Button
                size="zero"
                type="noStyle"
                onClick={() => setAddCCField(true)}
                customStyle={(props) =>
                  `position: absolute;
                  top: 8px;
                  right: 0;
                  ${addCcCustomStyle(props)}
                  `
                }
              >
                {i18n.t('slideouts-CreateEmail-addCc')}
              </Button>
            )}
            <SelectedEntity
              label={i18n.t('slideouts-ForwardMessageSlideout-toLabel')}
              avatarChildren={inferAvatarChildren(contact)}
              maintext={inferEntityCardMainText(contact)}
              subtext={inferEntityCardSubText(contact)}
              onDelete={clearToFieldBubble}
              avatarProps={{
                avatarUrl: '',
                type: 'external',
                active: false,
              }}
            />
          </div>
        )}
        {addCCField && (
          <InfiniteSuggest
            inputProps={{
              label: i18n.t('slideouts-CreateEmail-cC'),
              onBlur: certifyCCInputValueOnBlur,
              value: carbonCopyContactText,
              onChange: onCCFieldChange,
              disabled: carbonCopyContacts.length === 10,
              autoFocus: false,
              placeholder: i18n.t('slideouts-CreateEmail-searchBy'),
              hideBottomSpace: carbonCopyContacts?.length >= 1,
              rightSideLabelContent: carbonCopyContacts?.length >= 1 && (
                <Text
                  customStyle={(props) =>
                    `font-size: 12px; color: ${
                      carbonCopyContacts?.length === 10
                        ? THEMES.THEME_RED(props)
                        : THEMES.FOREGROUND_HIGH(props)
                    }`
                  }
                >
                  {carbonCopyContacts?.length}
                  /10
                </Text>
              ),
            }}
            query={SEARCH_CUSTOMERS_QUERY}
            queryKey="searchCustomers"
            queryVariables={{
              query: carbonCopyContactText,
              after: null,
              typeFilter: 'CUSTOMER_CONTACT',
            }}
            onSelect={onCCSuggestionSelected}
            includeGroupIndicator
            isDisabled={(node) => !node.emailAddress}
          />
        )}
        {carbonCopyContacts?.length >= 1 && (
          <CarbonCopyChipsWrapper>
            {carbonCopyContacts.map((contact, i) => {
              const { emailAddress } = contact;
              const name = contactName(contact);
              return (
                <ChipContainer key={i}>
                  <Chip
                    onDelete={() => onCarbonCopyContactDeleted(contact)}
                    customMaintextStyle={() =>
                      'max-width: 150px; text-overflow: ellipsis; overflow: hidden;'
                    }
                  >
                    {name || emailAddress}
                  </Chip>
                </ChipContainer>
              );
            })}
          </CarbonCopyChipsWrapper>
        )}
        {contactEmail ? (
          <>
            <TextInput
              label={i18n.t('slideouts-ForwardMessageSlideout-subjectLabel')}
              value={subjectInputValue}
              onChange={handleSubjectInputChange}
              id="textinput"
              name="subjectInputValue"
              dataTestId="at-textinput"
            />
            <TextArea
              label={i18n.t('slideouts-ForwardMessageSlideout-messageLabel')}
              value={messageInputValue}
              onChange={handleMessageInputChange}
              id="textarea"
              name="messageInputValue"
              rows={9}
              dataTestId="at-textarea"
            />
            <SendAttachmentInput
              container
              name="welcomeMessage"
              alignItems="center"
              hasheight={!!hasAttachments}
            >
              {hasAttachments ? (
                <Checkbox
                  disabled={!contactEmail}
                  checked={includeAttachments}
                  onCheck={handleIncludeAttachmentsChange}
                  label={i18n.t(
                    'slideouts-ForwardMessageSlideout-includeAttachments'
                  )}
                />
              ) : null}
            </SendAttachmentInput>
            <ButtonContainer>
              <Button
                loadingSpinner={mutationLoading}
                disabled={
                  mutationLoading ||
                  !selectedChannelId ||
                  !contactEmail ||
                  (!messageInputValue && !includeAttachments)
                }
                onClick={startForwardMessageMutation}
                data-testid="send-message-button"
              >
                {i18n.t('slideouts-ForwardMessageSlideout-send')}
              </Button>
            </ButtonContainer>
          </>
        ) : null}
      </AutocompleteWrapper>
      <FullFormRow />
    </FormWrapper>
  );
};

export default ForwardMessageSlideout;
