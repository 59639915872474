import { PK_TYPENAMES } from 'client-lib';
import i18n from 'i18n-js';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import {
  TableHead,
  Button,
  useMultiSelectTableItems,
  Table,
} from '../../../../elements';
// import ContactsTableRow from './subComponents/ContactTableRow';
import CommonTableRow from './subComponents/CommonTableRow';
import {
  FlexCenterContainer,
  FlexBetweenContainer,
  EmptyListHeader,
  MediumHeader,
  SmallHeader,
} from '../sharedStyles';
import AudienceFilterSort from './subComponents/AudienceFilterSort';
import { getSortOptions, getOptInStatusText } from '../utils';
import InfiniteScroll from '../../../InfiniteScroll/InfiniteScroll';
import THEMES from '../../../../styles/themes/app';
import * as TYPES from '../../../../utils/helpers/types';

const ANNOUNCEMENT_TYPES = {
  PROMOTIONAL: 'PROMOTIONAL',
  INFORMATIONAL: 'INFORMATIONAL',
};

const ContactsTable = ({
  contacts,
  filters,
  loading,
  loadMoreRows,
  onAdd,
  onFilter,
  onSort,
  pageInfo,
  sort,
  heightDifference,
  disableCheckboxes,
  type,
}) => {
  const [contactIds, setContactIds] = useState([]);
  const [companyIds, setCompanyIds] = useState([]);
  const [optedInContacts, setOptedInContacts] = useState([]);

  const sortOptions = getSortOptions(i18n);

  const {
    handleCheckItem,
    handleSelectNone,
    selectedItems,
    allItemsSelected,
    shouldCheckItem,
  } = useMultiSelectTableItems({
    items: optedInContacts,
  });

  // Splits up selected ids by object type
  const handleCheck = (id, contactType) => {
    const newContacts = [...contactIds];
    const newCompanies = [...companyIds];

    if (contactType === PK_TYPENAMES.CUSTOMER_CONTACT) {
      if (shouldCheckItem(id)) {
        newContacts.splice(
          newContacts?.findIndex((contact) => contact === id),
          1
        );
      } else {
        newContacts.push(id);
      }
    } else if (shouldCheckItem(id)) {
      newCompanies.splice(
        newCompanies?.findIndex((comp) => comp === id),
        1
      );
    } else {
      newCompanies.push(id);
    }
    handleCheckItem(id);
    setContactIds(newContacts);
    setCompanyIds(newCompanies);
  };

  // Splits up all company/contact ids by object type
  const checkAll = () => {
    if (allItemsSelected) {
      const newContacts = [];
      const newCompanies = [];
      optedInContacts.forEach((item) => {
        if (item.__typename === PK_TYPENAMES.CUSTOMER_CONTACT) {
          newContacts.push(item.id);
        } else newCompanies.push(item.id);
      });
      setContactIds(newContacts);
      setCompanyIds(newCompanies);
    }
  };

  useEffect(() => {
    checkAll();
  }, [allItemsSelected]);

  useEffect(() => {
    if (type !== 'list') {
      setOptedInContacts(
        contacts.filter(
          (c) =>
            (type === ANNOUNCEMENT_TYPES.INFORMATIONAL &&
              !c.announcementsOptOut) ||
            (type === ANNOUNCEMENT_TYPES.PROMOTIONAL &&
              !c.announcementsOptOut &&
              !c.promotionalBroadcastOptOut) ||
            c.__typename !== PK_TYPENAMES.CUSTOMER_CONTACT
        )
      );
    } else {
      setOptedInContacts(contacts);
    }
  }, [contacts]);

  // Adds selected Ids to list and then resets selection
  const handleAdd = () => {
    onAdd({ companyIds, contactIds });
    setCompanyIds([]);
    setContactIds([]);
    handleSelectNone();
  };

  /**
   * Check if a given contact should be allowed to be added to the list
   * @param {TYPES.ContactSMSSettings} contact - contact with needed values to determine if addable
   * @returns {boolean} - true if contact is able to be added to list
   */
  const checkContactDisabled = (contact) =>
    disableCheckboxes ||
    (type === ANNOUNCEMENT_TYPES.INFORMATIONAL &&
      contact.announcementsOptOut) ||
    (type === ANNOUNCEMENT_TYPES.PROMOTIONAL &&
      (contact.promotionalBroadcastOptOut || contact.announcementsOptOut)) ||
    (contact.smsStatus &&
      contact.smsStatus.includes(TYPES.SMS_STATUS.landline)) ||
    (contact.smsStatus &&
      contact.smsStatus.includes(TYPES.SMS_STATUS.not_in_service));

  return (
    <>
      <FlexBetweenContainer>
        <AudienceFilterSort
          hasFilter
          filters={filters}
          sortBy={sort}
          sortOptions={sortOptions}
          handleSubmitFilter={onFilter}
          handleSubmitSort={onSort}
        />
        <Button
          onClick={() => handleAdd()}
          disabled={selectedItems.length === 0 || disableCheckboxes}
        >
          {i18n.t('broadcasts-broadcast-addSelected', {
            defaultValue: 'Add %{number} Selected',
            number: selectedItems.length,
          })}
        </Button>
      </FlexBetweenContainer>
      <Table>
        <TableHead>
          <tr>
            <MediumHeader>
              {i18n.t('settings-FinalMessage-customer', {
                defaultValue: 'contact',
              })}
            </MediumHeader>
            <SmallHeader>
              {i18n.t('settings-Groups-groups', { defaultValue: 'groups' })}
            </SmallHeader>
            <SmallHeader>
              {i18n.t('settings-Label-labels', { defaultValue: 'labels' })}
            </SmallHeader>
            <SmallHeader>
              {i18n.t('broadcasts-broadcast-optin', {
                defaultValue: 'Opt-in Status',
              })}
            </SmallHeader>
          </tr>
        </TableHead>
      </Table>
      <InfiniteScroll
        dataTestId="broadcast-recipient-list"
        bidirectionalScroll={false}
        height={`calc(100vh - ${heightDifference}px)`}
        scrollableList={contacts}
        noRowsElement={
          <FlexCenterContainer>
            <EmptyListHeader dataTestId="create-broadcastList-empty">
              {i18n.t('slideouts-TargetedAudience-noResults', {
                defaultValue:
                  'No results were found for this type of audience.',
              })}
            </EmptyListHeader>
          </FlexCenterContainer>
        }
        renderRow={({ list, index }) => {
          const contact = list[index];
          return (
            <CommonTableRow
              key={contact.id}
              id={contact.id}
              checked={contact.id ? shouldCheckItem(contact.id) : false}
              name={
                contact.__typename === PK_TYPENAMES.CUSTOMER_ACCOUNT
                  ? contact.name
                  : contactName(contact)
              }
              onCheck={() => handleCheck(contact.id, contact.__typename)}
              type={contact.__typename}
              company={contact.account?.name}
              groupIds={contact.groupIds}
              labels={contact.labels}
              optInStatusText={getOptInStatusText(contact)}
              disabled={checkContactDisabled(contact)}
            />
          );
        }}
        hasNextPage={pageInfo.hasNextPage}
        hasPreviousPage={pageInfo.hasPreviousPage}
        listItemHeight={64}
        loadingHeight={64}
        loadingBackgroundColor={THEMES.BACKGROUND_PRIMARY}
        loadingBorderBottom
        loadMoreRows={loadMoreRows}
        loading={loading}
      />
    </>
  );
};

ContactsTable.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
  filters: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  loadMoreRows: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  pageInfo: PropTypes.shape({
    hasNextPage: PropTypes.bool,
    hasPreviousPage: PropTypes.bool,
    totalCount: PropTypes.number,
  }),
  sort: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  heightDifference: PropTypes.number,
  disableCheckboxes: PropTypes.bool,
  type: PropTypes.oneOf([...Object.values(ANNOUNCEMENT_TYPES), 'list']),
};

ContactsTable.defaultProps = {
  pageInfo: {
    hasNextPage: false,
    hasPreviousPage: false,
    totalCount: 0,
  },
  heightDifference: 354,
  disableCheckboxes: false,
  type: 'list',
};

export default ContactsTable;
