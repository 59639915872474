import React from 'react';
import PropTypes from 'prop-types';
import { Text, Modal } from '../../elements';

const OpenThreadsWarning = ({ isOpen, onCloseClick, onThreadButtonClick }) => (
  <Modal
    modalTitle="You have open threads"
    isOpen={isOpen}
    onRequestClose={onCloseClick}
    primaryButtonText=" Take me to my threads"
    primaryButtonOnClick={onThreadButtonClick}
  >
    <Text>
      Before you switch groups, you need to close or transfer all of your
      threads.
    </Text>
  </Modal>
);

OpenThreadsWarning.propTypes = {
  onThreadButtonClick: PropTypes.func.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default OpenThreadsWarning;
