import { useQuery } from "@apollo/client";
import { useEffect, useRef } from "react";
import USERS_QUERY from "../../../../graphql/query/UsersQuery.graphql";

const INITIAL_TEAM_MEMBERS = 25;

export const usePaginatedTeamMember = ({
  client,
  queryVariables,
  searchTeamMemberInput,
  currentUser,
  presenceTrackingAvailable,
  includeCurrentUser = true,
}) => {
  const initialVariables = {
    ...queryVariables,
    first: INITIAL_TEAM_MEMBERS,
    filter: searchTeamMemberInput,
    includeAccountScopedUsers: false,
    includeCurrentUser,
  };

  const {
    loading: usersQueryLoading,
    data: originalUsersData,
    fetchMore,
    refetch,
  } = useQuery(USERS_QUERY, {
    client,
    variables: initialVariables,
    // fetchPolicy: 'cache-and-network',
  });

  const users =
    !usersQueryLoading && originalUsersData?.users?.edges
      ? originalUsersData.users.edges.map((userNode) => userNode.node)
      : [];
  const pageInfo =
    !usersQueryLoading && originalUsersData?.users?.pageInfo
      ? originalUsersData.users.pageInfo
      : {};

  // this section is for a very specific bug fix, there's a race condition that happens with users query where this query will resolve before
  // presence is fully set up. That means that the current user sometimes shows up as offline. To guard against that, we're watching
  // the current user's online value, if it's false, try refetch. We try a couple times and then give up to prevent an infinte loop situation.
  const refetchAttempts = useRef(0);
  useEffect(() => {
    const usersMatchedCurrentUser = users.find(
      (users) => users.id === currentUser?.userId
    );
    if (
      presenceTrackingAvailable &&
      usersMatchedCurrentUser &&
      usersMatchedCurrentUser?.online === false &&
      refetchAttempts.current < 2
    ) {
      refetchAttempts.current += 1;
      refetch();
    }
  }, [users.length]);

  const handleFetchMoreTeamMembers = ({
    variables: variablesArg,
    afterCursor = true,
  }) => {
    const userEdges = originalUsersData.users.edges;
    const variables = {
      ...initialVariables,
      ...variablesArg,
    };

    if (afterCursor) variables.after = userEdges[userEdges.length - 1].cursor;

    fetchMore({ variables });
  };

  const handlePreviousTeamMembers = () => {
    const userEdges = originalUsersData.users.edges;
    const variables = {
      ...initialVariables,
      before: userEdges[0].cursor,
      first: null,
      last: INITIAL_TEAM_MEMBERS,
    };
    fetchMore({ variables });
  };

  return {
    handleFetchMoreTeamMembers,
    handlePreviousTeamMembers,
    refetch,
    pageInfo,
    users,
    usersQueryLoading,
  };
};

export default usePaginatedTeamMember;
