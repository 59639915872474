export const webEventTrack = (eventName, properties, activeGroupId) => {
  if (window) {
    const xhr = new window.XMLHttpRequest();
    xhr.open('POST', '/events');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Screen-Width', window.outerWidth);
    xhr.setRequestHeader('Screen-Height', window.outerHeight);
    xhr.setRequestHeader('Active-Group-Id', activeGroupId);
    xhr.send(
      JSON.stringify({
        event: eventName,
        properties,
      })
    );
  }
};
