import React, { useState, useRef, createRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { thread_utils, useThreadHistory } from 'client-lib';
import i18n from 'i18n-js';
import {
  isInternalThreadAndOwnedByUser,
  contactName,
  accountName,
  accountNumber,
} from 'client-lib/src/lib/utils/helpers';
import Message from './Message';
import Separator from './Separator';
import { setActiveUnclaimedThread } from '../../actions';
import {
  openSnackbar,
  setActiveCustomerInfoSlideout,
  setActiveCreateCustomerSection,
} from '../../actions/general';
import { setDefaultContactTab } from '../../actions/editCustomer';
import { setCreateCustomerFormValues } from '../../actions/createSection';
import RouteFilter from '../Common/RouteFilter';
import useEventTracker from '../Common/useEventTracker';
import store from '../../store';
import { Heading3, Text, Button } from '../../elements';
import THEMES from '../../styles/themes/app';
import CloseThreadButton from './CloseThreadButton';
import InternalUseOnlyMessage from './InternalUseOnlyMessage';
import Carousel from '../../elements/AttachmentTile/Carousel';
import ClickableCard from '../../elements/EntityCard/ClickableCard';

const Wrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
`;

const LoadPreviousContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  margin-bottom: -40px;
  @media print {
    display: none;
  }
`;

const LastReadMarkerContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const LastReadMarker = styled.div`
  background: ${THEMES.THEME_RED};
  height: 1px;
  color: ${THEMES.THEME_RED};
  font-size: 13px;
  cursor: pointer;
  flex: 1;
`;

const NewMessagesTextContainer = styled.div`
  position: relative;
  height: 1px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const newMessageCustomStyle = (props) => `
  color: ${THEMES.THEME_RED(props)};
  position: absolute;
  cursor: pointer;
  background: transparent;
  @media print {display: none;}
  text-align: center;
`;

const ReturnToFullConvoWrap = styled.div`
  padding: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WrapHeadingAndButton = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  height: 100px;
  justify-content: space-around;
`;

const GenericLowerAreaWrap = styled.div`
  display: flex;
  padding: 20px;
  justify-content: center;
`;

const ThreadHistory = React.forwardRef(
  ({ thread, canLoadPastThreads, flipTagBubbleCallback, isTest }, ref) => {
    const client = useApolloClient();
    const params = useParams();
    const history = useHistory();
    const { track } = useEventTracker();

    const [loadingThreadHistory, setLoadingThreadHistory] = useState(false);
    const [displayClaimOptionsOrInfo, setDisplayClaimOptionsOrInfo] =
      useState(false);

    // Resets this state with each thread change otherwise the button next goes away
    useEffect(() => {
      setDisplayClaimOptionsOrInfo(false);
    }, [thread?.id]);

    const threadsWrapperNode = useRef();

    const onLoadingThreadHistory = () => setLoadingThreadHistory(true);
    const onLoadingThreadHistoryComplete = () => setLoadingThreadHistory(false);

    const dispatch = useDispatch();
    const contactId = useSelector(
      (state) => state?.session?.currentUser?.contactId
    );
    const userId = useSelector((state) => state?.session?.currentUser?.userId);
    const socketConnectionTimestamp = useSelector(
      (state) => state?.session?.socketConnectionTimestamp
    );
    const submitMessageInputHeight = useSelector(
      (state) => state?.general?.submitMessageInputHeight
    );
    const ff_spam_filtering = useSelector(
      (state) => state?.accountData?.account?.ff_spam_filtering
    );

    const onHandleClaimThread = () => {
      history.push(`/threads/open/${params.activeThreadId}`);
      dispatch(setActiveUnclaimedThread(''));
    };

    const onHandleClamAndMarkNotSpam = () => {
      onHandleClaimThread();
      dispatch(
        openSnackbar(
          i18n.t('threads-ThreadHistory-successClaimNotSpam', {
            defaultValue:
              'You have successfully claimed this message and it has moved to your open tab',
          })
        )
      );
    };

    const onHandleMarkNotSpamThread = () => {
      dispatch(
        openSnackbar(
          i18n.t('threads-ThreadHistory-successNotSpam', {
            defaultValue: 'Success! This message is now in inbox.',
          })
        )
      );
    };

    const onHandleClaimThreadFailure = () => {
      dispatch(openSnackbar(i18n.t('settings-AddOrEditGroup-error')));
    };

    const onHandleIgnoreCloseSpam = (msgId) => {
      handleIgnoreThread({
        messageId: msgId,
        ignoreReason: IgnoreReason.SPAM,
        onError: () => {
          dispatch(
            openSnackbar(
              i18n.t('threads-ThreadHistory-ignoreAndCloseSpamError', {
                defaultValue:
                  'There was an error trying to ignore and close spam',
              })
            )
          );
        },
        onSuccess: () => {
          dispatch(
            openSnackbar(
              i18n.t('threads-ThreadHistory-ignoreAndCloseSpamConfirmation')
            )
          );
          history.push(`/threads/inbox`);
        },
      });
    };

    const handleOpenSlideout = (activeCustomerData) => {
      if (!activeCustomerData?.firstName && !activeCustomerData?.lastName) {
        dispatch(
          setCreateCustomerFormValues({
            id: activeCustomerData.id,
            email: activeCustomerData.emailAddress,
            phoneNumber: activeCustomerData.phoneNumber,
          })
        );
        dispatch(setActiveCreateCustomerSection());
      } else {
        dispatch(
          setActiveCustomerInfoSlideout({
            activeSlideoutEntityId: activeCustomerData.id,
            activeSlideoutEntityType: activeCustomerData.__typename,
          })
        );
      }
    };

    const {
      threads,
      messages,
      hasMoreThreads,
      customerContactData,
      customerContactLoading,
      handleLoadPreviousThread,
      handleClaimThread,
      handleClaimThreadAndMarkNotSpam,
      handleMarkNotSpam,
      handleMarkThreadReadMutation,
      handleIgnoreThread,
      IgnoreReason,
    } = useThreadHistory({
      thread,
      track,
      isTest,
      client,
      store,
      onHandleClaimThread,
      onHandleClaimThreadFailure,
      onHandleClamAndMarkNotSpam,
      onHandleMarkNotSpamThread,
      onHandleIgnoreCloseSpam,
      onLoadingThreadHistoryComplete,
      onLoadingThreadHistory,
      socketConnectionTimestamp,
    });

    const threadsRefs = threads.reduce((obj, { id }) => {
      const newObj = { ...obj, [id]: createRef() };
      return newObj;
    }, {});

    const generateMessages = () => {
      // Generates all messages, ThreadDate, and unread message marker
      let hasUnreadLineMarker = false;
      /** Value for tracking where the unread indicator line first appears.
       * Determined by placing line when first message.insertedAt is more recent
       * than thread.lastReadAt.
       */

      return threads
        .filter((t) => messages[t.id] !== undefined) // filter for loaded threads
        .map((t) => {
          const participant =
            t.participants.find((p) => p.contactId === contactId) || null;
          const lastReadAt = participant !== null ? participant.readAt : null;
          const isActiveThread = thread_utils.isActive(t);
          const isClaimed =
            thread_utils.isInMyOpen(t, { userId }) ||
            thread_utils.isInAllOpen(t, { userId });

          const otherExternalContacts = t?.otherExternalContacts;
          // Were having issues getting the threadsWrapperNode to render claimButtonOrInfo consistently
          if (!displayClaimOptionsOrInfo) setDisplayClaimOptionsOrInfo(true);

          return (
            <div key={t.id} id={t.id} ref={threadsWrapperNode}>
              <div
                id={t.id}
                ref={threadsRefs[t.id]}
                style={{ paddingTop: '20px' }}
              />
              <Separator
                thread={t}
                flipTagBubbleCallback={flipTagBubbleCallback}
              />
              {otherExternalContacts?.length ? (
                <Carousel
                  customSliderStyle={() =>
                    `margin: 5px 4px 5px; padding-left: 13px;`
                  }
                  childWidth={180}
                  previousButtonMargin
                >
                  {otherExternalContacts.map((contact) => {
                    let maintext;
                    let subtext;
                    let highlightSubtext = false;
                    if (!contact?.firstName && !contact?.lastName) {
                      highlightSubtext = true;
                      subtext = i18n.t(
                        'threads-ThreadDetailsHeader-createContact'
                      );
                      maintext = contact?.phoneNumber || contact?.emailAddress;
                    } else {
                      const company =
                        contact?.account?.name &&
                        contact?.account?.accountNumber
                          ? `${accountName(contact)} - #${accountNumber(
                              contact
                            )}`
                          : accountName(contact);
                      maintext = contactName(contact);
                      subtext =
                        contact?.jobTitle && contact?.account?.name
                          ? `${contact?.jobTitle} at ${company}`
                          : contact?.jobTitle || `${company}` || null;
                    }
                    return (
                      <ClickableCard
                        maintext={maintext}
                        subtext={subtext}
                        highlightSubtext={highlightSubtext}
                        onClick={() => handleOpenSlideout(contact)}
                      />
                    );
                  })}
                </Carousel>
              ) : null}
              {messages[t.id].map((m, i) => {
                // we're having issues with the thread options bubble clashing for Z index priority with SubmitMessageInput.
                // we've resolved to flip the bubble to render upward in the case that it's an active & claimed thread,
                // and if the message is the latest message in the thread history.
                const isLatestMessage = messages[t.id].length - 1 === i;
                const flipOptionsBubbleYAxis =
                  isLatestMessage && isActiveThread && isClaimed;

                if (
                  t.archivedAt === null &&
                  (t.ownerContact?.id === contactId ||
                    isInternalThreadAndOwnedByUser(t, userId)) &&
                  (isTest || m.createdAt > lastReadAt) &&
                  !hasUnreadLineMarker
                ) {
                  // Set hasUnreadLineIndicator = to true so we get line at first unread message
                  hasUnreadLineMarker = true;
                  return (
                    <div key={m.id}>
                      <LastReadMarkerContainer
                        onClick={() =>
                          handleMarkThreadReadMutation(params.activeThreadId)
                        }
                      >
                        <LastReadMarker />
                        <NewMessagesTextContainer>
                          <Text customStyle={newMessageCustomStyle}>
                            {i18n.t('threads-ThreadHistory-newMessages')}
                          </Text>
                        </NewMessagesTextContainer>
                        <LastReadMarker />
                      </LastReadMarkerContainer>
                      <Message
                        message={m}
                        allowAnnotateAndReply={isActiveThread}
                        flipOptionsBubbleYAxis={flipOptionsBubbleYAxis}
                      />
                    </div>
                  );
                }
                return (
                  <Message
                    key={m.id}
                    message={m}
                    allowAnnotateAndReply={isActiveThread}
                    flipOptionsBubbleYAxis={flipOptionsBubbleYAxis}
                    mainThread={t}
                  />
                );
              })}
            </div>
          );
        });
    };

    const claimButtonOptionsOrInfo = (thread) => {
      if (thread_utils.isTypeFax(thread)) {
        return <CloseThreadButton threadType="fax" />;
      }

      if (thread_utils.isMarkedAsSpam(thread) && ff_spam_filtering) {
        return (
          <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
            <div style={{ display: 'flex', gap: 12 }}>
              <Button
                onClick={() => handleMarkNotSpam(params.activeThreadId)}
                dataTestId="mark-not-spam-button"
              >
                {i18n.t('threads-ThreadHistory-notSpam', {
                  defaultValue: 'Not Spam',
                })}
              </Button>
              <Button
                onClick={() =>
                  handleClaimThreadAndMarkNotSpam(params.activeThreadId)
                }
                dataTestId="claim-and-mark-not-spam-button"
              >
                {i18n.t('threads-ThreadHistory-claimNotSpam', {
                  defaultValue: 'Claim and Mark Not Spam',
                })}
              </Button>
            </div>
            <div style={{ marginInline: 'auto' }}>
              <Button
                onClick={() =>
                  onHandleIgnoreCloseSpam(thread?.latestMessage?.id)
                }
                dataTestId="ignore-close-message-button"
                type="destructiveLink"
                customStyle={() => 'text-decoration: underline;'}
              >
                {i18n.t('threads-ThreadHistory-ignoreAndCloseSpam', {
                  defaultValue: 'Ignore and Close Message',
                })}
              </Button>
            </div>
          </div>
        );
      }

      if (thread.claimedByContact === null) {
        return (
          <Button
            onClick={() =>
              handleClaimThread(
                params.activeThreadId,
                thread_utils.isMarkedAsSpam(thread)
              )
            }
            dataTestId="claim-thread-button"
          >
            {i18n.t('threads-ThreadHistory-claimThread')}
          </Button>
        );
      }

      if (thread.claimedByContact?.id !== contactId) {
        const ownerFullName = `${thread.claimedByContact.firstName} ${thread.claimedByContact.lastName}`;
        return (
          <WrapHeadingAndButton>
            <Heading3>
              {i18n.t('threads-ThreadHistory-claimedBy', {
                name: ownerFullName,
              })}
            </Heading3>
            <Button
              onClick={() =>
                history.push(`/threads/open/${params.activeThreadId}`)
              }
            >
              {i18n.t('threads-ThreadHistory-goToThread')}
            </Button>
          </WrapHeadingAndButton>
        );
      }

      return null;
    };

    const paddingBottom =
      120 + submitMessageInputHeight > 152
        ? 120 + submitMessageInputHeight
        : 152;

    return (
      <Wrapper data-testid="thread-history" ref={ref}>
        <div style={{ display: 'flex', flex: 1 }} />

        <RouteFilter exact path="/threads/:filter/:activeThreadId">
          <GenericLowerAreaWrap style={{ paddingBottom }} />
          {thread.archivedAt === null ? null : (
            <>
              <WrapHeadingAndButton>
                {thread?.type !== 'internal' && (
                  <Button
                    onClick={() =>
                      history.push(
                        `/contacts/all/${
                          thread.externalContact.id
                        }/${thread.externalContact.__typename.toLowerCase()}`,
                        dispatch(setDefaultContactTab('threads'))
                      )
                    }
                  >
                    {i18n.t('threads-ThreadHistory-goToAllThreads')}
                  </Button>
                )}
              </WrapHeadingAndButton>
              {customerContactLoading ? null : (
                <InternalUseOnlyMessage
                  message={{
                    createdAt: thread.archivedAt,
                    type: 'close',
                  }}
                  fullName={contactName(customerContactData?.customerContact)}
                />
              )}
            </>
          )}
        </RouteFilter>

        <RouteFilter exact path="/threads/inbox/:activeThreadId">
          <GenericLowerAreaWrap>
            {displayClaimOptionsOrInfo
              ? claimButtonOptionsOrInfo(thread)
              : null}
          </GenericLowerAreaWrap>
        </RouteFilter>

        <RouteFilter path="/contacts/:filter/:activeCustomerId/:entityType?/threads/:activeThreadId">
          <ReturnToFullConvoWrap>
            {threadsWrapperNode?.current ? (
              <Button
                type="secondary"
                onClick={() =>
                  history.push(
                    `/contacts/all/${params.activeCustomerId}/${params.entityType}`
                  )
                }
              >
                {i18n.t('threads-ThreadHistory-return')}
              </Button>
            ) : null}
          </ReturnToFullConvoWrap>
        </RouteFilter>

        {generateMessages()}

        {canLoadPastThreads && hasMoreThreads ? (
          <LoadPreviousContainer>
            {loadingThreadHistory ? (
              <Text>{i18n.t('threads-ThreadHistory-loading')}</Text>
            ) : (
              <Button
                type="link"
                size="md"
                disabled={loadingThreadHistory}
                onClick={handleLoadPreviousThread}
              >
                {i18n.t('threads-ThreadHistory-loadPrevious')}
              </Button>
            )}
          </LoadPreviousContainer>
        ) : (
          <div />
        )}
      </Wrapper>
    );
  }
);

ThreadHistory.propTypes = {
  thread: PropTypes.object,
  canLoadPastThreads: PropTypes.bool,
  flipTagBubbleCallback: PropTypes.func,
  isTest: PropTypes.bool,
};

ThreadHistory.defaultProps = {
  thread: null,
  canLoadPastThreads: false,
  flipTagBubbleCallback: undefined,
  isTest: false,
};

export default ThreadHistory;
