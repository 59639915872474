import React from 'react';
import i18n from 'i18n-js';
import { useDispatch } from 'react-redux';
import { contactName } from 'client-lib/src/lib/utils/helpers';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import THEMES from '../../../../styles/themes/app';
import { TextInput, EntityCard } from '../../../../elements';
import CustomerContactListHeader from './CustomerContactListHeader';
import { setActiveCustomerInfoSlideout } from '../../../../actions/general';

const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 1px solid ${THEMES.BORDER_COLOR};
  cursor: pointer;
`;

const SearchContainer = styled.div`
  background-color: ${THEMES.BACKGROUND_TERTIARY};
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 71px;
  padding-left: 16px;
  padding-right: 16px;
`;

const customTextStyle = () => `
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ContactModeContainer = styled.div`
  width: 100%;
`;

const CustomerAccountContactMode = ({
  contactList,
  search,
  handleOnChange,
  closeCustomerInfo,
  handleClickOnMode,
}) => {
  const dispatch = useDispatch();

  return (
    <ContactModeContainer>
      <CustomerContactListHeader
        closeCustomerInfo={closeCustomerInfo}
        handleClickOnMode={handleClickOnMode}
      />
      <SearchContainer>
        <TextInput
          hideBottomSpace
          icon={<i className="ri-search-line" />}
          iconRight
          value={search}
          onChange={(ev) => {
            handleOnChange(ev);
          }}
          placeholder={i18n.t('slideouts-Contacts-search', {
            defaultValue: 'Search Contacts',
          })}
        />
      </SearchContainer>
      {contactList.length > 0 &&
        contactList.map((contact) => {
          const name = contactName(contact);
          const jobTitle = contact?.jobTitle;

          return (
            <SectionContainer key={contact.id}>
              <EntityCard
                avatarProps={{ size: 'lg', type: 'external' }}
                avatarChildren={name}
                maintext={name}
                subtext={jobTitle}
                onClick={() => {
                  dispatch(
                    setActiveCustomerInfoSlideout({
                      activeSlideoutEntityId: contact.id,
                      activeSlideoutEntityType: contact.__typename,
                    })
                  );
                }}
                customMaintextStyle={customTextStyle}
                customSubtextStyle={customTextStyle}
                customContainerStyle={() =>
                  'width: 100%;   cursor: pointer; border-bottom: 0px;'
                }
              />
            </SectionContainer>
          );
        })}
    </ContactModeContainer>
  );
};

CustomerAccountContactMode.propTypes = {
  contactList: PropTypes.array.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  closeCustomerInfo: PropTypes.func.isRequired,
  handleClickOnMode: PropTypes.func.isRequired,
};

export default CustomerAccountContactMode;
